import React from "react";

export const RadioCheckIcon = () => (
  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4.71875" cy="4" r="4" fill="white" />
  </svg>
);

export const CheckIcon = () => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.47149 6.52864L10.6667 0.333374L11.6096 1.27618L4.47149 8.41426L0.666748 4.60952L1.60956 3.66671L4.47149 6.52864Z"
      fill="#E8E8E8"
    />
  </svg>
);

// TODO: fix names
export const CheckBigIcon = () => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70711 9.29289L15 0L16.4142 1.41421L5.70711 12.1213L0 6.41421L1.41421 5L5.70711 9.29289Z"
      fill="#08D74F"
    />
  </svg>
);
