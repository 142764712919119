import { useState } from "react";

import { useIcons } from "@/domains/icons/icon.provider";

import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from "../Command/Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import * as Styled from "./ComboBox.styled";
import type { ComboBoxProps } from "./ComboBox.types";

const customFilter = (value: string, search: string): number => (value.includes(search.toLocaleLowerCase()) ? 1 : 0);

export const ComboBox = ({
  value,
  setValue,
  optionList = [],
  avoidCollisions = true,
  side,
  // buttonSize = "m",
  emptyMessage,
  inputProps,
}: ComboBoxProps) => {
  const { IconTick } = useIcons();
  const [open, setOpen] = useState(false);

  const currentItem = optionList.find(option => option.label.toLowerCase() === value.toLowerCase());

  const handleSelectItem = (currentValue: string) => {
    const normalizeValue = currentValue.split(":")[0]!;

    setValue(normalizeValue === value.toLowerCase() ? "" : normalizeValue);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Styled.Button aria-expanded={open}>
          <Styled.IconStart>
            {currentItem?.icon && <img src={currentItem.icon} alt={currentItem.label} width={20} height={20} />}
          </Styled.IconStart>
          <span>{currentItem?.value || "+1"}</span>
          {/*{buttonSize === "m" && (*/}
          {/*  <Styled.IconEnd isIcon className="opacity-50">*/}
          {/*    {open ? <IconChevronDown /> : <IconChevronUp />}*/}
          {/*  </Styled.IconEnd>*/}
          {/*)}*/}
        </Styled.Button>
      </PopoverTrigger>
      <PopoverContent align="start" side={side} avoidCollisions={avoidCollisions} sideOffset={12} className="w-[271px]">
        <Command filter={customFilter}>
          <CommandInput placeholder={inputProps?.placeholder ?? "Search"} className="w-full" />
          <CommandEmpty>{emptyMessage}</CommandEmpty>
          <CommandList>
            {currentItem && (
              <CommandItem selected value={`${currentItem.label}:${currentItem.value}`} onSelect={handleSelectItem}>
                <Styled.ItemContent>
                  <img src={currentItem.icon} alt={currentItem.label} width={20} height={20} />
                  <Styled.CountryValue>{currentItem.value}</Styled.CountryValue>
                </Styled.ItemContent>

                <Styled.CountryName>{currentItem.label}</Styled.CountryName>

                <Styled.Icon>
                  <IconTick />
                </Styled.Icon>
              </CommandItem>
            )}

            {optionList.map((option, index) => {
              if (option.value === currentItem?.value) {
                return;
              }
              return (
                <CommandItem
                  key={`${option.value}:${index}`}
                  value={`${option.label}:${option.value}`}
                  onSelect={handleSelectItem}
                >
                  <Styled.ItemContent>
                    <img src={option.icon} alt={option.label} width={20} height={20} />
                    <Styled.CountryValue>{option.value}</Styled.CountryValue>
                  </Styled.ItemContent>
                  <Styled.CountryName selected={value === option.label}>{option.label}</Styled.CountryName>
                  {value === option.label && (
                    <Styled.Icon>
                      <IconTick />
                    </Styled.Icon>
                  )}
                </CommandItem>
              );
            })}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
