import { setUserContext } from "@/app/libs/sentry";
import { getProfile, getSettings } from "@/services/profile";
import type { ApiResponseType } from "@/services/types";

import { useBaseQuery } from "..";
import type { QueryOptionsType } from "../types";
import { profileQueryKeys } from "./profile.keys";

export const useProfileHeaderQuery = (options?: QueryOptionsType<ApiResponseType<typeof getProfile>>) => {
  return useBaseQuery<ApiResponseType<typeof getProfile>>({
    queryKey: profileQueryKeys.header(),
    queryFn: () => getProfile(),
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: setUserContext,
    ...options,
  });
};

export const useProfileSettingsQuery = (options?: QueryOptionsType<ApiResponseType<typeof getSettings>>) => {
  return useBaseQuery<ApiResponseType<typeof getSettings>>({
    queryKey: profileQueryKeys.settings(),
    queryFn: getSettings,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};
