import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const _Button = forwardRef<ElementRef<"button">, ComponentPropsWithoutRef<"button">>(
  ({ className, type = "button", ...props }, ref) => (
    <button
      ref={ref}
      type={type}
      className={cn(
        "grid select-none place-items-center text-contrast-primary transition-colors hover:text-contrast-secondary disabled:pointer-events-none disabled:text-contrast-tertiary group-data-[disabled=true]/input-wrapper:pointer-events-none group-data-[disabled=true]/input-wrapper:text-contrast-tertiary",
        className,
      )}
      {...props}
    />
  ),
);

export { _Button };
