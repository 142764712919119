import { SearchInput } from "@/components/form/inputs/search";
import { Table } from "@/components/table";
import type { Notification } from "@/services/openapi";
import { useNotificationsQuery } from "@/state/server/notifications";

import { useNotificationsTableFilters } from "./notification-table.helpers";
import { NotificationTableRow } from "./notification-table-row";

export const NotificationsTableContainer = () => {
  const { changePage, pageSize, currentPage, search, updateSearch, mask } = useNotificationsTableFilters();
  const { data } = useNotificationsQuery({ pageSize, page: currentPage, mask });

  return (
    <>
      <SearchInput value={search} onChange={updateSearch} size="small" />
      {data && (
        <Table<Notification>
          items={data.items!}
          renderBodyRow={notification => <NotificationTableRow key={notification.id} notification={notification} />}
          emptyMessage={<></>}
          changePage={changePage}
          currentPage={currentPage}
          pageSize={pageSize}
          total={data.total!}
        />
      )}
    </>
  );
};
