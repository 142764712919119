import { type ComponentProps, type FC } from "react";

import { Leverage } from "@/entities/accounts/leverage";

type Props = ComponentProps<typeof Leverage>;

const LeverageBadge: FC<Props> = props => {
  return (
    <div className="typography-XS-Regular rounded-[2px] bg-contrast-quinary px-1 text-contrast-secondary">
      <Leverage {...props} />
    </div>
  );
};

export { LeverageBadge };
