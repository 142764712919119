import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { BottomSheet, BottomSheetContent } from "@/components/bottom-sheet";

import { LanguagesList } from "../components/languages-list";
import type { LanguageDialogProps } from "../types";

export const LanguageSwitcherBottomSheet: FC<LanguageDialogProps> = ({
  open,
  languagesInfo,
  onOpenChange,
  onLocaleChange,
}) => {
  const { t } = useTranslation();

  return (
    <BottomSheet open={open} onOpenChange={onOpenChange}>
      <BottomSheetContent title={t("language-switcher.title")!}>
        <LanguagesList languagesInfo={languagesInfo} onChange={onLocaleChange} />
      </BottomSheetContent>
    </BottomSheet>
  );
};
