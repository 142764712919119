import { type FC } from "react";

import { SetBalanceDialogContent } from "@/features/dashboard/components/set-balance-button/dialog-content";
import { Dialog } from "@/shared/ui";

import { useTerminalAccountContext } from "../../contexts/account.context";
import { useLayoutContext } from "../context";

const DemoDepositDialog: FC = () => {
  const { demoDialogOpen, closeDemoDialog } = useLayoutContext();
  const { account } = useTerminalAccountContext();

  return (
    <Dialog open={demoDialogOpen} onOpenChange={closeDemoDialog}>
      <SetBalanceDialogContent accountId={account.id!} accountCurrency={account.currency!} onClose={closeDemoDialog} />
    </Dialog>
  );
};

export { DemoDepositDialog };
