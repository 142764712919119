import "./index.css";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";

import App from "./App";

// FIXME: пришлось перенести инициализацию i18n в main.tsx, так как в отдельном файле это говно перестало работать
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
      lookupQuerystring: "locale",
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
