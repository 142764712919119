import { type ChangeEvent, type ComponentPropsWithoutRef, memo, useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import { TextPhone } from "@/components/form";
import type { PlatformCountry } from "@/services/openapi";
import { usePlatformCountriesQuery } from "@/state/server/platform";

import { useField } from "../../field.hook";

export type TextPhoneFieldProps = Partial<ComponentPropsWithoutRef<typeof TextPhone>> & {
  namePhone: string;
  nameCode: string;
  nameCountry: string;
  errors?: Record<string, string>;
  defaultCountry?: string;
};

const getCorrectNullData = <T,>(value: T) => (value === null ? undefined : value);

// TODO: Нужено разобраться(упростить/сделать правильнее) с 3мя name
const _TextPhoneField = ({
  namePhone,
  nameCode,
  nameCountry,
  errors,
  inputProps,
  defaultCountry,
  ...restProps
}: TextPhoneFieldProps) => {
  const { data: countries } = usePlatformCountriesQuery();
  const { error, showError } = useField(namePhone);
  const { setValue } = useFormContext();

  const [country, setCountry] = useState<PlatformCountry>({});

  useEffect(() => {
    const allCountries = [...(countries?.countries || []), ...(countries?.unsupportedCountries || [])];
    const selectedCountry =
      allCountries.filter(el => el.code === defaultCountry || el.country === defaultCountry)[0] ||
      allCountries.filter(el => el.numberCode === "+1")[0] ||
      allCountries[0] ||
      {};

    // NOTE: setValue необходимы потому что в TextPhoneField сразу задействовано 3 филда
    setValue(nameCode, selectedCountry.numberCode);
    setValue(nameCountry, selectedCountry.code);
    setCountry(selectedCountry);
  }, [defaultCountry]);

  const minLength = getCorrectNullData(country.minLength);

  const { field } = useController({
    name: namePhone,
    rules: {
      required: errors?.required,
      // TODO: по возможности в будущем переписать с validate на minLength, либо заменить mode: "onChange" на mode: "onBlur" и оставить validate
      // NOTE: в данный момент не получиться использовать minLength,
      // потому что PatternFormat внутри TextPhone возвращает строку с пробелами
      // пример:
      // маска ## # ##
      // ввели 12345 = 5 символов
      // получили строку "12 3 45" = 7 символов
      // ––––––––––––––––––––––––––––––––––––––
      // minLength: !isNil(minLength)
      //   ? {
      //       value: minLength,
      //       message: errors?.minLength,
      //     }
      //   : undefined,
      validate: (value: string) => {
        if (minLength && value.replaceAll(" ", "").length < minLength) {
          return errors?.minLength;
        }

        return undefined;
      },
    },
  });

  const handleChange = (_value: string, event?: ChangeEvent<HTMLInputElement>) => {
    event && field.onChange(event);
  };

  const updateCountry = (value: PlatformCountry) => {
    setCountry(value);
    setValue(nameCode, value.numberCode);
    setValue(nameCountry, value.code);
  };

  return (
    <TextPhone
      {...restProps}
      name={namePhone}
      inputProps={{
        ...inputProps,
        ...field,
        onChange: handleChange,
      }}
      countries={countries}
      errorMessage={error?.message}
      showError={showError}
      country={country}
      setCountry={updateCountry}
    />
  );
};

export const TextPhoneField = memo(_TextPhoneField);
