import { type FC, memo } from "react";

import { DemoDepositDialog } from "./demo-deposit";
import { InsightsDialog } from "./insights";
import { MobileTradingDialog } from "./mobile-trading";

const _TerminalDialogsContainer: FC = () => {
  return (
    <>
      <DemoDepositDialog />
      <InsightsDialog />
      <MobileTradingDialog />
    </>
  );
};

const Component = memo(_TerminalDialogsContainer);

export { Component as TerminalDialogsContainer };
