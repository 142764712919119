import type { FC } from "react";

import { PopupActionButton } from "@/app/libs/firebase";
import { BottomSheet, BottomSheetContent } from "@/components/bottom-sheet";

import type { Props } from "../types";

export const DemoToRealBottomSheet: FC<Props> = ({ open, content: { buttons, content }, onClose, onButtonClick }) => {
  const { title, text, imagesUrls } = content!;
  const image = imagesUrls![0]!;

  return (
    <BottomSheet open={open} onClose={onClose}>
      <BottomSheetContent title={title!} description={text} image={{ src: image }}>
        <div className="grid gap-1">
          {buttons?.map(button => {
            return (
              <PopupActionButton
                key={button.text}
                action={button.action}
                color={button.color}
                text={button.text}
                className="mx-auto block w-full max-w-[300px] text-center"
                size="lg"
                onClick={onButtonClick}
              >
                {button.text}
              </PopupActionButton>
            );
          })}
        </div>
      </BottomSheetContent>
    </BottomSheet>
  );
};
