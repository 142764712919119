import type { FC, ReactNode } from "react";

import type { OnboardingStep } from "@/features/onboarding/helpers";

import { ProgressBar } from "./progress-bar/progress-bar";

type Props = {
  steps: OnboardingStep[];
  currentStepId: string;
  children: ReactNode;
};

const SurveyWrapper: FC<Props> = ({ steps, currentStepId, children }) => {
  return (
    <div className="mx-auto w-full px-4 md:mt-10 md:max-w-[500px]">
      <ProgressBar steps={steps} currentStepId={currentStepId} />

      {children}
    </div>
  );
};

export { SurveyWrapper };
