import type { FC } from "react";
import { memo } from "react";

import { useLayoutContext } from "./layout/context";
import { TerminalDesktop } from "./terminal-desktop";
import { TerminalMobile } from "./terminal-mobile";

const _Terminal: FC = () => {
  const { isMobile } = useLayoutContext();

  return isMobile ? <TerminalMobile /> : <TerminalDesktop />;
};

const Terminal = memo(_Terminal);

Terminal.displayName = "Terminal";

export { Terminal };
