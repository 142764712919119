import type { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";

import { TradingTable } from "../table";

type Props = {
  currency: string;
};

const ClosedPositionsHeaderRow: FC<Props> = ({ currency }) => {
  const { t } = useTranslation();

  return (
    <TradingTable.Row showBorder>
      <TradingTable.Head>{t("terminal.open-time")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.symbol")}</TradingTable.Head>
      <TradingTable.Head>{t("common.type")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.volume-lot")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.order")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.take-profit")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.stop-loss")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.close-time")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.close-price")}</TradingTable.Head>
      <TradingTable.Head align="end">
        {t("terminal.swap")}, {currency}
      </TradingTable.Head>
      <TradingTable.Head align="end">
        {t("terminal.profit")}, {currency}
      </TradingTable.Head>
    </TradingTable.Row>
  );
};

export { ClosedPositionsHeaderRow };
