import { useCallback, useEffect, useRef, useState } from "react";

import { useTimeout } from "./timeout.hook";

export function useHover<T extends HTMLElement = HTMLDivElement>({ delay = 0 }: { delay?: number } = {}) {
  const [hovered, setHovered] = useState(false);
  const ref = useRef<T>(null);

  const { start, clear } = useTimeout(() => setHovered(true), delay);

  const onMouseEnter = useCallback(() => start(), [start]);
  const onMouseLeave = useCallback(() => {
    setHovered(false);
    clear();
  }, [clear]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("mouseenter", onMouseEnter);
      ref.current.addEventListener("mouseleave", onMouseLeave);

      return () => {
        ref.current?.removeEventListener("mouseenter", onMouseEnter);
        ref.current?.removeEventListener("mouseleave", onMouseLeave);
      };
    }

    return undefined;
  }, []);

  return { ref, hovered };
}
