const tokenName = "dotoJwtToken";

class Token {
  getToken() {
    return window.localStorage.getItem(tokenName);
  }
  setToken(token: string) {
    window.localStorage.setItem(tokenName, token);
  }
  clearToken() {
    window.localStorage.removeItem(tokenName);
  }
  getHeaderString() {
    const token = this.getToken();
    if (token) {
      return `Bearer ${token}`;
    }
    return "";
  }
}

export const token = new Token();
