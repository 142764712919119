export const AppleIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_843_1236)">
        <path
          d="M20.6018 17.6447C20.2607 18.4133 19.8569 19.1208 19.3891 19.7713C18.7514 20.658 18.2292 21.2719 17.8268 21.6127C17.2031 22.1722 16.5347 22.4587 15.8191 22.475C15.3053 22.475 14.6857 22.3324 13.9645 22.0432C13.2409 21.7553 12.5759 21.6127 11.9678 21.6127C11.3301 21.6127 10.6462 21.7553 9.91467 22.0432C9.18202 22.3324 8.5918 22.4832 8.14054 22.4981C7.45425 22.5266 6.77019 22.2319 6.08738 21.6127C5.65158 21.242 5.10647 20.6064 4.45346 19.7061C3.75283 18.7446 3.17681 17.6297 2.72555 16.3587C2.24227 14.9857 2 13.6563 2 12.3692C2 10.8948 2.32665 9.62321 2.98091 8.5576C3.4951 7.70166 4.17917 7.02647 5.03532 6.53081C5.89148 6.03514 6.81655 5.78256 7.81278 5.7664C8.35789 5.7664 9.07272 5.93085 9.96104 6.25405C10.8468 6.57834 11.4156 6.74279 11.665 6.74279C11.8514 6.74279 12.4833 6.5505 13.5544 6.16714C14.5673 5.81162 15.4222 5.66441 16.1226 5.7224C18.0204 5.87178 19.4461 6.60142 20.3943 7.91595C18.6971 8.91896 17.8575 10.3238 17.8742 12.126C17.8895 13.5297 18.4116 14.6979 19.4378 15.6254C19.9028 16.0558 20.4222 16.3885 21 16.6248C20.8747 16.9793 20.7424 17.3188 20.6018 17.6447ZM16.2493 0.940125C16.2493 2.04038 15.8372 3.06768 15.0157 4.01854C14.0243 5.14892 12.8252 5.80211 11.5249 5.69904C11.5084 5.56705 11.4987 5.42812 11.4987 5.28214C11.4987 4.2259 11.9702 3.09552 12.8074 2.17127C13.2254 1.70331 13.757 1.31421 14.4017 1.00381C15.0449 0.69805 15.6534 0.528957 16.2256 0.5C16.2423 0.647086 16.2493 0.794182 16.2493 0.940111V0.940125Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_843_1236">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
