import { darkThemeSelector, styled } from "@/styles";

export const Alert = styled("div", {
  background: "#FFE8E8", // FIXME:
  borderRadius: "16px",
  p: "16px 24px",

  fontFamily: "$roboto",
  fontWeight: "$normal",
  lineHeight: "$1",
  color: "$textMain",

  [darkThemeSelector]: {
    background: "$negativeDefault",
  },
});
