import { produce } from "immer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import type { ProfileHeader } from "@/services/openapi";
import { profileQueryKeys } from "@/state/server/profile";
import { useDisableTargetPriceMutation, useEnableTargetPriceMutation } from "@/state/server/profile/profile.mutations";

// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartingLibraryWidget/#createbutton

const useTargetPriceButton = ({
  isTerminalTargetPricesEnabled,
  hasSignal,
}: {
  isTerminalTargetPricesEnabled: boolean;
  hasSignal: boolean;
}) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [button, setButton] = useState<HTMLElement>();

  const { mutate: enableTargetPrice, isLoading: enableIsLoading } = useEnableTargetPriceMutation({
    onSuccess: () => {
      queryClient.setQueryData<ProfileHeader>(profileQueryKeys.header(), oldData => {
        return produce(oldData, draft => {
          draft!.options!.isTerminalTargetPricesEnabled = true;
        })!;
      });
    },
  });
  const { mutate: disableTargetPrice, isLoading: disableIsLoading } = useDisableTargetPriceMutation({
    onSuccess: () => {
      queryClient.setQueryData<ProfileHeader>(profileQueryKeys.header(), oldData => {
        return produce(oldData, draft => {
          draft!.options!.isTerminalTargetPricesEnabled = false;
        })!;
      });
    },
  });

  const isLoading = enableIsLoading || disableIsLoading;

  useEffect(() => {
    window.tvWidget.headerReady().then(() => {
      const button = window.tvWidget.createButton({ align: "left", useTradingViewStyle: false });
      button.innerHTML = t("terminal.chart.target-button");
      button.classList.add("target-price-button");

      if (!hasSignal) {
        button.dataset.state = "none";
      }

      setButton(button);
    });
  }, []);

  useEffect(() => {
    if (!button) return;

    if (!hasSignal) {
      button.dataset.state = "none";
      button.onclick = () => {};
      return;
    }

    if (isLoading) {
      button.dataset.state = "disabled";
      button.onclick = () => {};
      return;
    }

    if (isTerminalTargetPricesEnabled) {
      button.dataset.state = "active";
      button.onclick = () => {
        disableTargetPrice(undefined);
      };
      return;
    }

    button.dataset.state = "idle";
    button.onclick = () => {
      enableTargetPrice(undefined);
    };
  }, [button, isTerminalTargetPricesEnabled, isLoading, hasSignal, disableTargetPrice, enableTargetPrice]);
};

export { useTargetPriceButton };
