import { type ComponentProps, type FC } from "react";

import { LanguageSwitcherContainer } from "@/features/language-switcher";
import { useDisclosure } from "@/hooks/disclosure.hook";

import { SidebarContent } from "./sidebar-content";

type Props = Pick<ComponentProps<typeof SidebarContent>, "initiallyCollapsed" | "onDeposit">;

const Sidebar: FC<Props> = props => {
  const [opened, { onOpenChange, open }] = useDisclosure();

  return (
    <>
      <SidebarContent {...props} onLanguageSelect={open} />
      <LanguageSwitcherContainer onOpenChange={onOpenChange} open={opened} />
    </>
  );
};

export { Sidebar };
