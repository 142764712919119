import { useEffect, useState } from "react";

// https://www.tradingview.com/charting-library-docs/latest/saving_loading/#additional-use-cases

const useRefreshChartLines = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const dataLoadedCallback = () => {
      setCount(c => c + 1);
    };

    const subscription = window.tvWidget.activeChart().onDataLoaded();

    subscription.subscribe(null, dataLoadedCallback);

    return () => subscription.unsubscribe(null, dataLoadedCallback);
  }, []);

  return count;
};

export { useRefreshChartLines };
