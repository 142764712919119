import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { NoContentMessage } from "@/components/no-content-message";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { CabinetPage } from "../cabinet/components/cabinet-page";
import { CabinetTitle } from "../cabinet/components/cabinet-title";
import { CreateAccountButton } from "../dashboard/components/create-account/create-account-button";
import { TransferContainer } from "./transfer.container";

export const TransferPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.Active,
  });

  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.transfer")}>
          <CabinetTitle.DepositButton />
          <CabinetTitle.TradeButton />
        </CabinetTitle>
      }
    >
      {accounts && (
        <>
          {accounts.length < 2 ? (
            <NoContentMessage title={t("transfer.no-items.title")} description={t("transfer.no-items.description")}>
              <CreateAccountButton
                type={TradingAccountType.Real}
                onClick={() => navigate(cabinetRoutes.createAccount)}
              />
            </NoContentMessage>
          ) : (
            <TransferContainer accounts={accounts} />
          )}
        </>
      )}
    </CabinetPage>
  );
};
