import { type FC, Fragment } from "react";

import type { TerminalSymbolSignal } from "@/services/openapi";

import type { MergedTerminalSymbol } from "../helpers/symbols";
import { useLayoutContext } from "../layout/context";
import { useSignalContext } from "../signal/context";
import type { ChartColors } from "./config/colors";
import { useChartContext } from "./context";
import { useChartAutoSave } from "./hooks/auto-save.hook";
import { useChartToggleButton } from "./hooks/chart-toggle-button.hook";
import { useHandleChartTheme } from "./hooks/handle-theme.hook";
import { useRefreshChartLines } from "./hooks/refresh-lines.hook";
import { useTargetPriceButton } from "./hooks/target-price-button.hook";
import { useUpdateChartSymbol } from "./hooks/update-symbol.hook";
import { ChartOrders } from "./orders";
import { SignalChartLine } from "./signal/chart-line";
import { TargetPriceLine } from "./signal/target-price-line";

type Props = {
  symbolInfo: MergedTerminalSymbol;
  chartColors: ChartColors;
  accountId: string;
  accountCurrency: string;
  isDarkTheme: boolean;
  isMobile: boolean;
  isChartExpanded: boolean;
  currencyDecimalScale: number;
  signal: TerminalSymbolSignal | undefined;
  setIsChartExpanded: ReturnType<typeof useLayoutContext>["setIsChartExpanded"];
  setChartInfo: ReturnType<typeof useChartContext>["setChartInfo"];
  isSignalLinesShown: ReturnType<typeof useSignalContext>["isSignalLinesShown"];
  chartLines: ReturnType<typeof useSignalContext>["chartLines"];
  hasSignal: ReturnType<typeof useSignalContext>["hasSignal"];
  showSignalChartDialog: ReturnType<typeof useSignalContext>["showSignalChartDialog"];
  isSignalChartDialogShown: ReturnType<typeof useSignalContext>["isSignalChartDialogShown"];
  isTerminalTargetPricesEnabled: ReturnType<typeof useSignalContext>["isTerminalTargetPricesEnabled"];
};

const Widget: FC<Props> = ({
  symbolInfo,
  chartColors,
  accountCurrency,
  accountId,
  isDarkTheme,
  isMobile,
  isChartExpanded,
  setIsChartExpanded,
  setChartInfo,
  currencyDecimalScale,
  isSignalLinesShown,
  chartLines,
  hasSignal,
  signal,
  showSignalChartDialog,
  isSignalChartDialogShown,
  isTerminalTargetPricesEnabled,
}) => {
  useTargetPriceButton({ isTerminalTargetPricesEnabled, hasSignal });
  useChartAutoSave({ accountId });
  useHandleChartTheme({ chartColors, isChartExpanded, isDarkTheme });
  useChartToggleButton({ isChartExpanded, isMobile, setChartInfo, setIsChartExpanded });
  useUpdateChartSymbol(symbolInfo.symbol!);
  const refreshKey = useRefreshChartLines();

  // needed to prevent situations when chart symbol !== selected symbol
  // it happens because app symbol changing acts immediately, while tv symbol changing triggers after loading chart data
  if (symbolInfo.symbol !== window.tvWidget.activeChart().symbol()) {
    return null;
  }

  return (
    <Fragment key={refreshKey}>
      <ChartOrders
        symbolInfo={symbolInfo}
        accountCurrency={accountCurrency}
        accountId={accountId}
        currencyDecimalScale={currencyDecimalScale}
        chartColors={chartColors}
      />
      {isSignalLinesShown &&
        chartLines.map(info => <SignalChartLine key={info.price} signalInfo={info} chartColors={chartColors} />)}
      {isTerminalTargetPricesEnabled && hasSignal && signal && (
        <TargetPriceLine
          isSignalChartDialogShown={isSignalChartDialogShown}
          chartColors={chartColors}
          signal={signal}
          showSignalChartDialog={showSignalChartDialog}
        />
      )}
    </Fragment>
  );
};

Widget.displayName = "ChartWidget";

export { Widget as ChartWidget };
