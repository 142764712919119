import { useEffect } from "react";

const useUpdateChartSymbol = (symbol: string) => {
  useEffect(() => {
    const { interval } = window.tvWidget.symbolInterval();

    // we need to close all dialogs in order to load correct layouts for selected symbol when clicking "save" button
    window.tvWidget.closePopupsAndDialogs();

    window.tvWidget.setSymbol(symbol, interval, () => {});
  }, [symbol]);
};

export { useUpdateChartSymbol };
