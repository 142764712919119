import { useEffect } from "react";

import type { ChartColors } from "../config/colors";
import { getChartOverrides } from "../config/overrides";

// https://www.tradingview.com/charting-library-docs/latest/customization/#theme
// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartingLibraryWidget/#changetheme
// https://www.tradingview.com/charting-library-docs/latest/core_concepts/widget-methods/#changetheme

const useHandleChartTheme = ({
  isDarkTheme,
  isChartExpanded,
  chartColors,
}: {
  isDarkTheme: boolean;
  isChartExpanded: boolean;
  chartColors: ChartColors;
}) => {
  useEffect(() => {
    window.tvWidget.changeTheme(isDarkTheme ? "dark" : "light", { disableUndo: true }).then(() => {
      window.tvWidget.applyOverrides(getChartOverrides({ chartColors, isChartExpanded }));
    });
  }, [isDarkTheme, chartColors]);
};

export { useHandleChartTheme };
