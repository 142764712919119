import { type FC } from "react";

import { useTerminalAccountSummary } from "@/features/terminal/account-summary/context";
import { useTranslation } from "@/hooks/translator.hook";

import { TradingTable } from "../table";
import { CloseAllOpenOrdersButton } from "./close-all-button";

type Props = {
  ordersCount: number;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
};

const OpenOrdersHeaderRow: FC<Props> = ({ ordersCount, currency, accountId, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const { pnl, swaps } = useTerminalAccountSummary();

  return (
    <TradingTable.Row showBorder>
      <TradingTable.Head>{t("terminal.symbol")}</TradingTable.Head>
      <TradingTable.Head>{t("common.type")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.volume-lot")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.margin-currency", { currency })}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.current-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.take-profit")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.stop-loss")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-time")}</TradingTable.Head>
      <TradingTable.Head align="end">
        {t("terminal.swap")}, {currency}
      </TradingTable.Head>
      <TradingTable.Head align="end">{t("terminal.pnl-currency", { currency })}</TradingTable.Head>
      <TradingTable.Head align="end">
        {ordersCount > 1 && (
          <CloseAllOpenOrdersButton
            accountId={accountId}
            pnl={pnl}
            ordersCount={ordersCount}
            swaps={swaps}
            currency={currency}
            currencyDecimalScale={currencyDecimalScale}
          />
        )}
      </TradingTable.Head>
    </TradingTable.Row>
  );
};

export { OpenOrdersHeaderRow };
