import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import type { PaymentMethod } from "@/services/openapi";

import { PaymentNoAccounts } from "../../components/no-accounts-state";
import { PaymentEmptyState } from "../../ui/empty-state";

type Props = {
  paymentMethods: PaymentMethod[];
};

const DepositNoAccounts: FC<Props> = ({ paymentMethods }) => {
  const { t } = useTranslation();

  return (
    <PaymentNoAccounts paymentMethods={paymentMethods}>
      <PaymentEmptyState
        title={t("deposit.info.create-profile.title")}
        description={t("deposit.info.create-profile.text-1")}
      >
        <PaymentEmptyState.Button asChild>
          <Link to={cabinetRoutes.createAccount}>{t("deposit.info.create-profile.button")}</Link>
        </PaymentEmptyState.Button>
      </PaymentEmptyState>
    </PaymentNoAccounts>
  );
};

export { DepositNoAccounts };
