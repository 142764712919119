import { type FC } from "react";

import { AccountSelect } from "./account-select/account-select";
import { AccountSummaryContainer } from "./account-summary/container";
import { ChartContainer } from "./chart/container";
import { ChartSymbolsContainer } from "./chart-symbols/container";
import { DepositButtonContainer } from "./deposit/container";
import { TerminalTableState, useLayoutContext } from "./layout/context";
import { DesktopPlaceOrderBlock } from "./place-order/desktop/block";
import { TerminalDesktopFrame } from "./terminal-desktop.frame";
import { DesktopTradingTablesBlock } from "./trading-tables/desktop/block";

const TerminalDesktop: FC = () => {
  const { table } = useLayoutContext();

  const isTableExpanded = table !== TerminalTableState.NONE;

  return (
    <TerminalDesktopFrame
      isTableExpanded={isTableExpanded}
      chartSymbolsSlot={<ChartSymbolsContainer />}
      accountSelectSlot={<AccountSelect centered />}
      depositButtonSlot={<DepositButtonContainer />}
      chartSlot={<ChartContainer />}
      placeOrderSlot={<DesktopPlaceOrderBlock />}
      tablesSlot={<DesktopTradingTablesBlock />}
      accountSummarySlot={<AccountSummaryContainer />}
    />
  );
};

export { TerminalDesktop };
