import { produce } from "immer";
import { type ComponentProps, type FC, memo } from "react";
import { useQueryClient } from "react-query";

import { IconStar, IconStarPicked } from "@/domains/icons";
import type { TerminalAccountSymbols } from "@/services/openapi";
import {
  terminalQueryKeys,
  useAddSymbolToFavoritesMutation,
  useRemoveSymbolFromFavoritesMutation,
} from "@/state/server/terminal";

type Props = {
  isFavorite: boolean;
  symbol: string;
  accountId: string;
};

const WatchlistFavoriteButton: FC<Props> = ({ isFavorite, symbol, accountId }) => {
  const queryClient = useQueryClient();

  const { mutate: addFavorite, isLoading: addLoading } = useAddSymbolToFavoritesMutation();
  const { mutate: removeFavorite, isLoading: removeLoading } = useRemoveSymbolFromFavoritesMutation();

  const disabled = addLoading || removeLoading;

  const toggleFavorite: ComponentProps<"button">["onClick"] = event => {
    event.stopPropagation();
    if (isFavorite) {
      removeFavorite({ symbol, tradingAccountId: accountId });
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        const favoriteIndex = oldData!.favorites!.findIndex(item => item === symbol);
        return produce(oldData!, draft => {
          draft.favorites!.splice(favoriteIndex, 1);
        })!;
      });
      return;
    }
    addFavorite({ symbol, tradingAccountId: accountId });
    queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
      return produce(oldData!, draft => {
        draft.favorites!.push(symbol);
      })!;
    });
  };

  return (
    <button
      onClick={toggleFavorite}
      disabled={disabled}
      type="button"
      className="grid size-9 place-items-center rounded-full text-contrast-secondary outline-none transition-colors hover:bg-contrast-quaternary"
    >
      {isFavorite ? <IconStarPicked /> : <IconStar />}
    </button>
  );
};

const Component = memo(WatchlistFavoriteButton);

export { Component as WatchlistFavoriteButton };
