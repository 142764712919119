import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const Symbol = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>((props, ref) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
    <path
      d="M10.0797 6.04163C10.0797 8.82604 7.8233 11.0833 5.03986 11.0833C2.25642 11.0833 0 8.82604 0 6.04163C0 3.25722 2.25642 1 5.03986 1C7.8233 1 10.0797 3.25722 10.0797 6.04163Z"
      fill="currentColor"
    />
    <path
      d="M23.237 12.241C23.237 18.4488 18.2065 23.4819 12 23.4819V1C18.2065 1 23.2379 6.03227 23.237 12.241Z"
      fill="currentColor"
    />
  </svg>
));

const Word = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>((props, ref) => (
  <svg width="52" height="18" viewBox="0 0 52 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
    <path
      d="M10.0372 5.05164C10.0372 3.66967 10.0372 2.33705 10.0372 0.955078C11.074 0.955078 12.1107 0.955078 13.1969 0.955078C13.1969 6.18684 13.1969 11.4186 13.1969 16.6503C12.1601 16.6503 11.1233 16.6503 10.0372 16.6503C10.0372 16.4529 10.0372 16.2061 10.0372 15.9594C9.88905 16.0581 9.79031 16.1074 9.69156 16.1568C7.61798 17.1933 5.49501 17.3907 3.37205 16.3542C1.59469 15.4658 0.508528 13.8864 0.16293 11.9122C-0.232039 9.93791 0.0641881 8.01301 1.34784 6.38426C2.53274 4.85422 4.11262 4.01516 6.13684 4.01516C7.46986 4.01516 8.75351 4.26194 9.93842 5.00229C9.93842 5.05164 9.98779 5.05164 10.0372 5.05164ZM6.72929 13.9358C8.65477 13.9358 10.0865 12.5044 10.0372 10.5302C10.0372 8.60529 8.60539 7.17396 6.67992 7.17396C4.75445 7.17396 3.32268 8.60529 3.37205 10.5795C3.37205 12.5044 4.75445 13.9358 6.72929 13.9358Z"
      fill="currentColor"
    />
    <path
      d="M28.4526 10.5302C28.4526 14.1825 25.6384 16.9958 21.9356 17.0452C18.2328 17.0452 15.468 14.2813 15.4186 10.5795C15.3692 7.02589 18.2328 4.06452 21.9356 4.06452C25.5891 4.01516 28.4526 6.92718 28.4526 10.5302ZM21.9356 13.9358C23.8611 13.9358 25.2928 12.5044 25.2928 10.5302C25.2928 8.55593 23.8611 7.1246 21.9356 7.17396C20.0101 7.17396 18.6277 8.60529 18.6277 10.5795C18.6277 12.5044 20.0595 13.9358 21.9356 13.9358Z"
      fill="currentColor"
    />
    <path
      d="M45.4856 4.01516C49.1391 4.01516 52.0026 6.92718 52.0026 10.5302C52.0026 14.1825 49.1391 17.0452 45.4363 16.9958C41.7828 16.9958 38.9686 14.1825 38.9686 10.4808C38.9686 6.87782 41.8322 4.01516 45.4856 4.01516ZM45.4363 13.9358C47.3617 13.9851 48.7935 12.5538 48.7935 10.6289C48.8429 8.704 47.4605 7.22332 45.535 7.17396C43.6095 7.1246 42.1778 8.50658 42.1284 10.4808C42.1284 12.4551 43.4614 13.8864 45.4363 13.9358Z"
      fill="currentColor"
    />
    <path
      d="M36.7469 13.837C36.7469 14.7748 36.7469 15.7126 36.7469 16.6997C35.957 16.7984 35.1671 16.8478 34.3771 16.7491C33.7353 16.6997 33.0935 16.601 32.501 16.2555C31.5136 15.7126 31.0693 14.8242 30.9212 13.7383C30.8718 13.1954 30.8224 12.6525 30.8224 12.1096C30.8224 8.50658 30.8224 4.95293 30.8224 1.34993C30.8224 1.20186 30.8224 1.10315 30.8224 0.955078C31.9086 0.955078 32.9454 0.955078 33.9822 0.955078C33.9822 2.09027 33.9822 3.22546 33.9822 4.36066C34.9202 4.36066 35.8089 4.36066 36.7469 4.36066C36.7469 5.39714 36.7469 6.38426 36.7469 7.42074C35.8583 7.42074 34.9202 7.42074 33.9822 7.42074C33.9822 7.56881 33.9822 7.66752 33.9822 7.76623C33.9822 9.29628 33.9822 10.8263 33.9822 12.3564C33.9822 12.5538 33.9822 12.7512 34.0315 12.9486C34.0809 13.3435 34.3278 13.6396 34.7227 13.7383C35.2164 13.837 35.7595 13.837 36.2532 13.8864C36.4013 13.837 36.5495 13.837 36.7469 13.837Z"
      fill="currentColor"
    />
  </svg>
));

const Container = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(({ className, ...props }, ref) => (
  <div className={cn("flex items-center gap-2", className)} {...props} ref={ref} />
));

const Logo = forwardRef<ElementRef<typeof Container>, ComponentPropsWithoutRef<typeof Container>>((props, ref) => (
  <Container {...props} ref={ref}>
    <Symbol />
    <Word />
  </Container>
));

const Component = Object.assign(Logo, { Symbol, Word, Container });

export { Component as Logo };
