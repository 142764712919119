import type { FC } from "react";

import { AccountCard } from "@/entities/accounts/card";
import { DemoAccountCard } from "@/entities/accounts/demo/card";
import { AvailabilityStatus, TradingAccountType } from "@/services/openapi";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { CreateAccountButton } from "../../components/create-account/create-account-button";
import { AccountsCardsWrapper } from "../cards-wrapper";
import { DemoAccountsEmptyState } from "./empty-state";

type Props = {
  onCreateAccount: (accountType: TradingAccountType) => void;
};

const DemoCardsContainer: FC<Props> = ({ onCreateAccount }) => {
  const { data, isFetching } = useAllAccountsQuery({
    type: TradingAccountType.Demo,
    status: AvailabilityStatus.Active,
  });

  return (
    <AccountsCardsWrapper
      cards={data}
      skeleton={<AccountCard.Skeleton rows={3} />}
      emptyState={<DemoAccountsEmptyState onCreateAccount={onCreateAccount} />}
      isFetching={isFetching}
      button={<CreateAccountButton secondary type={TradingAccountType.Demo} onClick={onCreateAccount} />}
    >
      {card => <DemoAccountCard key={card.id} account={card} />}
    </AccountsCardsWrapper>
  );
};

export { DemoCardsContainer };
