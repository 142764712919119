import Big from "big.js";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { DetailsTable as Table } from "@/components/details-table";
import CheckBottom from "@/features/payment/assets/check-bottom.svg";
import type { Field } from "@/services/openapi";

export type Props = {
  login: number;
  paymentMethod: {
    title: string;
    img: string;
  };
  amount: number;
  symbol: string;
  fee: number;
  bonusBalance: number;
  additionalFields?: Field[] | null;
  decimalScale: number;
  credit: number;
};

const cutWalletString = (input: string): string => {
  if (input.length <= 8) {
    return input;
  }

  return `${input.slice(0, 4)}...${input.slice(-4)}`;
};

const Check: FC<Props> = ({
  login,
  paymentMethod,
  amount,
  symbol,
  fee,
  bonusBalance,
  additionalFields,
  decimalScale,
  credit,
}) => {
  const { t } = useTranslation();
  const total = new Big(amount).minus(fee).toNumber();

  return (
    <>
      <div className="rounded-t-[2px] bg-surface-elevation-2 px-4 pb-1 pt-[30px] shadow">
        <Table>
          <Table.Row>
            <Table.Label>{t("payments.check.account-id")}</Table.Label>
            <Table.Value>{login}</Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("payments.payment-method")}</Table.Label>
            <Table.Value>
              <div className="flex items-center justify-end gap-1">
                <img src={paymentMethod.img} className="size-[16px]" />

                {paymentMethod.title}
              </div>
            </Table.Value>
          </Table.Row>
          {additionalFields?.map(({ id, title, value }) => {
            return (
              <Table.Row key={id}>
                <Table.Label>{title}</Table.Label>
                <Table.Value>{id === "purse" ? cutWalletString(value!) : value}</Table.Value>
              </Table.Row>
            );
          })}
          <Table.Row>
            <Table.Label>{t("payments.check.amount.withdrawal")}</Table.Label>
            <Table.Value>
              <NumberFormat
                className="mb-1 block text-end"
                value={amount}
                currency={symbol}
                decimalScale={decimalScale}
              />
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("payments.check.fee")}</Table.Label>
            <Table.Value>
              <NumberFormat className="mb-1 block text-end" value={fee} currency={symbol} decimalScale={decimalScale} />
            </Table.Value>
          </Table.Row>
          {!!bonusBalance && (
            <Table.Row>
              <Table.Label>{t("payments.check.bonus.withdrawal")}</Table.Label>
              <Table.Value>
                {!!credit && (
                  <span className="me-2 text-nowrap text-start text-contrast-secondary line-through">
                    <NumberFormat value={credit} decimalScale={decimalScale} />
                  </span>
                )}
                <span className="text-nowrap text-end">
                  <NumberFormat className="text-nowrap text-end" value={bonusBalance} decimalScale={decimalScale} />
                </span>
              </Table.Value>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Label>{t("payments.check.total")}</Table.Label>
            <Table.Value>
              <NumberFormat
                className="mb-1 block text-end"
                value={total}
                currency={symbol}
                decimalScale={decimalScale}
              />
            </Table.Value>
          </Table.Row>
        </Table>
      </div>

      <img className="w-full max-w-full" src={CheckBottom} />
    </>
  );
};

export { Check };
