import { cva } from "class-variance-authority";
import { type FC, type ReactNode } from "react";
import { NavLink, type To } from "react-router-dom";

import { cn } from "@/shared/styles";
import { Loader } from "@/shared/ui";

const buttonStyles = cva(
  "flex h-14 w-full items-center gap-3 rounded-[40px] px-4 font-gilroy text-[16px] font-bold leading-[1.2] text-accent-over transition-colors hover:bg-control-bg-active",
  {
    variants: {
      active: {
        true: "bg-control-bg-active",
      },
    },
  },
);

type BodyProps = {
  isCollapsed: boolean;
  startSection: ReactNode;
  title: ReactNode;
  endSection?: ReactNode;
  isLoading?: boolean;
};

const Body: FC<BodyProps> = ({ startSection, endSection, isCollapsed, isLoading, title }) => (
  <>
    {isLoading ? (
      <div className="w-full">
        <Loader />
      </div>
    ) : (
      <>
        <div className="grid size-6 shrink-0 place-items-center">{startSection}</div>
        <div
          className={cn(
            "truncate font-gilroy text-[16px] font-bold leading-[1.2] text-accent-over transition",
            isCollapsed && "opacity-0",
          )}
        >
          {title}
        </div>
        {endSection}
      </>
    )}
  </>
);

type ButtonProps = BodyProps & {
  active?: boolean;
  onClick?: () => void;
};

const Button: FC<ButtonProps> = ({ startSection, endSection, isCollapsed, active, isLoading, title, ...props }) => (
  <button className={buttonStyles({ active })} {...props}>
    <Body
      isLoading={isLoading}
      endSection={endSection}
      isCollapsed={isCollapsed}
      startSection={startSection}
      title={title}
    />
  </button>
);

type LinkProps = BodyProps & {
  to: To;
  state?: any;
  onClick?: () => void;
};

const Link: FC<LinkProps> = ({ startSection, endSection, isCollapsed, isLoading, title, ...props }) => (
  <NavLink className={({ isActive }) => buttonStyles({ active: isActive })} {...props}>
    <Body
      isLoading={isLoading}
      endSection={endSection}
      isCollapsed={isCollapsed}
      startSection={startSection}
      title={title}
    />
  </NavLink>
);

const Component = { Button, Link };

export { Component as SidebarNavItem };
