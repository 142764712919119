import type { FC, ReactNode } from "react";
import { useState } from "react";

import { Tab, Tabs } from "@/components/tabs";
import { useTranslation } from "@/hooks/translator.hook";

enum TwoFactorTabs {
  CODE = "code",
  RECOVERY = "recovery",
}

interface Props {
  renderTwoFACode: () => ReactNode;
  renderRecoveryCode: () => ReactNode;
}

export const TwoFATabs: FC<Props> = ({ renderTwoFACode, renderRecoveryCode }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<TwoFactorTabs>(TwoFactorTabs.CODE);

  return (
    <>
      <Tabs value={tab} onChange={setTab} tabSize="small">
        <Tab value={TwoFactorTabs.CODE} label={t("two-factor.two-fa-code")} />
        <Tab value={TwoFactorTabs.RECOVERY} label={t("two-factor.recovery-code")} />
      </Tabs>
      {tab === TwoFactorTabs.CODE && renderTwoFACode()}
      {tab === TwoFactorTabs.RECOVERY && renderRecoveryCode()}
    </>
  );
};
