import { TableHead, TableRow } from "@/components/table";
import { useTranslation } from "@/hooks/translator.hook";

export const TransactionHeaderRow = () => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableHead>{t("transactions.table.date")}</TableHead>
      <TableHead wide>{t("transactions.table.description")}</TableHead>
      <TableHead align="end">{t("transactions.table.amount")}</TableHead>
      <TableHead>{t("transactions.table.method")}</TableHead>
      <TableHead>{t("transactions.table.id")}</TableHead>
      <TableHead>{t("transactions.table.status")}</TableHead>
    </TableRow>
  );
};
