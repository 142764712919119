import { type FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

type Props = {
  accountId: string;
  orderId: number;
};

const ClosePendingOrderButton: FC<Props> = ({ accountId, orderId }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const closeOrder = () => {
    mutate({
      tradingAccountId: accountId,
      terminalCloseOrderRequest: { id: orderId, type: TerminalCloseOrderType.Limit },
    });
  };

  return (
    <Button className="w-full" variant="tertiary" size="sm" onClick={closeOrder} pending={isLoading}>
      {t("terminal.orders.delete-order")}
    </Button>
  );
};

export { ClosePendingOrderButton };
