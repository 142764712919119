import { ArchivedAccountCard } from "@/entities/accounts/archived/card";
import { AccountCard } from "@/entities/accounts/card";
import { AvailabilityStatus } from "@/services/openapi";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { AccountsCardsWrapper } from "../cards-wrapper";
import { ArchivedAccountsEmptyState } from "./empty-state";

const ArchivedCardsContainer = () => {
  const { data, isFetching } = useAllAccountsQuery({
    status: AvailabilityStatus.Archived,
  });

  return (
    <AccountsCardsWrapper
      cards={data}
      skeleton={<AccountCard.Skeleton rows={0} />}
      emptyState={<ArchivedAccountsEmptyState />}
      isFetching={isFetching}
    >
      {card => <ArchivedAccountCard key={card.id} account={card} />}
    </AccountsCardsWrapper>
  );
};

export { ArchivedCardsContainer };
