import { breakpoints } from "@/styles";

import { useMediaQuery } from "./media-query.hook";

export const useScreenSize = () => {
  return {
    isMobile: !useMediaQuery(`(min-width: ${breakpoints.md}px)`),
    isDesktop: useMediaQuery(`(min-width: ${breakpoints.lg}px)`)!,
    isLargeDesktop: useMediaQuery(`(min-width: ${breakpoints.xl}px)`)!,
  };
};
