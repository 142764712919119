import type { IOrderLineAdapter } from "public/charting_library/charting_library";
import type { FC } from "react";
import { useEffect, useRef } from "react";

import type { ChartColors } from "../config/colors";

type SignalLineInfo = {
  priceDecimalScale: number;
  price: number;
  color: "positive" | "negative";
};

type Props = {
  signalInfo: SignalLineInfo;
  chartColors: ChartColors;
};

const SignalChartLine: FC<Props> = ({ signalInfo, chartColors }) => {
  const ref = useRef<IOrderLineAdapter | null>(null);

  useEffect(() => {
    window.tvWidget.activeChart().dataReady(() => {
      // https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi#createorderline
      try {
        ref.current = window.tvWidget
          .activeChart()
          .createOrderLine({ disableUndo: true })
          .setText("")
          .setQuantity("")
          .setLineStyle(5)
          .setLineColor(signalInfo.color === "positive" ? chartColors.positivePressed : chartColors.negativePressed)
          .setPrice(signalInfo.price);
      } catch (e) {
        console.log(e, "signal chart line");
      }
    });

    return () => {
      ref.current && ref.current.remove();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setPrice(signalInfo.price)
          .setLineColor(signalInfo.color === "positive" ? chartColors.positivePressed : chartColors.negativePressed);
      });
    }
  }, [signalInfo.price, signalInfo.color, chartColors]);

  return null;
};

SignalChartLine.displayName = "SignalChartLine";

export { SignalChartLine, type SignalLineInfo };
