import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo, useId } from "react";

const createUrlRef = (name: string): string => `url(#${name})`;

const Spinner = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>((props, ref) => {
  const id = useId();
  const gradient1 = `gradient-1-${id}`;
  const gradient2 = `gradient-2-${id}`;
  const gradient3 = `gradient-3-${id}`;

  return (
    <svg
      className="animate-spin"
      fill="none"
      height="48"
      width="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id={gradient1} x1="35.22" x2="14.75" y1="32.22" y2="40.02">
          <stop offset="0" stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" stopOpacity=".1" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id={gradient2} x1="1.98" x2="7.98" y1="25.14" y2="14.75">
          <stop offset="0" stopColor="currentColor" stopOpacity=".1" />
          <stop offset="1" stopColor="currentColor" stopOpacity=".27" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id={gradient3} x1="22.16" x2="8.16" y1="-8.81" y2="15.43">
          <stop offset="0" stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" stopOpacity=".25" />
        </linearGradient>
      </defs>
      <g>
        <path d="M34.4 30A12 12 0 0 1 18 34.4l-6 10.38A24 24 0 0 0 44.78 36L34.4 30Z" fill={createUrlRef(gradient1)} />
        <path d="M18 34.4A12 12 0 0 1 13.6 18L3.23 12A24 24 0 0 0 12 44.78l6-10.39Z" fill={createUrlRef(gradient2)} />
        <path
          d="M13.6 18 3.23 12A24 24 0 0 1 36 3.22a6 6 0 0 1-6 10.39A12 12 0 0 0 13.6 18Z"
          fill={createUrlRef(gradient3)}
        />
      </g>
    </svg>
  );
});

const Component = memo(Spinner);

Component.displayName = "Spinner";

export { Component as Spinner };
