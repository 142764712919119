import { useTerminalAccountContext } from "@/features/terminal/contexts/account.context";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cn } from "@/shared/styles";
import { Button } from "@/shared/ui";

import { DesktopChartSymbolButton } from "../../chart-symbols/button/desktop";
import { useLayoutContext } from "../../layout/context";
import { TradingUnavailableAlert } from "./alert";

const TradingUnavailableBlock = () => {
  const { t } = useTranslation();

  const { openWatchlist, isMobile, isMobileSymbolPage } = useLayoutContext();

  const { setSymbol } = useCurrentSymbolContext();
  const { favorites, symbolsList } = useSymbolsContext();
  const { account } = useTerminalAccountContext();

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, accountId: account.id! });

  return (
    <div className="flex flex-col gap-3 px-4 pb-4">
      <TradingUnavailableAlert />
      {favorites.topActive!.length > 0 && (
        <div className="flex flex-col gap-4 rounded-[16px] border border-card-border bg-card-bg-accent p-4">
          <p className="font-roboto text-[14px] leading-normal text-contrast-primary">
            {t("terminal.trading-unavailable.top-active-instruments")}
          </p>
          <div className="flex flex-col gap-2">
            {favorites.topActive!.map(symbol => (
              <DesktopChartSymbolButton
                key={symbol}
                onSelect={() => addChartSymbol(symbol)}
                symbolInfo={symbolsList[symbol]!}
              />
            ))}
          </div>
        </div>
      )}
      <div
        className={cn(
          "sticky bottom-0 -mb-4 mt-1 bg-surface-canvas pb-4",
          !isMobileSymbolPage && isMobile && "bottom-10",
        )}
      >
        <Button className="w-full" variant="secondary" onClick={() => openWatchlist()}>
          {t("terminal.trading-unavailable.open-watchlist")}
        </Button>
      </div>
    </div>
  );
};

export { TradingUnavailableBlock };
