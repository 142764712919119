import {
  addLastIndicator,
  addSymbolToCharts,
  addSymbolToFavorites,
  closeTerminalOrders,
  modifyTerminalOrder,
  openTerminalOrder,
  removeSymbolFromCharts,
  removeSymbolFromFavorites,
  updateFavoriteSymbolsGroup,
} from "@/services/terminal";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseMutation } from "..";
import type { MutationOptionsType } from "../types";

export const useModifyTerminalOrderMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof modifyTerminalOrder>,
    ApiRequestParamsType<typeof modifyTerminalOrder>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof modifyTerminalOrder>, ApiRequestParamsType<typeof modifyTerminalOrder>>(
    modifyTerminalOrder,
    options,
  );
};

export const useCloseTerminalOrdersMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof closeTerminalOrders>,
    ApiRequestParamsType<typeof closeTerminalOrders>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof closeTerminalOrders>, ApiRequestParamsType<typeof closeTerminalOrders>>(
    closeTerminalOrders,
    options,
  );
};

export const useOpenTerminalOrderMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof openTerminalOrder>,
    ApiRequestParamsType<typeof openTerminalOrder>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof openTerminalOrder>, ApiRequestParamsType<typeof openTerminalOrder>>(
    openTerminalOrder,
    options,
  );
};

export const useAddSymbolToChartsMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof addSymbolToCharts>,
    ApiRequestParamsType<typeof addSymbolToCharts>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof addSymbolToCharts>, ApiRequestParamsType<typeof addSymbolToCharts>>(
    addSymbolToCharts,
    options,
  );
};

export const useRemoveSymbolFromChartsMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof removeSymbolFromCharts>,
    ApiRequestParamsType<typeof removeSymbolFromCharts>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof removeSymbolFromCharts>,
    ApiRequestParamsType<typeof removeSymbolFromCharts>
  >(removeSymbolFromCharts, options);
};

export const useAddSymbolToFavoritesMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof addSymbolToFavorites>,
    ApiRequestParamsType<typeof addSymbolToFavorites>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof addSymbolToFavorites>,
    ApiRequestParamsType<typeof addSymbolToFavorites>
  >(addSymbolToFavorites, options);
};

export const useRemoveSymbolFromFavoritesMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof removeSymbolFromFavorites>,
    ApiRequestParamsType<typeof removeSymbolFromFavorites>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof removeSymbolFromFavorites>,
    ApiRequestParamsType<typeof removeSymbolFromFavorites>
  >(removeSymbolFromFavorites, options);
};

export const useUpdateFavoriteSymbolsGroupMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof updateFavoriteSymbolsGroup>,
    ApiRequestParamsType<typeof updateFavoriteSymbolsGroup>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof updateFavoriteSymbolsGroup>,
    ApiRequestParamsType<typeof updateFavoriteSymbolsGroup>
  >(updateFavoriteSymbolsGroup, options);
};

export const useAddLastIndicatorMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof addLastIndicator>,
    ApiRequestParamsType<typeof addLastIndicator>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof addLastIndicator>, ApiRequestParamsType<typeof addLastIndicator>>(
    addLastIndicator,
    options,
  );
};
