import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo, type ReactNode, useMemo } from "react";

import { cn } from "@/shared/styles";

import { Loader } from "../loader";

// TODO: improve
const SectionWrapper = ({
  size,
  children,
  position,
}: {
  children: ReactNode;
  position: "start" | "end";
  size: VariantProps<typeof buttonStyles>["size"];
}) => {
  return (
    <div
      className={cn("flex grow", {
        "size-6": size === "md",
        "size-4": size === "sm",
      })}
      // className={cn("flex grow", {
      //   "*:size-6": size === "md",
      //   "*:size-4": size === "sm",
      //   "justify-start": position === "start",
      //   "justify-end": position === "end",
      // })}
    >
      {children}
    </div>
  );
};

const buttonStyles = cva(
  // TODO: focus-visible
  "inline-flex items-center gap-3 whitespace-nowrap rounded-full px-6 transition-colors focus-visible:outline-none disabled:pointer-events-none",
  {
    variants: {
      layout: {
        // TODO: start layout. It works poorly with asChild prop
        center: "w-full max-w-60 justify-center text-center",
        auto: "justify-center text-center",
      },
      variant: {
        primary: "",
        secondary: "",
        flat: "text-contrast-primary hover:bg-control-bg-hover active:bg-control-bg-active disabled:text-contrast-tertiary",
      },
      color: {
        accent: "",
        positive: "",
        warning: "",
        negative: "",
        staticBlack: "",
        staticWhite: "",
      },
      size: {
        sm: "typography-S-Compact-Medium h-10",
        md: "typography-M-Compact-Medium h-14",
      },
      pending: {
        true: "",
      },
      fullWidth: {
        true: "w-full max-w-[auto]",
      },
    },
    compoundVariants: [
      {
        variant: "flat",
        className: "disabled:text-contrast-primary",
      },
      {
        variant: "primary",
        color: "accent",
        className:
          "bg-accent-bg text-accent-over hover:bg-accent-bg-hover active:bg-accent-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "accent",
        pending: true,
        className: "disabled:bg-accent-bg disabled:text-accent-over",
      },
      {
        variant: "primary",
        color: "positive",
        className:
          "bg-positive-bg text-positive-over hover:bg-positive-bg-hover active:bg-positive-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "positive",
        pending: true,
        className: "disabled:bg-positive-bg disabled:text-positive-over",
      },
      {
        variant: "primary",
        color: "warning",
        className:
          "bg-warning-bg text-warning-over hover:bg-warning-bg-hover active:bg-warning-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "warning",
        pending: true,
        className: "disabled:bg-warning-bg disabled:text-warning-over",
      },
      {
        variant: "primary",
        color: "negative",
        className:
          "bg-negative-bg text-negative-over hover:bg-negative-bg-hover active:bg-negative-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "negative",
        pending: true,
        className: "disabled:bg-negative-bg disabled:text-negative-over",
      },
      {
        variant: "primary",
        color: "staticBlack",
        className:
          "bg-static-black text-static-white hover:bg-static-black/85 active:bg-static-black/75 disabled:bg-static-black/10 disabled:text-static-black/45",
      },
      {
        variant: "primary",
        color: "staticBlack",
        pending: true,
        className: "disabled:bg-static-black disabled:text-static-white",
      },
      {
        variant: "primary",
        color: "staticWhite",
        className:
          "bg-static-white text-static-black hover:bg-static-white/80 active:bg-static-white/60 disabled:bg-static-white/10 disabled:text-static-black/35",
      },
      {
        variant: "primary",
        color: "staticWhite",
        pending: true,
        className: "disabled:bg-static-white disabled:text-static-black",
      },
      {
        variant: "secondary",
        color: "accent",
        className:
          "bg-secondary-button-bg text-contrast-primary hover:bg-secondary-button-bg-hover active:bg-secondary-button-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "accent",
        pending: true,
        className: "disabled:bg-secondary-button-bg disabled:text-contrast-primary",
      },
      {
        variant: "secondary",
        color: "positive",
        className:
          "bg-positive-bg/15 text-positive-text hover:bg-positive-bg/20 active:bg-positive-bg/10 disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "positive",
        pending: true,
        className: "disabled:bg-positive-bg/15 disabled:text-positive-text",
      },
      {
        variant: "secondary",
        color: "warning",
        className:
          "bg-warning-bg/15 text-warning-text hover:bg-warning-bg/20 active:bg-warning-bg/10 disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "warning",
        pending: true,
        className: "disabled:bg-warning-bg/15 disabled:text-warning-text",
      },
      {
        variant: "secondary",
        color: "negative",
        className:
          "bg-negative-bg/15 text-negative-text hover:bg-negative-bg/20 active:bg-negative-bg/10 disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "negative",
        pending: true,
        className: "disabled:bg-negative-bg/15 disabled:text-negative-text",
      },
      // TODO: tokens
      {
        variant: "secondary",
        color: "staticBlack",
        className:
          "bg-[#E3E3E7] text-static-black hover:bg-[#E0E0E5] active:bg-[#D2D2DA] disabled:bg-[#E9E9EE] disabled:text-static-black/45",
      },
      {
        variant: "secondary",
        color: "staticBlack",
        pending: true,
        className: "disabled:bg-[#E3E3E7] disabled:text-static-black",
      },
      // FIXME: style is not the same as in the design
      {
        variant: "secondary",
        color: "staticWhite",
        className:
          "bg-static-white/10 text-static-black hover:bg-static-white/20 active:bg-static-white/30 disabled:bg-static-white/10 disabled:text-static-black/45",
      },
      {
        variant: "secondary",
        color: "staticWhite",
        pending: true,
        className: "disabled:bg-static-white/10 disabled:text-static-black",
      },
    ],
  },
);

type ButtonProps = ComponentPropsWithoutRef<"button"> &
  VariantProps<typeof buttonStyles> & {
    asChild?: boolean;
    startSection?: ReactNode;
    endSection?: ReactNode;
    pending?: boolean;
  };

const Button = forwardRef<ElementRef<"button">, ButtonProps>(
  (
    {
      className,
      variant = "primary",
      size = "md",
      asChild = false,
      pending,
      disabled: disabledProp,
      type = "button",
      startSection,
      endSection,
      children,
      color = "accent",
      layout = "center",
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const disabled = disabledProp || pending;
    const Comp = asChild && !pending ? Slot : "button";

    const hasSections = !!startSection || !!endSection;

    return (
      <Comp
        className={useMemo(
          () => cn(buttonStyles({ variant, size, pending, color, layout, fullWidth }), className),
          [variant, size, pending, color, layout, className, fullWidth],
        )}
        disabled={disabled}
        type={type}
        ref={ref}
        {...props}
      >
        {!pending && hasSections && (
          <SectionWrapper size={size} position="start">
            {startSection}
          </SectionWrapper>
        )}
        {!pending && <Slottable>{children}</Slottable>}
        {!pending && hasSections && (
          <SectionWrapper size={size} position="end">
            {endSection}
          </SectionWrapper>
        )}
        {pending && <Loader className="px-4" />}
      </Comp>
    );
  },
);

const Component = memo(Button);

Component.displayName = "Button";

// TODO: remove replace NewButton name with Button
export { Component as NewButton, buttonStyles };
