import { globalCss } from "./stitches.config";

export const globalStyles = globalCss({
  // https://github.com/sindresorhus/modern-normalize/blob/main/modern-normalize.css

  "*, ::before, ::after": {
    boxSizing: "border-box",
  },

  html: {
    lineHeight: "1.15",
    "-webkit-text-size-adjust": "100%",
    "-moz-tab-size": 4,
    tabSize: "4",
  },

  body: {
    margin: 0,
    fontFamily:
      "system-ui, -apple-system,	'Segoe UI', Roboto,	Helvetica,Arial,	sans-serif,'Apple Color Emoji', 'Segoe UI Emoji'",
  },

  hr: {
    height: "0",
    color: "inherit",
  },

  "abbr[title]": {
    textDecoration: "underline dotted",
  },

  "b, strong": {
    fontWeight: "bolder",
  },

  "code, kbd, samp, pre": {
    fontFamily: "ui-monospace, SFMono-Regular,	Consolas,'Liberation Mono',	Menlo,	monospace",
    fontSize: "1em",
  },

  small: {
    fontSize: "80%",
  },

  "sub, sup": {
    fontSize: "75%",
    lineHeight: "0",
    position: "relative",
    verticalAlign: "baseline",
  },

  sub: {
    bottom: "-0.25em",
  },

  sup: {
    top: "-0.5em",
  },

  table: {
    textIndent: "0",
    borderColor: "inherit",
  },

  "button, input, optgroup, select, textarea": {
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: "1.15",
    margin: "0",
  },

  "button, select": {
    textTransform: "none",
  },

  "button, [type='button'], [type='reset'], [type='submit']": {
    "-webkit-appearance": "button",
  },

  "::-moz-focus-inner": {
    borderStyle: "none",
    padding: "0",
  },

  ":-moz-focusring": {
    outline: "1px dotted ButtonText",
  },

  ":-moz-ui-invalid": {
    boxShadow: "none",
  },

  legend: {
    padding: "0",
  },

  progress: {
    verticalAlign: "baseline",
  },

  "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
    height: "auto",
  },

  "[type='search']": {
    "-webkit-appearance": "textfield",
    outlineOffset: "-2px",
  },

  "::-webkit-search-decoration": {
    "-webkit-appearance": "none",
  },

  "::-webkit-file-upload-button": {
    "-webkit-appearance": "button",
    font: "inherit",
  },

  summary: {
    display: "list-item",
  },

  // custom

  "h1, h2, h3, h4, h5, h6, p, small, span, div, label, strong": {
    margin: 0,
    fontSize: "100%",
    verticalAlign: "baseline",
  },

  a: {
    color: "inherit",
    textDecoration: "none",
  },

  ":focus:not(:focus-visible)": {
    outline: "none",
  },

  "html, body": {
    height: "100% !important", // vaul sets body height to auto, so "important" fixes the overriding issue
    backgroundColor: "$bgPrimary",
    color: "$textMain",
  },

  ul: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },

  "#root": {
    height: "100%",
  },
});
