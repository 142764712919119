import { type FC } from "react";

import { AccountSelectSkeleton } from "../account-select/skeleton";
import { AccountSummarySkeleton } from "../account-summary/skeleton";
import { ChartLoader } from "../chart/loader";
import { ChartSymbolsSkeleton } from "../chart-symbols/skeleton";
import { DepositButtonSkeleton } from "../deposit/skeleton";
import { PlaceOrderSkeleton } from "../place-order/skeleton";
import { TerminalDesktopFrame } from "../terminal-desktop.frame";
import { DesktopTradingTablesSkeleton } from "../trading-tables/desktop/skeleton";

const TerminalDesktopSkeleton: FC = () => {
  return (
    <TerminalDesktopFrame
      chartSymbolsSlot={<ChartSymbolsSkeleton />}
      accountSelectSlot={<AccountSelectSkeleton />}
      depositButtonSlot={<DepositButtonSkeleton />}
      chartSlot={<ChartLoader />}
      placeOrderSlot={<PlaceOrderSkeleton hasTabs />}
      tablesSlot={<DesktopTradingTablesSkeleton />}
      accountSummarySlot={<AccountSummarySkeleton />}
    />
  );
};

export { TerminalDesktopSkeleton };
