import { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/shared/ui";

import { TerminalDialogContent } from "../../components/terminal-dialog-content";
import { MobileTradingTablesContainer } from "../../trading-tables/mobile/container";
import { TerminalTableState, useLayoutContext } from "../context";

const MobileTradingDialog: FC = () => {
  const { t } = useTranslation();

  const { changeTable, isMobileSymbolPage, isPositionsPage, isMobile, table } = useLayoutContext();

  const closeTable = useCallback(() => {
    changeTable(TerminalTableState.NONE);
  }, [changeTable]);

  return (
    <Dialog
      open={!isMobileSymbolPage && !isPositionsPage && isMobile && table !== TerminalTableState.NONE}
      onOpenChange={closeTable}
    >
      <TerminalDialogContent title={t("terminal.orders.title")}>
        <MobileTradingTablesContainer onClose={closeTable} />
      </TerminalDialogContent>
    </Dialog>
  );
};

export { MobileTradingDialog };
