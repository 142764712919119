import * as SheetPrimitive from "@radix-ui/react-dialog";
import { type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { IconArrowLeft } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { overlayStyles, sheetStyles } from "@/shared/ui";

const Content = forwardRef<
  ElementRef<typeof SheetPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Content> & VariantProps<typeof sheetStyles>
>(({ children, className, side = "start", ...props }, ref) => (
  <SheetPrimitive.Portal>
    <SheetPrimitive.Overlay className={overlayStyles()} />
    <SheetPrimitive.Content className={cn(sheetStyles({ side }), "w-[270px] bg-dark", className)} {...props} ref={ref}>
      {children}
      <SheetPrimitive.Close
        style={{
          boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)",
        }}
        className="absolute end-0 top-3 grid size-14 translate-x-1/2 place-items-center rounded-[40px] bg-bg-back text-text backdrop-blur-md"
      >
        <IconArrowLeft />
      </SheetPrimitive.Close>
    </SheetPrimitive.Content>
  </SheetPrimitive.Portal>
));

const SheetRoot: typeof SheetPrimitive.Root = props => {
  return <SheetPrimitive.Root {...props} />;
};
SheetRoot.displayName = SheetPrimitive.Root.displayName;

export const Sheet = Object.assign(SheetRoot, {
  Trigger: SheetPrimitive.Trigger,
  Content,
});
