import { onboardingRoutes } from "@/routes/onboarding.routes";
import type { KycInfo, ProfileHeader, SurveyGroup } from "@/services/openapi";

enum NavigationTitle {
  PROFILE = "profile",
  SUMSUB = "sumsub",
}

type OnboardingStep = {
  id: string;
  filled: boolean;
};

const getVerificationRoute = (isSurveyCompleted: boolean) =>
  isSurveyCompleted ? onboardingRoutes.kyc : onboardingRoutes.onboarding;

const createSurveyItem = ({ isFilled, id }: SurveyGroup): OnboardingStep => {
  return { filled: isFilled!, id: id! };
};

const createOnboardingSteps = (kycInfo: KycInfo, profileHeader: ProfileHeader): OnboardingStep[] => {
  const profileFilled = profileHeader.options!.isProfileFilled!;
  const profileItem = {
    id: NavigationTitle.PROFILE,
    filled: profileFilled,
  };
  const surveyItems = kycInfo.survey!.surveyGroups!.map(createSurveyItem);

  return [profileItem, ...surveyItems];
};

export { getVerificationRoute, createOnboardingSteps, NavigationTitle, type OnboardingStep };
