import { type FC, memo } from "react";

import type { TerminalSymbolSignal, TerminalSymbolWidget } from "@/services/openapi";

import { NewsVolumeWidget } from "./widgets/news-volume";
import { SentimentTrendWidget } from "./widgets/sentiment-trend";
import { SignalWidget } from "./widgets/signal";
import { TechnicalsSummaryScoreWidget } from "./widgets/technicals-summary-score";

type Props = {
  signal: TerminalSymbolSignal | undefined;
  widget: TerminalSymbolWidget | undefined;
  priceDecimalScale: number;
  showNewsVolumeWidget: boolean;
  showSentimentWidget: boolean;
  showSignalWidget: boolean;
  showTechnicalsSummaryScoreWidget: boolean;
  onClose?: () => void;
};

const AnalyticsContainer: FC<Props> = ({
  showNewsVolumeWidget,
  showSentimentWidget,
  showSignalWidget,
  showTechnicalsSummaryScoreWidget,
  signal,
  priceDecimalScale,
  onClose,
  widget,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {showSignalWidget && <SignalWidget signal={signal!} priceDecimalScale={priceDecimalScale} onClose={onClose} />}
      {showSentimentWidget && <SentimentTrendWidget trend={widget!.trend!} />}
      {showTechnicalsSummaryScoreWidget && (
        <TechnicalsSummaryScoreWidget
          resistance={widget!.supportAndResistance!.resistance!}
          support={widget!.supportAndResistance!.support!}
          priceDecimalScale={priceDecimalScale}
          summaryScore={widget!.summaryScore!}
        />
      )}
      {showNewsVolumeWidget && <NewsVolumeWidget buzzing={widget!.buzzing!} />}
    </div>
  );
};

const Component = memo(AnalyticsContainer);

export { Component as AnalyticsContainer };
