import type { AnyObjectType } from "@/constants/types";
import { FieldType, type SurveyUpdateItem } from "@/services/openapi";

export const generateSurveyFieldId = (id: string, type: FieldType) => {
  return id + "_" + type;
};

export const generateSurveyFieldTitle = (title: string) => {
  // return title + (isRequired ? " *" : "");
  return title;
};

export const createSurveyRequestItems = (formValues: AnyObjectType) => {
  const items: SurveyUpdateItem[] = Object.entries(formValues)
    .filter(([_, value]) => typeof value === "boolean" || Boolean(value))
    .map(([key, value]) => {
      const [fieldId, type] = key.split("_") as [string, FieldType];
      switch (type) {
        case FieldType.PollSingle:
          return {
            fieldId,
            pollItems: [value],
          };
        case FieldType.PollMulti:
          const pollItems = Array.isArray(value) ? value : [value];
          return {
            fieldId,
            pollItems,
          };
        default:
          return {
            fieldId,
            value: String(value),
          };
      }
    });
  return items;
};
