import type { FC } from "react";
import { useCallback } from "react";

import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { useDisableTwoFAMutation } from "@/state/server/auth";

import { TwoFASteps } from "../two-factor.container";
import { DisableTwoFAForm, type IDisableTwoFAFormValues } from "./disable-two-fa.form";

type Props = {
  setStep: (step: TwoFASteps) => void;
};
export const DisableTwoFAContainer: FC<Props> = ({ setStep }) => {
  const { t } = useTranslation();

  const { mutateAsync: disable } = useDisableTwoFAMutation({ successMessage: "two-factor-disabled" });

  const handleSubmit = useCallback(
    (values: IDisableTwoFAFormValues) => {
      return disable(
        { twoFactorDisableRequest: values },
        {
          onSuccess: () => {
            setStep(TwoFASteps.DISABLED);
          },
        },
      );
    },
    [disable, setStep],
  );

  return (
    <>
      <Text weight="bold" size="3" css={{ mb: "30px" }}>
        {t("two-factor.settings.disable-two-fa")}
      </Text>
      <DisableTwoFAForm onSubmit={handleSubmit} setStep={setStep} />
    </>
  );
};
