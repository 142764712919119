import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { IconCross } from "@/domains/icons";

import {
  _NewButton as _Button,
  type _NewExternalWrapperProps as _ExternalWrapperProps,
  _NewInput as _Input,
  _NewWrapper as _Wrapper,
} from "../_base";

type Props = Omit<ComponentPropsWithoutRef<typeof _Input>, "size" | "onChange" | "value"> &
  Omit<_ExternalWrapperProps, "hasInputValue"> & {
    value: string;
    onChange: (value: string) => void;
    clearable?: boolean;
  };

const TextInput = forwardRef<ElementRef<typeof _Input>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      startSection,
      disabled,
      invalid,
      pending,
      clearable,
      descriptor,
      endSection,
      floatingLabel = true,
      ...props
    },
    ref,
  ) => {
    const showClearButton = clearable && value !== "";

    const hasEndSection = !!(showClearButton || endSection);

    return (
      <_Wrapper
        type="input"
        className={className}
        startSection={startSection}
        invalid={invalid}
        pending={pending}
        label={label}
        disabled={disabled}
        descriptor={descriptor}
        floatingLabel={floatingLabel}
        hasInputValue={value !== ""}
        endSection={
          hasEndSection ? (
            <>
              {showClearButton && (
                <_Button disabled={disabled} onClick={() => onChange("")}>
                  <IconCross />
                </_Button>
              )}
              {endSection}
            </>
          ) : null
        }
      >
        <_Input disabled={disabled} value={value} onChange={e => onChange(e.target.value)} {...props} ref={ref} />
      </_Wrapper>
    );
  },
);

// TODO: remove replace NewTextInput name with TextInput
export { TextInput as NewTextInput };
