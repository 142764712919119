import type { FC } from "react";

import { Text } from "@/components/text";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { useCopy } from "@/hooks/copy.hook";
import { styled } from "@/styles";

const Container = styled("div", {
  borderRadius: 12,
  background: "$bgBack",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  p: "11px 24px",

  color: "$textSecondary",
  fontFamily: "$roboto",
  fontSize: "16px",
  fontWeight: "$normal",
  lineHeight: 1.5,
});

type Props = {
  title: string;
  value: string;
};

export const PlatformCopyButton: FC<Props> = ({ title, value }) => {
  const { copy } = useCopy();

  return (
    <div>
      <Text css={{ lineHeight: 1.2, mb: 12 }} weight="bold" size="3">
        {title}
      </Text>
      <Container>
        {value}
        <UnstyledButton css={{ color: "$textMain" }} onClick={() => copy(value)}>
          {/* TODO: add icon */}
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 6.5C7 3.73858 9.23858 1.5 12 1.5L18 1.5C20.7614 1.5 23 3.73858 23 6.5V12.5C23 15.2614 20.7614 17.5 18 17.5H17V18.5C17 21.2614 14.7614 23.5 12 23.5H6C3.23858 23.5 1 21.2614 1 18.5V12.5C1 9.73858 3.23858 7.5 6 7.5H7V6.5ZM9 7.5H12C14.7614 7.5 17 9.73858 17 12.5V15.5H18C19.6569 15.5 21 14.1569 21 12.5V6.5C21 4.84315 19.6569 3.5 18 3.5L12 3.5C10.3431 3.5 9 4.84315 9 6.5V7.5ZM6 9.5C4.34315 9.5 3 10.8431 3 12.5V18.5C3 20.1569 4.34315 21.5 6 21.5H12C13.6569 21.5 15 20.1569 15 18.5V12.5C15 10.8431 13.6569 9.5 12 9.5H6Z"
              fill="currentColor"
            />
          </svg>
        </UnstyledButton>
      </Container>
    </div>
  );
};
