import { CaptchaProvider } from "./captcha.provider";
import { GoogleOAuthProvider } from "./google-oauth.provider";

export const AuthDataProvider = ({ children }: { children: React.ReactElement }) => {
  return (
    <GoogleOAuthProvider>
      <CaptchaProvider>{children}</CaptchaProvider>
    </GoogleOAuthProvider>
  );
};
