import { type ElementRef, forwardRef, type ReactNode } from "react";

import { focusModalAutocloseElement } from "@/components/dialog/helpers";
import { IconArrowLeft } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { Dialog, overlayStyles } from "@/shared/ui";

type Props = {
  children: ReactNode;
  title?: string;
};

const TerminalBackDialogContent = forwardRef<ElementRef<typeof Dialog.Content>, Props>(
  ({ children, title }, forwardedRef) => (
    <Dialog.Portal>
      <Dialog.Overlay
        className={cn(
          overlayStyles({ hasAnimation: false }),
          "grid place-items-center overflow-y-auto overflow-x-hidden scrollbar-hide",
        )}
      >
        <Dialog.Content
          className="relative z-50 flex h-full w-full flex-col overflow-y-auto overflow-x-hidden bg-surface-canvas p-4 outline-none"
          ref={forwardedRef}
          onOpenAutoFocus={focusModalAutocloseElement}
        >
          <div className="mb-4 flex items-center gap-4">
            <Dialog.Close className="grid size-6 place-items-center text-contrast-primary outline-none">
              <IconArrowLeft />
            </Dialog.Close>
            {title && (
              <Dialog.Title className="font-gilroy text-[24px] font-semibold leading-[1.2] text-contrast-primary">
                {title}
              </Dialog.Title>
            )}
          </div>
          <div className="-m-1 flex h-full flex-col overflow-y-auto p-1 scrollbar-hide">{children}</div>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  ),
);

export { TerminalBackDialogContent };
