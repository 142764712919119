import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

const Strong = forwardRef<ElementRef<"span">, ComponentPropsWithoutRef<"span">>(({ className, ...props }, ref) => {
  return <span className={cn("font-bold", className)} {...props} ref={ref} />;
});

type Props = ComponentPropsWithoutRef<"div"> & {
  icon: ReactNode;
  text: ReactNode;
};

const Alert = forwardRef<ElementRef<"div">, Props>(({ className, icon, text, ...props }, ref) => (
  <div className={cn("flex gap-2 rounded-[16px] bg-attention/10 p-4", className)} {...props} ref={ref}>
    <div className="grid size-6 place-items-center text-attention">{icon}</div>
    <div className="typography-S-Regular leading-normal text-contrast-primary">{text}</div>
  </div>
));

const Component = Object.assign(Alert, { Strong });

export { Component as Alert };
