import { type FC, type RefObject } from "react";
import { useTranslation } from "react-i18next";

import { useLocalStorage } from "@/hooks/localstorage.hook";
import { Hint, Popover } from "@/shared/ui";

type Props = {
  virtualRef: RefObject<any>;
};

const WatchlistHint: FC<Props> = ({ virtualRef }) => {
  const { t } = useTranslation();

  const [hintIsOpen, setHintIsOpen] = useLocalStorage("watchlistHintIsOpen", true);

  return (
    <Popover open={hintIsOpen} onOpenChange={() => setHintIsOpen(false)}>
      <Hint.PopoverContent
        hideWhenDetached
        align="start"
        sideOffset={10}
        alignOffset={-10}
        title={t("terminal.watchlist.hint.title")!}
        description={t("terminal.watchlist.hint.description")}
      />
      <Popover.Anchor virtualRef={virtualRef} />
    </Popover>
  );
};

export { WatchlistHint };
