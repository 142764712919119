import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_KEY,
  authDomain: import.meta.env.VITE_FCM_APP_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FCM_APP_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FCM_APP_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FCM_APP_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FCM_APP_APP_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) return getMessaging(app);

    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return Promise.resolve(
        getToken(messagingResolve, {
          vapidKey: import.meta.env.VITE_FCM_VAPID_KEY,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const deleteFcmToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return deleteToken(messagingResolve);
    }
  } catch (error) {
    console.log(error);
  }
};

export { messaging, onMessage, getFirebaseToken, deleteFcmToken };
