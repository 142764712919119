import Big from "big.js";
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import type { Field, PaymentMethod, TradingAccount } from "@/services/openapi";
import { NewButton } from "@/shared/ui";
import { Text } from "@/shared/ui";

import { Check } from "../../check";

type Props = {
  fee: number;
  currentAmount: string;
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  additionalFieldsData: Field[] | null;
  submitIsloading: boolean;
  back: () => void;
  onSubmit: () => void;
};

const CheckStep: FC<Props> = ({
  fee,
  currentAmount,
  currentAccount,
  currentPaymentMethod,
  additionalFieldsData,
  submitIsloading,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bonusBalance = useMemo(() => {
    const credit = new Big(currentAccount?.credit ?? 0);
    const amount = new Big(currentAmount ?? 0);
    const availableToWithdraw = new Big(currentAccount.availableToWithdraw!);
    const result = credit.minus(amount.div(availableToWithdraw).times(credit)).toNumber();

    if (result < 0) return 0;
    return result;
  }, [currentAccount.availableToWithdraw, currentAccount?.credit, currentAmount]);

  return (
    <>
      <Text variant="S / Regular" color="primary" className="mb-4">
        {t("payments.check.title")!}
      </Text>
      <Check
        credit={currentAccount.credit!}
        amount={Number(currentAmount)}
        login={currentAccount.login!}
        symbol={currentAccount.currency!}
        additionalFields={additionalFieldsData}
        fee={fee}
        bonusBalance={bonusBalance ?? 0}
        decimalScale={currentAccount.digits!}
        paymentMethod={{
          img: currentPaymentMethod.images!.mini!,
          title: currentPaymentMethod.title!,
        }}
      />

      <PaymentWrapper.Footer offset="sm">
        <NewButton pending={submitIsloading} fullWidth onClick={onSubmit}>
          {t("withdrawal.form.submit")}
        </NewButton>
      </PaymentWrapper.Footer>
    </>
  );
};

export { CheckStep };
