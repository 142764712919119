import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { SymbolIcon } from "@/features/terminal/symbols/icon";
import { cn } from "@/shared/styles";
import { Loader, ScrollArea } from "@/shared/ui";

const cellStyles = cva("h-10 whitespace-nowrap px-2 font-roboto font-normal leading-normal first:ps-0", {
  variants: {
    align: {
      start: "text-start",
      end: "text-end",
    },
  },
});

const Symbol: FC<ComponentPropsWithoutRef<"button"> & { symbol: string }> = ({ symbol, ...props }) => {
  return (
    <button className="flex items-center gap-2 outline-none" {...props}>
      <SymbolIcon symbol={symbol} />
      <div className="font-roboto text-[14px] leading-normal text-contrast-primary transition-colors hover:text-contrast-secondary">
        {symbol}
      </div>
    </button>
  );
};

const Head = forwardRef<
  ElementRef<"th">,
  Omit<ComponentPropsWithoutRef<"th">, "align"> & VariantProps<typeof cellStyles>
>(({ className, align = "start", ...props }, ref) => (
  <th
    className={cn(
      cellStyles({ align }),
      "sticky top-0 bg-surface-canvas text-[12px] text-contrast-secondary",
      className,
    )}
    {...props}
    ref={ref}
  />
));

const Cell = forwardRef<
  ElementRef<"td">,
  Omit<ComponentPropsWithoutRef<"td">, "align"> & VariantProps<typeof cellStyles>
>(({ className, align = "start", ...props }, ref) => (
  <td className={cn(cellStyles({ align }), "text-[14px] text-contrast-primary", className)} {...props} ref={ref} />
));

const rowStyles = cva("border-b border-contrast-quinary bg-surface-canvas last:border-0", {
  variants: {
    showBorder: {
      true: "!border-b",
    },
  },
});

const Row = forwardRef<ElementRef<"tr">, ComponentPropsWithoutRef<"tr"> & VariantProps<typeof rowStyles>>(
  ({ className, showBorder, ...props }, ref) => (
    <tr className={cn(rowStyles({ showBorder }), className)} {...props} ref={ref} />
  ),
);

type Props<T> = {
  renderHeader: () => ReactNode;
  emptyState: ReactNode;
  renderRow: (items: T) => ReactNode;
  isLoading: boolean;
  items: T[];
  bottomRow?: ReactNode;
};

const Root = <T,>({ renderRow, items, renderHeader, emptyState, isLoading, bottomRow }: Props<T>) => {
  if (isLoading) {
    return (
      <div className="grid h-full place-items-center">
        <Loader />
      </div>
    );
  }

  if (items.length === 0) {
    return <div className="grid h-full place-items-center">{emptyState}</div>;
  }

  return (
    <ScrollArea scrollbars="both" className="px-4 pb-2 pt-0.5">
      <table className="w-full">
        <thead>{renderHeader()}</thead>
        <tbody>
          {items.map(renderRow)}
          {bottomRow}
        </tbody>
      </table>
    </ScrollArea>
  );
};

const Component = Object.assign(Root, { Row, Head, Cell, Symbol });

export { Component as TradingTable };
