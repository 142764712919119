import image from "@/assets/images/DocumentsSubmittedImage.svg";
import { Badge } from "@/components/badge";
import { NoContentMessage } from "@/components/no-content-message";
import { useTranslation } from "@/hooks/translator.hook";

export const KycPendingMessage = () => {
  const { t } = useTranslation();

  return (
    <NoContentMessage
      title={t("profile-settings.kyc-pending.title")}
      description={t("profile-settings.kyc-pending.description")}
      imageSrc={image}
      verticalPaddings="small"
    >
      <Badge color="blue">{t("common.pending")}</Badge>
    </NoContentMessage>
  );
};
