import { useCallback, useEffect } from "react";

import { useCookie } from "@/hooks/cookie.hook";

export const analyticsUtmProperty = "Analytics-Utm";
export const analyticsClickProperty = "Analytics-Click";

export const useMarketingCookies = () => {
  const [, setCookie] = useCookie();

  const setMarketingCookies = useCallback(() => {
    const url = window.location.href;
    const utmRegex = /(utm_[^&=]+=[^&]+)+/g;
    const utmResult = url.match(utmRegex);
    const clickIdRegex = /click_id=([^&]+)/;
    const clickIdResult = url.match(clickIdRegex);

    if (utmResult !== null) {
      setCookie(analyticsUtmProperty, "&" + utmResult.join("&"));
    }

    if (clickIdResult !== null) {
      setCookie(analyticsClickProperty, "&" + clickIdResult[0]);
    }
  }, []);

  useEffect(() => {
    setMarketingCookies();
  }, []);
};
