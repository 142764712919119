import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

const descriptorButtonStyles = cva(
  "pointer-events-auto underline hover:text-contrast-primary hover:transition-colors group-data-[invalid=true]/input-wrapper:hover:text-negative-bg-hover",
)();

const _Descriptor: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="mt-2 text-start font-roboto text-[14px] leading-[1.5] text-contrast-secondary group-data-[disabled=true]/input-wrapper:pointer-events-none group-data-[invalid=true]/input-wrapper:text-negative-text">
      {children}
    </div>
  );
};

const _DescriptorButton = forwardRef<ElementRef<"button">, ComponentPropsWithoutRef<"button"> & { asChild?: boolean }>(
  ({ className, type = "button", asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp ref={ref} type={type} className={cn(descriptorButtonStyles, className)} {...props} />;
  },
);

export { _Descriptor, _DescriptorButton };
