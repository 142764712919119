import { type FC } from "react";

import { Skeleton } from "@/shared/ui";

const MobileSymbolBlockSkeleton: FC = () => {
  return (
    <div className="sticky top-0 z-10 px-4 pb-2 pt-5 backdrop-blur">
      <div className="flex items-center justify-between gap-2">
        <div className="flex gap-4">
          <Skeleton className="size-6 rounded-[8px]" />
          <Skeleton className="size-9 rounded-[8px]" />
          <div className="flex flex-col gap-2">
            <Skeleton className="h-[18px] w-[100px] rounded-[16px]" />
            <Skeleton className="h-3.5 w-[100px] rounded-[8px]" />
          </div>
        </div>
        <Skeleton className="h-10 w-[100px] rounded-[32px]" />
      </div>
    </div>
  );
};

export { MobileSymbolBlockSkeleton };
