import { PnlFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { TerminalDealType } from "@/services/openapi";

import { calculateProfitAndLoss } from "../../helpers/formulas";

const ModifyProfitAndLoss = ({
  baseCurrency,
  openPrice,
  accountCurrency,
  orderType,
  contractSize,
  currentPrice,
  quoteCurrency,
  volume,
  currencyDecimalScale,
  profitRate,
}: {
  volume: number;
  accountCurrency: string;
  currentPrice: number;
  openPrice: number;
  contractSize: number;
  orderType: TerminalDealType;
  baseCurrency: string;
  quoteCurrency: string;
  currencyDecimalScale: number;
  profitRate?: number;
}) => {
  const { symbols } = useSymbolsContext();

  const profitAndLoss = calculateProfitAndLoss({
    type: orderType,
    volume,
    openPrice,
    accountCurrency,
    currentPrice,
    baseCurrency,
    quoteCurrency,
    contractSize,
    symbols,
    profitRate,
  });

  return (
    <div className={getNumberColorClassname(profitAndLoss)}>
      <PnlFormat value={profitAndLoss} decimalScale={currencyDecimalScale} />
    </div>
  );
};

export { ModifyProfitAndLoss };
