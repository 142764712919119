import { useCallback, useState } from "react";

import { CaptchaType } from "@/services/openapi";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

export const useGoogleCaptcha = () => {
  const [captchaToken, setCaptchaToken] = useState("");
  const [refreshReCaptchaValue, setRefreshReCaptcha] = useState(false);
  const platformData = usePlatformData();

  const hasCaptcha = platformData.captcha!.types!.includes(CaptchaType.GoogleReCaptchaV3);
  const disabledSubmit = hasCaptcha && !captchaToken;

  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token);
  }, []);

  const refreshCaptcha = useCallback(() => {
    setRefreshReCaptcha(r => !r);
  }, []);

  return {
    captchaToken,
    onVerify,
    hasCaptcha,
    disabledSubmit,
    refreshCaptcha,
    refreshReCaptchaValue,
  };
};
