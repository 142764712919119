import { useCallback, useState } from "react";

import { usePagination } from "@/hooks/pagination.hook";
import { FundsTxType } from "@/services/openapi";

const ITEMS_COUNT = 7;

// TODO: find better way to handling this logic
export const useTransactionsTableFilters = () => {
  const [type, setType] = useState<FundsTxType>(FundsTxType.All);

  const pagination = usePagination({ pageSize: ITEMS_COUNT });
  const { resetPagination } = pagination;

  const updateTypeFilter = useCallback(
    (filter: FundsTxType) => {
      resetPagination();
      setType(filter);
    },
    [resetPagination],
  );

  return { type, updateTypeFilter, ...pagination };
};
