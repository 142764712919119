import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NewDialogContent } from "@/components/new-dialog";

import { SetBalanceContainer } from "./set-balance.container";

type Props = {
  accountId: string;
  accountCurrency: string;
  onClose: () => void;
};

const SetBalanceDialogContent: FC<Props> = ({ accountCurrency, accountId, onClose }) => {
  const { t } = useTranslation();

  return (
    <NewDialogContent title={t("accounts.demo.add-balance.dialog-title")!}>
      <SetBalanceContainer accountId={accountId} currency={accountCurrency} onClose={onClose} />
    </NewDialogContent>
  );
};

export { SetBalanceDialogContent };
