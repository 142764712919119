import React from "react";

export const PasswordShowIcon = () => (
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C14.7275 0 17.3357 1.43062 19.7663 3.78115C20.5955 4.58305 21.3457 5.43916 22.0061 6.29562C22.4046 6.81244 22.6875 7.21899 22.8424 7.4612L23.187 8L22.8424 8.5388C22.6875 8.781 22.4046 9.18756 22.0061 9.70438C21.3457 10.5608 20.5955 11.417 19.7663 12.2189C17.3357 14.5694 14.7275 16 12 16C9.27245 16 6.66432 14.5694 4.23371 12.2189C3.40449 11.417 2.65431 10.5608 1.99391 9.70438C1.59541 9.18756 1.31247 8.781 1.15756 8.5388L0.812965 8L1.15756 7.4612C1.31247 7.21899 1.59541 6.81244 1.99391 6.29562C2.65431 5.43916 3.40449 4.58305 4.23371 3.78115C6.66432 1.43062 9.27245 0 12 0ZM20.4223 7.51688C19.8176 6.73272 19.1302 5.9482 18.376 5.21885C16.2825 3.19438 14.1051 2 12 2C9.89492 2 7.71749 3.19438 5.62404 5.21885C4.86984 5.9482 4.18239 6.73272 3.57775 7.51688C3.44716 7.68624 3.32649 7.84782 3.21617 8C3.32649 8.15218 3.44716 8.31376 3.57775 8.48312C4.18239 9.26728 4.86984 10.0518 5.62404 10.7811C7.71749 12.8056 9.89492 14 12 14C14.1051 14 16.2825 12.8056 18.376 10.7811C19.1302 10.0518 19.8176 9.26728 20.4223 8.48312C20.5528 8.31376 20.6735 8.15218 20.7838 8C20.6735 7.84782 20.5528 7.68624 20.4223 7.51688ZM8 8C8 10.2091 9.79086 12 12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8ZM14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z"
      fill="currentColor"
    />
  </svg>
);

export const PasswordHideIcon = () => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.70711 0.292847L1.29289 1.70706L5.35771 5.77188C4.97906 6.08569 4.60432 6.42271 4.23371 6.7811C3.40449 7.583 2.65431 8.43911 1.99391 9.29558C1.59541 9.81239 1.31247 10.2189 1.15756 10.4612L0.812965 11L1.15756 11.5388C1.31247 11.781 1.59541 12.1875 1.99391 12.7043C2.65431 13.5608 3.40449 14.4169 4.23371 15.2188C6.66432 17.5693 9.27245 19 12 19C13.7209 19 15.3943 18.4304 17.0055 17.4197L21.2929 21.7071L22.7071 20.2928L2.70711 0.292847ZM15.5446 15.9588L14.032 14.4461C13.4365 14.798 12.7418 15 12 15C9.79086 15 8 13.2091 8 11C8 10.2581 8.20193 9.5635 8.55382 8.96799L6.77972 7.19389C6.39232 7.5063 6.00678 7.84868 5.62404 8.2188C4.86984 8.94816 4.18239 9.73267 3.57775 10.5168C3.44716 10.6862 3.32649 10.8478 3.21617 11C3.32649 11.1521 3.44716 11.3137 3.57775 11.4831C4.18239 12.2672 4.86984 13.0518 5.62404 13.7811C7.71749 15.8056 9.89492 17 12 17C13.1682 17 14.3586 16.6322 15.5446 15.9588ZM10.0677 10.4819C10.0236 10.6471 10 10.8208 10 11C10 12.1045 10.8954 13 12 13C12.1792 13 12.3528 12.9764 12.518 12.9322L10.0677 10.4819ZM20.0981 14.8909L18.6838 13.4767C19.318 12.8356 19.9009 12.1592 20.4223 11.4831C20.5528 11.3137 20.6735 11.1521 20.7838 11C20.6735 10.8478 20.5528 10.6862 20.4223 10.5168C19.8176 9.73267 19.1302 8.94816 18.376 8.2188C16.2825 6.19433 14.1051 4.99995 12 4.99995C11.4777 4.99995 10.9509 5.07348 10.4221 5.21497L8.84015 3.633C9.8726 3.22133 10.9271 2.99995 12 2.99995C14.7275 2.99995 17.3357 4.43058 19.7663 6.7811C20.5955 7.583 21.3457 8.43911 22.0061 9.29558C22.4046 9.81239 22.6875 10.2189 22.8424 10.4612L23.187 11L22.8424 11.5388C22.6875 11.781 22.4046 12.1875 22.0061 12.7043C21.4349 13.4451 20.7966 14.1855 20.0981 14.8909Z"
      fill="currentColor"
    />
  </svg>
);
