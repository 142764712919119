import { useCallback, useState } from "react";

import type { PaymentMethod } from "@/services/openapi";

type DepositInfo = {
  currentStep: "paymentMethod" | "amount" | "check";
  // navigation
  backFromAmount: () => void;
  backFromCheck: () => void;
  nextFromPaymentMethod: () => void;
  nextFromAmount: () => void;
  // form
  isBonusAccepted: boolean;
  setIsBonusAccepted: (value: boolean) => void;
  currentAmount: string;
  setCurrentAmount: (value: string) => void;
  currentPaymentMethod: PaymentMethod | null;
  setCurrentPaymentMethod: (method: PaymentMethod) => void;
};

const useDepositInfo = (): DepositInfo => {
  const [currentStep, setCurrentStep] = useState<DepositInfo["currentStep"]>("paymentMethod");
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<DepositInfo["currentPaymentMethod"]>(null);
  const [currentAmount, setCurrentAmount] = useState<DepositInfo["currentAmount"]>("");
  const [isBonusAccepted, setIsBonusAccepted] = useState<DepositInfo["isBonusAccepted"]>(true);

  // Payment method step
  const nextFromPaymentMethod = useCallback(() => {
    setCurrentStep("amount");
  }, []);

  // Amount step
  const nextFromAmount = useCallback(() => {
    setCurrentStep("check");
  }, []);

  const backFromAmount = useCallback(() => {
    setCurrentStep("paymentMethod");
    setCurrentAmount("");
  }, []);

  // Check step
  const backFromCheck = useCallback(() => {
    setCurrentStep("amount");
    setIsBonusAccepted(true);
  }, []);

  return {
    currentStep,
    currentPaymentMethod,
    currentAmount,
    isBonusAccepted,
    setIsBonusAccepted,
    setCurrentPaymentMethod,
    setCurrentAmount,
    backFromAmount,
    backFromCheck,
    nextFromAmount,
    nextFromPaymentMethod,
  };
};

export { useDepositInfo };
