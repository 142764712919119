import { type FC } from "react";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useLayoutContext } from "../layout/context";
import { DepositButton } from "./button";

const DepositButtonContainer: FC = () => {
  const {
    account: { id },
  } = useTerminalAccountContext();
  const { isMobile, isMobileAppMode } = useLayoutContext();

  return <DepositButton isMobile={isMobile} isMobileAppMode={isMobileAppMode} accountId={id!} />;
};

export { DepositButtonContainer };
