import { type FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../context";

type Props = {
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  maxBalanceVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeMargin"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  minSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeMargin"];
  maxSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeLots"];
  maxSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  onDeposit: () => void;
  currency: string;
};

const VolumeMarginFieldDescriptor: FC<Props> = ({
  changeLots,
  volumeLotsError,
  minSystemVolumeLots,
  minSystemVolumeMargin,
  maxBalanceVolumeMargin,
  maxBalanceVolumeLots,
  maxSystemVolumeMargin,
  maxSystemVolumeLots,
  currency,
  volumeMarginDecimalScale,
  onDeposit,
}) => {
  return (
    <>
      {volumeLotsError === VolumeLotsError.LESS_THAN_SYSTEM && (
        <Trans
          i18nKey="terminal.place-order.margin.input-descriptor.min-system"
          values={{ currency }}
          components={{
            value: <NumberFormat value={minSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
            valueButton: <NumberInput.DescriptorButton onClick={() => changeLots(minSystemVolumeLots)} />,
          }}
        />
      )}
      {volumeLotsError === VolumeLotsError.MORE_THAN_FREE_MARGIN && (
        <>
          <Trans
            i18nKey="terminal.place-order.margin.input-descriptor.more-than-free-margin"
            values={{ currency }}
            components={{
              value: <NumberFormat value={maxBalanceVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
              valueButton: <NumberInput.DescriptorButton onClick={() => changeLots(maxBalanceVolumeLots)} />,
              depositButton: <NumberInput.DescriptorButton onClick={onDeposit} />,
            }}
          />
        </>
      )}
      {volumeLotsError === VolumeLotsError.MORE_THAN_SYSTEM && (
        <Trans
          i18nKey="terminal.place-order.margin.input-descriptor.max-system"
          values={{ currency }}
          components={{
            value: <NumberFormat value={maxSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
            valueButton: <NumberInput.DescriptorButton onClick={() => changeLots(maxSystemVolumeLots)} />,
          }}
        />
      )}
    </>
  );
};

export { VolumeMarginFieldDescriptor };
