import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

const IconPriceChangeArrowDown = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>((props, ref) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
    <path
      d="M8.61929 2.73542L8.61928 10.901L12.2102 7.29874L13.0966 8.17374L7.99428 13.2646L2.90338 8.17374L3.76701 7.29874L7.36928 10.901L7.36929 2.73542L8.61929 2.73542Z"
      fill="currentColor"
    />
  </svg>
));

export { IconPriceChangeArrowDown };
