import { type FC } from "react";

import { Popover, PopoverTrigger } from "@/components/popover";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { terminalLocators } from "@/features/terminal/locators";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button } from "@/shared/ui/button";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { CloseAllOpenOrdersDataList } from "../../open-orders/close-all/data-list";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  pnl: number;
  swaps: number;
  ordersCount: number;
};

const CloseAllOpenOrdersButton: FC<Props> = ({
  accountId,
  ordersCount,
  pnl,
  swaps,
  currency,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, open }] = useDisclosure();

  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const closeOrders = () => {
    mutate(
      { tradingAccountId: accountId, terminalCloseOrderRequest: { type: TerminalCloseOrderType.Market } },
      {
        onSuccess: () => {
          open();
        },
      },
    );
  };

  return (
    <Popover open={opened} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="flat" size="sm" data-test={terminalLocators.tradingTables.open.closeAll}>
          {t("terminal.orders.close-all.name")}
        </Button>
      </PopoverTrigger>
      <TerminalPopover title={t("terminal.orders.close-all.name")}>
        <CloseAllOpenOrdersDataList
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
          ordersCount={ordersCount}
          pnl={pnl}
          swaps={swaps}
        />
        <TerminalPopover.Buttons>
          <Button pending={isLoading} onClick={closeOrders} className="w-full">
            {t("terminal.orders.close-all.name")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalPopover>
    </Popover>
  );
};

export { CloseAllOpenOrdersButton };
