import { type FC } from "react";

import { ChartContainer } from "./chart/container";
import { useLayoutContext } from "./layout/context";
import { TerminalContainer } from "./terminal.container";

const Chart = () => {
  const { isChartExpanded } = useLayoutContext();

  return <ChartContainer key={String(isChartExpanded)} />;
};

const TerminalChartPage: FC = () => {
  return (
    <TerminalContainer simpleLoader>
      <Chart />
    </TerminalContainer>
  );
};

export default TerminalChartPage;
