import type { FC } from "react";
import { memo } from "react";

import { useTranslation } from "@/hooks/translator.hook";

import { TextField, type TextFieldProps } from "../text";

export const _EmailField: FC<TextFieldProps> = props => {
  const { t } = useTranslation();
  return (
    <TextField
      {...props}
      registerOptions={{
        required: t("form-errors.required-error")!,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: t("form-errors.email-error"),
        },
        // ...props.registerOptions,
      }}
      type="email"
      inputMode="email"
      autoComplete="email"
      placeholder={props.placeholder || t("fields.email.email")!}
    />
  );
};

export const EmailField = memo(_EmailField);
