import { type FC, memo, useEffect, useState } from "react";

import { NumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";
import { IconPriceChangeArrowDown, IconPriceChangeArrowUp } from "@/shared/ui/icons";

import { calculateSymbolPriceChange } from "../../helpers/formulas";

type Props = {
  bid: number | undefined | null;
  priceLast24H: number | null | undefined;
  size?: "md" | "sm";
};

const _SymbolPriceChange: FC<Props> = ({ bid, priceLast24H, size = "md" }) => {
  const [priceChange, setPriceChange] = useState(() => calculateSymbolPriceChange({ bid, priceLast24H }));

  useEffect(() => {
    setPriceChange(calculateSymbolPriceChange({ bid, priceLast24H }));
  }, [bid, priceLast24H]);

  if (priceChange === null) {
    return null;
  }

  const color = getNumberColorClassname(priceChange.direction);

  return (
    <div className={cn("flex items-center", color ? color : "text-contrast-secondary")}>
      {priceChange.direction === 1 && <IconPriceChangeArrowUp />}
      {priceChange.direction === -1 && <IconPriceChangeArrowDown />}
      <Text variant={size === "md" ? "S / Regular" : "XS / Regular"} color="inherit">
        <NumberFormat value={priceChange.priceChange} decimalScale={2} suffix="%" />
      </Text>
    </div>
  );
};

const SymbolPriceChange = memo(_SymbolPriceChange);

export { SymbolPriceChange };
