import Big from "big.js";
import type { FC } from "react";
import { createContext, useContext, useMemo, useState } from "react";

import { TerminalDealType, type TerminalSymbolSignal, TradingAccountTradeMode } from "@/services/openapi";

import { useTerminalAccountSummary } from "../account-summary/context";
import { useTerminalAccountContext } from "../contexts/account.context";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { formatInputNumberValue, getInputNumberValue, getTickSizeFromDecimalScale } from "../helpers/formatting";
import { calculateMargin } from "../helpers/formulas";
import { useSignalContext } from "../signal/context";
import { getIsUpSignal } from "../signal/helpers";
import { getInitialSlPrice, getInitialTpPrice } from "./helpers";

enum PlaceOrderDirection {
  UP = "up",
  DOWN = "down",
}

enum VolumeLotsError {
  NONE = "none",
  MORE_THAN_FREE_MARGIN = "moreThanFreeMargin",
  LESS_THAN_SYSTEM = "lessThanSystem",
  MORE_THAN_SYSTEM = "moreThanSystem",
}

enum TakeProfitStopLossError {
  NONE = "none",
  MORE = "more",
  LESS = "less",
}

type FormValueType = string;
type ChangeInput = (value: FormValueType | number, config?: { format: boolean }) => void;

type ContextProps = {
  // slider
  sliderDisabled: boolean;
  sliderValue: number[];
  changeSliderValue: (value: number[]) => void;
  onSliderCommit: () => void;
  // direction
  direction: PlaceOrderDirection;
  changeDirection: (direction: PlaceOrderDirection) => void;
  // tp
  takeProfit: number | null;
  takeProfitThresholdValue: number;
  takeProfitError: TakeProfitStopLossError;
  takeProfitFormValue: FormValueType;
  changeTakeProfit: ChangeInput;
  takeProfitEnabled: boolean;
  changeTakeProfitEnabled: (value: boolean) => void;
  // sl
  stopLoss: number | null;
  stopLossThresholdValue: number;
  stopLossError: TakeProfitStopLossError;
  stopLossFormValue: FormValueType;
  changeStopLoss: ChangeInput;
  stopLossEnabled: boolean;
  changeStopLossEnabled: (value: boolean) => void;
  // pending order
  isPendingOrder: boolean;
  changeIsPendingOrder: (value: boolean) => void;
  openPrice: number | null;
  openPriceFormValue: FormValueType;
  changeOpenPrice: ChangeInput;
  openPriceOnBlur: () => void;
  // lots
  volumeLotsError: VolumeLotsError;
  volumeLots: number | null;
  volumeLotsFormValue: FormValueType;
  changeLots: ChangeInput;
  volumeLotsDecimalScale: number;
  maxBalanceVolumeLots: number; // max available lots considering account free margin
  minSystemVolumeLots: number; // min valid lots considering symbol settings
  maxSystemVolumeLots: number; // max valid lots considering symbol settings
  maxAvailableVolumeLots: number; // max valid lots considering symbol settings and account free margin
  // margin
  realTimeMargin: number | null; // for info display
  volumeMargin: number | null;
  volumeMarginFormValue: FormValueType;
  changeMargin: ChangeInput;
  volumeMarginDecimalScale: number;
  maxBalanceVolumeMargin: number; // max available margin considering account free margin
  minSystemVolumeMargin: number; // min valid margin considering symbol settings
  maxSystemVolumeMargin: number; // max valid margin considering symbol settings
  maxAvailableVolumeMargin: number; // max valid margin considering symbol settings and account free margin
  //
  resetForm: () => void;
  volumeOnBlur: () => void;
  volumeMode: TradingAccountTradeMode;
  changeVolumeMode: (mode: TradingAccountTradeMode) => void;
  hasNoFreeMargin: boolean;
  currency: string;
  ask: number;
  bid: number;
  currentPrice: number;
  isBuyOrder: boolean;
  hasErrors: boolean;
  isSignal: boolean;
  signal: TerminalSymbolSignal | undefined;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider: FC<{ children: React.ReactNode; isSignal?: boolean }> = ({ children, isSignal: isSignalProp }) => {
  const { symbolInfo } = useCurrentSymbolContext();

  const { changeAccountTradeMode, account } = useTerminalAccountContext();

  const { symbols } = useSymbolsContext();
  const { hasSignal } = useSignalContext();

  const {
    volumeMin: minSystemVolumeLots,
    volumeMax: maxSystemVolumeLots,
    volumeStep: volumeLotsStep,
    volumeDecimalScale: volumeLotsDecimalScale,
    baseCurrency,
    contractSize,
    quoteCurrency,
    type: instrumentType,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    priceDecimalScale,
    signal,
  } = symbolInfo;

  const isSignal = hasSignal && !!isSignalProp;

  const [takeProfitEnabled, setTakeProfitEnabled] = useState<ContextProps["takeProfitEnabled"]>(isSignal);
  const [stopLossEnabled, setStopLossEnabled] = useState<ContextProps["stopLossEnabled"]>(isSignal);
  const [isPendingOrder, setIsPendingOrder] = useState<ContextProps["isPendingOrder"]>(false);

  const [sliderValue, setSliderValue] = useState([0]);

  const [direction, setDirection] = useState<ContextProps["direction"]>(() => {
    if (isSignal) {
      return getIsUpSignal(signal!.type!) ? PlaceOrderDirection.UP : PlaceOrderDirection.DOWN;
    }
    return PlaceOrderDirection.UP;
  });

  const isBuyOrder = direction === PlaceOrderDirection.UP;

  const [openPriceFormValue, setOpenPrice] = useState<ContextProps["openPriceFormValue"]>("");
  const openPrice = useMemo(() => getInputNumberValue(openPriceFormValue), [openPriceFormValue]);

  const ask = symbolInfo.priceAsk || 0;
  const bid = symbolInfo.priceBid || 0;
  const bidAsk = isBuyOrder ? ask : bid;
  const currentPrice = isPendingOrder && openPrice ? openPrice : bidAsk;

  const [takeProfitFormValue, setTakeProfit] = useState<ContextProps["takeProfitFormValue"]>(() =>
    getInitialTpPrice({ isSignal, priceDecimalScale, signal }),
  );
  const takeProfit = useMemo(() => getInputNumberValue(takeProfitFormValue), [takeProfitFormValue]);
  const takeProfitThresholdValue: ContextProps["takeProfitThresholdValue"] = useMemo(() => {
    const multiplier = isPendingOrder ? 1 : isBuyOrder ? 1.001 : 0.999;
    return new Big(currentPrice).mul(multiplier).toNumber();
  }, [currentPrice, isBuyOrder, isPendingOrder]);

  const takeProfitError: ContextProps["takeProfitError"] = useMemo(() => {
    if (!takeProfitEnabled || takeProfit === null) {
      return TakeProfitStopLossError.NONE;
    }
    if (isBuyOrder) {
      if (takeProfit < currentPrice) {
        return TakeProfitStopLossError.MORE;
      }
    } else {
      if (takeProfit > currentPrice) {
        return TakeProfitStopLossError.LESS;
      }
    }
    return TakeProfitStopLossError.NONE;
  }, [takeProfit, takeProfitEnabled, currentPrice, isBuyOrder]);

  const [stopLossFormValue, setStopLoss] = useState<ContextProps["stopLossFormValue"]>(() =>
    getInitialSlPrice({ isSignal, priceDecimalScale, signal, currentPrice }),
  );
  const stopLoss = useMemo(() => getInputNumberValue(stopLossFormValue), [stopLossFormValue]);
  const stopLossThresholdValue: ContextProps["stopLossThresholdValue"] = useMemo(() => {
    const multiplier = isPendingOrder ? 1 : isBuyOrder ? 0.999 : 1.001;
    return new Big(currentPrice).mul(multiplier).toNumber();
  }, [currentPrice, isBuyOrder, isPendingOrder]);

  const stopLossError: ContextProps["stopLossError"] = useMemo(() => {
    if (!stopLossEnabled || stopLoss === null) {
      return TakeProfitStopLossError.NONE;
    }
    if (isBuyOrder) {
      if (stopLoss > currentPrice) {
        return TakeProfitStopLossError.LESS;
      }
    } else {
      if (stopLoss < currentPrice) {
        return TakeProfitStopLossError.MORE;
      }
    }
    return TakeProfitStopLossError.NONE;
  }, [stopLoss, stopLossEnabled, currentPrice, isBuyOrder]);

  const { marginFree, currency, leverage, currencyDecimalScale } = useTerminalAccountSummary();

  const volumeStepMargin = useMemo(() => {
    const result = new Big(
      calculateMargin({
        accountCurrency: currency,
        baseCurrency: baseCurrency!,
        contractSize: contractSize!,
        leverage,
        quoteCurrency: quoteCurrency!,
        volume: volumeLotsStep!,
        type: direction === PlaceOrderDirection.UP ? TerminalDealType.Buy : TerminalDealType.Sell,
        symbols,
        openPrice: currentPrice,
        instrumentType: instrumentType!,
        marginRate: "initial",
        marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
        marginRateInitialMarketSell: marginRateInitialMarketSell!,
        marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
        marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
      }),
    )
      .round(currencyDecimalScale, 1)
      .toNumber();
    // Returning a minimal available value, if it rounds to zero
    return result ? result : +getTickSizeFromDecimalScale(currencyDecimalScale);
  }, [
    direction,
    volumeLotsStep,
    currency,
    contractSize,
    instrumentType,
    baseCurrency,
    leverage,
    quoteCurrency,
    symbols,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    currentPrice,
    currencyDecimalScale,
  ]);

  const marginMultiplier = useMemo(() => {
    const result = new Big(volumeStepMargin).div(volumeLotsStep!).round(currencyDecimalScale).toNumber();
    return result ? result : +getTickSizeFromDecimalScale(currencyDecimalScale);
  }, [volumeStepMargin, volumeLotsStep, currencyDecimalScale]);

  const [volumeLotsFormValue, setVolumeLots] = useState<ContextProps["volumeLotsFormValue"]>(() =>
    formatInputNumberValue(minSystemVolumeLots, volumeLotsDecimalScale),
  );
  const volumeLots = useMemo(() => getInputNumberValue(volumeLotsFormValue), [volumeLotsFormValue]);

  const [volumeMarginFormValue, setVolumeMargin] = useState<ContextProps["volumeMarginFormValue"]>(() =>
    formatInputNumberValue(
      new Big(minSystemVolumeLots!).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
      currencyDecimalScale,
    ),
  );
  const volumeMargin = useMemo(() => getInputNumberValue(volumeMarginFormValue), [volumeMarginFormValue]);

  const changeDirection: ContextProps["changeDirection"] = value => {
    setDirection(value);
    setIsPendingOrder(false);
    setTakeProfitEnabled(false);
    setStopLossEnabled(false);
  };

  const changeIsPendingOrder: ContextProps["changeIsPendingOrder"] = value => {
    if (value) {
      setOpenPrice(formatInputNumberValue(bidAsk, priceDecimalScale));
    }
    setIsPendingOrder(value);
  };

  const changeTakeProfitEnabled: ContextProps["changeTakeProfitEnabled"] = value => {
    if (value) {
      const tp = isSignal
        ? getInitialTpPrice({ isSignal, priceDecimalScale, signal })
        : formatInputNumberValue(takeProfitThresholdValue, priceDecimalScale);
      setTakeProfit(tp);
    }
    setTakeProfitEnabled(value);
  };

  const changeStopLossEnabled: ContextProps["changeStopLossEnabled"] = value => {
    if (value) {
      const sl = isSignal
        ? getInitialSlPrice({ isSignal, priceDecimalScale, signal, currentPrice })
        : formatInputNumberValue(stopLossThresholdValue, priceDecimalScale);
      setStopLoss(sl);
    }
    setStopLossEnabled(value);
  };

  const changeLots: ContextProps["changeLots"] = (value, config) => {
    const parsedValue = getInputNumberValue(value);
    if (parsedValue === null) {
      setVolumeLots(value as string);
      setVolumeMargin("");
      return;
    }
    const { format } = config || { format: true };
    const newValue = format ? formatInputNumberValue(parsedValue, volumeLotsDecimalScale)! : (value as string);

    setVolumeLots(newValue);
    setVolumeMargin(
      formatInputNumberValue(
        new Big(parsedValue).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
        currencyDecimalScale,
      ),
    );
  };

  const changeMargin: ContextProps["changeMargin"] = (value, config) => {
    const parsedValue = getInputNumberValue(value);
    if (parsedValue === null) {
      setVolumeMargin(value as string);
      setVolumeLots("");
      return;
    }
    const { format } = config || { format: true };
    const newValue = format ? formatInputNumberValue(value, volumeLotsDecimalScale)! : (value as string);

    setVolumeMargin(newValue);
    setVolumeLots(
      formatInputNumberValue(
        new Big(parsedValue).div(marginMultiplier).round(volumeLotsDecimalScale, 0).toNumber() || minSystemVolumeLots,
        volumeLotsDecimalScale,
      ),
    );
  };

  const maxBalanceVolumeLots = useMemo(
    () => new Big(marginFree).div(volumeStepMargin).mul(volumeLotsStep!).round(volumeLotsDecimalScale, 0).toNumber(),
    [marginFree, volumeStepMargin, volumeLotsStep, volumeLotsDecimalScale],
  );

  const minSystemVolumeMargin = useMemo(
    () => new Big(minSystemVolumeLots!).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
    [minSystemVolumeLots, marginMultiplier, currencyDecimalScale],
  );

  const maxSystemVolumeMargin = useMemo(
    () => new Big(maxSystemVolumeLots!).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
    [maxSystemVolumeLots, marginMultiplier, currencyDecimalScale],
  );

  const maxBalanceVolumeMargin = useMemo(
    () => new Big(maxBalanceVolumeLots).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
    [maxBalanceVolumeLots, marginMultiplier, currencyDecimalScale],
  );

  const maxAvailableVolumeLots = useMemo(
    () => Math.min(maxBalanceVolumeLots, maxSystemVolumeLots!),
    [maxBalanceVolumeLots, maxSystemVolumeLots],
  );

  const maxAvailableVolumeMargin = useMemo(
    () => Math.min(maxBalanceVolumeMargin, maxSystemVolumeMargin),
    [maxBalanceVolumeMargin, maxSystemVolumeMargin],
  );

  const hasNoFreeMargin = useMemo(
    () => maxBalanceVolumeLots < minSystemVolumeLots!,
    [minSystemVolumeLots, maxBalanceVolumeLots],
  );

  const volumeLotsError = useMemo(() => {
    if (volumeLots === null || volumeLots < minSystemVolumeLots!) {
      return VolumeLotsError.LESS_THAN_SYSTEM;
    }

    if (volumeLots > maxAvailableVolumeLots) {
      return maxBalanceVolumeLots > maxSystemVolumeLots!
        ? VolumeLotsError.MORE_THAN_SYSTEM
        : VolumeLotsError.MORE_THAN_FREE_MARGIN;
    }

    if (volumeLots > maxSystemVolumeLots!) {
      return VolumeLotsError.MORE_THAN_SYSTEM;
    }
    if (volumeLots > maxBalanceVolumeLots) {
      return VolumeLotsError.MORE_THAN_FREE_MARGIN;
    }

    return VolumeLotsError.NONE;
  }, [volumeLots, minSystemVolumeLots, maxBalanceVolumeLots, maxSystemVolumeLots, maxAvailableVolumeLots]);

  const changeOpenPrice: ContextProps["changeOpenPrice"] = (openPrice, config) => {
    const { format } = config || { format: true };
    const newOpenPrice = format ? formatInputNumberValue(openPrice, priceDecimalScale) : (openPrice as string);
    setOpenPrice(newOpenPrice);
  };

  const sliderDisabled = useMemo(
    () => maxAvailableVolumeLots === minSystemVolumeLots || hasNoFreeMargin,
    [hasNoFreeMargin, maxAvailableVolumeLots, minSystemVolumeLots],
  );

  const changeSliderValue: ContextProps["changeSliderValue"] = value => {
    setSliderValue(value);
    if (sliderDisabled) return;

    const percent = value[0]!;

    if (percent === 0) {
      changeLots(minSystemVolumeLots!);
      return;
    }

    if (percent === 100) {
      changeLots(maxAvailableVolumeLots);
      return;
    }

    const lotsPercent = new Big(percent)
      .div(100)
      .mul(maxAvailableVolumeLots)
      .round(volumeLotsDecimalScale, 1)
      .toNumber();

    if (lotsPercent === 0) {
      changeLots(minSystemVolumeLots!);
      return;
    }

    changeLots(lotsPercent);
  };

  const onSliderCommit: ContextProps["onSliderCommit"] = () => {
    if (sliderDisabled) {
      setSliderValue([0]);
    }
  };

  const changeTakeProfit: ContextProps["changeTakeProfit"] = (openPrice, config) => {
    const { format } = config || { format: true };
    const newOpenPrice = format ? formatInputNumberValue(openPrice, priceDecimalScale) : (openPrice as string);
    setTakeProfit(newOpenPrice);
  };

  const changeStopLoss: ContextProps["changeStopLoss"] = (openPrice, config) => {
    const { format } = config || { format: true };
    const newOpenPrice = format ? formatInputNumberValue(openPrice, priceDecimalScale) : (openPrice as string);
    setStopLoss(newOpenPrice);
  };

  const changeVolumeMode: ContextProps["changeVolumeMode"] = mode => {
    changeAccountTradeMode(mode);
    if (volumeLots) {
      changeLots(volumeLots);
    }
  };

  const volumeOnBlur: ContextProps["volumeOnBlur"] = () => {
    if (volumeLots === null || volumeLots <= minSystemVolumeLots!) {
      setSliderValue([0]);
      return;
    }
    if (volumeLots >= maxAvailableVolumeLots) {
      setSliderValue([100]);
      return;
    }

    const percent = new Big(volumeLots).div(maxAvailableVolumeLots).mul(100).round(0, 1).toNumber();

    setSliderValue([percent]);
  };

  const openPriceOnBlur: ContextProps["openPriceOnBlur"] = () => {
    if (!openPrice) {
      changeOpenPrice(formatInputNumberValue(bidAsk, priceDecimalScale));
    }
    volumeOnBlur();
  };

  const realTimeMargin = useMemo(() => {
    if (!volumeLots) {
      return null;
    }

    return new Big(volumeLots).mul(marginMultiplier).round(currencyDecimalScale).toNumber();
  }, [volumeLots, marginMultiplier, currencyDecimalScale]);

  const resetForm: ContextProps["resetForm"] = () => {
    setIsPendingOrder(false);
    setTakeProfitEnabled(false);
    setStopLossEnabled(false);
    changeSliderValue([0]);
  };

  const hasErrors = useMemo(
    () =>
      takeProfitError !== TakeProfitStopLossError.NONE ||
      stopLossError !== TakeProfitStopLossError.NONE ||
      volumeLotsError !== VolumeLotsError.NONE,
    [takeProfitError, stopLossError, volumeLotsError],
  );

  return (
    <Context.Provider
      value={{
        signal,
        resetForm,
        isSignal,
        volumeOnBlur,
        sliderDisabled,
        onSliderCommit,
        sliderValue,
        changeSliderValue,
        hasErrors,
        takeProfitThresholdValue,
        stopLossThresholdValue,
        takeProfitError,
        stopLossError,
        changeStopLoss,
        changeTakeProfit,
        stopLoss,
        stopLossFormValue,
        takeProfit,
        takeProfitFormValue,
        takeProfitEnabled,
        changeTakeProfitEnabled,
        stopLossEnabled,
        changeStopLossEnabled,
        isBuyOrder,
        currentPrice,
        isPendingOrder,
        changeIsPendingOrder,
        openPriceFormValue,
        volumeLotsFormValue,
        volumeMarginFormValue,
        openPriceOnBlur,
        openPrice,
        changeOpenPrice,
        ask,
        bid,
        realTimeMargin,
        volumeLotsError,
        hasNoFreeMargin,
        volumeLotsDecimalScale: volumeLotsDecimalScale,
        maxAvailableVolumeLots,
        maxAvailableVolumeMargin,
        volumeMarginDecimalScale: currencyDecimalScale,
        maxBalanceVolumeLots,
        maxBalanceVolumeMargin,
        volumeMode: account.tradeMode!,
        volumeLots,
        volumeMargin,
        maxSystemVolumeMargin,
        changeVolumeMode,
        changeLots,
        changeMargin,
        minSystemVolumeLots: minSystemVolumeLots!,
        maxSystemVolumeLots: maxSystemVolumeLots!,
        minSystemVolumeMargin,
        currency,
        direction,
        changeDirection,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.displayName = "TerminalPlaceOrderProvider";

const usePlaceOrderContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("usePlaceOrderContext must be used within a TerminalPlaceOrderProvider");
  }

  return context;
};

export {
  PlaceOrderDirection,
  TakeProfitStopLossError,
  Provider as TerminalPlaceOrderProvider,
  usePlaceOrderContext,
  VolumeLotsError,
};
