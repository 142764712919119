import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentWrapper } from "@/features/payment/ui/wrapper/index";
import type { PaymentMethod } from "@/services/openapi";
import { useDepositMethodQuery } from "@/state/server/payment";

import { PaymentMethodStep } from "./step";

type Props = {
  accountId: string;
  setPaymentMethod: (method: PaymentMethod) => void;
  next: () => void;
};

const PaymentMethodStepContainer: FC<Props> = ({ accountId, next, setPaymentMethod }) => {
  const { t } = useTranslation();

  const { data: paymentMethodsData } = useDepositMethodQuery({
    accountId,
  });

  return (
    <PaymentWrapper title={t("payments.payment-method")}>
      {paymentMethodsData ? (
        <PaymentMethodStep
          next={next}
          paymentMethods={paymentMethodsData.items!.filter(({ isAvailable }) => isAvailable)}
          setPaymentMethod={setPaymentMethod}
        />
      ) : (
        <PaymentWrapper.Loader />
      )}
    </PaymentWrapper>
  );
};

export { PaymentMethodStepContainer };
