import { useQueryClient } from "react-query";

// import { updateAddress, updateProfile, updateSurvey } from "@/services/kyc";
// import { AddressUpdate, ProfileUpdate, SurveyUpdate } from "@/services/openapi";
import { updateProfile, updateSurvey } from "@/services/kyc";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseMutation } from "..";
import { profileQueryKeys } from "../profile";
import type { MutationOptionsType } from "../types";
import { kycQueryKeys } from "./kyc.keys";

// export const useUpdateAddressMutation = (options?: MutationOptionsType<void, AddressUpdate>) => {
//   const client = useQueryClient();
//   return useBaseMutation<void, AddressUpdate>(values => updateAddress(values), {
//     onSuccess: () => {
//       client.invalidateQueries(profileQueryKeys.header());
//       client.invalidateQueries(kycQueryKeys.info());
//     },
//     ...options,
//   });
// };

export const useUpdateProfileMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof updateProfile>, ApiRequestParamsType<typeof updateProfile>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof updateProfile>, ApiRequestParamsType<typeof updateProfile>>(
    updateProfile,
    {
      onSuccess: () => {
        client.invalidateQueries(profileQueryKeys.header());
        client.invalidateQueries(kycQueryKeys.info());
      },
      ...options,
    },
  );
};

export const useUpdateSurveyMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof updateSurvey>, ApiRequestParamsType<typeof updateSurvey>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof updateSurvey>, ApiRequestParamsType<typeof updateSurvey>>(
    updateSurvey,
    {
      onSuccess: () => {
        client.invalidateQueries(profileQueryKeys.header());
        client.invalidateQueries(kycQueryKeys.info());
      },
      ...options,
    },
  );
};
