import { type FC, useEffect } from "react";
import { useQueryClient } from "react-query";

import image from "@/assets/images/DialogImage4.svg";
import { Button } from "@/components/button";
import { InfoDialog } from "@/components/info-dialog";
import { useTranslation } from "@/hooks/translator.hook";
import { Dialog } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { profileQueryKeys } from "@/state/server/profile/profile.keys";

type Props = {
  email: string;
};

export const EmailConfirmDialogContent: FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const profileData = useProfileData();
  const queryClient = useQueryClient();

  useEffect(() => {
    const { options } = profileData;
    queryClient.setQueryData(profileQueryKeys.header(), () => ({
      ...profileData,
      options: {
        ...options,
        isEmailConfirmed: true,
      },
    }));
  }, []);

  return (
    <InfoDialog
      imageSrc={image}
      title={t("email-confirm.dialog-title")}
      description={t("email-confirm.dialog-description", { email })}
    >
      <div className="mt-[60px] flex justify-center">
        <Dialog.Close asChild>
          <Button>{t("button.continue")}</Button>
        </Dialog.Close>
      </div>
    </InfoDialog>
  );
};
