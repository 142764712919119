export interface ISkipTake {
  skip: number;
  take: number;
}
export interface IPagingFilters {
  page: number;
  pageSize: number;
}

export const calculateSkipTake = ({ page, pageSize }: IPagingFilters): ISkipTake => {
  const skip = (page - 1) * pageSize;
  return { skip, take: pageSize };
};

export type OmitSkipTake<T> = Omit<T, "skip" | "take">;

export type RequestParamsWithPaging<T = object> = OmitSkipTake<T> & IPagingFilters;

export const getPagingRequestParams = <T>(requestParams: RequestParamsWithPaging<T>) => {
  const { page, pageSize, ...restFilters } = requestParams;
  const skipTake = calculateSkipTake({
    page,
    pageSize,
  });
  return { ...restFilters, ...skipTake };
};

// helpers for component

export const MAX_PAGINATION_ITEMS = 5;
export const calculateTotalPages = (total: number, pageSize: number): number => {
  return Math.ceil(total / pageSize);
};

export const createVisiblePages = (totalPages: number, currentPage: number): number[] => {
  if (totalPages <= MAX_PAGINATION_ITEMS) {
    return new Array(totalPages).fill("").map((_, i) => i + 1);
  }

  const pages: number[] = [];

  for (let i = currentPage; i < MAX_PAGINATION_ITEMS + currentPage; i++) {
    if (currentPage <= Math.floor(MAX_PAGINATION_ITEMS / 2)) {
      pages.push(i - (currentPage - 1));
      continue;
    } else if (currentPage >= totalPages - Math.floor(MAX_PAGINATION_ITEMS / 2)) {
      pages.push(i - (MAX_PAGINATION_ITEMS - (totalPages - currentPage + 1)));
      continue;
    }
    pages.push(i - Math.floor(MAX_PAGINATION_ITEMS / 2));
  }

  return pages;
};
