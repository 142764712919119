import { type ElementRef, forwardRef } from "react";

import { Loader } from "@/shared/ui";

const InsightsLoader = forwardRef<ElementRef<"div">>((_, ref) => (
  <div className="my-6" ref={ref}>
    <Loader />
  </div>
));

export { InsightsLoader };
