/* eslint-disable @typescript-eslint/await-thenable */
import { terminalService } from "../api";
import type {
  TerminalApiAddLastIndicatorRequest,
  TerminalApiAddOrUpdateChartDataRequest,
  TerminalApiAddStudyTemplateDataRequest,
  TerminalApiAddSymbolToChartsRequest,
  TerminalApiAddSymbolToFavoritesRequest,
  TerminalApiCloseOrdersRequest,
  TerminalApiGetAccountSymbolsRequest,
  TerminalApiGetChartDataRequest,
  TerminalApiGetChartsMetaInfoRequest,
  TerminalApiGetLastIndicatorRequest,
  TerminalApiGetStudyTemplateDataRequest,
  TerminalApiGetStudyTemplatesMetaInfoRequest,
  TerminalApiGetSymbolChartsMsRequest,
  TerminalApiGetSymbolsRequest,
  TerminalApiGetSymbolsUpdatesRequest,
  TerminalApiGetTerminalAccountTokenRequest,
  TerminalApiGetTradingCentralCalendarCredentialsRequest,
  TerminalApiGetTradingCentralNewsRequest,
  TerminalApiModifyOrderRequest,
  TerminalApiOpenOrderRequest,
  TerminalApiPositionsHistoryRequest,
  TerminalApiRemoveChartDataRequest,
  TerminalApiRemoveStudyTemplateDataRequest,
  TerminalApiRemoveSymbolFromChartsRequest,
  TerminalApiRemoveSymbolFromFavoritesRequest,
  TerminalApiUpdateFavoriteSymbolsGroupRequest,
} from "../openapi";

const getTerminalSymbols = async (values: TerminalApiGetSymbolsRequest) =>
  await (
    await terminalService().getSymbols(values)
  ).data;

const getSymbolChartsMs = async (values: TerminalApiGetSymbolChartsMsRequest) =>
  await (
    await terminalService().getSymbolChartsMs(values)
  ).data;

const getTerminalPositionsHistory = async (values: TerminalApiPositionsHistoryRequest) =>
  await (
    await terminalService().positionsHistory(values)
  ).data;

const getTerminalAccountSymbols = async (values: TerminalApiGetAccountSymbolsRequest) =>
  await (
    await terminalService().getAccountSymbols(values)
  ).data;

const modifyTerminalOrder = async (values: TerminalApiModifyOrderRequest) =>
  await (
    await terminalService().modifyOrder(values)
  ).data;

const closeTerminalOrders = async (values: TerminalApiCloseOrdersRequest) =>
  await (
    await terminalService().closeOrders(values)
  ).data;

const openTerminalOrder = async (values: TerminalApiOpenOrderRequest) =>
  await (
    await terminalService().openOrder(values)
  ).data;

const addSymbolToCharts = async (values: TerminalApiAddSymbolToChartsRequest) =>
  await (
    await terminalService().addSymbolToCharts(values)
  ).data;

const removeSymbolFromCharts = async (values: TerminalApiRemoveSymbolFromChartsRequest) =>
  await (
    await terminalService().removeSymbolFromCharts(values)
  ).data;

const addSymbolToFavorites = async (values: TerminalApiAddSymbolToFavoritesRequest) =>
  await (
    await terminalService().addSymbolToFavorites(values)
  ).data;

const removeSymbolFromFavorites = async (values: TerminalApiRemoveSymbolFromFavoritesRequest) =>
  await (
    await terminalService().removeSymbolFromFavorites(values)
  ).data;

const updateFavoriteSymbolsGroup = async (values: TerminalApiUpdateFavoriteSymbolsGroupRequest) =>
  await (
    await terminalService().updateFavoriteSymbolsGroup(values)
  ).data;

const getTerminalAccountToken = async (values: TerminalApiGetTerminalAccountTokenRequest) =>
  await (
    await terminalService().getTerminalAccountToken(values)
  ).data;

const addStudyTemplateData = async (values: TerminalApiAddStudyTemplateDataRequest) =>
  await (
    await terminalService().addStudyTemplateData(values)
  ).data;

const getStudyTemplateData = async (values: TerminalApiGetStudyTemplateDataRequest) =>
  await (
    await terminalService().getStudyTemplateData(values)
  ).data;

const removeStudyTemplateData = async (values: TerminalApiRemoveStudyTemplateDataRequest) =>
  await (
    await terminalService().removeStudyTemplateData(values)
  ).data;

const getStudyTemplatesMetaInfo = async (values: TerminalApiGetStudyTemplatesMetaInfoRequest) =>
  await (
    await terminalService().getStudyTemplatesMetaInfo(values)
  ).data;

const addOrUpdateChartData = async (values: TerminalApiAddOrUpdateChartDataRequest) =>
  await (
    await terminalService().addOrUpdateChartData(values)
  ).data;

const getChartData = async (values: TerminalApiGetChartDataRequest) =>
  await (
    await terminalService().getChartData(values)
  ).data;

const removeChartData = async (values: TerminalApiRemoveChartDataRequest) =>
  await (
    await terminalService().removeChartData(values)
  ).data;

const getChartsMetaInfo = async (values: TerminalApiGetChartsMetaInfoRequest) =>
  await (
    await terminalService().getChartsMetaInfo(values)
  ).data;

const addLastIndicator = async (values: TerminalApiAddLastIndicatorRequest) =>
  await (
    await terminalService().addLastIndicator(values)
  ).data;

const getLastIndicator = async (values: TerminalApiGetLastIndicatorRequest) =>
  await (
    await terminalService().getLastIndicator(values)
  ).data;

const getTradingCentralCalendarCredentials = async (values: TerminalApiGetTradingCentralCalendarCredentialsRequest) =>
  await (
    await terminalService().getTradingCentralCalendarCredentials(values)
  ).data;

const getSymbolsUpdates = async (values: TerminalApiGetSymbolsUpdatesRequest) =>
  await (
    await terminalService().getSymbolsUpdates(values)
  ).data;

const getTradingCentralNews = async (values: TerminalApiGetTradingCentralNewsRequest) =>
  await (
    await terminalService().getTradingCentralNews(values)
  ).data;

export {
  getTerminalSymbols,
  getSymbolChartsMs,
  getTerminalPositionsHistory,
  getTerminalAccountSymbols,
  modifyTerminalOrder,
  closeTerminalOrders,
  openTerminalOrder,
  addSymbolToCharts,
  removeSymbolFromCharts,
  addSymbolToFavorites,
  removeSymbolFromFavorites,
  updateFavoriteSymbolsGroup,
  getTerminalAccountToken,
  addStudyTemplateData,
  getStudyTemplateData,
  removeStudyTemplateData,
  getStudyTemplatesMetaInfo,
  addOrUpdateChartData,
  getChartData,
  removeChartData,
  getChartsMetaInfo,
  addLastIndicator,
  getLastIndicator,
  getTradingCentralCalendarCredentials,
  getSymbolsUpdates,
  getTradingCentralNews,
};
