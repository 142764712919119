import { signal } from "@maverick-js/signals";
import { useEffect, useRef } from "react";

// https://dev.to/this-is-learning/the-evolution-of-signals-in-javascript-8ob

// it is very simple and stupid implementation of signals in react and it does not affect react rendering model
// it is only used to pass reactive values to static functions like "getDatafeed"
const useSignal = <T>(value: T) => {
  const ref = useRef(signal(value));

  useEffect(() => {
    ref.current.set(value);
  }, [value]);

  return ref.current;
};

export { useSignal };
