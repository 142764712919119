import type { ComponentProps, FC } from "react";
import { useCallback } from "react";

import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { TerminalDealType } from "@/services/openapi";
import { useModifyTerminalOrderMutation } from "@/state/server/terminal";

import { ModifyTakeProfitForm } from "./take-profit.form";

type Props = {
  accountId: string;
  orderId: number;
  priceDecimalScale: number;
  takeProfit: number | undefined;
  stopLoss: number | undefined;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  onClose: () => void;
  symbol: string;
  volume: number;
  currencyDecimalScale: number;
  baseCurrency: string;
  quoteCurrency: string;
  contractSize: number;
  currency: string;
  openPrice: number;
  currentPrice: number;
  profitRate: number | undefined;
  isMobile?: boolean;
};

export const ModifyOpenOrderTakeProfitContainer: FC<Props> = ({
  accountId,
  orderId,
  onClose,
  priceDecimalScale,
  takeProfit,
  ask,
  bid,
  orderType,
  symbol,
  volume,
  stopLoss,
  baseCurrency,
  contractSize,
  currency,
  openPrice,
  quoteCurrency,
  isMobile,
  currentPrice,
  currencyDecimalScale,
  profitRate,
}) => {
  const { mutateAsync: modifyOrder } = useModifyTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof ModifyTakeProfitForm>["onSubmit"] = useCallback(
    ({ takeProfit }) => {
      return modifyOrder(
        {
          tradingAccountId: accountId,
          id: orderId,
          terminalModifyOrderRequest: {
            takeProfit: getInputNumberValue(takeProfit),
            stopLoss,
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [modifyOrder, accountId, orderId, onClose, stopLoss],
  );

  return (
    <ModifyTakeProfitForm
      onSubmit={handleSubmit}
      priceDecimalScale={priceDecimalScale}
      takeProfit={takeProfit}
      ask={ask}
      bid={bid}
      orderType={orderType}
      baseCurrency={baseCurrency}
      contractSize={contractSize}
      currency={currency}
      openPrice={openPrice}
      quoteCurrency={quoteCurrency}
      volume={volume}
      isMobile={isMobile}
      symbol={symbol}
      currentPrice={currentPrice}
      currencyDecimalScale={currencyDecimalScale}
      profitRate={profitRate}
    />
  );
};
