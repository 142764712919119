import { useEffect } from "react";

import { GlobalLoader } from "@/components/loader";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";

/* TODO: Remove this page */
/* We need it because of old versions of mobile app */
/* We don't use it on web */
export const TerminalAutoRoutePage = () => {
  const { open } = useTerminalAutoRoute();

  useEffect(() => {
    open();
  }, []);

  return <GlobalLoader />;
};
