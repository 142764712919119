import type { FC, ReactNode } from "react";
import { Drawer } from "vaul";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { overlayStyles } from "@/shared/ui";
import { type CSS, styled } from "@/styles";

import { Text } from "../text";

const StyledContent = styled(Drawer.Content, {
  position: "fixed",
  zIndex: 50,
  left: "0",
  bottom: "0",
  right: "0",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "$lite",
  borderRadius: "24px 24px 0 0",
  overflow: "hidden",
  p: "8px 0",
});

const GreyBox = styled("div", {
  width: "40px",
  height: "4px",
  margin: "0 auto 24px",
  borderRadius: "100px",
  backgroundColor: "$gray",
});

type BottomSheetContentProps = React.ComponentProps<typeof StyledContent> & {
  css?: CSS;
  title?: string;
  description?: ReactNode;
  image?: {
    src: string;
    alt?: string;
  };
};

const BottomSheetContent: FC<BottomSheetContentProps> = (
  { children, title, description, image, ...props },
  forwardedRef,
) => {
  const { isLargeDesktop } = useScreenSize();

  return (
    <Drawer.Portal>
      <Drawer.Overlay className={overlayStyles({ hasAnimation: false })} />
      <StyledContent
        onOpenAutoFocus={e => !isLargeDesktop && e.preventDefault()}
        onCloseAutoFocus={e => !isLargeDesktop && e.preventDefault()}
        {...props}
      >
        <GreyBox />

        {image && (
          <div className="pointer-events-none mx-auto mb-[25px]">
            <img alt={image.alt} src={image.src} />
          </div>
        )}

        <div className="px-[24px]">
          {title && (
            <BottomSheetTitle asChild>
              <Text size={5} weight="bold" align="center">
                {title}
              </Text>
            </BottomSheetTitle>
          )}
          {description && (
            <BottomSheetDescription asChild>
              <Text family="roboto" align="center" css={{ mt: "16px" }}>
                {description}
              </Text>
            </BottomSheetDescription>
          )}
        </div>
        <div className="mt-[30px]">{children}</div>
      </StyledContent>
    </Drawer.Portal>
  );
};

const BottomSheet = Drawer.Root;
const BottomSheetTrigger = Drawer.Trigger;
const BottomSheetDescription = Drawer.Description;
const BottomSheetTitle = Drawer.Title;

export { BottomSheet, BottomSheetContent, BottomSheetDescription, BottomSheetTrigger, BottomSheetTitle };
