import { useQueryClient } from "react-query";

import { useAuth } from "@/contexts/auth.context";
import {
  changePassword,
  confirmEmail,
  confirmTwoStepAuth,
  createTwoStepAuth,
  createTwoStepAuthRecoveryCodes,
  disableTwoStepAuth,
  forgotPassword,
  resendConfirmationLink,
  resetPassword,
  signIn,
  signInFacebook,
  signInGoogle,
  signUp,
} from "@/services/auth";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { token } from "@/utils/token";

import { useBaseMutation } from "..";
import { profileQueryKeys } from "../profile";
import type { MutationOptionsType } from "../types";

export const useForgotPasswordMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof forgotPassword>, ApiRequestParamsType<typeof forgotPassword>>,
) => {
  return useBaseMutation<ApiResponseType<typeof forgotPassword>, ApiRequestParamsType<typeof forgotPassword>>(
    forgotPassword,
    options,
  );
};

export const useResetPasswordMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof resetPassword>, ApiRequestParamsType<typeof resetPassword>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof resetPassword>, ApiRequestParamsType<typeof resetPassword>>(
    resetPassword,
    {
      onSuccess: token => {
        login(token);
      },
      successMessage: "password-changed",
      ...options,
    },
  );
};

export const useSignInMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signIn>, ApiRequestParamsType<typeof signIn>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof signIn>, ApiRequestParamsType<typeof signIn>>(signIn, {
    onSuccess: token => {
      login(token);
    },
    ...options,
  });
};

export const useSignUpMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signUp>, ApiRequestParamsType<typeof signUp>>,
) => {
  return useBaseMutation<ApiResponseType<typeof signUp>, ApiRequestParamsType<typeof signUp>>(signUp, options);
};

export const useResendConfirmationLinkMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof resendConfirmationLink>,
    ApiRequestParamsType<typeof resendConfirmationLink>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof resendConfirmationLink>,
    ApiRequestParamsType<typeof resendConfirmationLink>
  >(resendConfirmationLink, options);
};

export const useConfirmEmailMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof confirmEmail>, ApiRequestParamsType<typeof confirmEmail>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof confirmEmail>, ApiRequestParamsType<typeof confirmEmail>>(
    confirmEmail,
    {
      onSuccess: token => {
        login(token);
      },
      ...options,
    },
  );
};

export const useChangePasswordMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof changePassword>, ApiRequestParamsType<typeof changePassword>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof changePassword>, ApiRequestParamsType<typeof changePassword>>(
    changePassword,
    {
      onSuccess: response => {
        token.setToken(response);
        client.invalidateQueries(profileQueryKeys.header());
      },
      ...options,
    },
  );
};

export const useConfirmTwoFAMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof confirmTwoStepAuth>,
    ApiRequestParamsType<typeof confirmTwoStepAuth>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof confirmTwoStepAuth>, ApiRequestParamsType<typeof confirmTwoStepAuth>>(
    confirmTwoStepAuth,
    {
      onSuccess: res => {
        token.setToken(res.authToken!);
        client.invalidateQueries(profileQueryKeys.header());
      },
      ...options,
    },
  );
};

export const useCreateTwoFAMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof createTwoStepAuth>,
    ApiRequestParamsType<typeof createTwoStepAuth>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof createTwoStepAuth>, ApiRequestParamsType<typeof createTwoStepAuth>>(
    createTwoStepAuth,
    options,
  );
};

export const useDisableTwoFAMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof disableTwoStepAuth>,
    ApiRequestParamsType<typeof disableTwoStepAuth>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof disableTwoStepAuth>, ApiRequestParamsType<typeof disableTwoStepAuth>>(
    disableTwoStepAuth,
    {
      onSuccess: () => {
        client.invalidateQueries(profileQueryKeys.header());
      },
      ...options,
    },
  );
};

export const useCreateNewRecoveryCodesMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof createTwoStepAuthRecoveryCodes>,
    ApiRequestParamsType<typeof createTwoStepAuthRecoveryCodes>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof createTwoStepAuthRecoveryCodes>,
    ApiRequestParamsType<typeof createTwoStepAuthRecoveryCodes>
  >(createTwoStepAuthRecoveryCodes, options);
};

export const useSignInGoogleMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signInGoogle>, ApiRequestParamsType<typeof signInGoogle>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof signInGoogle>, ApiRequestParamsType<typeof signInGoogle>>(
    signInGoogle,
    {
      onSuccess: res => {
        login(res.authToken!);
      },
      ...options,
    },
  );
};

export const useSignInFacebookMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signInFacebook>, ApiRequestParamsType<typeof signInFacebook>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof signInFacebook>, ApiRequestParamsType<typeof signInFacebook>>(
    signInFacebook,
    {
      onSuccess: res => {
        login(res.authToken!);
      },
      ...options,
    },
  );
};
