import isNil from "lodash/isNil";
import type { ChangeEvent } from "react";
import { forwardRef, useCallback } from "react";

import { useIcons } from "@/domains/icons/icon.provider";

import { InputBase } from "../InputBase/InputBase";
import * as Styled from "./InputText.styled";
import type { InputTextProps } from "./InputText.types";

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      className,
      children,
      onChange: originalOnChange,
      clearable,
      // loading,
      iconStart,
      iconEnd,
      value,
      inputSize,
      dataTest = "InputText",
      ...otherProps
    },
    ref,
  ) => {
    const { IconCross } = useIcons();

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        originalOnChange && originalOnChange(newValue, e);
      },
      [originalOnChange],
    );

    const onClearClick = () => {
      originalOnChange && originalOnChange("", {} as ChangeEvent<HTMLInputElement>);
    };

    const hasValue = !isNil(value) && value !== "";
    const shouldRenderClearButton = clearable && hasValue;

    return (
      <InputBase
        ref={ref}
        className={className}
        dataTest={dataTest}
        onChange={onChange}
        value={value}
        inputSize={inputSize}
        // loading={loading}
        iconStart={iconStart}
        iconEnd={
          shouldRenderClearButton ? (
            <Styled.Button
              type="button"
              tabIndex={-1}
              onClick={onClearClick}
              data-test={dataTest + "__ClearButton"}
              disabled={otherProps.disabled}
            >
              <IconCross />
            </Styled.Button>
          ) : (
            iconEnd
          )
        }
        {...otherProps}
      />
    );
  },
);
InputText.displayName = "InputText";
