import { type FC, type ReactNode } from "react";

type Props = {
  label: ReactNode;
  description: ReactNode;
};

const TextBlock: FC<Props> = ({ label, description }) => (
  <div className="flex flex-col gap-2">
    <div className="font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-primary">{label}</div>
    <div className="font-roboto text-[14px] leading-normal text-contrast-secondary">{description}</div>
  </div>
);

export { TextBlock };
