import { type FC, memo } from "react";

import { TradingCentralTheme } from "@/services/openapi";
import { useTradingCentralCalendarCredentialsQuery } from "@/state/server/terminal";

import { InsightsLoader } from "../ui/loader";

const CalendarContainer: FC = () => {
  const { data } = useTradingCentralCalendarCredentialsQuery({
    terminalTradingCentralHandShake: { theme: TradingCentralTheme.Light, page: "economic_calendar" },
  });

  if (!data) {
    return <InsightsLoader />;
  }

  return (
    <div className="h-full lg:-mb-4 lg:grow">
      <div className="-mx-4 h-full">
        <iframe
          id="tradingcentral"
          src={`https://site.tradingcentral.com/doto/serve.shtml?tkn=${data.token}`}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

const Component = memo(CalendarContainer);

export { Component as CalendarContainer };
