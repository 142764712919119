import { type FC } from "react";

import { IconCross } from "@/domains/icons";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { useSignalContext } from "../../signal/context";
import { SignalTextFormat } from "../../signal/text-format";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";

const PlaceOrderSignalBlock: FC = () => {
  const { closeSignalForm } = useSignalContext();
  const { symbolInfo } = useCurrentSymbolContext();

  return (
    <div
      className="rounded-t-[24px] bg-surface-elevation-1 animate-in fade-in-0"
      style={{
        boxShadow: "0px -6px 8px 0px rgba(0, 0, 0, 0.03)",
      }}
    >
      <div className="grid grid-cols-3 items-center justify-between px-2 py-5">
        <div />
        <h5 className="text-center font-gilroy text-[18px] font-semibold leading-[1.2] text-contrast-primary">
          <SignalTextFormat type={symbolInfo.signal!.type!} />
        </h5>
        <button className="ms-auto p-2" onClick={closeSignalForm}>
          <IconCross />
        </button>
      </div>
      <TerminalPlaceOrderProvider isSignal>
        <PlaceOrderContainer />
      </TerminalPlaceOrderProvider>
    </div>
  );
};

export { PlaceOrderSignalBlock };
