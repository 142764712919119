import { useState } from "react";

import { useDebounce } from "./debounce.hook";

export const useSearch = (initialValue?: string) => {
  const [search, setSearch] = useState(initialValue || "");
  const mask = useDebounce(search, 300);

  return { search, setSearch, mask };
};
