import type { FC } from "react";
import { memo, useCallback } from "react";

import { useDemoDepositMutation } from "@/state/server/accounts";

import { type ISetBalanceFormValues, SetBalanceForm } from "./set-balance.form";

type Props = {
  accountId: string;
  currency: string;
  onClose: () => void;
};

export const _SetBalanceContainer: FC<Props> = ({ accountId, currency, onClose }) => {
  const { mutateAsync: demoDeposit } = useDemoDepositMutation({
    successMessage: "demo-balance-added",
  });

  const submitDeposit = useCallback(
    ({ value }: ISetBalanceFormValues) => {
      return demoDeposit(
        { id: accountId, decimalContainer: { value } },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [demoDeposit, accountId, onClose],
  );

  return <SetBalanceForm onSubmit={submitDeposit} currency={currency} />;
};

export const SetBalanceContainer = memo(_SetBalanceContainer);
