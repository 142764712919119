import { forwardRef } from "react";

import { StyledInput } from "../styles";
import { InputWrapper, type InputWrapperExternalProps } from "../wrapper";

export type TextInputExternalProps = InputWrapperExternalProps & Omit<React.ComponentProps<typeof StyledInput>, "size">;

type Props = TextInputExternalProps;

export const TextInput = forwardRef<React.ElementRef<typeof StyledInput>, Props>((props, ref) => {
  const {
    css,
    leftAdornment,
    rightAdornment,
    showError,
    error,
    bottomOffset,
    type = "text",
    name,
    label,
    disabled,
    size,
    description,
    border,
    passwordText,
    ...inputProps
  } = props;

  return (
    <InputWrapper
      css={css}
      leftAdornment={leftAdornment}
      rightAdornment={rightAdornment}
      showError={showError}
      error={error}
      bottomOffset={bottomOffset}
      name={name}
      label={label}
      disabled={disabled}
      size={size}
      description={description}
      border={border}
      passwordText={passwordText}
    >
      <StyledInput {...inputProps} ref={ref} type={type} id={name} name={name} disabled={disabled} />
    </InputWrapper>
  );
});
TextInput.displayName = "TextInput";
