import { GoogleOAuthProvider as LibProvider } from "@react-oauth/google";

import { usePlatformData } from "@/state/server/platform/platform.hooks";

export const GoogleOAuthProvider = ({ children }: { children: React.ReactElement }) => {
  const platform = usePlatformData();

  const clientId = platform.signIn!.google!.clientId;

  if (!clientId) {
    return children;
  }

  return <LibProvider clientId={clientId}>{children}</LibProvider>;
};
