import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { cn } from "@/shared/styles";

const TooltipQuestionIcon = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>(
  ({ className, ...props }, ref) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(
        "text-contrast-tertiary transition-colors data-[state=delayed-open]:text-contrast-primary data-[state=instant-open]:text-contrast-primary",
        className,
      )}
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM5.33336 5.66667C5.26879 6.18193 5.48551 6.60422 6.00005 6.66667C6.80105 6.76387 6.89104 6.41835 6.99596 6.01549L7 6C7.0873 5.66524 7.34671 5.26667 7.99999 5.26667C8.65327 5.26667 9.20002 5.67201 9.20002 6.33333C9.20002 7.00588 8.76581 7.33054 8.31622 7.66668C7.83363 8.0275 7.33333 8.40157 7.33333 9.23333C7.33333 9.7 7.53333 10 8.06667 10C8.46667 10 8.66667 9.73333 8.66667 9.4C8.66666 8.96873 9.06346 8.66251 9.51479 8.3142C10.11 7.85488 10.8 7.32236 10.8 6.33333C10.8 4.66667 9.39075 4 7.97369 4C6.55663 4 5.44611 4.76687 5.33336 5.66667ZM8.73327 11.3334C8.73327 11.7384 8.40494 12.0667 7.99994 12.0667C7.59493 12.0667 7.2666 11.7384 7.2666 11.3334C7.2666 10.9283 7.59493 10.6 7.99994 10.6C8.40494 10.6 8.73327 10.9283 8.73327 11.3334Z"
        fill="currentColor"
      />
    </svg>
  ),
);

TooltipQuestionIcon.displayName = "TooltipQuestionIcon";

export { TooltipQuestionIcon };
