import type { FC } from "react";
import { useState } from "react";

import { Flex } from "@/components/flex";
import { TwoFactorIcon } from "@/components/icons";
import { Text } from "@/components/text";
import { CabinetInputWrapper } from "@/features/cabinet/components/cabinet-styles";
import { useTranslation } from "@/hooks/translator.hook";
import type { TwoFactor, TwoFactorRecoveryCode } from "@/services/openapi";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { ConfirmTwoFAContainer } from "./components/confirm-two-fa.container";
import { DisableTwoFAContainer } from "./components/disable-two-fa.container";
import { DisabledTwoFAContainer } from "./components/disabled-two-fa.container";
import { EnabledTwoFAContainer } from "./components/enabled-two-fa-container";
import { NewRecoveryCodesContainer } from "./components/new-recovery-codes.container";
import { RecoveryCodesContainer } from "./components/recovery-codes.container";

export enum TwoFASteps {
  DISABLED = "disabled",
  ENABLED = "enabled",
  CONFIRM = "confirm",
  DISABLE = "disable",
  RECOVERY_CODES = "recoveryCodes",
  NEW_RECOVERY_CODES = "newRecoveryCodes",
}

export const TwoFactorContainer: FC = () => {
  const { t } = useTranslation();
  const { options } = useProfileData();
  const { is2FaEnabled } = options!;

  const [step, setStep] = useState<TwoFASteps>(is2FaEnabled ? TwoFASteps.ENABLED : TwoFASteps.DISABLED);
  const [twoFactorData, setTwoFactorData] = useState<TwoFactor>();
  const [recoveryCodes, setRecoveryCodes] = useState<TwoFactorRecoveryCode[]>();

  return (
    <>
      <Flex css={{ alignItems: "center", gap: "10px", mb: "16px", color: "$textMain" }}>
        <TwoFactorIcon />
        <Text as="h2" weight="bold" size="4">
          {t("two-factor.settings.title")}
        </Text>
      </Flex>
      <CabinetInputWrapper>
        {step === TwoFASteps.DISABLED && (
          <DisabledTwoFAContainer setStep={setStep} setTwoFactorData={setTwoFactorData} />
        )}
        {step === TwoFASteps.CONFIRM && twoFactorData && (
          <ConfirmTwoFAContainer setStep={setStep} twoFactorData={twoFactorData} setRecoveryCodes={setRecoveryCodes} />
        )}
        {step === TwoFASteps.RECOVERY_CODES && recoveryCodes && (
          <RecoveryCodesContainer recoveryCodes={recoveryCodes} />
        )}
        {step === TwoFASteps.NEW_RECOVERY_CODES && (
          <NewRecoveryCodesContainer setRecoveryCodes={setRecoveryCodes} setStep={setStep} />
        )}
        {step === TwoFASteps.DISABLE && <DisableTwoFAContainer setStep={setStep} />}
        {step === TwoFASteps.ENABLED && <EnabledTwoFAContainer setStep={setStep} />}
      </CabinetInputWrapper>
    </>
  );
};
