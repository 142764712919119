import type { FC } from "react";
import { useMemo } from "react";

import { Header } from "@/components/header";
import { MT4TerminalContainer } from "@/features/mt-terminal/mt4-terminal.container";
import { TradingServerPlatform } from "@/services/openapi";
import { useServersQuery } from "@/state/server/accounts";

export const MT4TerminalPage: FC = () => {
  const { data: servers } = useServersQuery();

  const mt4Servers = useMemo(
    () => servers?.filter(({ platformType }) => platformType === TradingServerPlatform.MetaTrader4),
    [servers],
  );

  if (!servers) return null;

  return (
    <>
      <Header />
      <MT4TerminalContainer servers={mt4Servers!} />
    </>
  );
};
