import { styled } from "@/styles";

import { mixinsCommon } from "../mixins/common";

export const Button = styled("div", {
  position: "relative",
  width: "270px",

  borderRadius: "24px",
  backgroundColor: "$bgPrimary",
  boxShadow: "0px 10px 40px -10px rgba(21, 38, 149, 0.15)",
  maxHeight: "400px",
});

export const Input = styled("div", {
  position: "relative",
  width: "270px",

  borderRadius: "24px",
  backgroundColor: "$bgPrimary",
  boxShadow: "0px 10px 40px -10px rgba(21, 38, 149, 0.15)",
  maxHeight: "400px",
});

export const InputWrapper = styled("div", {
  padding: "16px",
});

export const List = styled("div", {
  maxHeight: "300px",
  borderRadius: "0 0 24px 24px",
  overflowY: "auto",
  overflowX: "hidden",
});

export const Item = styled("div", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  outline: "none",
  padding: "16px",

  cursor: "default",
  userSelect: "none",

  "&[aria-selected]": {
    backgroundColor: "$bgSelected",
  },

  "&[data-[disabled]": {
    pointerEvents: "none",
  },

  "&:hover": {
    backgroundColor: "$bgSelected",
  },

  variants: {
    disabled: {
      true: mixinsCommon.disable!,
    },
    selected: {
      true: {
        backgroundColor: "$bgSelected",
      },
    },
  },
});
