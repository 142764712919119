import { type FC, memo } from "react";

import { NumberFormat } from "@/app/components";
import { TerminalBackDialogContent } from "@/features/terminal/components/terminal-back-dialog-content";
import type { PendingOrder } from "@/features/terminal/orders/extended-orders.context";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { useTranslation } from "@/hooks/translator.hook";

import { OrderType } from "../../components/trading-table/order-type";
import { OpeningPriceContainer } from "../../modify-order/opening-price/opening-price.container";
import { ModifyPendingOrderStopLossContainer } from "../../modify-order/stop-loss/pending-order-stop-loss.container";
import { ModifyPendingOrderTakeProfitContainer } from "../../modify-order/take-profit/pending-order-take-profit.container";
import { TradingCard } from "../card";
import { ModifyOrderButton } from "../modify-order/button";
import { ClosePendingOrderButton } from "./close-button";

type Props = {
  order: PendingOrder;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
};

const _PendingOrderCard: FC<Props> = ({ order, accountId, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    ask,
    bid,
    currentPrice,
    volumeDecimalScale,
    priceDecimalScale,
    baseCurrency,
    contractSize,
    quoteCurrency,
    margin,
  } = order;

  return (
    <TradingCard
      header={
        <TradingCard.Header
          type={type}
          volume={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}
          endBottomSection={<>{price ? <NumberFormat value={price} decimalScale={priceDecimalScale} /> : "—"}</>}
          symbol={symbol}
          endTopSection={<TradingCard.Text size="md">{t("terminal.open-price")!}</TradingCard.Text>}
        />
      }
      body={
        <TradingCard.Body>
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("common.type")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <OrderType type={type} withCircle={false} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.volume-lot")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <NumberFormat value={volume} decimalScale={volumeDecimalScale} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={
              <TradingCard.ListLabel>{t("terminal.margin-currency", { currency: currency })}</TradingCard.ListLabel>
            }
            end={
              <TradingCard.ListValue>
                <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.open-price")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue filled>
                <ModifyOrderButton value={<NumberFormat value={price} decimalScale={priceDecimalScale} />}>
                  {onClose => (
                    <TerminalBackDialogContent title={t("terminal.open-price")!}>
                      <OpeningPriceContainer
                        onClose={onClose}
                        openPrice={price}
                        orderType={type}
                        orderId={id}
                        priceDecimalScale={priceDecimalScale}
                        stopLoss={stopLoss}
                        takeProfit={takeProfit}
                        ask={ask}
                        bid={bid}
                        accountId={accountId}
                        currentPrice={currentPrice!}
                        symbol={symbol}
                        volume={volume}
                        volumeDecimalScale={volumeDecimalScale}
                        isMobile
                      />
                    </TerminalBackDialogContent>
                  )}
                </ModifyOrderButton>
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.current-price")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                {currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.take-profit")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue filled>
                <ModifyOrderButton
                  value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={priceDecimalScale} /> : null}
                >
                  {onClose => (
                    <TerminalBackDialogContent title={t("terminal.take-profit")!}>
                      <ModifyPendingOrderTakeProfitContainer
                        onClose={onClose}
                        openPrice={price}
                        orderType={type}
                        orderId={id}
                        priceDecimalScale={priceDecimalScale}
                        stopLoss={stopLoss}
                        takeProfit={takeProfit}
                        ask={ask}
                        bid={bid}
                        accountId={accountId}
                        baseCurrency={baseCurrency}
                        quoteCurrency={quoteCurrency}
                        contractSize={contractSize}
                        volume={volume}
                        currency={currency}
                        currentPrice={currentPrice!}
                        symbol={symbol}
                        currencyDecimalScale={currencyDecimalScale}
                        isMobile
                      />
                    </TerminalBackDialogContent>
                  )}
                </ModifyOrderButton>
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.stop-loss")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue filled>
                <ModifyOrderButton
                  value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={priceDecimalScale} /> : null}
                >
                  {onClose => (
                    <TerminalBackDialogContent title={t("terminal.stop-loss")!}>
                      <ModifyPendingOrderStopLossContainer
                        onClose={onClose}
                        openPrice={price}
                        orderType={type}
                        orderId={id}
                        priceDecimalScale={priceDecimalScale}
                        stopLoss={stopLoss}
                        takeProfit={takeProfit}
                        ask={ask}
                        bid={bid}
                        accountId={accountId}
                        baseCurrency={baseCurrency}
                        quoteCurrency={quoteCurrency}
                        contractSize={contractSize}
                        volume={volume}
                        currency={currency}
                        currentPrice={currentPrice!}
                        symbol={symbol}
                        currencyDecimalScale={currencyDecimalScale}
                        isMobile
                      />
                    </TerminalBackDialogContent>
                  )}
                </ModifyOrderButton>
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.time")}</TradingCard.ListLabel>}
            end={<TradingCard.ListValue>{terminalFormatDate(date)}</TradingCard.ListValue>}
          />
        </TradingCard.Body>
      }
      footer={<ClosePendingOrderButton accountId={accountId} orderId={id} />}
    />
  );
};

const Component = memo(_PendingOrderCard);

export { Component as PendingOrderCard };
