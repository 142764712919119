import * as Slider from "@radix-ui/react-slider";
import { type ComponentProps, type ElementRef, type FC, useRef } from "react";

import { cn } from "@/shared/styles";

import { usePlaceOrderContext } from "../context";
import { _Tooltip } from "./_tooltip";
import { _MinMaxValues } from "./min-max-values";

type Props = ComponentProps<typeof _MinMaxValues> & {
  sliderValue: ReturnType<typeof usePlaceOrderContext>["sliderValue"];
  changeSliderValue: ReturnType<typeof usePlaceOrderContext>["changeSliderValue"];
  onSliderCommit: ReturnType<typeof usePlaceOrderContext>["onSliderCommit"];
  className?: string;
};

const PlaceOrderSlider: FC<Props> = ({
  changeSliderValue,
  sliderValue,
  onSliderCommit,
  sliderDisabled,
  volumeMode,
  minSystemVolumeLots,
  minSystemVolumeMargin,
  maxAvailableVolumeLots,
  maxAvailableVolumeMargin,
  volumeLotsDecimalScale,
  volumeMarginDecimalScale,
  currency,
  className,
}) => {
  const ref = useRef<ElementRef<typeof Slider.Thumb>>(null);

  return (
    <div className={cn("px-1", className)}>
      <Slider.Root
        className="group relative flex h-6 touch-none select-none items-center"
        max={100}
        step={1}
        value={sliderValue}
        onValueChange={changeSliderValue}
        onValueCommit={() => {
          onSliderCommit();
          if (navigator.vibrate) {
            navigator.vibrate(50);
          }
          ref.current!.blur();
        }}
        onFocus={() => {
          if (navigator.vibrate) {
            navigator.vibrate(50);
          }
        }}
      >
        {/* TODO: */}
        {/* animating "transform scale" is more performant than height but it doesn't consider border-radius so it is tradeoff  */}
        {/* group-has-[:focus]:scale-y-150 */}
        <Slider.Track className="relative h-2 grow rounded-[8px] bg-contrast-quaternary transition-all group-has-[:focus]:h-3 group-has-[:focus]:rounded-[16px] group-has-[:focus]:bg-control-border">
          <Slider.Range className="absolute h-full rounded-inherit bg-accent-bg pe-6" />
        </Slider.Track>
        <Slider.Thumb
          ref={ref}
          // FIXME:
          style={{ boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.12)" }}
          className="relative z-10 block size-6 rounded-full border border-positive-over bg-positive-over transition-colors hover:border-accent-bg focus:border-accent-bg focus:outline-none"
        >
          {!sliderDisabled && <_Tooltip sliderValue={sliderValue} />}
        </Slider.Thumb>
      </Slider.Root>
      <_MinMaxValues
        volumeLotsDecimalScale={volumeLotsDecimalScale}
        volumeMarginDecimalScale={volumeMarginDecimalScale}
        maxAvailableVolumeLots={maxAvailableVolumeLots}
        maxAvailableVolumeMargin={maxAvailableVolumeMargin}
        sliderDisabled={sliderDisabled}
        minSystemVolumeLots={minSystemVolumeLots}
        minSystemVolumeMargin={minSystemVolumeMargin}
        volumeMode={volumeMode}
        currency={currency}
      />
    </div>
  );
};

export { PlaceOrderSlider };
