import { type FC, useEffect, useMemo } from "react";

import { NumberFormat } from "@/app/components";
import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { NumberInput } from "@/shared/ui";

import { getModifyOrderRules, getTakeProfitThresholdValue } from "../helpers";
import { ModifyMoreLessDescription } from "../more-less-description";
import { ModifyOrderSymbol } from "../order-symbol";
import { ModifyProfitAndLoss } from "../profit-and-loss";

enum Fields {
  TAKE_PROFIT = "takeProfit",
}

type FormValues = {
  [Fields.TAKE_PROFIT]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  ask: number;
  bid: number;
  currentPrice: number;
  openPrice: number;
  takeProfit: number | undefined;
  priceDecimalScale: number;
  currencyDecimalScale: number;
  orderType: TerminalDealType;
  baseCurrency: string;
  quoteCurrency: string;
  contractSize: number;
  currency: string;
  volume: number;
  symbol: string;
  profitRate?: number;
  isMobile?: boolean;
};

const ModifyTakeProfitForm: FC<Props> = ({
  onSubmit,
  priceDecimalScale,
  takeProfit: takeProfitProp,
  ask,
  bid,
  orderType,
  baseCurrency,
  quoteCurrency,
  contractSize,
  currency,
  volume,
  openPrice,
  isMobile,
  symbol,
  currentPrice,
  currencyDecimalScale,
  profitRate,
}) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.TAKE_PROFIT]: formatInputNumberValue(takeProfitProp, priceDecimalScale),
    },
  });
  const { watch, formState, trigger, control } = form;
  const { isValid, touchedFields } = formState;

  const isTouched = touchedFields[Fields.TAKE_PROFIT];

  const { takeProfit: tp } = watch();
  const takeProfit = getInputNumberValue(tp);

  const takeProfitThresholdValue = useMemo(
    () => getTakeProfitThresholdValue({ ask, bid, priceDecimalScale, openPrice, orderType }),
    [orderType, priceDecimalScale, ask, bid, openPrice],
  );

  useEffect(() => {
    if (isTouched) {
      trigger(Fields.TAKE_PROFIT);
    }
  }, [takeProfitThresholdValue, isTouched, takeProfit]);

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.TAKE_PROFIT,
    control,
    rules: getModifyOrderRules({
      thresholdValue: takeProfitThresholdValue,
      type: "takeProfit",
      orderType,
      value: takeProfit,
    }),
  });

  return (
    <HookForm form={form} onSubmit={onSubmit} className="flex grow flex-col">
      <ModifyOrderSymbol symbol={symbol} isMobile={isMobile} />
      <NumberInput
        size={isMobile ? "sm" : "md"}
        autoFocus
        clearable
        decimalScale={priceDecimalScale}
        pending={pending}
        invalid={invalid}
        placeholder={t("terminal.not-set")!}
        descriptor={
          <ModifyMoreLessDescription
            decimalScale={priceDecimalScale}
            thresholdValue={takeProfitThresholdValue}
            orderType={orderType}
            onChange={field.onChange}
            type="takeProfit"
          />
        }
        {...field}
      />
      <TerminalPopover.List className="mt-6">
        {isMobile && (
          <>
            <TerminalPopover.ListItem
              value={<NumberFormat value={openPrice} decimalScale={priceDecimalScale} />}
              label={`${t("terminal.open-price")}:`}
            />
            <TerminalPopover.ListItem
              value={<NumberFormat value={currentPrice} decimalScale={priceDecimalScale} />}
              label={`${t("terminal.current-price")}:`}
            />
          </>
        )}
        <TerminalPopover.ListItem
          value={
            isValid && !!takeProfit ? (
              <ModifyProfitAndLoss
                accountCurrency={currency}
                baseCurrency={baseCurrency}
                quoteCurrency={quoteCurrency}
                contractSize={contractSize}
                currentPrice={takeProfit}
                openPrice={openPrice}
                orderType={orderType}
                volume={volume}
                currencyDecimalScale={currencyDecimalScale}
                profitRate={profitRate}
              />
            ) : (
              "—"
            )
          }
          label={t("terminal.pnl-currency-full", { currency })}
        />
      </TerminalPopover.List>
      <TerminalPopover.Buttons isMobile={isMobile}>
        <SubmitButton>{t("button.confirm")}</SubmitButton>
      </TerminalPopover.Buttons>
    </HookForm>
  );
};

export { ModifyTakeProfitForm };
