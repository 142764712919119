import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

const IconPriceChangeArrowUp = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>((props, ref) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
    <path
      d="M7.38071 13.2646L7.38071 5.09899L3.78981 8.70126L2.90344 7.82626L8.00572 2.73535L13.0966 7.82626L12.233 8.70126L8.63071 5.09899L8.63071 13.2646L7.38071 13.2646Z"
      fill="currentColor"
    />
  </svg>
));

export { IconPriceChangeArrowUp };
