import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { centeredDialogContentStyles, overlayStyles } from "../popper";

const Portal = AlertDialogPrimitive.Portal;
const Trigger = AlertDialogPrimitive.Trigger;
const Cancel = AlertDialogPrimitive.Cancel;
const Action = AlertDialogPrimitive.Action;

const Overlay = forwardRef<
  ElementRef<typeof AlertDialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay> & VariantProps<typeof overlayStyles>
>(({ className, hasAnimation = false, ...props }, ref) => (
  <AlertDialogPrimitive.Overlay className={cn(overlayStyles({ hasAnimation, className }))} {...props} ref={ref} />
));

type ContentProps = ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content> & {
  title: string;
  description: string;
  hasOverlayAnimation?: boolean;
  noCross?: boolean;
};

const Content = forwardRef<ElementRef<typeof AlertDialogPrimitive.Content>, ContentProps>(
  ({ className, title, description, children, hasOverlayAnimation, noCross, ...props }, ref) => (
    <Portal>
      <Overlay hasAnimation={hasOverlayAnimation} />
      <AlertDialogPrimitive.Content
        ref={ref}
        className={cn(
          centeredDialogContentStyles(),
          "w-[90vw] max-w-xs rounded-[24px] border border-bg-border bg-bg p-6",
          className,
        )}
        {...props}
      >
        <div className="grid grid-cols-[1fr,auto,1fr] gap-2">
          <div />
          <AlertDialogPrimitive.Title className="text-center text-[22px] font-semibold leading-[1.2] text-text">
            {title}
          </AlertDialogPrimitive.Title>
          {!noCross && (
            <AlertDialogPrimitive.Cancel className="relative ms-auto grid place-items-center before:absolute before:size-11">
              <IconCross />
            </AlertDialogPrimitive.Cancel>
          )}
        </div>
        <AlertDialogPrimitive.Description className="mt-3 text-center font-roboto text-[16px] leading-[1.5] text-text">
          {description}
        </AlertDialogPrimitive.Description>
        {children}
      </AlertDialogPrimitive.Content>
    </Portal>
  ),
);

const Buttons = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(({ className, ...props }, ref) => {
  return <div className={cn("mt-6 flex flex-col gap-2", className)} {...props} ref={ref} />;
});

const Component = Object.assign(AlertDialogPrimitive.Root, {
  Trigger,
  Portal,
  Buttons,
  Action,
  Cancel,
  Content,
});

export { Component as AlertDialog, overlayStyles };
