import React from "react";

export const ChevronLeftIcon = () => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.70726 12.2928L7.29304 13.7071L0.585938 6.99995L7.29304 0.292847L8.70726 1.70706L3.41436 6.99995L8.70726 12.2928Z"
      fill="currentColor"
    />
  </svg>
);
