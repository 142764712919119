import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";

import { useAuth } from "@/contexts/auth.context";
import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";

export const UnauthenticatedRoute = ({ children }: { children?: React.ReactElement }) => {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  if (
    isAuthenticated &&
    !searchParams.get("isFirstLogin") &&
    location.pathname !== authRoutes.emailConfirm &&
    location.pathname !== authRoutes.resetPassword
  ) {
    return <Navigate to={cabinetRoutes.dashboard} replace />;
  }

  return children ? children : <Outlet />;
};
