import { cva } from "class-variance-authority";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import tickIcon from "@/assets/images/tick-icon.svg";
import { PopoverClose } from "@/components/popover";
import { Text } from "@/domains/ui-kit/Text/Text";
import { useLocalStorage } from "@/hooks/localstorage.hook";

type Props = {
  currencies: string[];
  onSelect: (value: string) => void;
  defaultCurrency: string;
};

export const LOCAL_STORAGE_CURRENCY = "defaultCurrency";

const buttonStyles = cva("flex w-full items-center gap-3 p-4 transition hover:bg-bg-deep", {
  variants: {
    selected: {
      true: "bg-bg-deep",
    },
  },
});

const PopoverContent: FC<Props> = ({ currencies, onSelect, defaultCurrency }) => {
  const { t } = useTranslation();
  const [_, setLocalCurrency] = useLocalStorage(LOCAL_STORAGE_CURRENCY, defaultCurrency);

  const setCurrency = (value: string) => {
    setLocalCurrency(value);
    onSelect(value);
  };

  return (
    <div className="w-[240px] overflow-hidden rounded-[24px] bg-bg pt-4">
      <Text color="dimmed" family="roboto" size="2" className="mb-3 px-4">
        {t("cabinet.cabinet-title.popover.text")}
      </Text>

      {currencies.map(currency => (
        <PopoverClose asChild key={currency}>
          <button
            key={currency}
            className={buttonStyles({ selected: defaultCurrency === currency })}
            onClick={() => setCurrency(currency)}
          >
            <img src={`${import.meta.env.VITE_CURRENCY_IMAGE_URL}/${currency}.png`} width={24} height={24} />

            <Text family="roboto" size="2">
              {t("cabinet.cabinet-title.popover.display", { currency })}
            </Text>

            <div className="h-[24px] w-[24px]">
              {defaultCurrency === currency && <img src={tickIcon} className="ml-auto" />}
            </div>
          </button>
        </PopoverClose>
      ))}
    </div>
  );
};

export { PopoverContent as CurrencySelectPopoverContent };
