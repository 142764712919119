import { type FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../context";

type Props = {
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  maxSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeLots"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  onDeposit: () => void;
};

const VolumeLotsFieldDescriptor: FC<Props> = ({
  changeLots,
  volumeLotsError,
  minSystemVolumeLots,
  maxBalanceVolumeLots,
  maxSystemVolumeLots,
  volumeLotsDecimalScale,
  onDeposit,
}) => {
  return (
    <>
      {volumeLotsError === VolumeLotsError.LESS_THAN_SYSTEM && (
        <Trans
          i18nKey="terminal.place-order.lots.input-descriptor.min-system"
          components={{
            value: <NumberFormat value={minSystemVolumeLots} decimalScale={volumeLotsDecimalScale} />,
            valueButton: <NumberInput.DescriptorButton onClick={() => changeLots(minSystemVolumeLots)} />,
          }}
        />
      )}
      {volumeLotsError === VolumeLotsError.MORE_THAN_FREE_MARGIN && (
        <Trans
          i18nKey="terminal.place-order.lots.input-descriptor.more-than-free-margin"
          components={{
            value: <NumberFormat value={maxBalanceVolumeLots} decimalScale={volumeLotsDecimalScale} />,
            valueButton: <NumberInput.DescriptorButton onClick={() => changeLots(maxBalanceVolumeLots)} />,
            depositButton: <NumberInput.DescriptorButton onClick={onDeposit} />,
          }}
        />
      )}
      {volumeLotsError === VolumeLotsError.MORE_THAN_SYSTEM && (
        <Trans
          i18nKey="terminal.place-order.lots.input-descriptor.max-system"
          components={{
            value: <NumberFormat value={maxSystemVolumeLots} decimalScale={volumeLotsDecimalScale} />,
            valueButton: <NumberInput.DescriptorButton onClick={() => changeLots(maxSystemVolumeLots)} />,
          }}
        />
      )}
    </>
  );
};

export { VolumeLotsFieldDescriptor };
