import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactElement, type ReactNode } from "react";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { cn } from "@/shared/styles";

import { tooltipContentStyles } from "../popper";

const Action = forwardRef<ElementRef<"button">, ComponentPropsWithoutRef<"button">>(
  ({ className, type = "button", ...props }, ref) => {
    return (
      <button
        className={cn("typography-S-Medium mt-2 text-positive-text", className)}
        type={type}
        ref={ref}
        {...props}
      />
    );
  },
);

const textStyles = cva("typography-S-Regular text-text-inverse")();

const contentStyles = cva("max-w-[300px] rounded-[16px] bg-bg-additional p-6 outline-none")();

type Props = ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> &
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> &
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    children: ReactElement;
    content: ReactNode;
    action?: ReactElement;
  };

const Tooltip = forwardRef<ElementRef<"div">, Props>(
  (
    {
      children,
      content,
      open,
      defaultOpen,
      onOpenChange,
      delayDuration,
      disableHoverableContent,
      onInteractOutside,
      onFocusOutside,
      onCloseAutoFocus,
      onOpenAutoFocus,
      side = "top",
      align = "center",
      sideOffset = 5,
      collisionPadding = 10,
      className,
      action,
      ...props
    },
    ref,
  ) => {
    const { isMobile } = useScreenSize();

    if (isMobile) {
      // tooltips doesn't work on touch devices
      // https://github.com/radix-ui/primitives/issues/955#issuecomment-960610209
      return (
        <PopoverPrimitive.Root defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={open}>
          <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
          <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content
              ref={ref}
              side={side}
              align={align}
              sideOffset={sideOffset}
              collisionPadding={collisionPadding}
              onFocusOutside={onFocusOutside}
              onInteractOutside={onInteractOutside}
              onCloseAutoFocus={onCloseAutoFocus}
              onOpenAutoFocus={onOpenAutoFocus}
              className={cn(tooltipContentStyles(), contentStyles, className)}
              {...props}
            >
              <div className={textStyles}>{content}</div>
              {action && action}
              <PopoverPrimitive.Arrow width={16} height={8} className="fill-bg-additional" />
            </PopoverPrimitive.Content>
          </PopoverPrimitive.Portal>
        </PopoverPrimitive.Root>
      );
    }

    return (
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        disableHoverableContent={disableHoverableContent}
        delayDuration={delayDuration}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            ref={ref}
            side={side}
            align={align}
            sideOffset={sideOffset}
            collisionPadding={collisionPadding}
            className={cn(tooltipContentStyles(), contentStyles, className)}
            {...props}
          >
            <div className={textStyles}>{content}</div>
            {action && action}
            <TooltipPrimitive.Arrow width={16} height={8} className="fill-bg-additional" />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    );
  },
);

const Component = Object.assign(Tooltip, { Action });

export { Component as Tooltip };
