import { type FC } from "react";

import { AccountSelectSkeleton } from "../account-select/skeleton";
import { ChartLoader } from "../chart/loader";
import { ChartSymbolsSkeleton } from "../chart-symbols/skeleton";
import { DepositButtonSkeleton } from "../deposit/skeleton";
import { MobileSymbolBlockSkeleton } from "../mobile-symbol/skeleton";
import { PlaceOrderSkeleton } from "../place-order/skeleton";
import { TerminalMobileFrame } from "../terminal-mobile.frame";
import { MobileTradingTablesSkeleton } from "../trading-tables/mobile/skeleton";

type Props = {
  isMobileSymbolPage: boolean;
};

const TerminalMobileSkeleton: FC<Props> = ({ isMobileSymbolPage }) => {
  return (
    <TerminalMobileFrame
      isMobileSymbolPage={isMobileSymbolPage}
      symbolPageSlot={<MobileSymbolBlockSkeleton />}
      chartSymbolsSlot={<ChartSymbolsSkeleton />}
      accountSelectSlot={<AccountSelectSkeleton />}
      depositButtonSlot={<DepositButtonSkeleton />}
      chartSlot={<ChartLoader />}
      placeOrderSlot={<PlaceOrderSkeleton />}
      tablesSlot={<MobileTradingTablesSkeleton />}
    />
  );
};

export { TerminalMobileSkeleton };
