import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

const IconTrendSignals = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>((props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25263 5.38651C6.63756 5.78254 6.62857 6.41564 6.23254 6.80058C4.84446 8.14977 4 9.98449 4 11.9998C4 14.0151 4.84446 15.8498 6.23254 17.199C6.62857 17.5839 6.63756 18.217 6.25263 18.613C5.86769 19.0091 5.23459 19.0181 4.83856 18.6331C3.08976 16.9333 2 14.5915 2 11.9998C2 9.40805 3.08976 7.06622 4.83856 5.36642C5.23459 4.98148 5.86769 4.99047 6.25263 5.38651ZM17.8617 5.38651C18.2466 4.99047 18.8797 4.98148 19.2758 5.36642C21.0245 7.06622 22.1143 9.40805 22.1143 11.9998C22.1143 14.5915 21.0245 16.9333 19.2758 18.6331C18.8797 19.0181 18.2466 19.0091 17.8617 18.613C17.4767 18.217 17.4857 17.5839 17.8818 17.199C19.2698 15.8498 20.1143 14.0151 20.1143 11.9998C20.1143 9.98449 19.2698 8.14977 17.8818 6.80058C17.4857 6.41564 17.4767 5.78254 17.8617 5.38651ZM8.8863 8.24884C9.23741 8.67515 9.17646 9.30537 8.75016 9.65649C7.98291 10.2884 7.55681 11.1228 7.55681 11.9998C7.55681 12.8768 7.98291 13.7111 8.75016 14.3431C9.17646 14.6942 9.23741 15.3244 8.8863 15.7507C8.53518 16.177 7.90495 16.238 7.47865 15.8869C6.32346 14.9354 5.55681 13.5633 5.55681 11.9998C5.55681 10.4363 6.32346 9.06416 7.47865 8.11271C7.90495 7.76159 8.53518 7.82254 8.8863 8.24884ZM15.228 8.24884C15.5791 7.82254 16.2094 7.76159 16.6357 8.11271C17.7908 9.06416 18.5575 10.4363 18.5575 11.9998C18.5575 13.5633 17.7908 14.9354 16.6357 15.8869C16.2094 16.238 15.5791 16.177 15.228 15.7507C14.8769 15.3244 14.9378 14.6942 15.3642 14.3431C16.1314 13.7111 16.5575 12.8768 16.5575 11.9998C16.5575 11.1228 16.1314 10.2884 15.3642 9.65649C14.9378 9.30537 14.8769 8.67515 15.228 8.24884Z"
      fill="currentColor"
    />
    <path
      d="M13.9999 11.9998C13.9999 13.1043 13.1045 13.9998 11.9999 13.9998C10.8953 13.9998 9.9999 13.1043 9.9999 11.9998C9.9999 10.8952 10.8953 9.99977 11.9999 9.99977C13.1045 9.99977 13.9999 10.8952 13.9999 11.9998Z"
      fill="currentColor"
    />
  </svg>
));

export { IconTrendSignals };
