import { cva, type VariantProps } from "class-variance-authority";
import type { FC } from "react";

import fallbackImg from "@/assets/images/pair-no-icon.svg";

const containerStyles = cva("flex shrink-0", {
  variants: {
    size: {
      sm: "size-4",
      md: "size-6",
      lg: "size-8",
      xl: "size-9",
    },
  },
});

const Icon: FC<{ symbol: string }> = ({ symbol }) => {
  return (
    <img
      src={`${import.meta.env.VITE_NEW_SYMBOL_IMAGE_URL}/${symbol}.svg`}
      alt={symbol}
      loading="lazy"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        // currentTarget.src = isDarkTheme ? fallbackDarkImg : fallbackLightImg;
        currentTarget.src = fallbackImg;
      }}
    />
  );
};

const SymbolIcon: FC<{ symbol: string } & VariantProps<typeof containerStyles>> = ({ size = "md", symbol }) => (
  <div className={containerStyles({ size })}>
    <Icon symbol={symbol} />
  </div>
);

export { SymbolIcon };
