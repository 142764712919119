import dayjs from "dayjs";
import { atom } from "nanostores";
import { setInterval } from "worker-timers";

const $serverTime = atom<number | null>(null);

const setServerTime = (time: string) => {
  $serverTime.set(dayjs(time).valueOf());
};

setInterval(() => {
  const time = $serverTime.get();
  if (time) {
    $serverTime.set(time + 1000);
  }
}, 1000);

export { setServerTime, $serverTime };
