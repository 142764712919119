import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { Button as _Button } from "@/shared/ui";

const Button = forwardRef<ElementRef<typeof _Button>, ComponentPropsWithoutRef<typeof _Button>>((props, ref) => (
  <_Button size="sm" variant="tertiary" {...props} ref={ref} />
));

type Props = {
  title: string;
  description: string;
  action?: ReactNode;
};

const EmptyState: FC<Props> = ({ title, description, action }) => (
  <div className="flex flex-col items-center gap-4">
    <div className="flex flex-col gap-2">
      <h5 className="text-center font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-primary">{title}</h5>
      <p className="text-center font-roboto text-[14px] leading-normal text-contrast-secondary">{description}</p>
    </div>
    {action}
  </div>
);

const Component = Object.assign(EmptyState, { Button });

export { Component as TradingTableEmptyState };
