import type { PlatformApiGetTranslationsRequest } from "@/services/openapi";

import { platformService } from "../api";

export const getCountries = async () => await (await platformService().getCountries()).data;

export const getPlatformInfo = async () => await (await platformService().getPlatformInfo()).data;

export const getPlatformLocales = async () => await (await platformService().getLocales()).data;

export const getDate = async () => await (await platformService().getDate()).data;

export const getTranslations = async (values: Omit<PlatformApiGetTranslationsRequest, "keys">) =>
  await (
    await platformService().getTranslations(values)
  ).data;

export const getApiUrl = async () => await (await platformService().getPlatformUrlInfo()).data;
