import { memo, useEffect } from "react";
import { Helmet } from "react-helmet";

export const GoogleTagManager = memo(() => {
  useEffect(() => {
    const iframe = document.createElement("iframe");

    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-P6RGBZD";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";

    document.body.appendChild(iframe);
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P6RGBZD');
          `}
        </script>
      </Helmet>
    </>
  );
});
GoogleTagManager.displayName = "GoogleTagManager";
