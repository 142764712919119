import { type FC, type ReactNode } from "react";

import { Pagination, type PaginationProps } from "../pagination";

export type TableFooterExternalProps = PaginationProps & {
  footerLeft?: ReactNode;
};

export const TableFooter: FC<TableFooterExternalProps> = ({ changePage, currentPage, pageSize, total, footerLeft }) => {
  return (
    <div className="mt-[25px] grid grid-rows-[auto_auto] justify-center gap-4 md:grid-cols-[auto_auto] md:grid-rows-[auto] md:justify-between">
      <div className="grid place-items-center">{footerLeft}</div>
      <div className="grid place-items-center">
        <Pagination changePage={changePage} currentPage={currentPage} pageSize={pageSize} total={total} />
      </div>
    </div>
  );
};
