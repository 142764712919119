import { type ReactNode } from "react";

import { Button } from "@/components/button";
import { DialogContent } from "@/components/dialog";
import { DoubleOkIcon } from "@/components/icons";
import { TextBad } from "@/components/text";
import { EmailConfirmDialogContent } from "@/features/onboarding/components/sumsub/email-confirm-dialog-content";
import { useTranslation } from "@/hooks/translator.hook";
import { Dialog } from "@/shared/ui";

import { ChangeEmailButton } from "./change-email/change-email-button";

export const PersonalLabel = ({ children }: { children: ReactNode }) => {
  return (
    <TextBad className="mb-2 text-[14px] md:text-[16px]" family="roboto" lineHeight="3">
      {children}
    </TextBad>
  );
};

export const PersonalValue = ({ children }: { children: ReactNode }) => {
  return (
    <TextBad className="text-[16px] md:text-[22px]" weight="bold">
      {children}
    </TextBad>
  );
};

export const PersonalEmail = ({ email, isEmailConfirmed }: { email: string; isEmailConfirmed: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <PersonalLabel>{t("profile-settings.personal.email")}</PersonalLabel>
      <div className="flex flex-wrap items-center gap-2">
        <ChangeEmailButton isEmailConfirmed={isEmailConfirmed} email={email} />
        <PersonalValue>{email}</PersonalValue>
        {isEmailConfirmed ? (
          <DoubleOkIcon />
        ) : (
          <Dialog>
            <Dialog.Trigger asChild>
              <Button size="small" variant="secondary">
                {t("button.verify-email")}
              </Button>
            </Dialog.Trigger>
            <DialogContent>
              <EmailConfirmDialogContent email={email} />
            </DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
};
