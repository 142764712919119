import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import type { BonusUserPlatform } from "@/services/openapi";
import { Banner, Dialog } from "@/shared/ui";
import { useHideBannerMutation } from "@/state/server/banners";
import { bonusesQueryKeys } from "@/state/server/bonuses";

import img from "./assets/promo.png";
import { BonusDialog } from "./dialog/bonus-dialog";
import { getBonusMainPercent } from "./helpers";

type Props = {
  bonus: BonusUserPlatform;
  showCloseButton?: boolean;
};

const BonusBanner: FC<Props> = ({ bonus, showCloseButton = true }) => {
  const { t } = useTranslation();

  const client = useQueryClient();

  const { mutate, isLoading } = useHideBannerMutation({
    onSuccess: () => {
      client.invalidateQueries(bonusesQueryKeys.base);
    },
  });

  return (
    <Dialog>
      <Banner
        text={t("banners.bonus.text", { value: getBonusMainPercent(bonus) })}
        actionButton={
          <Dialog.Trigger asChild>
            <Banner.ActionButton>{t("banners.bonus.button")}</Banner.ActionButton>
          </Dialog.Trigger>
        }
        closeButton={
          showCloseButton ? <Banner.CloseButton onClick={() => mutate({ id: bonus.id! })} disabled={isLoading} /> : null
        }
        bonusImg={img}
      />
      <BonusDialog bonus={bonus} />
    </Dialog>
  );
};

export { BonusBanner };
