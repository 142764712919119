import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { CabinetPage } from "@/features/cabinet/components/cabinet-page";
import { CabinetTitle } from "@/features/cabinet/components/cabinet-title";

type Props = {
  type: "deposit" | "withdrawal";
};

const PaymentLayout: FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  return (
    <CabinetPage
      title={
        <CabinetTitle
          title={type === "deposit" ? t("cabinet.navigation.deposit") : t("cabinet.navigation.withdrawal")}
          showBalance={false}
        >
          <CabinetTitle.TradeButton />
        </CabinetTitle>
      }
    >
      <Outlet />
    </CabinetPage>
  );
};

export { PaymentLayout };
