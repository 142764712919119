import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { darkTheme } from "@/styles";

export enum ColorTheme {
  LIGHT = "light",
  DARK = "dark",
}

type ThemeContextState = {
  theme: ColorTheme;
  isDarkTheme: boolean;
  changeTheme: (isDark: boolean) => void;
};

export const ThemeContext = createContext<ThemeContextState>({} as ThemeContextState);

export const ThemeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<ColorTheme>(() => {
    const localStorageValue = localStorage.getItem("dotoTheme");
    if (localStorageValue === ColorTheme.LIGHT || localStorageValue === ColorTheme.DARK) {
      return localStorageValue;
    }
    // if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //   return ColorTheme.DARK;
    // }
    return ColorTheme.LIGHT;
  });

  useEffect(() => {
    if (theme === ColorTheme.LIGHT) {
      document.body.classList.remove(darkTheme.className);
    } else {
      document.body.classList.add(darkTheme.className);
    }
  }, [theme]);

  const changeTheme = useCallback((value: boolean) => {
    const newTheme: ColorTheme = value ? ColorTheme.DARK : ColorTheme.LIGHT;
    localStorage.setItem("dotoTheme", newTheme);
    setTheme(newTheme);
  }, []);

  const value = useMemo(() => ({ isDarkTheme: theme === ColorTheme.DARK, changeTheme, theme }), [changeTheme, theme]);
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
