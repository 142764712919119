import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { ClockIcon, GlassBellIcon } from "@/components/icons";
import { IconClose, IconTick, IconWarningCircle } from "@/domains/icons";
import { Text } from "@/shared/ui";

import { cn } from "../../styles";

const Viewport = forwardRef<
  ElementRef<typeof ToastPrimitives.Viewport>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, id = "radix-toast-vp", ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    id={id}
    className={cn(
      "fixed end-1/2 top-0 z-100 w-full translate-x-1/2 outline-none md:end-0 md:max-w-[390px] md:translate-x-0",
      className,
    )}
    {...props}
  />
));

const toastStyles = cva(
  "p-4 transition-all data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out",
  {
    variants: {
      swipeDirection: {
        up: "data-[swipe=cancel]:translate-y-0 data-[swipe=end]:translate-y-[var(--radix-toast-swipe-end-y)] data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[state=closed]:slide-out-to-top-full data-[state=open]:slide-in-from-top-full",
        down: "data-[swipe=cancel]:translate-y-0 data-[swipe=end]:translate-y-[var(--radix-toast-swipe-end-y)] data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full",
        left: "data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-left-full",
        right:
          "data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full",
      },
    },
  },
);

const Root = forwardRef<
  ElementRef<typeof ToastPrimitives.Root>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastStyles>
>(({ className, swipeDirection, ...props }, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastStyles({ swipeDirection }), className)} {...props} />;
});

// TODO: update styles
const actionStyles = cva("typography-S-Regular mt-2 outline-none", {
  variants: {
    variant: {
      success: "text-positive-text",
      warning: "text-negative-text",
      inverted: "text-gray",
      default: "text-dark",
    },
  },
});

const Action = forwardRef<
  ElementRef<typeof ToastPrimitives.Action>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Action> & VariantProps<typeof actionStyles>
>(({ className, variant = "success", ...props }, ref) => (
  <ToastPrimitives.Action ref={ref} className={cn(actionStyles({ variant }), className)} {...props} />
));

const Close = forwardRef<
  ElementRef<typeof ToastPrimitives.Close>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close ref={ref} className={cn("size-6 text-contrast-primary outline-none", className)} {...props}>
    <IconClose />
  </ToastPrimitives.Close>
));

const Title = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>((props, ref) => (
  <ToastPrimitives.Title asChild>
    <Text variant="M / Medium" color="primary" ref={ref} {...props} />
  </ToastPrimitives.Title>
));

const Description = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>(
  ({ className, ...props }, ref) => (
    <ToastPrimitives.Description asChild>
      <Text variant="S / Regular" color="secondary" className={cn("mt-1", className)} ref={ref} {...props} />
    </ToastPrimitives.Description>
  ),
);

const AccentText = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>(
  ({ className, ...props }, ref) => (
    <Text ref={ref} className={cn("mt-2", className)} color="inherit" variant="M / Medium" {...props} />
  ),
);

enum Icons {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  WAITING = "waiting",
}

const getIcon = (icon: Icons | ReactNode) => {
  if (icon === Icons.SUCCESS) {
    return <IconTick className="text-positive-text" />;
  }

  if (icon === Icons.ERROR) {
    return <IconWarningCircle className="text-negative-text" />;
  }

  if (icon === Icons.WARNING) {
    return <GlassBellIcon />;
  }

  if (icon === Icons.WAITING) {
    return <ClockIcon />;
  }
  return icon;
};

const Icon: FC<{ icon: Icons | ReactNode; isSmallIcon: boolean }> = ({ isSmallIcon, icon }) => {
  return <div className={cn("grid size-8 shrink-0 place-items-center", isSmallIcon && "size-6")}>{getIcon(icon)}</div>;
};

type ToastProps = ComponentPropsWithoutRef<typeof Root> & { icon: Icons | ReactNode };

const Component = Object.assign(Root, {
  Viewport,
  Provider: ToastPrimitives.Provider,
  Close,
  Action,
  Title,
  Description,
  Icon,
  AccentText,
});

export { Component as Toast, Icons as ToastIcons, type ToastProps };
