import { usePreTransferQuery } from "@/state/server/funds";

import { useDebounce } from "./debounce.hook";

interface ITransferEvaluateHookInput {
  formAmount: number;
  destinationCurrency: string;
  sourceCurrency: string;
}

export const useTransferEvaluate = ({
  destinationCurrency,
  formAmount,
  sourceCurrency,
}: ITransferEvaluateHookInput) => {
  const amount = useDebounce(formAmount, 700);
  const { data: preTransferData, isFetching: transferEvaluateIsFetching } = usePreTransferQuery({
    destinationCurrency,
    sourceCurrency,
    amount,
  });

  return {
    preTransferData,
    transferEvaluateIsFetching,
  };
};
