import { useMemo } from "react";

import { useCookie } from "./cookie.hook";

export const useClientId = () => {
  const [, setCookie, getCookie] = useCookie();

  const clientId = useMemo(() => {
    const gaClientId = getCookie("_ga");
    const newId = gaClientId?.replace(/GA1\.1\./g, "");
    return newId;
  }, [getCookie]);

  return clientId;
};
