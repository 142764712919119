import { fundsService } from "../api";
import type { FundsApiGetTransactionsRequest, FundsApiPreTransferRequest, FundsApiTransferRequest } from "../openapi";

export const getTransactions = async (values: FundsApiGetTransactionsRequest) =>
  await (
    await fundsService().getTransactions(values)
  ).data;

export const transfer = async (values: FundsApiTransferRequest) => await (await fundsService().transfer(values)).data;

export const preTransfer = async (values: FundsApiPreTransferRequest) =>
  await (
    await fundsService().preTransfer(values)
  ).data;
