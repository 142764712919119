import dayjs from "dayjs";
import { useQueryClient } from "react-query";

import type { RequestParamsWithPaging } from "@/components/pagination";
import { getTransactions, preTransfer } from "@/services/funds";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseQuery, usePagingQuery } from "..";
import type { QueryOptionsType } from "../types";
import { fundsQueryKeys } from "./funds.keys";

export const useTransactionsQuery = (
  filters: RequestParamsWithPaging<ApiRequestParamsType<typeof getTransactions>>,
  options?: QueryOptionsType<ApiResponseType<typeof getTransactions>>,
) => {
  return usePagingQuery<ApiResponseType<typeof getTransactions>, ApiRequestParamsType<typeof getTransactions>>(
    filters,
    {
      queryKey: fundsQueryKeys.transactions(filters),
      queryFn: getTransactions,
      ...options,
    },
  );
};

export const usePreTransferQuery = (
  filters: ApiRequestParamsType<typeof preTransfer>["fundsPreTransferRequest"],
  options?: QueryOptionsType<ApiResponseType<typeof preTransfer>>,
) => {
  const client = useQueryClient();
  return useBaseQuery<ApiResponseType<typeof preTransfer>>({
    queryKey: fundsQueryKeys.transferEvaluate(filters!),
    queryFn: () => preTransfer({ fundsPreTransferRequest: filters }),
    enabled: !!filters?.destinationCurrency && !!filters.sourceCurrency && !!filters.amount,
    onSuccess: res => {
      const newStaleTime = dayjs(res.validTo).diff(dayjs());
      client.setQueryDefaults(fundsQueryKeys.transferEvaluate(filters!), {
        staleTime: newStaleTime,
        refetchInterval: newStaleTime,
      });
    },
    ...options,
  });
};
