import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { TextInput } from "@/components/form";
import { Loader } from "@/components/loader";
import { Text } from "@/components/text";
import { useAuth } from "@/contexts/auth.context";
import { DialogName, getDialogSearchParams } from "@/hooks/dialog.hook";
import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useResendConfirmationLinkMutation } from "@/state/server/auth";
import { useProfileHeaderQuery } from "@/state/server/profile";

import { AuthFormContainer } from "../auth-form.container";
import { AuthBox } from "../styles";

export const VerifyEmailContainer: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { data: profile } = useProfileHeaderQuery({ enabled: isAuthenticated });
  const { isLoading, mutate } = useResendConfirmationLinkMutation();

  if (!isAuthenticated) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  return (
    <AuthFormContainer title={t("auth.verify-email.title")} backLink>
      <AuthBox>
        {profile ? (
          <>
            <Text family="roboto" align="center" lineHeight="3" css={{ mt: "16px" }} as="p">
              {t("auth.verify-email.text-1")}
            </Text>
            <br />
            <Text family="roboto" align="center" lineHeight="3" css={{ mb: "16px" }} as="p">
              {t("auth.verify-email.text-2")}
            </Text>
            <TextInput value={profile.email!} disabled />
            <Flex css={{ justifyContent: "center", mt: "35px" }}>
              <Button disabled={isLoading} onClick={() => mutate(undefined)}>
                {t("button.resend-email")}
              </Button>
            </Flex>
            <Flex css={{ justifyContent: "center", mb: "35px" }}>
              <Button
                variant="text"
                size="small"
                onClick={() =>
                  navigate({
                    pathname: cabinetRoutes.dashboard,
                    search: `${getDialogSearchParams(DialogName.COMPLETE_PROFILE)}&isFirstLogin=true`,
                  })
                }
              >
                {t("button.verify-later")}
              </Button>
            </Flex>
          </>
        ) : (
          <Flex css={{ justifyContent: "center", mt: "60px" }}>
            <Loader />
          </Flex>
        )}
      </AuthBox>
    </AuthFormContainer>
  );
};
