import type { FC } from "react";
import { Trans } from "react-i18next";

import tournamentLogo from "@/assets/images/TournamentLogo.svg";
import { useTranslation } from "@/hooks/translator.hook";

export const TournamentTitle: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-[40px] flex justify-center md:mb-[48px]">
      <div className="flex flex-col items-center gap-[20px] md:flex-row md:gap-[43px]">
        <img src={tournamentLogo} alt="" className="hidden md:block" />
        <h1 className="text-center font-gilroy text-[40px] font-semibold leading-[1.1] tracking-[-0.8px] md:text-[64px] md:leading-[.96] md:tracking-[-1.28px]">
          <Trans t={t} i18nKey="tournament.title">
            <span className="text-[#19C651]">Simple trading</span> starts here
          </Trans>
        </h1>
        <div className="relative h-[89px] w-[93px] md:hidden">
          <img src={tournamentLogo} alt="" width={56} height={56} className="absolute left-0 top-0 md:hidden" />
        </div>
      </div>
    </div>
  );
};
