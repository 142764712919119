import {
  type CreateTradingAccountRequest,
  TradingAccountType,
  type TradingServer,
  TradingServerPlatform,
} from "@/services/openapi";

const getAccountId = ({ servers, type }: { type: TradingAccountType; servers: TradingServer[] }) => {
  let accountId = null;

  servers.forEach(({ accounts }) => {
    accounts!.forEach(account => {
      if (type === account.type) {
        accountId = account.id;
      }
    });
  });

  return accountId;
};

export const getCreateAccountSubmitValue = ({
  type,
  platform,
  servers,
  currency,
}: {
  type: TradingAccountType;
  platform: TradingServerPlatform;
  servers: TradingServer[];
  currency: string;
}): CreateTradingAccountRequest => {
  try {
    const filteredServers = servers.filter(server => server.platformType === platform)!;

    return { tradingServerAccountId: getAccountId({ servers: filteredServers, type })!, currency };
  } catch (e) {
    return {};
  }
};
