import type { FC } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import type { TradingTournamentItem } from "@/services/openapi";

import { TournamentTable as Table } from "./table";

type Props = {
  participants: TradingTournamentItem[];
};

export const TournamentParticipantsTable: FC<Props> = ({ participants }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <thead>
        <Table.HeadRow>
          <Table.Head>{t("tournament.table.rank")}</Table.Head>
          <Table.Head>{t("tournament.table.name")}</Table.Head>
          <Table.Head>{t("tournament.table.account")}</Table.Head>
          <Table.Head>{t("tournament.table.result")}</Table.Head>
        </Table.HeadRow>
      </thead>
      <tbody>
        {participants.map(({ id, isColored, rank, floatingPnl, userName, login }) => (
          <Fragment key={id}>
            <tr>
              <td className="h-[20px]"></td>
            </tr>
            <Table.CellRow isColored={isColored}>
              <Table.Cell className="font-gilroy text-[40px] font-semibold leading-[0.96] tracking-[-0.8px]">
                {rank}
              </Table.Cell>
              <Table.Cell className="font-gilroy font-semibold">{userName}</Table.Cell>
              <Table.Cell>{login}</Table.Cell>
              <Table.Cell>
                <NumericFormat
                  displayType="text"
                  value={floatingPnl}
                  thousandSeparator=","
                  prefix={`${floatingPnl! > 0 ? "+" : ""}$`}
                />
              </Table.Cell>
            </Table.CellRow>
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};
