import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { produce } from "immer";
import type { ElementRef } from "react";
import { forwardRef, memo, useEffect, useRef } from "react";
import { useQueryClient } from "react-query";

import type { TerminalAccountSymbols } from "@/services/openapi";
import {
  terminalQueryKeys,
  useAddSymbolToChartsMutation,
  useRemoveSymbolFromChartsMutation,
} from "@/state/server/terminal";

import type { MergedTerminalSymbol } from "../../helpers/symbols";
import { useLayoutContext } from "../../layout/context";
import { DesktopChartSymbolButton } from "./desktop";
import { MobileChartSymbolButton } from "./mobile";

type Props = {
  symbolInfo: MergedTerminalSymbol;
  accountId: string;
  chartFavoritesCount: number;
  setSymbol: (symbol: string) => void;
  openWatchlist?: ReturnType<typeof useLayoutContext>["openWatchlist"];
  isActive?: boolean;
};

const _ChartSymbolButtonContainer = forwardRef<ElementRef<"div">, Props>(
  ({ symbolInfo, chartFavoritesCount, accountId, setSymbol, isActive, openWatchlist }, forwardedRef) => {
    const queryClient = useQueryClient();
    const { isMobile } = useLayoutContext();
    const ref = useRef<HTMLDivElement>(null);

    const { symbol } = symbolInfo;

    const { mutate: addFavorite, isLoading } = useAddSymbolToChartsMutation();
    const { mutate: removeFavorite } = useRemoveSymbolFromChartsMutation();

    useEffect(() => {
      if (isActive) {
        ref.current?.scrollIntoView({ inline: "nearest", block: "end" });
      }
    }, [isActive]);

    const composedRefs = useComposedRefs(ref, forwardedRef);

    const removeFavoriteFn = () => {
      let oldCharts: string[] = [];
      let currentSymbolIndex = 0;
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        currentSymbolIndex = oldData!.charts!.findIndex(item => item === symbol);
        oldCharts = oldData!.charts!;
        const newChartFavorites: string[] = oldData!.charts!.filter(item => item !== symbol);
        return produce(oldData!, draft => {
          draft.charts = newChartFavorites;
        })!;
      });
      removeFavorite({ symbol: symbol!, tradingAccountId: accountId });
      if (isActive) {
        const newSymbol = oldCharts[currentSymbolIndex === 0 ? 1 : currentSymbolIndex - 1]!;

        queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
          return produce(oldData!, draft => {
            draft.chartsSelected = newSymbol;
          })!;
        });
        addFavorite({ symbol: newSymbol, tradingAccountId: accountId });
        setSymbol(newSymbol);
      }
    };

    const selectSymbol = () => {
      if (isLoading) {
        return;
      }
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        return produce(oldData!, draft => {
          draft.chartsSelected = symbol;
        })!;
      });
      addFavorite({ symbol: symbol!, tradingAccountId: accountId });
      setSymbol(symbol!);
      if (isActive && openWatchlist) {
        openWatchlist(symbol!);
      }
    };

    const showRemoveButton = chartFavoritesCount > 1;

    return isMobile ? (
      <MobileChartSymbolButton
        ref={composedRefs}
        onSelect={selectSymbol}
        onRemove={showRemoveButton ? removeFavoriteFn : undefined}
        symbolInfo={symbolInfo}
        isActive={isActive}
      />
    ) : (
      <DesktopChartSymbolButton
        ref={composedRefs}
        onSelect={selectSymbol}
        onRemove={showRemoveButton ? removeFavoriteFn : undefined}
        symbolInfo={symbolInfo}
        isActive={isActive}
      />
    );
  },
);

const Component = memo(_ChartSymbolButtonContainer);

export { Component as ChartSymbolButtonContainer };
