import { useEffect } from "react";
import { useQueryClient } from "react-query";

import type { TerminalIndicator, TerminalIndicatorContainer } from "@/services/openapi";
import { terminalQueryKeys, useAddLastIndicatorMutation } from "@/state/server/terminal";

// https://www.tradingview.com/charting-library-docs/latest/saving_loading/#additional-use-cases

const useChartAutoSave = ({ accountId }: { accountId: string }) => {
  const client = useQueryClient();

  const { mutate } = useAddLastIndicatorMutation();

  useEffect(() => {
    window.tvWidget.subscribe("onAutoSaveNeeded", () => {
      window.tvWidget.save(state => {
        const stringState = JSON.stringify(state);

        const indicator: TerminalIndicator = {
          interval: window.tvWidget.symbolInterval().interval,
          chartContent: stringState,
        };

        mutate(
          {
            tradingAccountId: accountId,
            terminalIndicator: indicator,
          },
          {
            onSuccess: () => {
              client.setQueryData<TerminalIndicatorContainer>(terminalQueryKeys.lastIndicator(accountId), {
                indicator,
              });
            },
          },
        );
      });
    });
  }, []);
};

export { useChartAutoSave };
