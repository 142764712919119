import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { NewDialogContent } from "@/components/new-dialog";
import type { TradingAccount } from "@/services/openapi";

import { LeverageContainer } from "./leverage.container";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

const LeverageDialog: FC<Props> = ({ account, onClose }) => {
  const { t } = useTranslation();

  return (
    <NewDialogContent title={t("accounts.leverage.dialog-title")!}>
      <LeverageContainer account={account} onClose={onClose} />
    </NewDialogContent>
  );
};

export { LeverageDialog };
