import { styled } from "@/styles/stitches.config";

import { Flex } from "../flex";

export const Stack = styled(Flex, {
  variants: {
    direction: {
      vertical: {
        flexDirection: "column",
      },
      horizontal: {
        flexDirection: "row",
      },
    },
    gap: {
      "0": {
        gap: "0px",
      },
      "1": {
        gap: "8px",
      },
      "2": {
        gap: "12px",
      },
      "3": {
        gap: "16px",
      },
      "4": {
        gap: "24px",
      },
      "5": {
        gap: "32px",
      },
      "6": {
        gap: "40px",
      },
    },
  },

  defaultVariants: {
    gap: "3",
    direction: "vertical",
  },
});
