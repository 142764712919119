import { cva } from "class-variance-authority";
import { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useDisclosure } from "@/hooks/disclosure.hook";
import { Dialog } from "@/shared/ui";

const textStyles = cva(
  "font-roboto text-[14px] leading-normal text-contrast-secondary transition-colors hover:text-contrast-primary",
  {
    variants: {
      bright: {
        true: "!text-contrast-primary",
      },
      underline: {
        true: "underline",
      },
    },
  },
);

type Props = {
  value: ReactNode;
  children: (onClose: () => void) => ReactNode;
};

const ModifyOrderButton: FC<Props> = ({ value, children }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <button className="flex grow items-center justify-between outline-none">
          <div className={textStyles({ bright: !!value })}>{value ? value : "—"}</div>
          <div className={textStyles({ underline: true })}>{t("button.edit")}</div>
        </button>
      </Dialog.Trigger>
      {children(close)}
    </Dialog>
  );
};

export { ModifyOrderButton };
