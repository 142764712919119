import type { FC } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { BalanceFormat } from "@/components/balance-format";
import { Separator } from "@/components/separator";
import { Text } from "@/components/text";
import { useTransferEvaluate } from "@/hooks/transfer-evaluate.hook";
import { useTranslation } from "@/hooks/translator.hook";
import type { TradingAccount } from "@/services/openapi";

import { type ITransferFormValues, TransferFields } from "./transfer.form";

type Props = {
  sourceAccount: TradingAccount;
  destinationAccount: TradingAccount;
};

export const AccountTransferCalculate: FC<Props> = ({ sourceAccount, destinationAccount }) => {
  const { t } = useTranslation();
  const { resetField, watch } = useFormContext<ITransferFormValues>();

  const amount = watch(TransferFields.AMOUNT);

  const { preTransferData } = useTransferEvaluate({
    formAmount: amount,
    destinationCurrency: destinationAccount.currency!,
    sourceCurrency: sourceAccount.currency!,
  });

  useEffect(() => {
    resetField(TransferFields.AMOUNT);
  }, [sourceAccount.id]);

  return (
    <>
      <Separator css={{ mb: "24px" }} />
      <div className="mb-8 flex items-center">
        <Text family="roboto">{t("transfer.to-be-transfered")} —&nbsp;</Text>

        <BalanceFormat
          value={preTransferData?.amount ? preTransferData.amount : "0"}
          currency={destinationAccount.currency!}
        />
      </div>
    </>
  );
};
