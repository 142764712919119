import { type ComponentProps, type FC, useCallback } from "react";

import type { TradingAccount } from "@/services/openapi";
import { useChangeAccountLeverageMutation } from "@/state/server/accounts";

import { LeverageForm } from "./leverage.form";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

export const LeverageContainer: FC<Props> = ({ account, onClose }) => {
  const { mutateAsync: changeLeverage } = useChangeAccountLeverageMutation({ successMessage: "leverage-changed" });

  const handleSubmit: ComponentProps<typeof LeverageForm>["onSubmit"] = useCallback(
    ({ value }) => {
      return changeLeverage(
        { id: account.id!, integerContainer: { value: +value } },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [changeLeverage, account, onClose],
  );

  return (
    <LeverageForm
      onSubmit={handleSubmit}
      leverage={account.leverage!}
      availableLeverages={account.leveragesAvailable!}
    />
  );
};
