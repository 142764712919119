import type { FC } from "react";

import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { TextInput } from "@/components/form";
import { CheckBigIcon, GoogleAuthenticatorIcon } from "@/components/icons";
import { Stack } from "@/components/stack";
import { useTranslation } from "@/hooks/translator.hook";

import { TwoFASteps } from "../two-factor.container";

type Props = {
  setStep: (step: TwoFASteps) => void;
};

export const EnabledTwoFAContainer: FC<Props> = ({ setStep }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <TextInput
        disabled
        value="Google Authenticator"
        leftAdornment={<GoogleAuthenticatorIcon />}
        rightAdornment={<CheckBigIcon />}
      />
      <Flex css={{ justifyContent: "space-between", gap: "10px", flexWrap: "wrap" }}>
        <Button variant="secondary" onClick={() => setStep(TwoFASteps.DISABLE)}>
          {t("button.disable-2fa")}
        </Button>
        <Button onClick={() => setStep(TwoFASteps.NEW_RECOVERY_CODES)} css={{ whiteSpace: "pre-wrap" }}>
          {t("two-factor.settings.create-new-codes")}
        </Button>
      </Flex>
    </Stack>
  );
};
