export const TwoFactorIcon = () => (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 22C3 19.3271 0 16.6604 0 14V4C0 3.23241 0.294599 3.05401 1.10993 2.56029L1.10993 2.56029C1.35679 2.4108 1.65139 2.23241 2 2C2.16179 1.89214 5.56321 0 9 0C11.9952 0 14.5 1 16 2C16.3486 2.23241 16.6432 2.4108 16.8901 2.56029C17.7054 3.05401 18 3.23241 18 4C18.0197 4.21723 18.01 8.83711 18.0038 11.7947C18.0017 12.8033 18 13.6186 18 14C18 16.6667 15 19.3333 9 22ZM16 14C16 13.7697 16.0006 13.3838 16.0018 12.7547L16.0059 10.8014C16.0072 10.1695 16.0082 9.62725 16.009 9.10978C16.0127 6.87764 16.0117 4.96182 16.0096 4.36928L15.8722 4.28403C15.5488 4.08342 15.1274 3.82194 14.8906 3.6641C13.4143 2.67992 11.2602 2 9 2C6.83809 2 4.02864 3.05128 3.1094 3.6641C2.87264 3.82194 2.45117 4.08341 2.1278 4.28402L2.12779 4.28403L2 4.36333V14C2 15.5085 4.22911 17.5687 9.00093 19.802C13.7722 17.5737 16 15.5142 16 14ZM12.2929 7.29289L8 11.5858L5.70711 9.29289L4.29289 10.7071L8 14.4142L13.7071 8.70711L12.2929 7.29289Z"
      fill="currentColor"
    />
  </svg>
);
