import type { FC } from "react";
import { useMemo } from "react";

import { Alert } from "@/components/alert";
import { Box } from "@/components/box";
import { CopyButton } from "@/components/copy-button";
import { Grid } from "@/components/grid";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import type { TwoFactorRecoveryCode } from "@/services/openapi";

const getCodesString = (codes: TwoFactorRecoveryCode[]) => codes.map(({ code }) => code).join("\n");

type Props = {
  recoveryCodes: TwoFactorRecoveryCode[];
};

export const RecoveryCodesContainer: FC<Props> = ({ recoveryCodes }) => {
  const { t } = useTranslation();
  const copyValue = useMemo(() => getCodesString(recoveryCodes), [recoveryCodes]);

  return (
    <Stack>
      <Text weight="bold" size="3">
        {t("two-factor.settings.put-codes")}
      </Text>
      <Alert>{t("two-factor.settings.lose-device-alert")}</Alert>
      <Grid css={{ gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
        {recoveryCodes.map(({ code }) => (
          <Text weight="bold" size="3" key={code}>
            {code}
          </Text>
        ))}
      </Grid>
      <Box>
        <CopyButton copyValue={copyValue} />
      </Box>
    </Stack>
  );
};
