import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TooltipQuestionIcon } from "@/components/icons";
import { Leverage } from "@/entities/accounts/leverage";
import { cn } from "@/shared/styles";
import { Tooltip } from "@/shared/ui";

import { usePlaceOrderDetails } from "./use-details";

const Divider = () => <div className="flex-1 border-t border-dashed border-contrast-quaternary" />;

const listItemStyles = cva("flex items-center justify-between gap-1")();

const textStyles = cva("font-roboto text-[14px] leading-normal text-contrast-primary", {
  variants: {
    dimmed: {
      true: "text-contrast-secondary",
    },
  },
});

type Props = Parameters<typeof usePlaceOrderDetails>[0] & {
  className?: string;
};

const PlaceOrderDetails: FC<Props> = ({ className, ...props }) => {
  const { t } = useTranslation();

  const { currency } = props;

  const { leverage, margin, tradeAmount } = usePlaceOrderDetails(props);

  return (
    <ul className={cn("flex flex-col gap-2 px-1", className)}>
      <li className={listItemStyles}>
        <div className="flex items-center gap-2">
          <div className={textStyles({ dimmed: true })}>{t("terminal.place-order.details.margin.title")}</div>
          <Tooltip content={t("terminal.place-order.details.margin.description")}>
            <TooltipQuestionIcon />
          </Tooltip>
        </div>
        <Divider />
        <div className={textStyles()}>
          {margin ? <NumberFormat value={margin.value} decimalScale={margin.decimalScale} currency={currency} /> : "–"}
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.place-order.details.leverage")}</div>
        <Divider />
        <div className={textStyles()}>
          <Leverage leverage={leverage} />
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.place-order.details.trade-amount")}</div>
        <Divider />
        <div className={textStyles()}>
          {tradeAmount ? (
            <NumberFormat value={tradeAmount.value} decimalScale={tradeAmount.decimalScale} currency={currency} />
          ) : (
            "–"
          )}
        </div>
      </li>
    </ul>
  );
};

export { PlaceOrderDetails };
