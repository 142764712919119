import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

function useControlledField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(controllerParams: UseControllerProps<TFieldValues, TName>) {
  const {
    field,
    formState: { isSubmitting },
    fieldState: { error, invalid },
  } = useController<TFieldValues, TName>(controllerParams);

  return [field, { invalid, errorMessage: error?.message, pending: isSubmitting }] as const;
}

export { useControlledField };
