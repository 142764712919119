import { useQueryClient } from "react-query";

import type { RequestParamsWithPaging } from "@/components/pagination";
import { getNotifications } from "@/services/notifications";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { usePagingQuery } from "..";
import { profileQueryKeys } from "../profile";
import type { QueryOptionsType } from "../types";
import { notificationsQueryKeys } from "./notifications.keys";

export const useNotificationsQuery = (
  filters: RequestParamsWithPaging<ApiRequestParamsType<typeof getNotifications>>,
  options?: QueryOptionsType<ApiResponseType<typeof getNotifications>>,
) => {
  const client = useQueryClient();
  return usePagingQuery<ApiResponseType<typeof getNotifications>, ApiRequestParamsType<typeof getNotifications>>(
    filters,
    {
      queryKey: notificationsQueryKeys.notifications(filters),
      queryFn: getNotifications,
      onSuccess: () => {
        client.invalidateQueries(profileQueryKeys.header()); // profile contains notificationsCount
      },
      ...options,
    },
  );
};
