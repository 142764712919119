import type { FC, MouseEventHandler, ReactElement } from "react";
import { cloneElement } from "react";

import { type CSS, styled } from "@/styles";

import { Flex } from "../flex";
import type { StyledTabProps, TabProps, TabVariantType } from "./tab";

interface TabsProps extends StyledTabProps {
  value: string;
  children: Array<ReactElement<TabProps>>;
  onChange: (value: any) => void; // FIXME: enums break string type
  onClick?: MouseEventHandler;
  variant?: TabVariantType;
  css?: CSS;
}

const StyledTabs = styled(Flex, {
  justifyContent: "center",
  position: "relative",

  overflowX: "auto",
  overflowY: "hidden",

  hideScroll: true,

  "@bp2": {
    justifyContent: "flex-start",
  },

  "&::before": {
    content: "",
    borderBottom: "2px solid $colors$bgBorder",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
});

const StyledTileTabs = styled(Flex, {
  flexWrap: "wrap",
});

export const Tabs: FC<TabsProps> = ({ value, onChange, css, children, stretch, variant = "tab", tabSize, onClick }) => {
  const tabs = children.map(child => {
    const childValue = child.props.value;
    const selected = childValue === value;
    return cloneElement(child, {
      key: childValue,
      selected,
      onChange,
      variant,
      tabSize,
      stretch,
    });
  });

  const TabsComponent = variant === "tab" ? StyledTabs : StyledTileTabs;
  return (
    <TabsComponent css={css} onClick={onClick}>
      {tabs}
    </TabsComponent>
  );
};
