import { type FC } from "react";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useTerminalAccountSummary } from "./context";
import { AccountSummary } from "./summary";

const AccountSummaryContainer: FC = () => {
  const { bonus, showBonus } = useTerminalAccountContext();

  const { currency, equity, marginLevel, currencyDecimalScale, marginFree, credit, margin } =
    useTerminalAccountSummary();

  return (
    <AccountSummary
      currencyDecimalScale={currencyDecimalScale}
      marginLevel={marginLevel}
      currency={currency}
      equity={equity}
      marginFree={marginFree}
      bonus={bonus}
      showBonus={showBonus}
      credit={credit}
      margin={margin}
    />
  );
};

export { AccountSummaryContainer };
