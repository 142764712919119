import type { ComponentPropsWithoutRef, ElementRef, ReactNode } from "react";
import { forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { Popover, Text } from "@/shared/ui";

import { popoverContentStyles } from "../popper";

type HintProps = ComponentPropsWithoutRef<"div"> & {
  title: ReactNode;
  description: ReactNode;
  closeButton?: ReactNode;
};

const Hint = forwardRef<ElementRef<"div">, HintProps>(
  ({ title, className, description, closeButton, children, ...props }, ref) => {
    return (
      <div
        className={cn("rounded-[16px] bg-surface-elevation-3 p-6 text-static-white shadow-modal", className)}
        ref={ref}
        {...props}
      >
        <div className="mb-1 flex items-center justify-between">
          <Text variant="L / Medium" color="inherit" as="h3">
            {title}
          </Text>
          {closeButton}
        </div>
        <Text variant="S / Regular" color="inherit">
          {description}
        </Text>
        {children}
      </div>
    );
  },
);

type PopoverContentProps = ComponentPropsWithoutRef<typeof Popover.Content> & Pick<HintProps, "description" | "title">;

const PopoverContent = forwardRef<ElementRef<typeof Popover.Content>, PopoverContentProps>(
  ({ title, className, description, children, collisionPadding = 10, ...props }, ref) => {
    return (
      <Popover.Portal>
        <Popover.Content
          collisionPadding={collisionPadding}
          className={cn(popoverContentStyles(), "w-[300px]", className)}
          {...props}
          ref={ref}
        >
          <Hint
            title={title}
            description={description}
            closeButton={
              <Popover.Close className="relative grid place-items-center outline-none before:absolute before:size-11">
                <IconCross />
              </Popover.Close>
            }
          >
            {children}
          </Hint>
          <Popover.Arrow width={16} height={8} className="fill-surface-elevation-3" />
        </Popover.Content>
      </Popover.Portal>
    );
  },
);

const Component = Object.assign(Hint, { PopoverContent });

export { Component as Hint };
