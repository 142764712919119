import type { VariantProps } from "@stitches/react";
import { cva, type VariantProps as CvaVariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { cn } from "@/shared/styles";
import { styled } from "@/styles/stitches.config";

/**
 * @deprecated
 */
export const UnstyledText = styled("span", {
  display: "block",

  elementTransition: "color",

  variants: {
    family: {
      gilroy: {
        fontFamily: "$gilroy",
      },
      roboto: {
        fontFamily: "$roboto",
      },
    },
    color: {
      black: {
        color: "$textMain",
      },
      white: {
        color: "$textInverse",
      },
      negative: {
        color: "$negativeDefault",
      },
      dimmed: {
        color: "$textSecondary",
      },
      positive: {
        color: "$positiveDefault",
      },
      warning: {
        color: "$warningDefault",
      },
      gray: {
        color: "$textSecondary",
      },
      extraLightGrey: {
        color: "$extraLightGray",
      },
    },
    size: {
      "0": {
        fontSize: "12px",
      },
      "1": {
        fontSize: "14px",
      },
      "2": {
        fontSize: "16px",
      },
      "3": {
        fontSize: "18px",
      },
      "4": {
        fontSize: "22px",
      },
      "5": {
        fontSize: "24px",
      },
      "6": {
        fontSize: "32px",
      },
      "7": {
        fontSize: "40px",
      },
    },
    weight: {
      normal: {
        fontWeight: "$normal",
      },
      bold: {
        fontWeight: "$bold",
      },
      bolder: {
        fontWeight: "$bolder",
      },
    },
    lineHeight: {
      "1": {
        lineHeight: "$1",
      },
      "2": {
        lineHeight: "$2",
      },
      "3": {
        lineHeight: "$3",
      },
      "4": {
        lineHeight: "$4",
      },
      "5": {
        lineHeight: "$5",
      },
      "6": {
        lineHeight: "$6",
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    inline: {
      true: {
        display: "inline",
      },
    },
  },
});

export type TextColorType = VariantProps<typeof UnstyledText>["color"];

/**
 * @deprecated
 */
export const Text = styled(UnstyledText, {
  defaultVariants: {
    family: "gilroy",
    size: "2",
    weight: "normal",
    color: "black",
    lineHeight: "1",
  },
});

// TODO: remove
const textStyles = cva("block transition-colors", {
  variants: {
    family: {
      gilroy: "font-gilroy",
      roboto: "font-roboto",
    },
    color: {
      black: "text-contrast-primary",
      white: "text-text-inverse",
      negative: "text-negative-text",
      dimmed: "text-contrast-secondary",
      positive: "text-positive-text",
      warning: "text-warning-text",
      gray: "text-contrast-secondary",
    },
    size: {
      "0": "text-[12px]",
      "1": "text-[14px]",
      "2": "text-[16px]",
      "3": "text-[18px]",
      "4": "text-[22px]",
      "5": "text-[24px]",
      "6": "text-[32px]",
      "7": "text-[40px]",
    },
    weight: {
      normal: "font-normal",
      bold: "font-semibold",
      bolder: "font-bold",
    },
    lineHeight: {
      "1": "leading-[19px]",
      "2": "leading-[22px]",
      "3": "leading-[24px]",
      "4": "leading-[32px]",
      "5": "leading-[56px]",
      "6": "leading-[64px]",
    },
    align: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
    inline: {
      true: "inline",
    },
  },
});

// TODO: remove
export const TextBad = forwardRef<
  ElementRef<"span">,
  ComponentPropsWithoutRef<"span"> & CvaVariantProps<typeof textStyles>
>(
  (
    {
      className,
      align,
      inline,
      lineHeight = "1",
      weight = "normal",
      size = "2",
      color = "black",
      family = "gilroy",
      ...props
    },
    ref,
  ) => (
    <span
      className={cn(textStyles({ inline, align, lineHeight, weight, size, color, family }), className)}
      {...props}
      ref={ref}
    />
  ),
);
