import { useCallback } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { accountSearchParamsName } from "@/features/dashboard/components/trade-button/trade-button";
import { DialogName, getDialogSearchParams } from "@/hooks/dialog.hook";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { router } from "@/routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import {
  AvailabilityStatus,
  type TradingAccount,
  TradingAccountStatus,
  TradingServerPlatform,
} from "@/services/openapi";
import { useAllAccountsQuery, useLastAccountMutation } from "@/state/server/accounts";

/*
  if user has 0 active accounts -> show dialog
  if user's last account not archived and it's platform is Doto -> navigate to terminal
  if user's last account platform isn't Doto and not archived -> navigate to dashboard and show dialog with credits
  if user's last account platform isn't Doto and archived -> navigate to dashboard
*/

export const useTerminalAutoRoute = () => {
  const navigate = router.navigate;
  const [opened, { open, close }] = useDisclosure();
  const [searchParams] = useSearchParams();
  const isWebView = searchParams.get("isWebView") === "true";
  const { refetch } = useAllAccountsQuery(
    {
      status: AvailabilityStatus.Active,
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
    },
  );
  const { isLoading, mutate } = useLastAccountMutation();

  const navigateToTerminal = useCallback(
    (id: string) => {
      navigate(getTerminalRoute(id, "", isWebView));
    },
    [navigate, isWebView],
  );

  const navigateToDashboard = useCallback(
    ({
      type,
      id,
      status,
    }: {
      type: TradingAccount["type"];
      id: TradingAccount["id"];
      status: TradingAccount["status"];
    }) => {
      if (status === TradingAccountStatus.Archived) {
        navigate(cabinetRoutes.dashboard);
      } else {
        navigate({
          pathname: cabinetRoutes.dashboard,
          search: createSearchParams({ [accountSearchParamsName]: id!, tab: type! }).toString(),
        });
      }
    },
    [navigate],
  );

  const navigator = async () => {
    try {
      const { data: activeAccounts } = await refetch();

      if (!(activeAccounts && activeAccounts.length > 0)) {
        navigate({ search: getDialogSearchParams(DialogName.NO_ACCOUNTS) });
        close();
        return;
      }

      mutate(
        { status: AvailabilityStatus.All },
        {
          onSuccess: ({ type, id, platform, status }) => {
            if (!id) return;

            if (platform === TradingServerPlatform.MetaTrader5 && status === TradingAccountStatus.Active) {
              navigateToTerminal(id);
            } else {
              navigateToDashboard({ type: type, id: id, status: status });
            }
            close();
          },
        },
      );
    } catch (error) {
      logError(error);
    }
  };

  return {
    isLoading,
    open: () => navigator().then(open),
  };
};
