import { type FC } from "react";

import { useTerminalAccountContext } from "../../contexts/account.context";
import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { useLayoutContext } from "../../layout/context";
import { ClosedPositionsTable } from "../desktop/closed-positions/table";
import { ClosedPositionsCardsContainer } from "../mobile/closed-positions/cards.container";

const ClosedPositionsContainer: FC = () => {
  const { account } = useTerminalAccountContext();

  const { setSymbol } = useCurrentSymbolContext();
  const { isMobile } = useLayoutContext();

  return isMobile ? (
    <ClosedPositionsCardsContainer
      accountId={account.id!}
      currency={account.currency!}
      currencyDecimalScale={account.digits!}
    />
  ) : (
    <ClosedPositionsTable
      accountId={account.id!}
      currency={account.currency!}
      currencyDecimalScale={account.digits!}
      setSymbol={setSymbol}
    />
  );
};

export { ClosedPositionsContainer };
