import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, useHookForm } from "@/app/form";
import { NewSubmitButton } from "@/app/form/new-submit-button";
import { IconWarningCircle } from "@/domains/icons";
import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import { AdditionalField } from "@/features/payment/withdrawal/components/form/additional-field";
import type { WithdrawalInfoProps } from "@/features/payment/withdrawal/withdrawal-info.hook";
import type { PaymentMethod } from "@/services/openapi";
import { Alert } from "@/shared/ui";

type Props = {
  next: () => void;
  back: () => void;
  currentPaymentMethod: PaymentMethod;
  setAdditionalFieldsData: WithdrawalInfoProps["setAdditionalFieldsData"];
};

const AdditionalFieldsStep: FC<Props> = ({ next, back, currentPaymentMethod, setAdditionalFieldsData }) => {
  const { t } = useTranslation();
  const additionalFields = currentPaymentMethod.fields!;

  const form = useHookForm();

  const onSubmit = useCallback(
    (values: any) => {
      const userData = additionalFields.map(({ id, title }) => ({
        id,
        title,
        value: values[id!],
      }));

      setAdditionalFieldsData(userData);
      next();
    },
    [additionalFields, next, setAdditionalFieldsData],
  );

  return (
    <PaymentWrapper title={t("payments.payment-method")} onBack={back}>
      {currentPaymentMethod.details?.widget && (
        <Alert
          className="mb-4"
          icon={<IconWarningCircle />}
          text={
            <div>
              <div>{currentPaymentMethod.details?.widget?.text?.title}</div>
              <div>{currentPaymentMethod.details?.widget?.text?.subtitle}</div>
              <div>{currentPaymentMethod.details?.widget?.text?.paragraph}</div>
            </div>
          }
        />
      )}
      <HookForm form={form} onSubmit={onSubmit}>
        <div className="flex flex-col gap-4">
          {additionalFields.map(field => {
            return <AdditionalField key={field.id} {...field} />;
          })}
        </div>

        <PaymentWrapper.Footer>
          <NewSubmitButton fullWidth>{t("button.next")}</NewSubmitButton>
        </PaymentWrapper.Footer>
      </HookForm>
    </PaymentWrapper>
  );
};

export { AdditionalFieldsStep };
