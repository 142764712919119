export const DoubleOkIcon = () => (
  <svg width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6192 0L5.54588 8.19988L2.35443 4.94996L0.973633 6.35001L5.54588 11L15 1.40006L13.6192 0ZM9.66642 9.09432L11.5459 11L21 1.40006L19.6192 0L11.5459 8.19988L11.0462 7.69324L9.66642 9.09432Z"
      fill="#08D74F"
    />
  </svg>
);
