import type { ReactNode } from "react";
import { createContext, memo, useContext, useEffect, useMemo, useState } from "react";

import { logError } from "@/app/libs/sentry";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalEventType, TerminalNotificationType, type TradingAccount } from "@/services/openapi";
import { toast, ToastIcons } from "@/shared/ui";

import { useSymbolsContext } from "../contexts/symbols.context";
import {
  isMarketOrder,
  isPendingOrder,
  type LiveOrder,
  normalizeOrdersList,
  toastDeal,
  toastMultipleDeals,
  transformTerminalOrder,
  updateOrdersList,
} from "../helpers/orders";
import { useTerminalSocket } from "../hooks/socket.hook";
import { useLayoutContext } from "../layout/context";

type OrdersContextState = {
  openOrders: LiveOrder[];
  pendingOrders: LiveOrder[];
  ordersIsLoading: boolean;
};

const OrdersContext = createContext<OrdersContextState>({} as OrdersContextState);

export const TerminalOrdersContextProvider = memo(
  ({ account, children }: { children: ReactNode; account: TradingAccount }) => {
    const { t } = useTranslation();

    const [ordersIsLoading, setOrdersIsLoading] = useState(true);

    const { changeTable } = useLayoutContext();

    const [list, setList] = useState<{
      [key: string]: LiveOrder;
    }>({});

    const { lastJsonMessage: socketMessage } = useTerminalSocket();

    const { symbolsList } = useSymbolsContext();

    useEffect(() => {
      try {
        if (socketMessage && socketMessage.t === TerminalEventType.Init) {
          const normalizedOrders = normalizeOrdersList(socketMessage.dds!);
          setOrdersIsLoading(false);
          setList(normalizedOrders);
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (socketMessage && socketMessage.t === TerminalEventType.Deal) {
          const order = transformTerminalOrder(socketMessage.dd!);

          setList(previousList =>
            updateOrdersList({
              newOrder: order,
              list: previousList,
            }),
          );
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (socketMessage && socketMessage.t === TerminalEventType.DealsNotifications) {
          const deals = socketMessage.ddn!;

          if (deals.length > 1) {
            toastMultipleDeals({
              toast,
              deals,
              symbolsList,
              changeTable,
              t,
              accountCurrency: account.currency!,
              currencyDecimalScale: account.digits!,
            });
            return;
          }

          toastDeal({
            toast,
            deal: deals[0]!,
            symbolsList,
            changeTable,
            t,
            accountCurrency: account.currency!,
            currencyDecimalScale: account.digits!,
          });
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (socketMessage && socketMessage.t === TerminalEventType.TradingNotification) {
          const { m: message, t: type, p: isPermanent, h: title } = socketMessage.dtn!;
          switch (type) {
            case TerminalNotificationType.Info:
            case TerminalNotificationType.Danger:
            case TerminalNotificationType.Default:
            case TerminalNotificationType.Waring:
            default: {
              toast({ title: title!, description: message, icon: ToastIcons.WARNING, autoClose: !isPermanent });
            }
          }
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    const orders = useMemo(() => {
      return Object.values(list).sort((a, b) => +new Date(b.date) - +new Date(a.date));
    }, [list]);

    const openOrders = useMemo(() => orders.filter(({ type }) => isMarketOrder(type)), [orders]);
    const pendingOrders = useMemo(() => orders.filter(({ type }) => isPendingOrder(type)), [orders]);

    const value: OrdersContextState = useMemo(
      () => ({ openOrders, pendingOrders, ordersIsLoading }),
      [openOrders, pendingOrders, ordersIsLoading],
    );

    return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
  },
);
TerminalOrdersContextProvider.displayName = "TerminalOrdersContextProvider";

export const useOrdersContext = () => {
  return useContext(OrdersContext);
};
