export const paymentQueryKeys = {
  base: ["payments"],
  depositMethods: (filters?: object) => [...paymentQueryKeys.base, "method/deposit", { filters }],
  withdrawMethods: (filters?: object) => [...paymentQueryKeys.base, "method/withdraw", { filters }],
  depositFee: (filters: object) => [...paymentQueryKeys.base, "fee/deposit", { filters }],
  withdrawFee: (filters: object) => [...paymentQueryKeys.base, "fee/withdraw", { filters }],
  maximumWithdrawAmount: (filters: object) => [...paymentQueryKeys.base, "withdraw/limit", { filters }],
  maximumDepositAmount: (filters: object) => [...paymentQueryKeys.base, "deposit/limit", { filters }],
  depositWidgets: () => [...paymentQueryKeys.base, "deposit/widgets"],
  withdrawWidgets: () => [...paymentQueryKeys.base, "withdraw/widgets"],
  info: () => [...paymentQueryKeys.base, "info"],
};
