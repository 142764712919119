import type { TFunction } from "i18next";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { AndroidIcon, AppleIcon, LinuxIcon, WindowsIcon } from "@/components/icons";
import type { OSType } from "@/constants/types";
import type { TradingAccount } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { getUserPlatformAndOS } from "@/utils/helpers";

// TODO: remove this garbage
const userPlatform = getUserPlatformAndOS().os as unknown as OSType;

const getInfo = ({
  version,
  account,
  t,
}: {
  t: TFunction;
  version: 4 | 5;
  account: TradingAccount;
}): { link: string; icon: ReactNode; text: string } => {
  const {
    serverTerminalAndroidUrl,
    serverTerminalIOsUrl,
    serverTerminalLinuxOsUrl,
    serverTerminalMacOsUrl,
    serverTerminalUrl,
  } = account;

  switch (userPlatform) {
    case "Android": {
      return {
        text: t("accounts.metatrader.install-android", { version }),
        icon: <AndroidIcon />,
        link: serverTerminalAndroidUrl!,
      };
    }
    case "Linux": {
      return {
        text: t("accounts.metatrader.install-linux", { version }),
        icon: <LinuxIcon />,
        link: serverTerminalLinuxOsUrl!,
      };
    }
    case "MacOS": {
      return {
        text: t("accounts.metatrader.install-mac", { version }),
        icon: <AppleIcon />,
        link: serverTerminalMacOsUrl!,
      };
    }
    case "IOS": {
      return {
        text: t("accounts.metatrader.install-ios", { version }),
        icon: <AppleIcon />,
        link: serverTerminalIOsUrl!,
      };
    }
    case "Windows": {
      return {
        text: t("accounts.metatrader.install-windows", { version }),
        icon: <WindowsIcon />,
        link: serverTerminalUrl!,
      };
    }
    default: {
      return { link: "", icon: null, text: "" };
    }
  }
};

type Props = {
  version: 4 | 5;
  account: TradingAccount;
};

export const MetatraderInstallButton: FC<Props> = ({ account, version }) => {
  const { t } = useTranslation();

  const { link, icon, text } = getInfo({ account, version, t });

  return (
    <Button asChild variant="tertiary" startSection={icon}>
      <a href={link} target="_blank" rel="noreferrer">
        {text}
      </a>
    </Button>
  );
};
