import { cva, type VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cn } from "@/shared/styles";
import { Dialog, overlayStyles } from "@/shared/ui";

const contentStyles = cva(
  "relative z-50 h-full w-full bg-surface-elevation-2 px-4 py-6 shadow outline-none md:mb-[30px] md:mt-[5vh] md:h-auto md:rounded-[24px] md:p-[60px]",
  {
    variants: {
      size: {
        sm: "md:max-w-[475px]",
        md: "md:max-w-[526px]",
      },
    },
  },
);

const DialogContent = forwardRef<
  ElementRef<typeof Dialog.Content>,
  ComponentPropsWithoutRef<typeof Dialog.Content> & VariantProps<typeof contentStyles>
>(({ children, className, size = "md", ...props }, forwardedRef) => {
  const { t } = useTranslation();
  const { isLargeDesktop } = useScreenSize();

  return (
    <Dialog.Portal>
      <Dialog.Overlay className={cn(overlayStyles(), "grid place-items-center overflow-y-auto overflow-x-hidden")}>
        <Dialog.Content
          className={cn(contentStyles({ size }), className)}
          onOpenAutoFocus={e => !isLargeDesktop && e.preventDefault()}
          onCloseAutoFocus={e => !isLargeDesktop && e.preventDefault()}
          {...props}
          ref={forwardedRef}
        >
          {children}
          <Dialog.Close
            className="absolute end-4 top-3 grid size-9 place-items-center text-contrast-primary"
            aria-label={t("button.close")!}
          >
            <IconCross className="size-6" />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
});

const DialogButtons = ({ children }: { children?: React.ReactNode }) => {
  return <div className="mt-[24px] flex flex-row-reverse flex-wrap gap-4 *:flex-1 md:mt-[32px]">{children}</div>;
};

export { DialogButtons, DialogContent };
