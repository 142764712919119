import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ExternalLink, InfoDialogContent } from "@/shared/ui";

const SignalDisclaimerDialog: FC = () => {
  const { t } = useTranslation();

  return (
    <InfoDialogContent title={t("terminal.signal.disclaimer.title")}>
      <p className="whitespace-pre-wrap font-roboto text-[14px] leading-normal text-contrast-primary">
        <Trans
          i18nKey="terminal.signal.disclaimer.text"
          values={{ link: "terms_of_use_web_sites_en.pdf" }}
          components={{
            anchor: (
              <ExternalLink
                className="text-positive-text"
                href="https://global.tradingcentral.com/www/pdf/terms_of_use_web_sites_en.pdf"
              />
            ),
          }}
        />
      </p>
    </InfoDialogContent>
  );
};

export { SignalDisclaimerDialog };
