import { Navigate } from "react-router-dom";

import { getWithdrawalRoute } from "@/routes/cabinet.routes";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { Loader } from "@/shared/ui";
import { useAllAccountsQuery, useLastAccountQuery } from "@/state/server/accounts";
import { useWithdrawMethodQuery } from "@/state/server/payment";

import { WithdrawalForm } from "./components/form/withdrawal.form";
import { WithdrawalNoAccounts } from "./components/no-accounts";

const WithdrawalPage = () => {
  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.AvailableToWithdraw,
  });
  const { data: paymentMethodsData } = useWithdrawMethodQuery();

  const { data: lastAccount } = useLastAccountQuery({
    type: TradingAccountType.Real,
    status: AvailabilityStatus.Active,
  });

  if (!accounts || !paymentMethodsData || !lastAccount) {
    return <Loader />;
  }

  if (!accounts.length) {
    return <WithdrawalNoAccounts paymentMethods={paymentMethodsData.items!} />;
  }

  if (accounts.length === 1) {
    return <Navigate to={getWithdrawalRoute(accounts[0]!.id!)} replace />;
  }

  return <WithdrawalForm lastAccount={lastAccount} accounts={accounts} />;
};

export { WithdrawalPage };
