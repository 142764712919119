import type { FC } from "react";
import { Link } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { Banner } from "@/shared/ui";

import img from "./assets/verify-promo.png";

type Props = {
  isSurveyCompleted: boolean;
};

export const CompleteVerificationBanner: FC<Props> = ({ isSurveyCompleted }) => {
  const { t } = useTranslation();
  const verificationRoute = getVerificationRoute(isSurveyCompleted);

  return (
    <Banner
      text={t("banners.verify.text")}
      bonusImg={img}
      actionButton={
        <Banner.ActionButton onClick={() => track(amplitudeEvents.verify.bannerClick)} asChild>
          <Link to={verificationRoute}>{t("banners.verify.button")}</Link>
        </Banner.ActionButton>
      }
    />
  );
};
