export const DepositIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.7 12c0 6.1 4.9 11 11 11 2.5 0 4.9-.8 6.8-2.3l-1.2-1.6c-1.6 1.2-3.5 1.9-5.6 1.9-5 0-9-4-9-9s4-9 9-9 9 4 9 9h2c0-6.1-4.9-11-11-11S.7 5.9.7 12zm10.2 4.7v2.2h1.6v-1.8c.9-.1 1.7-.5 2.2-1 .6-.6.9-1.3.9-2.1 0-2-1.9-2.6-3.4-2.9-1.7-.4-2.2-.6-2.2-1.3 0-.4.1-.7.3-1 .2-.2.7-.4 1.2-.4h.2c.9 0 2 .4 2.5.9l.6.5 1.1-1.2-.6-.5c-.7-.6-1.8-1-2.8-1.2V5.2h-1.7v1.6c-.7.1-1.3.4-1.7.8-.5.5-.8 1.2-.8 2 0 2 1.9 2.6 3.4 2.9 1.7.4 2.2.6 2.2 1.3 0 .4 0 .6-.3.9l-.133.068c-.377.193-.843.432-1.467.432h-.4c-1 0-2-.4-2.4-1l-.5-.6-1.2 1 .5.6c.7.7 1.8 1.3 2.9 1.5zm10.5-3.2h-2v2.1h-2.1v2h2.1v2.1h2v-2.1h2.1v-2h-2.1v-2.1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
