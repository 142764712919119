import type { FC } from "react";
import { useCallback } from "react";
import type { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { HookForm } from "@/app/form";
import { NewSubmitButton as SubmitButton } from "@/app/form/new-submit-button";
import { ArrowLeftIcon } from "@/components/icons/arrow-left-icon";
import type { AnyObjectType } from "@/constants/types";
import { createSurveyRequestItems } from "@/features/onboarding/components/survey/survey.helpers";
import { SurveyField } from "@/features/onboarding/components/survey/survey-field";
import { useHookForm } from "@/hooks/form.hook";
import type { SurveyGroup } from "@/services/openapi";
import { NewButton } from "@/shared/ui";
import { useUpdateSurveyMutation } from "@/state/server/kyc";

import { StepWrapper } from "../step-wrapper";

type Props = {
  surveyGroup: SurveyGroup;
  isLastStep: boolean;
  onSubmit: () => void;
  onBack: () => void;
};

const SurveyContainer: FC<Props> = ({ surveyGroup, isLastStep, onSubmit: onSubmitProps, onBack }) => {
  const { t } = useTranslation();
  const { mutateAsync: updateSurvey } = useUpdateSurveyMutation();
  const form = useHookForm();

  const onSubmit = useCallback(
    (values: AnyObjectType) => {
      return updateSurvey(
        {
          surveyUpdate: {
            surveyGroupId: surveyGroup.id!,
            items: createSurveyRequestItems(values),
          },
        },
        {
          onSuccess: () => {
            onSubmitProps();
          },
        },
      );
    },
    [updateSurvey, onSubmitProps, surveyGroup.id],
  );

  const onInvalid = useCallback((errors: FieldErrors) => {
    const allErrorsKeys = Object.keys(errors);

    document.querySelector(`[name="${allErrorsKeys[0]}"]`)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <StepWrapper title={surveyGroup.title!} subtitle={surveyGroup.description!}>
      <HookForm form={form} onSubmit={onSubmit} onInvalid={onInvalid} className="flex	h-full flex-col">
        <div className="grid gap-5 whitespace-pre-line">
          {surveyGroup.fields!.map(field => (
            <SurveyField field={field} key={field.id} />
          ))}
        </div>

        <StepWrapper.Footer>
          <NewButton variant="flat" className="max-w-min text-contrast-secondary" onClick={onBack}>
            <ArrowLeftIcon />
            {t("button.back")}
          </NewButton>
          <SubmitButton size="md" fullWidth className="ms-auto  max-w-[200px]">
            {isLastStep ? t("button.start-trading") : t("button.next")}
          </SubmitButton>
        </StepWrapper.Footer>
      </HookForm>
    </StepWrapper>
  );
};

export { SurveyContainer };
