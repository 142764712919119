import { darkTheme, theme } from "@/styles";

type ChartColors = {
  area: string;
  hlcAreaLowColor: string;
  hlcAreaHighColor: string;
  text: string;
  background: string;
  positive: string;
  positivePressed: string;
  positiveHover: string;
  negative: string;
  negativePressed: string;
  negativeHover: string;
  iconClose: string;
  orderLineBackground: string;
  orderLineText: string;
  loadingBackground: string;
  loadingForeground: string;
};

const lightChartColors: ChartColors = {
  area: "rgba(8, 215, 79, 0.3)",
  hlcAreaLowColor: "rgba(255, 52, 52, 0.3)",
  hlcAreaHighColor: "rgba(8, 215, 79, 0.3)",
  text: theme.colors.contrastPrimary.value,
  background: theme.colors.surfaceCanvas.value,
  positive: theme.colors.positiveDefault.value, // TODO: Something is wrong here. Changing color breaks the area chart
  positivePressed: theme.colors.positiveBgActive.value,
  positiveHover: theme.colors.positiveBgHover.value,
  negative: theme.colors.negativeText.value,
  negativePressed: theme.colors.negativeBgActive.value,
  negativeHover: theme.colors.negativeBgHover.value,
  iconClose: theme.colors.accentOver.value,
  orderLineBackground: theme.colors.accentBgDefault.value,
  orderLineText: theme.colors.accentOver.value,
  loadingBackground: theme.colors.surfaceCanvas.value,
  loadingForeground: theme.colors.positiveBgActive.value,
};

const darkChartColors: ChartColors = {
  area: "rgba(8, 215, 79, 0.3)",
  hlcAreaLowColor: "rgba(255, 52, 52, 0.3)",
  hlcAreaHighColor: "rgba(8, 215, 79, 0.3)",
  text: darkTheme.colors.contrastPrimary.value,
  background: darkTheme.colors.surfaceCanvas.value,
  positive: darkTheme.colors.positiveDefault.value,
  positivePressed: darkTheme.colors.positiveBgActive.value,
  positiveHover: darkTheme.colors.positiveBgHover.value,
  negative: darkTheme.colors.negativeText.value,
  negativePressed: darkTheme.colors.negativeBgActive.value,
  negativeHover: darkTheme.colors.negativeBgHover.value,
  iconClose: darkTheme.colors.accentOver.value,
  orderLineBackground: darkTheme.colors.accentBgDefault.value,
  orderLineText: darkTheme.colors.accentOver.value,
  loadingBackground: darkTheme.colors.surfaceCanvas.value,
  loadingForeground: darkTheme.colors.positiveBgActive.value,
};

const getChartColors = (isDarkTheme: boolean) => {
  return isDarkTheme ? darkChartColors : lightChartColors;
};

export { type ChartColors, getChartColors };
