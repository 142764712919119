import type { TFunction } from "i18next";

export const transferRules = (t: TFunction, accountAmount: number) => ({
  required: t("form-errors.required-error")!,
  validate: (value: number) => value !== 0 || t("form-errors.greater-than", { value: 0 })!,
  max: {
    value: accountAmount,
    message: t("form-errors.amount-more-than-available")!,
  },
});
