import { type FC } from "react";

import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import type { TradingAccount } from "@/services/openapi";
import { Dialog, NewButton } from "@/shared/ui";

import { SetBalanceDialogContent } from "./dialog-content";

type Props = {
  account: TradingAccount;
};

export const SetBalanceButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();
  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <NewButton variant="secondary" color="accent" fullWidth size="sm" data-test="account-demo-set-balance">
          {t("accounts.demo.add-balance.menu-title")}
        </NewButton>
      </Dialog.Trigger>
      <SetBalanceDialogContent accountId={account.id!} accountCurrency={account.currency!} onClose={close} />
    </Dialog>
  );
};
