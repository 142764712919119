import { type FC, Fragment } from "react";
import { Trans } from "react-i18next";

import { TradingCentralSignalType } from "@/services/openapi";

const getKey = (type: TradingCentralSignalType) => {
  switch (type) {
    case TradingCentralSignalType.StrongUp:
      return "terminal.signal.strong-up";
    case TradingCentralSignalType.Up:
      return "terminal.signal.up";
    case TradingCentralSignalType.Down:
      return "terminal.signal.down";
    case TradingCentralSignalType.StrongDown:
      return "terminal.signal.strong-down";
  }
};

type Props = {
  type: TradingCentralSignalType;
  hasAccentText?: boolean;
};

const SignalTextFormat: FC<Props> = ({ type, hasAccentText }) => {
  if (type === TradingCentralSignalType.None) return null;

  return (
    <Trans
      i18nKey={getKey(type)}
      components={{
        accentText: hasAccentText ? (
          <span
            className={
              type === TradingCentralSignalType.Up || type === TradingCentralSignalType.StrongUp
                ? "text-positive-text"
                : "text-negative-text"
            }
          />
        ) : (
          <Fragment />
        ),
      }}
    />
  );
};

export { SignalTextFormat };
