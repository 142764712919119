import type { FC } from "react";

import { PopupActionButton } from "@/app/libs/firebase";
import { NewDialogContent } from "@/components/new-dialog";
import { Dialog } from "@/shared/ui";

import type { Props } from "../types";

export const DemoToRealDialog: FC<Props> = ({ open, onClose, content: { buttons, content }, onButtonClick }) => {
  const { title, text, imagesUrls } = content!;
  const image = imagesUrls![0]!;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <NewDialogContent title={title!} description={text} image={{ src: image }}>
        <div className="grid gap-1">
          {buttons?.map(button => {
            return (
              <PopupActionButton
                key={button.text}
                action={button.action}
                color={button.color}
                text={button.text}
                className="w-full"
                size="lg"
                onClick={onButtonClick}
              >
                {button.text}
              </PopupActionButton>
            );
          })}
        </div>
      </NewDialogContent>
    </Dialog>
  );
};
