import * as PopoverPrimitive from "@radix-ui/react-popover";

const Component = Object.assign(PopoverPrimitive.Root, {
  Trigger: PopoverPrimitive.Trigger,
  Portal: PopoverPrimitive.Portal,
  Content: PopoverPrimitive.Content,
  Close: PopoverPrimitive.Close,
  Anchor: PopoverPrimitive.Anchor,
  Arrow: PopoverPrimitive.Arrow,
});

export { Component as Popover };
