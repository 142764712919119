import { type FC } from "react";

import { IconCross } from "@/domains/icons";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { useSignalContext } from "../../signal/context";
import { SignalTextFormat } from "../../signal/text-format";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";

const PlaceOrderSignalBlock: FC = () => {
  const { closeSignalForm } = useSignalContext();
  const { symbolInfo } = useCurrentSymbolContext();

  return (
    <div className="flex grow flex-col overflow-auto bg-surface-canvas pt-4 animate-in fade-in-0">
      <div className="flex items-center justify-between gap-2 px-5">
        <h5 className="font-gilroy text-[18px] font-semibold leading-[1.2] text-contrast-primary">
          <SignalTextFormat type={symbolInfo.signal!.type!} />
        </h5>
        <button className="p-2" onClick={closeSignalForm}>
          <IconCross />
        </button>
      </div>
      <TerminalPlaceOrderProvider isSignal>
        <div className="overflow-auto pt-3 scrollbar-hide">
          <PlaceOrderContainer />
        </div>
      </TerminalPlaceOrderProvider>
    </div>
  );
};

export { PlaceOrderSignalBlock };
