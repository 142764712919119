import { type FC, Fragment } from "react";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { useSignalContext } from "../../signal/context";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";
import { TradingUnavailableBlock } from "../trading-unavailable/block";
import { PlaceOrderSignalBlock } from "./signal-block";

const MobilePlaceOrderBlock: FC = () => {
  const { currentSymbol, isTradingAvailable } = useCurrentSymbolContext();

  const { signalFormOpened } = useSignalContext();

  return (
    <Fragment key={currentSymbol}>
      {isTradingAvailable ? (
        <>
          {signalFormOpened ? (
            <PlaceOrderSignalBlock />
          ) : (
            <TerminalPlaceOrderProvider>
              <PlaceOrderContainer />
            </TerminalPlaceOrderProvider>
          )}
        </>
      ) : (
        <TradingUnavailableBlock />
      )}
    </Fragment>
  );
};

export { MobilePlaceOrderBlock };
