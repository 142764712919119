import { type FC, type ReactNode } from "react";

import { Dialog } from "@/shared/ui";
import { styled } from "@/styles";

import { DialogDescriptionText } from "../dialog/styles";
import { TextBad } from "../text";

type Props = {
  children: ReactNode;
  title: ReactNode;
  imageSrc?: string;
  description?: ReactNode;
};

export const InfoDialog: FC<Props> = ({ children, description, title, imageSrc }) => {
  return (
    <div className="flex h-full flex-col">
      {imageSrc && (
        <div className="flex justify-center">
          <img className="h-[158px] w-[158px]" src={imageSrc} alt="" loading="lazy" />
        </div>
      )}
      <Dialog.Title asChild>
        <TextBad className="my-4 lg:mt-0" weight="bold" size="6" align="center" lineHeight="4">
          {title}
        </TextBad>
      </Dialog.Title>
      {description && (
        <Dialog.Description asChild>
          <DialogDescriptionText>{description}</DialogDescriptionText>
        </Dialog.Description>
      )}

      <div className="mt-6 flex grow flex-col">{children}</div>
    </div>
  );
};

export const InfoDialogContent = styled("div", {
  p: "40px 32px",
  mb: "25px",
  border: "1px solid",
  borderColor: "$bgBorder",
  borderRadius: "16px",
  variants: {
    dashed: {
      true: {
        border: "dashed $primary",
      },
    },
  },
});
