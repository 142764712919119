import { cva } from "class-variance-authority";
import { Fragment, type ReactNode } from "react";

const wrapper = cva("grid gap-4 grid-auto-fill-[300px]")();

type Props<TData> = {
  cards: TData[] | undefined;
  children: (card: TData) => ReactNode;
  emptyState: ReactNode;
  skeleton: ReactNode;
  isFetching: boolean;
  button?: ReactNode;
};

const AccountsCardsWrapper = <TData,>({ children, cards, emptyState, skeleton, isFetching, button }: Props<TData>) => {
  if (!cards) {
    return (
      <div className={wrapper}>
        {[...Array(8)].map((_, i) => (
          <Fragment key={i}>{skeleton}</Fragment>
        ))}
      </div>
    );
  }

  if (cards.length === 0) {
    return <>{emptyState}</>;
  }

  return (
    <>
      <div className={wrapper}>{cards.map(children)}</div>
      {button && <div className="mt-4 grid place-items-center md:block">{button}</div>}
    </>
  );
};

export { AccountsCardsWrapper };
