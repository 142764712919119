import { type FC, type ReactNode } from "react";

import { Text } from "@/shared/ui";

type Props = {
  icon: ReactNode;
  text: ReactNode;
};

const WatchlistEmptyState: FC<Props> = ({ text, icon }) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <div className="grid size-14 place-items-center rounded-full bg-contrast-quinary">{icon}</div>
      <Text variant="M / Medium" align="center" color="primary">
        {text}
      </Text>
    </div>
  );
};

export { WatchlistEmptyState };
