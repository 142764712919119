import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconArrowRight, IconFire } from "@/domains/icons";
import { TradingCentralBuzzingType } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Tooltip } from "@/shared/ui";

const barStyles = cva("opacity-25", {
  variants: {
    variant: {
      neutral: "fill-constant-label",
      positive: "fill-positive-text",
    },
    active: {
      true: "!opacity-100",
    },
  },
});

type Props = {
  buzzingTrend: TradingCentralBuzzingType;
};

const Gauge: FC<Props> = ({ buzzingTrend }) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div
        className={cn("absolute bottom-0 end-1/2 translate-x-1/2", {
          "text-positive-text":
            buzzingTrend === TradingCentralBuzzingType.Buzzing ||
            buzzingTrend === TradingCentralBuzzingType.AboveAverage,
          "text-constant-label":
            buzzingTrend === TradingCentralBuzzingType.BelowAverage ||
            buzzingTrend === TradingCentralBuzzingType.Average,
        })}
      >
        {buzzingTrend === TradingCentralBuzzingType.AboveAverage && <IconArrowRight className="-rotate-90" />}
        {buzzingTrend === TradingCentralBuzzingType.Buzzing && <IconFire />}
        {(buzzingTrend === TradingCentralBuzzingType.BelowAverage ||
          buzzingTrend === TradingCentralBuzzingType.Average) && <IconArrowRight className="rotate-90" />}
      </div>
      <svg width="119" height="60" viewBox="0 0 119 60" xmlns="http://www.w3.org/2000/svg">
        <Tooltip content={t("terminal.insights.widgets.news-volume.buzzing")}>
          <path
            className={barStyles({ variant: "positive", active: buzzingTrend === TradingCentralBuzzingType.Buzzing })}
            d="M116.025 59.5C117.668 59.5 119.008 58.167 118.926 56.526C118.272 43.4565 113.326 30.963 104.855 20.9882C103.792 19.7358 101.903 19.6812 100.705 20.806C99.5072 21.9307 99.4553 23.8085 100.512 25.0668C107.954 33.9302 112.325 44.9706 112.967 56.5262C113.059 58.1667 114.382 59.5 116.025 59.5Z"
          />
        </Tooltip>
        <Tooltip content={t("terminal.insights.widgets.news-volume.above-average")}>
          <path
            className={barStyles({
              variant: "positive",
              active: buzzingTrend === TradingCentralBuzzingType.AboveAverage,
            })}
            d="M98.7656 18.8393C99.9069 17.6574 99.8787 15.7678 98.6413 14.6869C88.7858 6.0782 76.3626 0.957704 63.3034 0.121628C61.6637 0.0166512 60.3122 1.33762 60.2893 2.98051C60.2663 4.6234 61.581 5.96522 63.2201 6.07931C74.7656 6.88294 85.7438 11.4078 94.5024 18.973C95.7459 20.047 97.6242 20.0212 98.7656 18.8393Z"
          />
        </Tooltip>
        <Tooltip content={t("terminal.insights.widgets.news-volume.average")}>
          <path
            className={barStyles({ variant: "neutral", active: buzzingTrend === TradingCentralBuzzingType.Average })}
            d="M58.5135 2.98361C58.4848 1.34081 57.1287 0.0245638 55.4894 0.135262C42.4332 1.0169 30.0279 6.18075 20.2025 14.8238C18.9688 15.909 18.9472 17.7987 20.0927 18.9767C21.2382 20.1546 23.1166 20.1738 24.3563 19.0954C33.0885 11.4997 44.0508 6.93653 55.5935 6.09262C57.2322 5.97281 58.5422 4.62641 58.5135 2.98361Z"
          />
        </Tooltip>
        <Tooltip content={t("terminal.insights.widgets.news-volume.below-average")}>
          <path
            className={barStyles({
              variant: "neutral",
              active: buzzingTrend === TradingCentralBuzzingType.BelowAverage,
            })}
            d="M2.975 59.5C1.33195 59.5 -0.00775909 58.167 0.0743103 56.526C0.727959 43.4565 5.6745 30.963 14.1447 20.9882C15.2082 19.7358 17.0973 19.6812 18.2951 20.806C19.4928 21.9307 19.5447 23.8085 18.4882 25.0668C11.0461 33.9302 6.67491 44.9706 6.03257 56.5262C5.94138 58.1667 4.61805 59.5 2.975 59.5Z"
          />
        </Tooltip>
        {buzzingTrend === TradingCentralBuzzingType.AboveAverage && (
          <circle className="fill-white stroke-positive-text" cx="81" cy="7" r="4.5" strokeWidth="3" />
        )}
        {buzzingTrend === TradingCentralBuzzingType.BelowAverage && (
          <circle className="fill-white stroke-constant-label" cx="7" cy="38" r="4.5" strokeWidth="3" />
        )}
        {buzzingTrend === TradingCentralBuzzingType.Average && (
          <circle className="fill-white stroke-constant-label" cx="38" cy="8" r="4.5" strokeWidth="3" />
        )}
        {buzzingTrend === TradingCentralBuzzingType.Buzzing && (
          <circle className="fill-white stroke-positive-text" cx="112" cy="40" r="4.5" strokeWidth="3" />
        )}
      </svg>
    </div>
  );
};

export { Gauge };
