import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { terminalRoutes } from "@/routes/terminal.routes";

export const useMobileAppMode = () => {
  const location = useLocation();

  const isMobileAppMode = useMemo(() => location.pathname === terminalRoutes.terminalMobile, [location]);

  return isMobileAppMode;
};
