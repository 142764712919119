import type { FC, ReactNode } from "react";

import { Text } from "@/shared/ui";

type Props = {
  title: string;
  subtitle: string;
  children: ReactNode;
};

const StepWrapper: FC<Props> = ({ title, subtitle, children }) => {
  return (
    <>
      <div className="mt-4">
        <Text variant="XL / Medium">{title}</Text>
        <Text variant="M / Regular" color="secondary" className="mt-2">
          {subtitle}
        </Text>
      </div>

      <div className="mt-6 md:pb-[140px]">{children}</div>
    </>
  );
};

const Footer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="mx-auto flex w-full max-w-[500px] items-center justify-between bg-surface-elevation-2 pb-6 pt-10 md:fixed md:bottom-[-1px] md:end-0 md:start-0 md:px-0 md:pb-10">
      {children}
    </div>
  );
};

const Component = Object.assign(StepWrapper, {
  Footer,
});

export { Component as StepWrapper };
