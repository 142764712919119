import { useCallback, useState } from "react";

export function useDisclosure(initialState = false) {
  const [opened, setOpened] = useState(initialState);

  const open = useCallback(() => {
    setOpened(true);
  }, []);

  const close = useCallback(() => {
    setOpened(false);
  }, []);

  const toggle = useCallback(() => {
    setOpened(prev => !prev);
  }, []);

  const onOpenChange = useCallback((value: boolean) => {
    setOpened(value);
  }, []);

  return [opened, { open, close, toggle, onOpenChange }] as const;
}
