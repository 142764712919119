import type { ComponentProps, FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { PositiveNumberFormat } from "@/app/components";
import { AccountSettingsButton } from "@/features/dashboard/components/account-settings/account-settings-button";
import type { TradingAccount } from "@/services/openapi";
import { Skeleton, Text } from "@/shared/ui";
import { useIsReadOnly } from "@/state/server/profile/profile.hooks";

const Card: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex flex-col gap-4 rounded-[24px] border border-card-border bg-card-bg p-6">{children}</div>;
};

const AccentText: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Text color="primary" variant="M / Medium">
      {children}
    </Text>
  );
};

const Footer: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex flex-col gap-2">{children}</div>;
};

const Badges: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex flex-wrap gap-2">{children}</div>;
};

const DataList: FC<{ children: ReactNode }> = ({ children }) => {
  return <dl className="flex flex-col gap-1">{children}</dl>;
};

const DataListItem: FC<{ label: ReactNode; value: ReactNode; valueColor?: ComponentProps<typeof Text>["color"] }> = ({
  label,
  value,
  valueColor = "primary",
}) => {
  return (
    <div className="flex items-center justify-between">
      <Text as="dt" color="primary" variant="S / Regular">
        {label}
      </Text>
      <Text as="dd" color={valueColor} variant="S / Regular">
        {value}
      </Text>
    </div>
  );
};

const CardSkeleton: FC<{ rows: number }> = ({ rows }) => {
  return (
    <Card>
      <div className="flex items-center justify-between">
        <div className="flex grow flex-col gap-2">
          <Skeleton className="h-4 w-[20%]" />
          <Skeleton className="h-6 w-[50%]" />
        </div>
        <Skeleton className="size-10 rounded-full" />
      </div>
      <Badges>
        <Skeleton className="aspect-video h-8 rounded-[8px]" />
        <Skeleton className="aspect-video h-8 rounded-[8px]" />
      </Badges>
      {rows > 0 && (
        <div className="my-2 grid gap-4">
          {[...Array(rows)].map((_, i) => (
            <li key={i} className="grid grid-cols-[4fr,3fr] gap-8">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
            </li>
          ))}
        </div>
      )}
      <Footer>
        <Skeleton className="h-10 w-full rounded-[32px]" />
      </Footer>
    </Card>
  );
};

const Header: FC<{ account: TradingAccount; showSettingsButton?: boolean }> = ({
  account,
  showSettingsButton = true,
}) => {
  const { equity, currency, digits, login } = account;
  const { t } = useTranslation();

  const isReadOnly = useIsReadOnly();

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex flex-col gap-1">
        <Text as="h4" color="secondary" variant="S / Regular" title={t("accounts.account-title", { login })!}>
          {t("accounts.account-title", { login })}
        </Text>
        <AccentText>
          <PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} />
        </AccentText>
      </div>
      {!isReadOnly && showSettingsButton && <AccountSettingsButton account={account} />}
    </div>
  );
};

const Component = Object.assign(Card, {
  DataList,
  DataListItem,
  Header,
  Footer,
  Badges,
  AccentText,
  Skeleton: CardSkeleton,
});

export { Component as AccountCard };
