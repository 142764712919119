import { Flex } from "@/components/flex";
import { styled } from "@/styles";

export const Wrapper = styled("div", {
  variants: {
    bottomOffset: {
      "0": {
        mb: "0px",
      },
      "1": {
        mb: "8px",
      },
      "2": {
        mb: "12px",
      },
      "3": {
        mb: "16px",
      },
      "4": {
        mb: "24px",
      },
      "5": {
        mb: "32px",
      },
      "6": {
        mb: "40px",
      },
    },
  },

  defaultVariants: {
    bottomOffset: "0",
  },
});

export const InputWrapper = styled(Flex, {
  gap: "8px",
});
