import { useQueryClient } from "react-query";

import { getTournament } from "@/services/tournaments";
import type { ApiResponseType } from "@/services/types";

import { useBaseQuery } from "..";
import type { QueryOptionsType } from "../types";
import { tournamentsQueryKeys } from "./tournaments.keys";

export const useTournamentQuery = (id: string, options?: QueryOptionsType<ApiResponseType<typeof getTournament>>) => {
  const client = useQueryClient();

  return useBaseQuery<ApiResponseType<typeof getTournament>>({
    queryKey: tournamentsQueryKeys.tournament(id),
    queryFn: () => getTournament({ id }),
    onSuccess: ({ isActive }) => {
      client.setQueryDefaults(tournamentsQueryKeys.tournament(id), {
        staleTime: isActive ? 0 : Infinity,
        refetchIntervalInBackground: isActive,
        refetchInterval: isActive ? 30000 : false,
      });
    },
    ...options,
  });
};
