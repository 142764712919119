import { Navigate, useParams } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountStatus, TradingAccountType } from "@/services/openapi";
import { Loader } from "@/shared/ui";
import { useAccountQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";

import { DepositOptionsContainer } from "./deposit-options.container";

const DepositOptionsPage = () => {
  const { id: currentAccountId } = useParams<{ id: string }>();

  const { data: account } = useAccountQuery(currentAccountId!);
  const { data: bonusesData } = useBonusesQuery();

  if (!account || !bonusesData) {
    return <Loader />;
  }

  if (account.type !== TradingAccountType.Real || account.status !== TradingAccountStatus.Active) {
    return <Navigate to={cabinetRoutes.deposit} replace />;
  }

  return <DepositOptionsContainer currentAccount={account} bonusesData={bonusesData} />;
};

export { DepositOptionsPage };
