import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, useHookForm } from "@/app/form";
import { NewSubmitButton } from "@/app/form/new-submit-button";
import { TextPhoneField } from "@/components/form/fields";
import { StepWrapper } from "@/features/onboarding/components/steps/step-wrapper";
import type { AddressInfo, PlatformCountry, ProfileInfo } from "@/services/openapi";
import { ScrollArea } from "@/shared/ui";

import { CountrySelect } from "./country-field";
import { DateFieldOnboarding } from "./date-field";
import { NameField } from "./name-field";
import { NotUSACitizenField } from "./not-usa-citizen-field";
import { LastnameField } from "./surname-field";

export enum AddressFields {
  PHONE_NUMBER = "phoneNumber",
  COUNTRY = "country",
  IS_NOT_USA_RESIDENT = "isNotUSAResident",
  PHONE_COUNTRY = "phoneCountry",
  PHONE_CODE = "phoneCode",
}

export enum ProfileFields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE_NUMBER = "phoneNumber",
  DATE_OF_BIRTH = "dateOfBirth",
  COUNTRY = "country",
}

export type FormValues = {
  [ProfileFields.FIRST_NAME]: string;
  [ProfileFields.LAST_NAME]: string;
  [AddressFields.PHONE_NUMBER]: string;
  [AddressFields.PHONE_COUNTRY]: string;
  [AddressFields.PHONE_CODE]: string;
  [ProfileFields.COUNTRY]: string;
  [ProfileFields.DATE_OF_BIRTH]: string;
  [AddressFields.IS_NOT_USA_RESIDENT]: boolean;
};

type Props = {
  profile: ProfileInfo;
  address: AddressInfo;
  unsupportedCountries: PlatformCountry[];
  onSubmit: (values: FormValues) => Promise<unknown>;
};

const ProfileForm: FC<Props> = ({ unsupportedCountries, profile, address, onSubmit }) => {
  const { t } = useTranslation();
  const phoneNumber = address.phoneCode ? address?.phoneNumber?.replace(address?.phoneCode, "") : "";

  const form = useHookForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      firstName: profile.firstName || "",
      lastName: profile.lastName || "",
      country: address.country || "",
      dateOfBirth: profile.dateOfBirth || "",
      isNotUSAResident: address.isNotUSAResident || false,
      phoneNumber: phoneNumber,
      phoneCode: address.phoneCode || "",
      phoneCountry: address.phoneCountry || "",
    },
  });
  const { watch } = form;
  const { country, phoneCountry } = watch();

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="grid gap-5">
        <NameField />
        <LastnameField />
        <DateFieldOnboarding />

        <TextPhoneField
          namePhone={AddressFields.PHONE_NUMBER}
          nameCode={AddressFields.PHONE_CODE}
          nameCountry={AddressFields.PHONE_COUNTRY}
          errors={{
            required: t("fields.phone-number.required-error")!,
            minLength: t("fields.phone-number.valid-error")!,
          }}
          inputProps={{
            placeholder: t("fields.phone-number.placeholder")!,
          }}
          comboBoxProps={{
            emptyMessage: t("fields.phone-number.search-empty-message"),
            inputProps: {
              placeholder: t("fields.phone-number.search-placeholder"),
            },
          }}
          defaultCountry={phoneCountry || country}
        />

        <CountrySelect country={country} unsupportedCountries={unsupportedCountries} />

        <NotUSACitizenField />
      </div>

      <StepWrapper.Footer>
        <NewSubmitButton size="md" className="ms-auto w-full max-w-[200px]">
          {t("button.next")}
        </NewSubmitButton>
      </StepWrapper.Footer>
    </HookForm>
  );
};

export { ProfileForm };
