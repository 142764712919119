import type { TFunction } from "i18next";

import { type TerminalSymbolWidgetSummaryScoreItem, TradingCentralSignalTrend } from "@/services/openapi";

type Params = {
  t: TFunction;
  summary: TerminalSymbolWidgetSummaryScoreItem;
};

const getScoreLabel = (score: number) => {
  const mappings = {
    1: "weak",
    2: "normal",
    3: "strong",
    4: "very-strong",
  };

  return mappings[score as keyof typeof mappings] || "weak";
};

const getKeyEnd = (summary: TerminalSymbolWidgetSummaryScoreItem) => {
  const { trend, score } = summary;

  const scoreLabel = getScoreLabel(score!);

  if (trend === TradingCentralSignalTrend.Bullish) {
    return `bullish.${scoreLabel}`;
  }

  if (trend === TradingCentralSignalTrend.Bearish) {
    return `bearish.${scoreLabel}`;
  }

  return `neutral.${scoreLabel}`;
};

const getShortTermDescription = ({ t, summary }: Params) => {
  const keyEnd = getKeyEnd(summary);

  return t(`terminal.insights.widgets.technicals-summary-score.short.${keyEnd}`);
};

const getIntermediateTermDescription = ({ t, summary }: Params) => {
  const keyEnd = getKeyEnd(summary);

  return t(`terminal.insights.widgets.technicals-summary-score.intermediate.${keyEnd}`);
};

const getLongTermDescription = ({ t, summary }: Params) => {
  const keyEnd = getKeyEnd(summary);

  return t(`terminal.insights.widgets.technicals-summary-score.long.${keyEnd}`);
};

export { getLongTermDescription, getIntermediateTermDescription, getShortTermDescription };
