import Big from "big.js";
import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { DetailsTable as Table } from "@/components/details-table";

const values = {
  amount: 100,
  lots: 0.5,
  profit: 50,
};

type Props = {
  currency: string;
  mainPercent: number;
};

const ExampleTable: FC<Props> = ({ currency, mainPercent }) => {
  const { t } = useTranslation();

  const valuesAfterBonus: typeof values = useMemo(() => {
    const multiplier = new Big(mainPercent).div(100).plus(1);
    return {
      amount: new Big(values.amount).mul(multiplier).toNumber(),
      lots: new Big(values.lots).mul(multiplier).toNumber(),
      profit: new Big(values.profit).mul(multiplier).toNumber(),
    };
  }, [mainPercent]);

  return (
    <>
      <p className="mb-6 text-center font-roboto text-[14px] leading-normal text-text-secondary md:text-[16px]">
        <Trans
          i18nKey="bonus.dialog.example-table.caption"
          values={{ currency }}
          components={{
            value: <NumberFormat value={values.amount} />,
          }}
        />
      </p>
      <Table.Card>
        <Table>
          <Table.Row>
            <Table.Label></Table.Label>
            <Table.Value>{t("bonus.dialog.example-table.no-bonus")}</Table.Value>
            <Table.Value className="font-bold">
              {t("bonus.dialog.example-table.bonus-value", { value: mainPercent })}
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("bonus.dialog.example-table.balance")}</Table.Label>
            <Table.Value>
              <NumberFormat prefix="$" value={values.amount} decimalScale={2} />
            </Table.Value>
            <Table.Value className="text-action">
              <NumberFormat prefix="$" value={valuesAfterBonus.amount} decimalScale={2} />
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("bonus.dialog.example-table.volume")}</Table.Label>
            <Table.Value>{t("bonus.dialog.example-table.lot", { value: values.lots })}</Table.Value>
            <Table.Value className="text-action">
              {t("bonus.dialog.example-table.lot", { value: valuesAfterBonus.lots })}
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("bonus.dialog.example-table.profit")}</Table.Label>
            <Table.Value>
              <NumberFormat prefix="+$" value={values.profit} decimalScale={2} />
            </Table.Value>
            <Table.Value className="text-action">
              <NumberFormat prefix="+$" value={valuesAfterBonus.profit} decimalScale={2} />
            </Table.Value>
          </Table.Row>
        </Table>
      </Table.Card>
    </>
  );
};

export { ExampleTable };
