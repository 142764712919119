import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { NewButton, Text } from "@/shared/ui";

const Button = forwardRef<ElementRef<typeof NewButton>, ComponentPropsWithoutRef<typeof NewButton>>((props, ref) => {
  return <NewButton ref={ref} size="sm" {...props} />;
});

type Props = {
  title: ReactNode;
  children?: ReactNode;
  description?: ReactNode;
};

const PaymentEmptyState: FC<Props> = ({ title, description, children }) => {
  return (
    <div className="flex flex-col items-center gap-6 rounded-[16px] bg-card-bg px-6 py-8">
      <div className="grid gap-2">
        <Text variant="L / Medium" color="primary" align="center" as="h4">
          {title}
        </Text>
        {description && (
          <Text variant="S / Regular" color="secondary" align="center">
            {description}
          </Text>
        )}
      </div>
      {children}
    </div>
  );
};

const Component = Object.assign(PaymentEmptyState, { Button });

export { Component as PaymentEmptyState };
