import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import {
  setEmailLiveChat,
  setLiveChatCountry,
  setLiveChatKycStatus,
  setLiveChatPhoneNumber,
  setLiveChatUserId,
} from "@/app/libs/liveChat";
import { useAuth } from "@/contexts/auth.context";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { useKycInfoQuery } from "@/state/server/kyc";
import { useProfileHeaderQuery } from "@/state/server/profile";

import { authRoutes } from "./auth.routes";

export const PrivateRoute = ({ children }: { children?: React.ReactElement }) => {
  const { isAuthenticated } = useAuth();
  const { data: profile } = useProfileHeaderQuery({ enabled: isAuthenticated });
  const { data: kyc } = useKycInfoQuery({ enabled: isAuthenticated });
  const navigate = useNavigate();

  if (!isAuthenticated) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  useEffect(() => {
    if (
      profile?.featuresInfo?.mandatorySurvey &&
      !profile?.options?.isSurveyCompleted &&
      location.pathname !== onboardingRoutes.onboarding
    ) {
      navigate(onboardingRoutes.onboarding, { replace: true });
    }
  }, [profile?.featuresInfo?.mandatorySurvey, profile?.options?.isSurveyCompleted]);

  useEffect(() => {
    profile?.kycStatus && setLiveChatKycStatus(profile.kycStatus);
    profile?.email && setEmailLiveChat(profile.email);
    kyc?.address?.country && setLiveChatCountry(kyc.address.country);
    profile?.phoneNumber && setLiveChatPhoneNumber(profile.phoneNumber);
    profile?.id && setLiveChatUserId(profile.id);
  }, [kyc?.address?.country, profile?.email, profile?.id, profile?.kycStatus, profile?.phoneNumber]);

  return children ? children : <Outlet />;
};
