import { type To } from "react-router-dom";

const terminalRoutes = {
  autoRoute: "/web-trading",
  terminal: "/web-trading/doto",
  terminalMobile: "/web-trading/doto-mobile",
  terminalPositionsMobile: "/web-trading/doto-mobile-positions",
  terminalSymbolMobile: "/web-trading/doto-mobile-symbol",
  terminalMobileChart: "/web-trading/doto-mobile-chart",
};

const terminalRoutesArray = Object.values(terminalRoutes);
const isCurrentPageTerminal = (pathname: string): boolean => terminalRoutesArray.includes(pathname);

const terminalAccountParamsName = "accountId";

const getTerminalRoute = (accountId: string, searchParams: string = "", isMoble?: boolean): To => ({
  pathname: !isMoble ? terminalRoutes.terminal : terminalRoutes.terminalMobile,
  search: `${terminalAccountParamsName}=${accountId}${searchParams}`,
});

export { getTerminalRoute, terminalAccountParamsName, terminalRoutes, isCurrentPageTerminal };
