import { cva } from "class-variance-authority";

import { useTranslation } from "@/hooks/translator.hook";

import { TerminalTableState, useLayoutContext } from "../../layout/context";
import { useOrdersContext } from "../../orders/context";

const itemStyles = cva(
  "relative grid h-10 grow place-items-center outline-none after:absolute after:end-0 after:h-5 after:w-px after:bg-contrast-quaternary last:after:hidden",
)();

const textStyles = cva("font-gilroy text-[14px] font-bold leading-[1.2] text-contrast-primary", {
  variants: {
    hasIndicator: {
      true: "relative before:absolute before:-end-3 before:-top-0.5 before:size-2 before:rounded-full before:bg-negative-text",
    },
  },
});

const MobileTradingTablesBlock = () => {
  const { t } = useTranslation();
  const { changeTable, openInsightsDialog } = useLayoutContext();
  const { openOrders, pendingOrders } = useOrdersContext();

  const totalPositions = openOrders.length + pendingOrders.length;

  return (
    <div
      className="border-t border-contrast-quaternary bg-surface-canvas"
      // FIXME:
      style={{ boxShadow: "0px -10px 40px -10px rgba(21, 38, 149, 0.05)" }}
    >
      <div className="flex bg-surface-canvas">
        <button className={itemStyles} onClick={() => changeTable(TerminalTableState.OPEN)}>
          <div className={textStyles({ hasIndicator: totalPositions > 0 })}>
            {t("terminal.positions.tab", { value: totalPositions })}
          </div>
        </button>
        <button className={itemStyles} onClick={openInsightsDialog}>
          <div className={textStyles()}>{t("terminal.insights.title")}</div>
        </button>
      </div>
    </div>
  );
};

export { MobileTradingTablesBlock };
