export const BankCardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.638 14.9029C22.0353 21.3315 15.5243 25.2438 9.09495 23.6407C2.66826 22.038 -1.24407 15.5266 0.359421 9.09837C1.9614 2.66907 8.47246 -1.24364 14.8999 0.359094C21.3288 1.96182 25.2408 8.47401 23.638 14.9029ZM20.0682 6.52579L8.49122 2.59972C7.95707 2.41887 7.37116 2.70483 7.18999 3.23724L6.92346 4.02431L20.4366 8.61972L20.7095 7.82359C20.8937 7.29064 20.6013 6.70636 20.0682 6.52579ZM6.37258 5.62965L17.2951 9.32739C17.2794 9.32547 17.2654 9.32547 17.2538 9.32547H17.2538H5.11155L6.37258 5.62965ZM18.9184 13.0675V10.9845C18.9184 10.4332 18.6442 9.94303 18.2265 9.6428L19.895 10.2068L18.9184 13.0675ZM5.0259 9.96155H17.2535C17.8177 9.96155 18.278 10.4223 18.278 10.9846V18.3134C18.278 18.8757 17.8174 19.3365 17.2535 19.3365H5.0259C4.46063 19.3365 4 18.8755 4 18.3134V10.9846C4 10.4223 4.46063 9.96155 5.0259 9.96155ZM5.73764 17.7936H7.3065V16.9402H5.73764V17.7936ZM8.10331 13.7663C8.36598 13.7663 8.58075 13.5523 8.58075 13.2904V11.8104C8.58075 11.547 8.36598 11.3332 8.10331 11.3332H5.93533C5.67266 11.3332 5.45927 11.5467 5.45927 11.8104V13.2904C5.45927 13.5523 5.67266 13.7663 5.93533 13.7663H8.10331ZM8.86021 17.7936H10.4266V16.9402H8.86021V17.7936ZM11.9814 17.7936H13.5478V16.9402H11.9814V17.7936Z"
      fill="#0070FF"
    />
  </svg>
);
