import * as React from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import { useGoogleCaptcha } from "@/hooks/captcha.hook";

interface WithCaptchaProps {
  onVerify: (token: string) => void;
  refreshReCaptchaValue: boolean;
}

export const withCaptcha =
  <T extends object>(Component: React.ComponentType<T>): React.ComponentType<WithCaptchaProps & T> =>
  // eslint-disable-next-line react/display-name
  props => {
    const { hasCaptcha } = useGoogleCaptcha();
    // eslint-disable-next-line react/prop-types
    const { onVerify, refreshReCaptchaValue, ...other } = props;

    if (hasCaptcha) {
      return (
        <>
          <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptchaValue} />
          <Component {...(other as T)} />
        </>
      );
    }

    return <Component {...(other as T)} />;
  };
