import { type FC, type ReactNode } from "react";

import { IconCross } from "@/domains/icons";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cn } from "@/shared/styles";

import { centeredDialogContentStyles, overlayStyles } from "../popper";
import { ScrollArea } from "../scroll-area";
import { Dialog } from "./dialog";

type Props = {
  title: ReactNode;
  children: ReactNode;
};

// FIXME: component looks almost exactly the same as bonus dialog

const InfoDialogContent: FC<Props> = ({ title, children }) => {
  const { isMobile } = useScreenSize();

  return (
    <Dialog.Portal>
      <Dialog.Overlay className={overlayStyles({ hasAnimation: !isMobile })} />
      <Dialog.Content
        className={cn(
          centeredDialogContentStyles({ hasAnimation: !isMobile }),
          "flex h-full w-full flex-col overflow-auto bg-surface-elevation-2 pb-4 shadow outline-none md:h-auto md:max-h-[calc(100dvh-112px)] md:max-w-[640px] md:rounded-[24px] md:pb-14",
        )}
      >
        <div className="mb-2 flex justify-between p-4 md:mb-4 md:px-14 md:pb-0 md:pt-14">
          <Dialog.Title className="font-gilroy text-[24px] font-semibold leading-[1.2] text-contrast-primary">
            {title}
          </Dialog.Title>
          <Dialog.Close className="flex outline-none">
            <IconCross />
          </Dialog.Close>
        </div>
        <ScrollArea scrollbars="vertical" viewportClassname="px-4 md:px-14">
          {children}
        </ScrollArea>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

export { InfoDialogContent };
