import { type FC } from "react";
import { Link } from "react-router-dom";

import { useToPageState } from "@/hooks/to-page-state.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { getDepositRoute } from "@/routes/cabinet.routes";
import { NewButton } from "@/shared/ui";

type Props = {
  accountId: string;
};

const DepositButton: FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();
  const { toPageState } = useToPageState("account");

  return (
    <NewButton data-test="account-settings-deposit" size="sm" variant="secondary" color="accent" fullWidth asChild>
      <Link to={getDepositRoute(accountId)} state={toPageState}>
        {t("accounts.deposit.menu-title")}
      </Link>
    </NewButton>
  );
};

export { DepositButton };
