import React from "react";

export const FacebookIcon = () => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5001 -1.52588e-05C8.5827 -1.52588e-05 5.7848 1.15891 3.7219 3.22181C1.659 5.28471 0.500076 8.0826 0.500076 11C0.500076 13.9174 1.659 16.7153 3.7219 18.7782C5.7848 20.8411 8.5827 22 11.5001 22C14.4175 22 17.2154 20.8411 19.2782 18.7782C21.3412 16.7153 22.5001 13.9174 22.5001 11C22.5001 8.0826 21.3412 5.28471 19.2782 3.22181C17.2154 1.15891 14.4175 -1.52588e-05 11.5001 -1.52588e-05Z"
      fill="#039BE5"
    />
    <path
      d="M12.9887 13.9155H15.8354L16.2823 11.0237H12.9881V9.44315C12.9881 8.24184 13.3806 7.17657 14.5044 7.17657H16.3101V4.65294C15.9928 4.6101 15.3218 4.51631 14.0539 4.51631C11.4064 4.51631 9.85425 5.91447 9.85425 9.09984V11.0237H7.13261V13.9155H9.85425V21.8639C10.3932 21.9449 10.9392 21.9999 11.4996 21.9999C12.0062 21.9999 12.5006 21.9536 12.9887 21.8876V13.9155Z"
      fill="white"
    />
  </svg>
);
