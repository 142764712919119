import { NoContentMessage } from "@/components/no-content-message";
import { useTranslation } from "@/hooks/translator.hook";

const ArchivedAccountsEmptyState = () => {
  const { t } = useTranslation();
  return (
    <NoContentMessage
      title={t("accounts.no-items.no-archived-title")}
      description={t("accounts.no-items.no-archived-description")}
    />
  );
};

export { ArchivedAccountsEmptyState };
