import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const barStyles = cva(
  "flex touch-none select-none rounded-[8px] data-[orientation=horizontal]:h-1 data-[orientation=vertical]:w-1 data-[orientation=horizontal]:flex-col data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out-0 data-[state=visible]:fade-in-0",
  {
    variants: {
      hidden: {
        true: "hidden",
      },
      scrollOffset: {
        sm: "m-1",
        md: "m-2",
      },
    },
  },
);

const thumbStyles = cva(
  "relative flex-1 rounded-inherit bg-text-placeholder transition-colors before:absolute before:start-1/2 before:top-1/2 before:h-full before:min-h-4 before:w-full before:min-w-4 before:-translate-x-1/2 before:-translate-y-1/2",
  {
    variants: {
      hidden: {
        true: "hidden",
      },
    },
  },
);

type Props = Omit<ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>, "type"> &
  Pick<VariantProps<typeof barStyles>, "scrollOffset"> & {
    scrollbars: "both" | "vertical" | "horizontal";
    viewportClassname?: string;
    type?: ScrollAreaPrimitive.ScrollAreaContextValue["type"] | "never";
  };

const ScrollArea = forwardRef<ElementRef<typeof ScrollAreaPrimitive.Root>, Props>(
  ({ children, scrollbars, className, type, viewportClassname, scrollOffset = "md", ...props }, ref) => {
    const hidden = type === "never";

    return (
      <ScrollAreaPrimitive.Root
        scrollHideDelay={200}
        type={hidden ? "always" : type}
        className={cn("group-scrollarea relative flex h-full w-full flex-col overflow-hidden", className)}
        {...props}
        ref={ref}
      >
        <ScrollAreaPrimitive.Viewport
          className={cn(
            "flex h-full w-full flex-col group-data-[orientation=horizontal]/scrollarea:overscroll-x-contain",
            viewportClassname,
          )}
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        {scrollbars !== "vertical" && (
          <ScrollAreaPrimitive.Scrollbar orientation="horizontal" className={barStyles({ hidden, scrollOffset })}>
            <ScrollAreaPrimitive.Thumb className={thumbStyles({ hidden })} />
          </ScrollAreaPrimitive.Scrollbar>
        )}
        {scrollbars !== "horizontal" && (
          <ScrollAreaPrimitive.Scrollbar orientation="vertical" className={barStyles({ hidden, scrollOffset })}>
            <ScrollAreaPrimitive.Thumb className={thumbStyles({ hidden })} />
          </ScrollAreaPrimitive.Scrollbar>
        )}
        {scrollbars === "both" && <ScrollAreaPrimitive.Corner />}
      </ScrollAreaPrimitive.Root>
    );
  },
);
ScrollArea.displayName = "ScrollArea";

export { ScrollArea };
