import { type FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Chip } from "@/shared/ui";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useLayoutContext } from "../layout/context";
import { useSignalContext } from "../signal/context";
import { AnalyticsContainer } from "./analytics/container";
import {
  canShowAnalyticsTab,
  canShowNewsVolumeWidget,
  canShowSentimentWidget,
  canShowSignalWidget,
  canShowTechnicalsSummaryScoreWidget,
} from "./analytics/helpers";
import { CalendarContainer } from "./calendar/container";
import { NewsContainer } from "./news/container";

enum Tabs {
  ANALYTICS = "analalytics",
  NEWS = "news",
  CALENDAR = "calendar",
}

type Props = {
  onClose?: () => void;
};

const InsightsContainer: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const { tradingCentral } = usePlatformData();
  const { isNewsEnabled } = tradingCentral!;

  const { insightsSection } = useLayoutContext();

  const { symbolInfo } = useCurrentSymbolContext();
  const signal = symbolInfo.signal;
  const widget = symbolInfo.widget;
  const priceDecimalScale = symbolInfo.digits!;
  const symbol = symbolInfo.symbol!;

  const { hasSignal } = useSignalContext();

  const showSignalWidget = useMemo(() => canShowSignalWidget({ hasSignal, signal }), [signal, hasSignal]);
  const showSentimentWidget = useMemo(() => canShowSentimentWidget({ widget }), [widget]);
  const showTechnicalsSummaryScoreWidget = useMemo(() => canShowTechnicalsSummaryScoreWidget({ widget }), [widget]);
  const showNewsVolumeWidget = useMemo(() => canShowNewsVolumeWidget({ widget }), [widget]);

  const showAnalyticsTab = useMemo(
    () =>
      canShowAnalyticsTab({
        showNewsVolumeWidget,
        showSentimentWidget,
        showSignalWidget,
        showTechnicalsSummaryScoreWidget,
      }),
    [showNewsVolumeWidget, showSentimentWidget, showSignalWidget, showTechnicalsSummaryScoreWidget],
  );

  const [tab, setTab] = useState<Tabs>(() => {
    if (showAnalyticsTab && (insightsSection === "analytics" || insightsSection === "none")) {
      return Tabs.ANALYTICS;
    }
    if (isNewsEnabled && (insightsSection === "news" || insightsSection === "none")) {
      return Tabs.NEWS;
    }
    return Tabs.CALENDAR;
  });

  return (
    <>
      <Chip.Group value={tab} onValueChange={setTab} className="mb-4">
        {showAnalyticsTab && <Chip value={Tabs.ANALYTICS}>{t("terminal.insights.tabs.analytics")}</Chip>}
        {isNewsEnabled && <Chip value={Tabs.NEWS}>{t("terminal.insights.tabs.news")}</Chip>}
        <Chip value={Tabs.CALENDAR}>{t("terminal.insights.tabs.calendar")}</Chip>
      </Chip.Group>
      {showAnalyticsTab && tab === Tabs.ANALYTICS && (
        <AnalyticsContainer
          showNewsVolumeWidget={showNewsVolumeWidget}
          showSentimentWidget={showSentimentWidget}
          showSignalWidget={showSignalWidget}
          showTechnicalsSummaryScoreWidget={showTechnicalsSummaryScoreWidget}
          signal={signal}
          priceDecimalScale={priceDecimalScale}
          widget={widget}
          onClose={onClose}
        />
      )}
      {isNewsEnabled && tab === Tabs.NEWS && <NewsContainer symbol={symbol} />}
      {tab === Tabs.CALENDAR && <CalendarContainer />}
    </>
  );
};

export { InsightsContainer };
