import { type FC, useCallback } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { BonusBanner } from "@/entities/bonuses/banner";
import { getShowBonusBanner } from "@/entities/bonuses/helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountType } from "@/services/openapi";
import { useServersQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { CompleteVerificationBanner } from "../cabinet/components/banners/CompleteVerificationBanner";
import { CabinetPage } from "../cabinet/components/cabinet-page";
import { CabinetTitle } from "../cabinet/components/cabinet-title";
import { AccountsCardsContainer } from "./accounts/cards.container";
import { CongratulationsDialog } from "./components/dialogs/congratulations/congratulations-dialog";
// import { EmailConfirmDialog } from "./components/dialogs/email-confirm/email-confirm-dialog";
import { VerificationDialog } from "./components/dialogs/verification/verification-dialog";

export enum DashboardState {
  DASHBOARD = "dashboard",
  CREATE = "create",
}

export const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const profile = useProfileData();
  const { data } = useBonusesQuery();
  const { data: servers } = useServersQuery();

  const navigate = useNavigate();

  const switchDashboardToCreateScreen = useCallback(
    (accountType: TradingAccountType) => {
      navigate({ pathname: cabinetRoutes.createAccount, search: createSearchParams({ type: accountType }).toString() });
    },
    [navigate],
  );

  if (!servers) return null;

  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.accounts")}>
          <CabinetTitle.DepositButton onClick={() => track(amplitudeEvents.deposit.fromMyAccounts)} />
        </CabinetTitle>
      }
      banner={
        data ? (
          <>
            {data.items![0] && getShowBonusBanner(data.items![0]) ? (
              <BonusBanner bonus={data.items![0]} />
            ) : (
              !profile.options!.isKycCompleted && (
                <CompleteVerificationBanner isSurveyCompleted={profile.options?.isSurveyCompleted!} />
              )
            )}
          </>
        ) : null
      }
    >
      {data && (
        <AccountsCardsContainer
          bonus={data.items![0] ? data.items![0] : null}
          onCreateAccount={switchDashboardToCreateScreen}
        />
      )}
      <CongratulationsDialog />
      <VerificationDialog />
    </CabinetPage>
  );
};
