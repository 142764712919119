import { type FC } from "react";

import { DialogButtons } from "@/components/dialog";
import { useTranslation } from "@/hooks/translator.hook";
import type { TradingAccount } from "@/services/openapi";
import { Button, Dialog } from "@/shared/ui";
import { useArchiveAccountMutation } from "@/state/server/accounts";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

export const ArchiveAccountContainer: FC<Props> = ({ account, onClose }) => {
  const { t } = useTranslation();
  const { mutate: archiveAccount, isLoading } = useArchiveAccountMutation({ successMessage: "account-archived" });

  return (
    <DialogButtons>
      <Button
        pending={isLoading}
        onClick={() =>
          archiveAccount(
            { id: account.id! },
            {
              onSuccess: () => {
                onClose();
              },
            },
          )
        }
        data-test="dialog-archive-submit"
      >
        {t("button.archive")}
      </Button>
      <Dialog.Close asChild>
        <Button variant="tertiary" data-test="dialog-archive-cancel">
          {t("button.cancel")}
        </Button>
      </Dialog.Close>
    </DialogButtons>
  );
};
