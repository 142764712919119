import { type FC, type ReactNode } from "react";

import { Box } from "@/components/box";
import { DialogTitleText } from "@/components/dialog/styles";
import { Dialog } from "@/shared/ui";

type Props = {
  children: ReactNode;
  title: ReactNode;
  smallTitleOffset?: boolean;
};

export const AccountDialogContent: FC<Props> = ({ children, title, smallTitleOffset }) => {
  return (
    <Box css={{ maxWidth: "445px", m: "0 auto" }}>
      <Dialog.Title asChild>
        <DialogTitleText css={{ mt: 30, mb: smallTitleOffset ? "10px" : "30px", "@bp3": { mt: 0 } }}>
          {title}
        </DialogTitleText>
      </Dialog.Title>
      {children}
    </Box>
  );
};
