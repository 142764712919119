import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconDeposit } from "@/domains/icons";
import { TradingAccountTradeMode } from "@/services/openapi";
import { Alert } from "@/shared/ui";

import { usePlaceOrderContext } from "../context";
import { VolumeLotsField } from "../volume-lots/field";
import { VolumeMarginField } from "../volume-margin/field";

type Props = ComponentProps<typeof VolumeLotsField> &
  ComponentProps<typeof VolumeMarginField> & {
    volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  };

const VolumeFieldsBlock: FC<Props> = ({
  changeLots,
  changeMargin,
  currency,
  hasNoFreeMargin,
  isSubmitting,
  maxBalanceVolumeLots,
  maxBalanceVolumeMargin,
  maxSystemVolumeLots,
  maxSystemVolumeMargin,
  minSystemVolumeLots,
  minSystemVolumeMargin,
  volumeLots,
  volumeLotsDecimalScale,
  volumeLotsError,
  volumeMarginDecimalScale,
  volumeMarginFormValue,
  volumeLotsFormValue,
  volumeMode,
  changeVolumeMode,
  onDeposit,
  volumeMargin,
  volumeOnBlur,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      {hasNoFreeMargin && <Alert icon={<IconDeposit />} text={t("terminal.place-order.no-free-margin-text")} />}
      {volumeMode === TradingAccountTradeMode.Lots && (
        <VolumeLotsField
          volumeOnBlur={volumeOnBlur}
          onDeposit={onDeposit}
          volumeMargin={volumeMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          changeVolumeMode={changeVolumeMode}
          isSubmitting={isSubmitting}
          volumeLotsFormValue={volumeLotsFormValue}
          changeLots={changeLots}
          currency={currency}
          minSystemVolumeLots={minSystemVolumeLots}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
        />
      )}
      {volumeMode === TradingAccountTradeMode.Margin && (
        <VolumeMarginField
          volumeOnBlur={volumeOnBlur}
          onDeposit={onDeposit}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
          changeVolumeMode={changeVolumeMode}
          isSubmitting={isSubmitting}
          maxBalanceVolumeMargin={maxBalanceVolumeMargin}
          maxSystemVolumeMargin={maxSystemVolumeMargin}
          minSystemVolumeMargin={minSystemVolumeMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          changeMargin={changeMargin}
          volumeLots={volumeLots}
          changeLots={changeLots}
          currency={currency}
          minSystemVolumeLots={minSystemVolumeLots}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeMarginFormValue={volumeMarginFormValue}
        />
      )}
    </div>
  );
};

export { VolumeFieldsBlock };
