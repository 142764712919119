export const authRoutes = {
  signIn: "/sign-in",
  signUp: "/create-account",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  twoFactor: "/two-factor",
  twoFactorRecovery: "/two-factor-recovery",
  verifyEmail: "/verify-email",
  emailConfirm: "/email-confirm",
};
