import { type FC } from "react";

import { TerminalPopover } from "@/features/terminal/components/popover";
import { TerminalBackDialogContent } from "@/features/terminal/components/terminal-back-dialog-content";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Dialog } from "@/shared/ui";
import { Button } from "@/shared/ui/button";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

type Props = {
  accountId: string;
};

const CloseAllPendingOrdersButton: FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();

  const [open, { onOpenChange, close }] = useDisclosure();

  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const closeOrders = () => {
    mutate(
      { tradingAccountId: accountId, terminalCloseOrderRequest: { type: TerminalCloseOrderType.Limit } },
      {
        onSuccess: () => {
          close();
        },
      },
    );
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <Button className="w-full" variant="tertiary" size="sm">
          {t("terminal.orders.delete-all")}
        </Button>
      </Dialog.Trigger>
      <TerminalBackDialogContent>
        <div className="flex grow items-center">
          <p className="text-center font-gilroy text-[24px] font-semibold leading-[1.2]">
            {t("terminal.orders.close-all-pending-message")}
          </p>
        </div>
        <TerminalPopover.Buttons isMobile>
          <Button pending={isLoading} onClick={closeOrders}>
            {t("terminal.orders.delete-all")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalBackDialogContent>
    </Dialog>
  );
};

export { CloseAllPendingOrdersButton };
