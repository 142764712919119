import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const labelStyles = cva("typography-S-Regular inline-grid h-7 min-w-[54px] place-items-center rounded-[8px] px-2", {
  variants: {
    variant: {
      default: "bg-contrast-quinary text-contrast-primary",
      blue: "bg-constant-label/15 text-constant-label",
      orange: "bg-warning-bg/15 text-warning-text",
    },
  },
});

const Label = forwardRef<ElementRef<"span">, ComponentPropsWithoutRef<"span"> & VariantProps<typeof labelStyles>>(
  ({ className, variant = "default", ...props }, ref) => {
    return <span className={cn(labelStyles({ variant }), className)} {...props} ref={ref} />;
  },
);
Label.displayName = "Label";

export { Label };
