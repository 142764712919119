export const WindowsIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 4.61484L9.963 3.39422L9.96702 12.0398L1.00829 12.0907L1 4.61484ZM9.95873 13.0358L9.96576 21.6889L1.00703 20.4572L1.00653 12.9778L9.95873 13.0358ZM11.0452 3.23448L22.9294 1.5V11.9298L11.0452 12.0242V3.23448ZM22.9322 13.1172L22.9294 23.5L11.0452 21.8228L11.0287 13.0979L22.9322 13.1172Z"
        fill="currentColor"
      />
    </svg>
  );
};
