import { styled } from "@/styles";

export const selectClassName = "react-select-container";
export const selectClassNamePrefix = "react-select";

export const SelectWrapper = styled("div", {
  // styles for input
  ".react-select-container": {
    fontFamily: "$roboto",
    fontWeight: "$normal",
    fontSize: "14px",
    lineHeight: "$3",
    color: "$textMain",
    "caret-color": "transparent",

    "@bp3": {
      fontSize: "16px",
    },
  },

  ".react-select__control": {
    background: "$bgPrimary",
    border: "1px solid",
    borderColor: "$bgBorder",
    borderRadius: "8px",
    boxShadow: "none",
    flexWrap: "nowrap",

    "@bp3": {
      borderRadius: "16px",
    },

    "&:hover": {
      borderColor: "$textSecondary",
    },
  },

  ".react-select__placeholder": {
    color: "$extraLightGray",
    fontWeight: "$light",
  },

  ".react-select__control--is-focused": {
    borderColor: "$textSecondary",
  },

  ".react-select__single-value": {
    color: "$textMain",
  },

  ".react-select__value-container": {
    p: "0px",
  },

  ".react-select__indicator-separator": {
    display: "none",
    width: "0",
    height: "0",
  },

  ".react-select__input-container": {
    m: "0px",
    p: "0px",
  },

  ".react-select__dropdown-indicator": {
    p: "0px",
    pl: "15px",
    "&:hover": {
      color: "$textSecondary",
    },
  },

  // styles for dropdown

  ".react-select__menu": {
    backgroundColor: "$bgPrimary",
    border: "1px solid",
    borderColor: "$bgBorder",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px",
  },

  ".react-select__menu-list": {
    p: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    hideScroll: true,
  },

  ".react-select__option": {
    p: "8px",
    borderRadius: "8px",
    cursor: "pointer",
    "&:active": {
      backgroundColor: "$bgSelected",
    },
  },

  ".react-select__option--is-focused": {
    background: "$bgSelected",
  },

  ".react-select__option--is-selected": {
    background: "$bgSelected",
    color: "inherit",
  },

  variants: {
    error: {
      true: {
        ".react-select__control": {
          borderColor: "red",
        },
      },
    },
    disabled: {
      true: {
        ".react-select__dropdown-indicator": {
          display: "none",
        },
      },
    },
    size: {
      small: {
        ".react-select__control": {
          p: "8px 16px",
        },
      },
      middle: {
        ".react-select__control": {
          p: "15px 24px",

          "@bp3": {
            p: "20px 24px",
          },
        },
      },
    },
  },

  defaultVariants: {
    size: "middle",
  },
});
