import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { IconArrowSort } from "@/domains/icons";
import { TradingAccountTradeMode } from "@/services/openapi";
import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../context";
import { VolumeLotsFieldDescriptor } from "./descriptor";

type Props = ComponentProps<typeof VolumeLotsFieldDescriptor> & {
  volumeLotsFormValue: ReturnType<typeof usePlaceOrderContext>["volumeLotsFormValue"];
  changeVolumeMode: ReturnType<typeof usePlaceOrderContext>["changeVolumeMode"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
  volumeOnBlur: ReturnType<typeof usePlaceOrderContext>["volumeOnBlur"];
  currency: string;
  isSubmitting: boolean;
};

const VolumeLotsField: FC<Props> = ({
  changeLots,
  currency,
  hasNoFreeMargin,
  isSubmitting,
  maxBalanceVolumeLots,
  maxSystemVolumeLots,
  minSystemVolumeLots,
  volumeLotsFormValue,
  volumeLotsDecimalScale,
  volumeLotsError,
  onDeposit,
  changeVolumeMode,
  volumeMarginDecimalScale,
  volumeMargin,
  volumeOnBlur,
}) => {
  const { t } = useTranslation();

  const invalid = volumeLotsError !== VolumeLotsError.NONE && !hasNoFreeMargin;

  return (
    <NumberInput
      bottomLabel
      placeholder={t("terminal.place-order.lots.placeholder")!}
      label={
        volumeMargin || volumeLotsFormValue ? (
          <NumberFormat value={volumeMargin} decimalScale={volumeMarginDecimalScale} currency={currency} />
        ) : (
          "–"
        )
      }
      disabled={hasNoFreeMargin}
      pending={isSubmitting}
      value={volumeLotsFormValue}
      suffix={` ${t("terminal.lots")}`}
      onChange={value => changeLots(value, { format: false })}
      onBlur={volumeOnBlur}
      invalid={invalid}
      decimalScale={volumeLotsDecimalScale}
      endSection={
        <NumberInput.IconButton onClick={() => changeVolumeMode(TradingAccountTradeMode.Margin)}>
          <IconArrowSort />
        </NumberInput.IconButton>
      }
      descriptor={
        invalid ? (
          <VolumeLotsFieldDescriptor
            onDeposit={onDeposit}
            changeLots={changeLots}
            volumeLotsError={volumeLotsError}
            maxBalanceVolumeLots={maxBalanceVolumeLots}
            maxSystemVolumeLots={maxSystemVolumeLots}
            minSystemVolumeLots={minSystemVolumeLots}
            volumeLotsDecimalScale={volumeLotsDecimalScale}
          />
        ) : null
      }
    />
  );
};

export { VolumeLotsField };
