import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import type { PaymentMethod, TradingAccount } from "@/services/openapi";
import { useMaximumWithdrawAmountQuery } from "@/state/server/payment";

import { AmountStep } from "./step";

type Props = {
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  currentAmount: string;
  setCurrentAmount: (value: string) => void;
  back: () => void;
  next: () => void;
};

const AmountStepContainer: FC<Props> = ({
  currentAccount,
  currentPaymentMethod,
  currentAmount,
  next,
  back,
  setCurrentAmount,
}) => {
  const { t } = useTranslation();

  const { data: withdrawLimit } = useMaximumWithdrawAmountQuery({
    accountId: currentAccount.id,
    paymentMethodId: currentPaymentMethod.id,
    recurringId: currentPaymentMethod.recurringId!,
  });

  return (
    <PaymentWrapper title={t("payments.payment-method")} onBack={back}>
      {withdrawLimit ? (
        <AmountStep
          currentAmount={currentAmount}
          currentAccount={currentAccount}
          next={next}
          setCurrentAmount={setCurrentAmount}
          currentLimitBeforeKyc={withdrawLimit.extendedInfo!.withdrawCurrentLimitNoKyc!}
          safeToWithdrawAmount={withdrawLimit.safeToWithdrawAmount!}
          limits={currentPaymentMethod.details!.limits!.find(({ currency }) => currency === currentAccount.currency)!}
        />
      ) : (
        <PaymentWrapper.Loader />
      )}
    </PaymentWrapper>
  );
};

export { AmountStepContainer };
