import { type TerminalSymbolSignal, TradingCentralSignalType } from "@/services/openapi";

const getIsUpSignal = (type: TradingCentralSignalType) =>
  type === TradingCentralSignalType.StrongUp || type === TradingCentralSignalType.Up;

const getHasSignalCondition = ({
  isTradingAvailable,
  signal,
  ask,
  bid,
}: {
  isTradingAvailable: boolean;
  signal: TerminalSymbolSignal | undefined;
  ask: number | undefined | null;
  bid: number | undefined | null;
}) => {
  if (!isTradingAvailable || !signal || signal.type === TradingCentralSignalType.None) return false;

  const isUpSignal = getIsUpSignal(signal.type as TradingCentralSignalType);

  const currentPrice = (isUpSignal ? ask : bid) || 0;

  if (isUpSignal) {
    return signal.priceTarget! > currentPrice && signal.priceSupport2! < currentPrice;
  }

  return signal.priceTarget! < currentPrice && signal.priceResistance2! > currentPrice;
};

export { getHasSignalCondition, getIsUpSignal };
