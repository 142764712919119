export const GoogleAuthenticatorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_601_2)">
      <path
        d="M12.0005 24.001C18.6282 24.001 24.001 18.6282 24.001 12.0005C24.001 5.3728 18.6282 0 12.0005 0C5.3728 0 0 5.3728 0 12.0005C0 18.6282 5.3728 24.001 12.0005 24.001Z"
        fill="#616161"
      />
      <path
        d="M12.0005 19.6369C7.78243 19.6369 4.36408 16.2185 4.36408 12.0005C4.36408 7.78242 7.78243 4.36408 12.0005 4.36408C14.1085 4.36408 16.0174 5.2189 17.4002 6.60075L20.4859 3.51505C18.314 1.34316 15.3134 0 12.0005 0C5.37266 0 0 5.37266 0 12.0005C0 18.6283 5.37266 24.001 12.0005 24.001C15.3144 24.001 18.314 22.6578 20.4869 20.4869L17.4012 17.4012C16.0174 18.7821 14.1085 19.6369 12.0005 19.6369Z"
        fill="#9E9E9E"
      />
      <path
        d="M19.637 12.0003H15.8183C15.8183 9.89227 14.1086 8.18164 11.9996 8.18164C9.89058 8.18164 8.18188 9.8913 8.18188 12.0003C8.18188 13.0273 8.58803 13.9585 9.24849 14.646L9.24559 14.6489L13.9733 19.3766L13.9742 19.3776C17.2349 18.5073 19.637 15.5347 19.637 12.0003Z"
        fill="#424242"
      />
      <path
        d="M24 12H19.6359C19.6359 15.5344 17.2329 18.507 13.9741 19.3773L17.3441 22.7473C21.2895 20.7813 24 16.7074 24 12Z"
        fill="#616161"
      />
      <path
        d="M12.0005 23.8635C5.39587 23.8635 0.0386801 18.5276 0.000967005 11.9316C0.000967005 11.9548 0 11.9771 0 12.0003C0 18.6281 5.37266 24.0008 12.0005 24.0008C18.6283 24.0008 24.001 18.6281 24.001 12.0003C24.001 11.9771 24 11.9548 24 11.9316C23.9623 18.5276 18.6041 23.8635 12.0005 23.8635Z"
        fill="#212121"
        fillOpacity="0.1"
      />
      <path
        d="M13.9751 19.3773L14.0815 19.4836C17.2861 18.5785 19.6369 15.633 19.6369 12.1363V12C19.6369 15.5344 17.2339 18.507 13.9751 19.3773Z"
        fill="white"
        fillOpacity="0.05"
      />
      <path
        d="M21.8189 10.9092H12C11.3975 10.9092 10.9092 11.3975 10.9092 12C10.9092 12.6024 11.3975 13.0907 12 13.0907H21.8179C22.4204 13.0907 22.9087 12.6024 22.9087 12C22.9097 11.3975 22.4213 10.9092 21.8189 10.9092Z"
        fill="#9E9E9E"
      />
      <path
        opacity="0.5"
        d="M21.8189 10.9092H12C11.3975 10.9092 10.9092 11.3975 10.9092 12C10.9092 12.6024 11.3975 13.0907 12 13.0907H21.8179C22.4204 13.0907 22.9087 12.6024 22.9087 12C22.9097 11.3975 22.4213 10.9092 21.8189 10.9092Z"
        fill="#BDBDBD"
      />
      <path
        d="M2.18161 12.8188C2.63342 12.8188 2.99969 12.4525 2.99969 12.0007C2.99969 11.5489 2.63342 11.1826 2.18161 11.1826C1.72979 11.1826 1.36353 11.5489 1.36353 12.0007C1.36353 12.4525 1.72979 12.8188 2.18161 12.8188Z"
        fill="#BDBDBD"
      />
      <path
        d="M12.0005 2.99945C12.4523 2.99945 12.8185 2.63318 12.8185 2.18136C12.8185 1.72955 12.4523 1.36328 12.0005 1.36328C11.5486 1.36328 11.1824 1.72955 11.1824 2.18136C11.1824 2.63318 11.5486 2.99945 12.0005 2.99945Z"
        fill="#BDBDBD"
      />
      <path
        d="M12.0005 22.6362C12.4523 22.6362 12.8185 22.2699 12.8185 21.8181C12.8185 21.3663 12.4523 21 12.0005 21C11.5486 21 11.1824 21.3663 11.1824 21.8181C11.1824 22.2699 11.5486 22.6362 12.0005 22.6362Z"
        fill="#BDBDBD"
      />
      <path
        d="M5.04587 5.87542C5.49768 5.87542 5.86395 5.50916 5.86395 5.05734C5.86395 4.60553 5.49768 4.23926 5.04587 4.23926C4.59405 4.23926 4.22778 4.60553 4.22778 5.05734C4.22778 5.50916 4.59405 5.87542 5.04587 5.87542Z"
        fill="#BDBDBD"
      />
      <path
        d="M5.04587 19.7729C5.49768 19.7729 5.86395 19.4066 5.86395 18.9548C5.86395 18.503 5.49768 18.1367 5.04587 18.1367C4.59405 18.1367 4.22778 18.503 4.22778 18.9548C4.22778 19.4066 4.59405 19.7729 5.04587 19.7729Z"
        fill="#BDBDBD"
      />
      <path
        d="M18.955 19.7729C19.4069 19.7729 19.7731 19.4066 19.7731 18.9548C19.7731 18.503 19.4069 18.1367 18.955 18.1367C18.5032 18.1367 18.137 18.503 18.137 18.9548C18.137 19.4066 18.5032 19.7729 18.955 19.7729Z"
        fill="#757575"
      />
      <path
        d="M12.0004 11.0465H21.8184C22.3976 11.0465 22.8715 11.4991 22.9053 12.0696C22.9063 12.0464 22.9092 12.0241 22.9092 12.0009C22.9092 11.3985 22.4209 10.9102 21.8184 10.9102H12.0004C11.398 10.9102 10.9097 11.3985 10.9097 12.0009C10.9097 12.0241 10.9106 12.0464 10.9135 12.0696C10.9474 11.4991 11.4202 11.0465 12.0004 11.0465Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M22.9053 12.0684C22.8695 12.6379 22.3967 13.0914 21.8184 13.0914H12.0004C11.4212 13.0914 10.9474 12.6389 10.9135 12.0684C10.9106 12.0916 10.9097 12.1138 10.9097 12.136C10.9097 12.7385 11.398 13.2268 12.0004 13.2268H21.8184C22.4209 13.2268 22.9092 12.7385 22.9092 12.136C22.9092 12.1138 22.9082 12.0916 22.9053 12.0684Z"
        fill="#212121"
        fillOpacity="0.2"
      />
      <path
        d="M12.0004 4.50001C14.1085 4.50001 16.0173 5.35484 17.4002 6.73669L20.5526 3.58233C20.5294 3.55912 20.5071 3.53591 20.4849 3.51367L17.4002 6.59937C16.0173 5.21753 14.1085 4.3627 12.0004 4.3627C7.78236 4.3627 4.36401 7.78105 4.36401 11.9991C4.36401 12.0223 4.36498 12.0446 4.36498 12.0678C4.40173 7.88162 7.80557 4.50001 12.0004 4.50001Z"
        fill="#212121"
        fillOpacity="0.1"
      />
      <path
        d="M12.0005 24.001C18.6282 24.001 24.001 18.6282 24.001 12.0005C24.001 5.3728 18.6282 0 12.0005 0C5.3728 0 0 5.3728 0 12.0005C0 18.6282 5.3728 24.001 12.0005 24.001Z"
        fill="url(#paint0_radial_601_2)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_601_2"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.51922 3.49189) scale(23.9595 23.9595)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_601_2">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
