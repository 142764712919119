import { useLocation } from "react-router-dom";

type FromDataStateType = {
  toPageState: {
    from: { entryPoint: string; entryPage: string };
  };
};

/**
 *  Creates state for useTrackVisitPage
 */
const useToPageState = (entryPoint: string): FromDataStateType => {
  const { pathname } = useLocation();

  return {
    toPageState: {
      from: {
        entryPoint,
        entryPage: pathname,
      },
    },
  };
};

export { useToPageState };
