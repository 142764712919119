import type { WidgetState } from "@livechat/widget-react";
import { atom } from "nanostores";

type LiveChatStateType = {
  visibility: WidgetState["visibility"];
  email?: string;
  sessionVariables?: {
    userId?: string;
    phoneNumber?: string;
    country?: string;
    kycStatus?: string;
  };
};

const $liveChatState = atom<LiveChatStateType>({ visibility: "minimized" });

const toggleLiveChat = () => {
  if ($liveChatState.get().visibility === "maximized")
    $liveChatState.set({ ...$liveChatState.get(), visibility: "hidden" });
  else $liveChatState.set({ ...$liveChatState.get(), visibility: "maximized" });
};

const setEmailLiveChat = (value: string) => {
  $liveChatState.set({ ...$liveChatState.get(), email: value });
};

const setLiveChatVisibility = (variant: WidgetState["visibility"]) =>
  $liveChatState.set({ ...$liveChatState.get(), visibility: variant });

/* Session variables */
const setLiveChatCountry = (value: string) => {
  $liveChatState.set({
    ...$liveChatState.get(),
    sessionVariables: { ...$liveChatState.get().sessionVariables, country: value },
  });
};

const setLiveChatUserId = (value: string) => {
  $liveChatState.set({
    ...$liveChatState.get(),
    sessionVariables: { ...$liveChatState.get().sessionVariables, userId: value },
  });
};

const setLiveChatKycStatus = (value: string) => {
  $liveChatState.set({
    ...$liveChatState.get(),
    sessionVariables: { ...$liveChatState.get().sessionVariables, kycStatus: value },
  });
};

const setLiveChatPhoneNumber = (value: string) => {
  $liveChatState.set({
    ...$liveChatState.get(),
    sessionVariables: { ...$liveChatState.get().sessionVariables, phoneNumber: value },
  });
};

export {
  $liveChatState,
  toggleLiveChat,
  setLiveChatVisibility,
  setEmailLiveChat,
  setLiveChatCountry,
  setLiveChatPhoneNumber,
  setLiveChatUserId,
  setLiveChatKycStatus,
};
