import type { ComponentProps, FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { AddressInfo, ProfileInfo } from "@/services/openapi";
import { useUpdateProfileMutation } from "@/state/server/kyc";
import { usePlatformCountriesQuery } from "@/state/server/platform";

import { StepWrapper } from "../step-wrapper";
import { ProfileForm } from "./profile.form";

type Props = {
  profile: ProfileInfo;
  address: AddressInfo;
  onNext: () => void;
};

const ProfileStep: FC<Props> = ({ profile, address, onNext }) => {
  const { t } = useTranslation();
  const { mutateAsync: updateProfile } = useUpdateProfileMutation();
  const { data: countries, isLoading: isCountriesLoading } = usePlatformCountriesQuery();

  const onSubmit: ComponentProps<typeof ProfileForm>["onSubmit"] = useCallback(
    ({ country, dateOfBirth, firstName, isNotUSAResident, lastName, phoneCode, phoneCountry, phoneNumber }) => {
      return updateProfile(
        {
          profileUpdate: {
            country,
            dateOfBirth,
            firstName,
            isNotUSAResident,
            lastName,
            phoneCode,
            phoneCountry,
            phoneNumber: phoneNumber.replaceAll(" ", ""),
          },
        },
        {
          onSuccess: () => {
            onNext();
          },
        },
      );
    },
    [onNext, updateProfile],
  );

  if (isCountriesLoading) {
    return null;
  }

  return (
    <StepWrapper title={t("onboarding.profile.title")} subtitle={t("onboarding.profile.subtitle")}>
      <ProfileForm
        profile={profile}
        address={address}
        unsupportedCountries={countries!.unsupportedCountries!}
        onSubmit={onSubmit}
      />
    </StepWrapper>
  );
};

export { ProfileStep };
