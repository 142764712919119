import * as Collapsible from "@radix-ui/react-collapsible";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { isBuyOrder } from "@/features/terminal/helpers/orders";
import { SymbolIcon } from "@/features/terminal/symbols/icon";
import { TerminalDealType } from "@/services/openapi";
import { cn } from "@/shared/styles";

import { getOrderTypeText } from "../../components/trading-table/order-type";

type Props = {
  header: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
};

const Card: FC<Props> = ({ body, footer, header }) => (
  <Collapsible.Root className="flex flex-col rounded-[24px] border border-card-border bg-card-bg p-4">
    <Collapsible.Trigger className="flex items-center gap-4 outline-none">{header}</Collapsible.Trigger>
    <Collapsible.Content className="collapsible">{body}</Collapsible.Content>
    {footer && <div className="mt-3">{footer}</div>}
  </Collapsible.Root>
);

const textStyles = cva("font-roboto text-[14px] leading-normal text-contrast-primary", {
  variants: {
    size: {
      sm: "text-[14px]",
      md: "text-[16px]",
    },
  },
});
const Text: FC<{ children: ReactNode } & VariantProps<typeof textStyles>> = ({ children, size = "sm" }) => (
  <div className={textStyles({ size })}>{children}</div>
);

const PnlText: FC<ComponentPropsWithoutRef<"div">> = ({ className, ...props }) => (
  <div className={cn("font-gilroy text-[16px] font-semibold leading-[1.2] text-accent-text", className)} {...props} />
);

const Header: FC<{
  symbol: string;
  type: TerminalDealType;
  volume: ReactNode;
  endTopSection: ReactNode;
  endBottomSection: ReactNode;
}> = ({ endBottomSection, endTopSection, symbol, type, volume }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="grow">
        <div className="mb-1 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <SymbolIcon symbol={symbol} />
            <div className="font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-primary">{symbol}</div>
          </div>
          {endTopSection}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className={cn("size-2 rounded-full", isBuyOrder(type) ? "bg-positive-text" : "bg-negative-text")} />
            <Text>
              {getOrderTypeText(t, type)}
              {" ∙ "}
              {volume} {t("terminal.lots")}
            </Text>
          </div>
          <Text>{endBottomSection}</Text>
        </div>
      </div>
      {/* FIXME: */}
      <svg
        className="shrink-0"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.52859 5.69526C2.78894 5.43491 3.21105 5.43491 3.4714 5.69526L7.66666 9.89052L11.8619 5.69526C12.1223 5.43491 12.5444 5.43491 12.8047 5.69526C13.0651 5.95561 13.0651 6.37772 12.8047 6.63807L8.13807 11.3047C7.87772 11.5651 7.45561 11.5651 7.19526 11.3047L2.52859 6.63807C2.26824 6.37772 2.26824 5.95561 2.52859 5.69526Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

const Body: FC<{ children: ReactNode }> = ({ children }) => <dl className="mt-3 grid gap-1">{children}</dl>;

const ListRow: FC<{ start: ReactNode; end: ReactNode }> = ({ start, end }) => (
  <div className="grid grid-cols-[2fr_3fr]">
    {start}
    {end}
  </div>
);

const ListLabel: FC<{ children: ReactNode }> = ({ children }) => (
  <dt className="py-1.5 font-roboto text-[14px] leading-normal text-contrast-secondary">{children}</dt>
);

const valueStyles = cva("py-1.5 font-roboto text-[14px] leading-normal text-contrast-primary", {
  variants: {
    filled: {
      true: "flex items-center justify-between rounded-[8px] bg-surface-canvas px-2",
    },
  },
});

const ListValue: FC<{ children: ReactNode; className?: string } & VariantProps<typeof valueStyles>> = ({
  children,
  className,
  filled,
}) => <dd className={cn(valueStyles({ filled }), className)}>{children}</dd>;

const Component = Object.assign(Card, {
  Header,
  Body,
  PnlText,
  Text,
  ListRow,
  ListLabel,
  ListValue,
});

export { Component as TradingCard };
