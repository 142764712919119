import type { FC } from "react";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { toggleLiveChat } from "@/app/libs/liveChat";
import logoDark from "@/assets/images/LogoDark.svg";
import logoLight from "@/assets/images/LogoLight.svg";
import { Box } from "@/components/box";
import { DepositIcon } from "@/components/icons";
import { SidebarButton } from "@/components/sidebar";
import { useAuth } from "@/contexts/auth.context";
import { LanguageSwitcherButton, LanguageSwitcherContainer } from "@/features/language-switcher";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { styled } from "@/styles/stitches.config";

import { Button } from "../button";
import { Flex } from "../flex";
import { ExternalLink as Link } from "../link";
import { Link as ReactLink } from "../link";
import { Text } from "../text";
import { headerStyles, headerWrapperStyles, styledTradingHeader, tradingHeaderWrapperStyles } from "./styles";

const Wrapper = styled("div", headerWrapperStyles);
const TradingWrapper = styled("div", tradingHeaderWrapperStyles);

const StyledHeader = styled("header", headerStyles);
const StyledTradingHeader = styled("header", styledTradingHeader);

const Ul = styled("ul", {
  display: "flex",
  gap: "40px",
});

const NavItem: FC<{ link: string; isTradingPage: boolean; title: string }> = ({ link, isTradingPage, title }) => (
  <li>
    <Link href={link}>
      <Text color={isTradingPage ? "white" : "black"} weight="bold">
        {title}
      </Text>
    </Link>
  </li>
);

const HeaderContent: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { isMobile, isLargeDesktop } = useScreenSize();
  const isSignInPage = pathname === authRoutes.signIn;
  const isTradingPage = pathname === cabinetRoutes.mt4Terminal || pathname === cabinetRoutes.mt5Terminal;
  const [open, setOpen] = useState(false);

  const authenticatedContent = useMemo(
    () => (
      <>
        <NavItem
          isTradingPage={isTradingPage}
          link={cabinetRoutes.dashboard}
          title={t("cabinet.navigation.accounts")}
        />
        <NavItem isTradingPage={isTradingPage} link={cabinetRoutes.deposit} title={t("cabinet.navigation.deposit")} />
        <NavItem
          isTradingPage={isTradingPage}
          link={cabinetRoutes.withdrawal}
          title={t("cabinet.navigation.withdrawal")}
        />
        <NavItem isTradingPage={isTradingPage} link={cabinetRoutes.transfer} title={t("cabinet.navigation.transfer")} />
        <li>
          <Text
            color={isTradingPage ? "white" : "black"}
            weight="bold"
            css={{ cursor: "pointer" }}
            onClick={toggleLiveChat}
          >
            Support
          </Text>
        </li>
      </>
    ),
    [isTradingPage, t],
  );

  const unAutheticatedContent = useMemo(
    () => (
      <>
        <li>
          <Link href="https://doto.com/markets">
            <Text color={isTradingPage ? "white" : "black"} weight="bold">
              Markets
            </Text>
          </Link>
        </li>
        <li>
          <Link href="https://doto.com/platforms">
            <Text color={isTradingPage ? "white" : "black"} weight="bold">
              Trading
            </Text>
          </Link>
        </li>
        <li>
          <Link href="https://doto.com/about">
            <Text color={isTradingPage ? "white" : "black"} weight="bold">
              Company
            </Text>
          </Link>
        </li>
      </>
    ),
    [isTradingPage],
  );

  const navigationContent = useMemo(() => {
    if (isAuthenticated) {
      return authenticatedContent;
    } else {
      return unAutheticatedContent;
    }
  }, [authenticatedContent, isAuthenticated, unAutheticatedContent]);

  return (
    <>
      <Flex css={{ alignItems: "center" }}>
        {isAuthenticated && isMobile && (
          <Box css={{ mr: "18px" }}>
            <SidebarButton />
          </Box>
        )}
        <ReactLink to={isAuthenticated ? cabinetRoutes.dashboard : authRoutes.signIn}>
          <img alt="doto" src={isTradingPage ? logoLight : logoDark} height="28" width="102" />
        </ReactLink>
        {isLargeDesktop && (
          <nav className="hidden lg:ms-20 lg:block">
            <Ul>{navigationContent}</Ul>
          </nav>
        )}
      </Flex>
      <Flex css={{ gap: "16px", alignItems: "center" }}>
        {isLargeDesktop && (
          <>
            <LanguageSwitcherButton onOpen={() => setOpen(true)} />
            <LanguageSwitcherContainer open={open} onOpenChange={setOpen} />
          </>
        )}

        {!isAuthenticated ? (
          <>
            {isTradingPage ? (
              <>
                <ReactLink to={authRoutes.signIn}>
                  <Button variant="black" size="small">
                    {t("auth.sign-in.login")}
                  </Button>
                </ReactLink>
                <ReactLink to={authRoutes.signUp}>
                  <Button variant="black" size="small" css={{ border: "1px solid $textInverse" }}>
                    {t("auth.sign-up.sign-up")}
                  </Button>
                </ReactLink>
              </>
            ) : (
              <ReactLink to={isSignInPage ? authRoutes.signUp : authRoutes.signIn}>
                <Button variant="secondary" size="small">
                  {isSignInPage ? t("auth.sign-up.sign-up") : t("auth.sign-in.login")}
                </Button>
              </ReactLink>
            )}
          </>
        ) : (
          <ReactLink to={cabinetRoutes.deposit} target="_blank" rel="noreferrer">
            <Button size="small" variant="secondary" leftIcon={<DepositIcon />}>
              {t("button.deposit")}
            </Button>
          </ReactLink>
        )}
      </Flex>
    </>
  );
};

const TradingHeader: FC = () => (
  <TradingWrapper>
    <StyledTradingHeader>
      <HeaderContent />
    </StyledTradingHeader>
  </TradingWrapper>
);

export const Header: FC = () => {
  const { pathname } = useLocation();
  const isTradingPage = pathname === cabinetRoutes.mt4Terminal || pathname === cabinetRoutes.mt5Terminal;

  if (isTradingPage) return <TradingHeader />;

  return (
    <Wrapper>
      <StyledHeader>
        <HeaderContent />
      </StyledHeader>
    </Wrapper>
  );
};
