import type { PasswordBorderType } from "@/components/form";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

export enum PasswordComplexity {
  STRONG = "strong",
  MEDIUM = "medium",
}

export const usePasswordRules = (
  password: string | undefined,
): {
  passwordText: string | undefined;
  border: PasswordBorderType | undefined;
  isPasswordCorrect: boolean;
  maxLength: number;
  complexity?: PasswordComplexity;
} => {
  const { password: passwordRules } = usePlatformData();

  if (!password) {
    return {
      passwordText: undefined,
      border: undefined,
      isPasswordCorrect: false,
      maxLength: passwordRules!.maxLength!,
    };
  }

  const strongRegex = new RegExp(passwordRules!.strong!.mask!);
  const mediumRegex = new RegExp(passwordRules!.medium!.mask!);

  if (strongRegex.test(password)) {
    return {
      passwordText: passwordRules!.strong!.description!,
      border: "success",
      isPasswordCorrect: true,
      maxLength: passwordRules!.maxLength!,
      complexity: PasswordComplexity.STRONG,
    };
  }

  if (mediumRegex.test(password)) {
    return {
      passwordText: passwordRules!.medium!.description!,
      border: "warning",
      isPasswordCorrect: true,
      maxLength: passwordRules!.maxLength!,
      complexity: PasswordComplexity.MEDIUM,
    };
  }

  return {
    passwordText: passwordRules!.weak!.description!,
    border: "error",
    isPasswordCorrect: false,
    maxLength: passwordRules!.maxLength!,
  };
};
