import React from "react";

import { Text, TextBad } from "@/components/text";
import { styled } from "@/styles";

export const StyledInput = styled("input", {
  all: "unset",
  boxSizing: "border-box",

  width: "100%",
  minHeight: "25px",

  fontFamily: "$roboto",
  fontWeight: "$normal",
  fontSize: "14px",
  color: "$textMain",

  "&::placeholder": {
    color: "$textSecondary",
  },

  "@bp3": {
    fontSize: "16px",
  },

  "&:-webkit-autofill": {
    border: "1px solid transparent",
    "-webkit-text-fill-color": "$textMain",
    "-webkit-box-shadow": "0 0 0px 1000px $textMain inset",
    transition: "background-color 5000s ease-in-out 0s",
  },
});

export const Label = ({ label, name }: { label: React.ReactNode; name?: string }) => {
  return (
    <Text as="label" htmlFor={name} css={{ mb: "12px", fontWeight: "400" }} family="roboto" size="2">
      {label}
    </Text>
  );
};

export const Description = ({ description }: { description: React.ReactNode }) => {
  return (
    <Text family="roboto" lineHeight="3" color="dimmed" css={{ mb: 12 }}>
      {description}
    </Text>
  );
};

export const Error = ({ errorMessage }: { errorMessage: React.ReactNode }) => {
  // could be empty string
  if (!errorMessage) {
    return null;
  }
  return (
    <TextBad className="mt-2 text-[14px] lg:text-[16px]" family="roboto" color="negative">
      {errorMessage}
    </TextBad>
  );
};
