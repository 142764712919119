import * as DialogPrimitive from "@radix-ui/react-dialog";
import type { FC } from "react";

const Separator: FC = () => {
  return <div className="my-6 min-h-px w-full bg-contrast-quaternary md:my-8" />;
};

const Component = Object.assign(DialogPrimitive.Root, {
  Trigger: DialogPrimitive.Trigger,
  Portal: DialogPrimitive.Portal,
  Overlay: DialogPrimitive.Overlay,
  Content: DialogPrimitive.Content,
  Title: DialogPrimitive.Title,
  Description: DialogPrimitive.Description,
  Close: DialogPrimitive.Close,
  Separator: Separator,
});

export { Component as Dialog };
