import type { FC } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTranslation } from "@/hooks/translator.hook";

import { CabinetPage } from "../cabinet/components/cabinet-page";
import { CabinetTitle } from "../cabinet/components/cabinet-title";
import { NotificationsTableContainer } from "./components/notifications-table.container";

export const NotificationsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.notifications")}>
          <CabinetTitle.DepositButton onClick={() => track(amplitudeEvents.deposit.fromNotifications)} />
          <CabinetTitle.TradeButton />
        </CabinetTitle>
      }
    >
      <NotificationsTableContainer />
    </CabinetPage>
  );
};
