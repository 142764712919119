import * as PopoverPrimitive from "@radix-ui/react-popover";
import { type FC, type ReactNode } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { cn } from "@/shared/styles";
import { Button, Dialog, popoverContentStyles, Tooltip } from "@/shared/ui";

type TerminalPopoverProps = {
  title: string;
  tooltipText?: string;
  children?: ReactNode;
};

const TerminalPopoverRoot: FC<TerminalPopoverProps> = ({ children, tooltipText, title }) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      className={cn(popoverContentStyles(), "w-[320px] rounded-[24px] bg-bg p-6 shadow")}
      sideOffset={8}
      side="top"
      collisionPadding={20}
    >
      <div className="mb-4 flex justify-between">
        <h3 className="font-gilroy text-[18px] font-semibold leading-[1.2] text-text">{title}</h3>
        {tooltipText && (
          <Tooltip content={tooltipText}>
            <TooltipQuestionIcon />
          </Tooltip>
        )}
      </div>
      {children}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
);

const List: FC<{
  children?: ReactNode;
  className?: string;
}> = ({ className, children }) => <ul className={cn("flex flex-col gap-[10px]", className)}>{children}</ul>;

const ListItem: FC<{
  label: ReactNode;
  value: ReactNode;
  valueClassName?: string;
}> = ({ label, value, valueClassName }) => (
  <div className="grid grid-cols-2 gap-2 lg:flex">
    <div className="font-roboto text-[14px] leading-[1.5] text-text-secondary lg:text-[16px]">{label}</div>
    <div className={cn("font-roboto text-[14px] leading-[1.5] text-text lg:text-[16px]", valueClassName)}>{value}</div>
  </div>
);

const Buttons: FC<{
  children: ReactNode;
  isMobile?: boolean;
}> = ({ children, isMobile }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-auto flex gap-3 *:flex-1 lg:mt-4">
      {isMobile ? (
        <Dialog.Close asChild>
          <Button variant="tertiary">{t("button.cancel")}</Button>
        </Dialog.Close>
      ) : (
        <PopoverPrimitive.Close asChild>
          <Button variant="tertiary">{t("button.cancel")}</Button>
        </PopoverPrimitive.Close>
      )}
      {children}
    </div>
  );
};

const Component = Object.assign(TerminalPopoverRoot, {
  List,
  ListItem,
  Buttons,
});

export { Component as TerminalPopover };
