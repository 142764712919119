import { cva } from "class-variance-authority";
import { type FC } from "react";

import { NumberFormat, PositiveNumberFormat } from "@/app/components";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { useTranslation } from "@/hooks/translator.hook";
import type { BonusUserPlatform } from "@/services/openapi";

import { InternetConnectionInfo } from "../components/internet-connection/internet-connection-info";

const textStyles = cva("font-roboto text-[12px] leading-[1.5] text-text", {
  variants: {
    dimmed: {
      true: "text-text-secondary",
    },
  },
});

const LabeledValue: FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => {
  return (
    <div className="flex items-center gap-1">
      <div className={textStyles({ dimmed: true })}>{label}</div>
      {value}
    </div>
  );
};

type Props = {
  equity: number;
  marginLevel: number;
  currency: string;
  currencyDecimalScale: number;
  marginFree: number;
  margin: number;
  credit: number;
  bonus: BonusUserPlatform | null;
  showBonus: boolean;
};

const AccountSummary: FC<Props> = ({
  currency,
  equity,
  marginLevel,
  currencyDecimalScale,
  marginFree,
  bonus,
  showBonus,
  credit,
  margin,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-7 items-center justify-end gap-4 bg-surface-canvas px-4">
      <LabeledValue
        label={t("terminal.account-summary.equity")}
        value={
          <div className={textStyles()}>
            <PositiveNumberFormat value={equity} currency={currency} decimalScale={currencyDecimalScale} />
          </div>
        }
      />
      <LabeledValue
        label={t("terminal.account-summary.free-margin")}
        value={
          <div className={textStyles()}>
            <PositiveNumberFormat value={marginFree} currency={currency} decimalScale={currencyDecimalScale} />
          </div>
        }
      />
      <LabeledValue
        label={t("terminal.account-summary.margin")}
        value={
          <div className={textStyles()}>
            <NumberFormat value={margin} currency={currency} decimalScale={currencyDecimalScale} />
          </div>
        }
      />
      {bonus && showBonus && (
        <LabeledValue
          label={t("terminal.account-summary.bonus.name")}
          value={
            <div className="flex items-center gap-2">
              <div className={textStyles()}>
                <NumberFormat value={credit} currency={currency} decimalScale={currencyDecimalScale} />
              </div>
              <BonusTooltip
                bonus={bonus}
                actionText={t("terminal.account-summary.bonus.button")}
                content={t("terminal.account-summary.bonus.description", { percent: getBonusMainPercent(bonus) })}
              />
            </div>
          }
        />
      )}
      <LabeledValue
        label={t("terminal.account-summary.margin-level")}
        value={
          <div className={textStyles()}>
            <NumberFormat value={marginLevel} decimalScale={2} suffix="%" />
          </div>
        }
      />
      <InternetConnectionInfo />
    </div>
  );
};

export { AccountSummary };
