import { type FC } from "react";

import { SubmitButton } from "@/app/form";
import { DialogButtons } from "@/components/dialog";
import { PasswordField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { Stack } from "@/components/stack";
import { TwoFATabsContainer } from "@/features/two-factor/two-factor-tabs.container";
import { useHookForm } from "@/hooks/form.hook";
import { PasswordComplexity, usePasswordRules } from "@/hooks/password-rules.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingServerPlatform } from "@/services/openapi";
import { Button, Dialog } from "@/shared/ui";

enum ChangePasswordFields {
  PASSWORD = "pwd",
  TWO_FA_CODE = "twoFactorCode",
  RECOVERY_CODE = "recoveryCode",
}

export interface IChangeAccountPasswordFormValues {
  [ChangePasswordFields.PASSWORD]: string;
  [ChangePasswordFields.TWO_FA_CODE]?: string;
  [ChangePasswordFields.RECOVERY_CODE]?: string;
}

type Props = {
  onSubmit: (values: IChangeAccountPasswordFormValues) => void;
  is2FaEnabled: boolean;
  accountPlatform: TradingServerPlatform;
};

export const ChangeAccountPasswordForm: FC<Props> = ({ onSubmit, is2FaEnabled, accountPlatform }) => {
  const { t } = useTranslation();
  const form = useHookForm<IChangeAccountPasswordFormValues>();

  const password = form.watch(ChangePasswordFields.PASSWORD);

  const { isPasswordCorrect, border, passwordText, maxLength, complexity } = usePasswordRules(password);

  const disabled =
    !isPasswordCorrect ||
    (accountPlatform === TradingServerPlatform.MetaTrader5 && complexity !== PasswordComplexity.STRONG);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Stack>
        <PasswordField
          name={ChangePasswordFields.PASSWORD}
          border={border}
          passwordText={passwordText}
          maxLength={maxLength}
          registerOptions={{ validate: () => !disabled, required: t("form-errors.password.account-new.required")! }}
          data-test="dialog-password-field"
        />
        {is2FaEnabled && (
          <TwoFATabsContainer
            recoveryCodeName={ChangePasswordFields.RECOVERY_CODE}
            twoFACodeName={ChangePasswordFields.TWO_FA_CODE}
            withLabels={false}
          />
        )}
        <DialogButtons>
          <SubmitButton data-test="dialog-password-submit">{t("button.set")}</SubmitButton>
          <Dialog.Close asChild>
            <Button data-test="dialog-password-cancel" variant="tertiary">
              {t("button.cancel")}
            </Button>
          </Dialog.Close>
        </DialogButtons>
      </Stack>
    </HookForm>
  );
};
