import { cva } from "class-variance-authority";
import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

import image404 from "@/assets/images/404.png";
import image500 from "@/assets/images/500.png";
import { Button } from "@/components/button";
import { Footer } from "@/components/footer";
import { Grid } from "@/components/grid";
import { Header } from "@/components/header";
import { useMarketingCookies } from "@/hooks/marketing-cookies.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { darkTheme, styled } from "@/styles";

const Wrapper = styled(Grid, {
  minHeight: "100%",
  gridTemplateRows: "auto 1fr auto",
  background: "$white",
});

const imageStyles = cva("mx-auto mb-10 h-[76px] w-[178px] lg:mb-14 lg:h-auto lg:w-auto")();

export const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useMarketingCookies();

  const error = useRouteError() as any;

  const is404 = error.status === 404;
  const is500 = error.status === 500;

  const errorMessage = is404 ? t("error.not-found-message") : t("error.went-wrong");

  useEffect(() => {
    document.body.classList.remove(darkTheme.className);
  }, []);

  return (
    <Wrapper>
      <Header />
      <main className="mx-auto flex max-w-[650px] flex-col justify-center px-4 py-[25px] lg:py-[50px]">
        {is404 && <img className={imageStyles} src={image404} alt="not found" />}
        {is500 && <img className={imageStyles} src={image500} alt="server error" />}
        <div className="align-center mb-5 font-gilroy text-[26px] font-semibold leading-[32px] text-contrast-primary lg:mb-10 lg:text-[26px] lg:leading-[64px]">
          {errorMessage}
        </div>
        <div className="mx-auto">
          {is404 ? (
            <Button variant="black" onClick={() => navigate(-1)}>
              {t("button.go-back")}
            </Button>
          ) : (
            <Button variant="black" onClick={() => window.location.reload()}>
              {t("button.reload")}
            </Button>
          )}
        </div>
      </main>
      <Footer />
    </Wrapper>
  );
};
