import Big from "big.js";
import type { TFunction } from "i18next";
import type { FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import {
  TerminalDealEventType,
  TerminalDealNotificationCloseType,
  TerminalDealNotificationOpenType,
  TerminalDealNotificationType,
  TerminalDealType,
  TerminalDealUpdateType,
  type TerminalEventDeal,
  type TerminalEventDealNotification,
} from "@/services/openapi";
import { Toast, type toast as toastFn, ToastIcons } from "@/shared/ui";

import { TerminalTableState, type useLayoutContext } from "../layout/context";
import { SymbolIcon } from "../symbols/icon";
import type { SymbolsListType } from "./symbols";

type LiveOrder = {
  id: number;
  symbol: string;
  type: TerminalDealType;
  swap: number;
  volume: number;
  price: number;
  event: TerminalDealEventType;
  date: string;
  isUpdate: boolean;
  marginRate: number;
  profitRate: number;
  stopLoss?: number;
  takeProfit?: number;
  updateVolume?: number;
  updatePrice?: number;
  updateProfit?: number | null;
  updateType?: TerminalDealType;
  updateAction?: TerminalDealUpdateType;
  updatePosition?: number;
};

const transformTerminalOrder = ({ d, e, p, s, sl, sw, t, tp, v, dt, u, rm, rp }: TerminalEventDeal): LiveOrder => ({
  marginRate: rm!,
  profitRate: rp!,
  id: d!,
  symbol: s!,
  date: dt!,
  type: t!,
  swap: sw!,
  volume: v!,
  price: p!,
  stopLoss: sl!,
  takeProfit: tp!,
  event: e!,
  updatePosition: u?.i,
  updateProfit: u?.r,
  updateAction: u?.u,
  updatePrice: u?.p,
  updateType: u?.t,
  updateVolume: u?.v,
  isUpdate: !!u,
});

const normalizeOrdersList = (list: TerminalEventDeal[]) => {
  const initObject: { [key: string]: LiveOrder } = {};
  list.forEach(item => (initObject[item.d!] = transformTerminalOrder(item)));
  return initObject;
};

const isPendingOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.BuyStop:
    case TerminalDealType.SellStop:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.SellLimit:
      return true;
    default:
      return false;
  }
};

const isMarketOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.Sell:
      return true;
    default:
      return false;
  }
};

const isBuyOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
    case TerminalDealType.BuyStopLimit:
      return true;
    default:
      return false;
  }
};

const isSellOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Sell:
    case TerminalDealType.SellLimit:
    case TerminalDealType.SellStop:
    case TerminalDealType.SellStopLimit:
      return true;
    default:
      return false;
  }
};

const updateOrdersList = ({
  newOrder,
  list,
}: {
  newOrder: LiveOrder;
  list: {
    [key: string]: LiveOrder;
  };
}): {
  [key: string]: LiveOrder;
} => {
  const updatedList: typeof list = {};
  for (const key in list) {
    updatedList[key] = { ...list[key]! };
  }

  const oldOrder = updatedList[newOrder.id]!;

  const isMarket = isMarketOrder(newOrder.type);

  if (isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      delete updatedList[newOrder.id];
      return updatedList;
    }

    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };

    return updatedList;
  }

  if (!isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      const oldPending = isPendingOrder(oldOrder?.type);
      const newPending = isPendingOrder(newOrder.type);
      if (oldPending && newPending) {
        delete updatedList[newOrder.id];
        return updatedList;
      } else {
        return updatedList;
      }
    }
    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };
  }

  return updatedList;
};

const Pnl: FC<{ pnl: number; decimalScale: number; currency: string }> = ({ pnl, currency, decimalScale }) => {
  return (
    <Toast.AccentText color={getNumberTextColor(pnl)}>
      <PnlFormat value={pnl} decimalScale={decimalScale} currency={currency} />
    </Toast.AccentText>
  );
};

const toastDeal = ({
  t,
  toast,
  deal,
  symbolsList,
  accountCurrency,
  changeTable,
  currencyDecimalScale,
}: {
  t: TFunction;
  toast: typeof toastFn;
  deal: TerminalEventDealNotification;
  symbolsList: SymbolsListType;
  accountCurrency: string;
  changeTable: ReturnType<typeof useLayoutContext>["changeTable"];
  currencyDecimalScale: number;
}) => {
  const { e: event, ec: eventCloseType, eo: eventOpenType, p: price, r: pnl, s: symbol, t: type, v: volume } = deal;

  const { priceDecimalScale, volumeDecimalScale } = symbolsList[symbol!]!;

  const isMarket = isMarketOrder(type!);
  const isBuy = isBuyOrder(type!);

  if (event === TerminalDealNotificationType.Opened) {
    if (isMarket) {
      if (eventOpenType === TerminalDealNotificationOpenType.FromPending) {
        // open order opened after pending order has reached limit price
        toast({
          size: "lg",
          icon: <SymbolIcon size="lg" symbol={symbol!} />,
          title: isBuy
            ? t("terminal.notifications.order-opened.market.executed.single.up", { symbol })!
            : t("terminal.notifications.order-opened.market.executed.single.down", { symbol })!,
          description: (
            <Trans
              i18nKey="terminal.notifications.open-price-description"
              components={{
                value: <NumberFormat value={price} decimalScale={priceDecimalScale} />,
              }}
            />
          ),
          onClick: () => {
            changeTable(TerminalTableState.OPEN);
          },
        });

        return;
      }

      // open order opened after manual user action
      toast({
        size: "lg",
        icon: <SymbolIcon size="lg" symbol={symbol!} />,
        title: isBuy
          ? t("terminal.notifications.order-opened.market.manual.up", { symbol })!
          : t("terminal.notifications.order-opened.market.manual.down", { symbol })!,
        description: (
          <Trans
            i18nKey="terminal.notifications.open-price-description"
            components={{
              value: <NumberFormat value={price} decimalScale={priceDecimalScale} />,
            }}
          />
        ),
        onClick: () => {
          changeTable(TerminalTableState.OPEN);
        },
      });

      return;
    }

    // pending order opened after manual user action
    toast({
      size: "lg",
      icon: <SymbolIcon size="lg" symbol={symbol!} />,
      title: isBuy
        ? t("terminal.notifications.order-opened.pending.placed.up", { symbol })!
        : t("terminal.notifications.order-opened.pending.placed.down", { symbol })!,
      description: (
        <Trans
          i18nKey="terminal.notifications.open-price-description"
          components={{
            value: <NumberFormat value={price} decimalScale={priceDecimalScale} />,
          }}
        />
      ),
      onClick: () => {
        changeTable(TerminalTableState.PENDING);
      },
    });

    return;
  }

  if (event === TerminalDealNotificationType.Modified) {
    // open order modified after manual user action (changing SL/TP)
    if (isMarket) {
      toast({
        size: "lg",
        icon: <SymbolIcon size="lg" symbol={symbol!} />,
        title: isBuy
          ? t("terminal.notifications.order-modified.market.up", { symbol })!
          : t("terminal.notifications.order-modified.market.down", { symbol })!,
      });
      return;
    }

    // pending order modified after manual user action (changing SL/TP, open price)
    toast({
      size: "lg",
      icon: <SymbolIcon size="lg" symbol={symbol!} />,
      title: isBuy
        ? t("terminal.notifications.order-modified.pending.up", { symbol })!
        : t("terminal.notifications.order-modified.pending.down", { symbol })!,
    });

    return;
  }

  if (event === TerminalDealNotificationType.Closed) {
    if (isMarket) {
      // open order partially closed after manual user action
      if (eventCloseType === TerminalDealNotificationCloseType.Partial) {
        toast({
          size: "lg",
          icon: <SymbolIcon size="lg" symbol={symbol!} />,
          title: isBuy
            ? t("terminal.notifications.order-closed.market.partially.up", { symbol })!
            : t("terminal.notifications.order-closed.market.partially.down", { symbol })!,
          accentText: <Pnl pnl={pnl!} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
          onClick: () => {
            changeTable(TerminalTableState.CLOSED_POSITIONS);
          },
        });
        return;
      }

      if (eventCloseType === TerminalDealNotificationCloseType.Sl) {
        // open order closed after stop loss price has been reached
        toast({
          size: "lg",
          icon: <SymbolIcon size="lg" symbol={symbol!} />,
          title: t("terminal.notifications.order-closed.market.sl-title")!,
          description: (
            <Trans
              i18nKey="terminal.notifications.order-closed.market.sl-tp.description-single"
              values={{ symbol }}
              components={{
                value: <NumberFormat value={volume} decimalScale={volumeDecimalScale} />,
              }}
            />
          ),
          accentText: <Pnl pnl={pnl!} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
          onClick: () => {
            changeTable(TerminalTableState.CLOSED_POSITIONS);
          },
        });
        return;
      }

      if (eventCloseType === TerminalDealNotificationCloseType.Tp) {
        // open order closed after take profit price has been reached
        toast({
          size: "lg",
          icon: <SymbolIcon size="lg" symbol={symbol!} />,
          title: t("terminal.notifications.order-closed.market.tp-title")!,
          description: (
            <Trans
              i18nKey="terminal.notifications.order-closed.market.sl-tp.description-single"
              values={{ symbol }}
              components={{
                value: <NumberFormat value={volume} decimalScale={volumeDecimalScale} />,
              }}
            />
          ),
          accentText: <Pnl pnl={pnl!} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
          onClick: () => {
            changeTable(TerminalTableState.CLOSED_POSITIONS);
          },
        });
        return;
      }

      if (eventCloseType === TerminalDealNotificationCloseType.So) {
        // open order closed because of stop out event
        toast({
          size: "lg",
          icon: ToastIcons.ERROR,
          title: t("terminal.notifications.order-closed.market.so.title")!,
          description: t("terminal.notifications.order-closed.market.so.description")!,
          accentText: <Pnl pnl={pnl!} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
          onClick: () => {
            changeTable(TerminalTableState.CLOSED_POSITIONS);
          },
        });
        return;
      }

      // open order closed after manual user action
      toast({
        size: "lg",
        icon: <SymbolIcon size="lg" symbol={symbol!} />,
        title: isBuy
          ? t("terminal.notifications.order-closed.market.manual.up", { symbol })!
          : t("terminal.notifications.order-closed.market.manual.down", { symbol })!,
        accentText: <Pnl pnl={pnl!} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
        onClick: () => {
          changeTable(TerminalTableState.CLOSED_POSITIONS);
        },
      });

      return;
    }

    // pending order canceled after manual user action
    toast({
      size: "lg",
      icon: <SymbolIcon size="lg" symbol={symbol!} />,
      title: isBuy
        ? t("terminal.notifications.order-closed.pending.manual.up", { symbol })!
        : t("terminal.notifications.order-closed.pending.manual.down", { symbol })!,
    });

    return;
  }
};

const toastMultipleDeals = ({
  accountCurrency,
  changeTable,
  symbolsList,
  t,
  toast,
  deals,
  currencyDecimalScale,
}: {
  t: TFunction;
  toast: typeof toastFn;
  deals: TerminalEventDealNotification[];
  symbolsList: SymbolsListType;
  accountCurrency: string;
  changeTable: ReturnType<typeof useLayoutContext>["changeTable"];
  currencyDecimalScale: number;
}) => {
  const { e: event, ec: eventCloseType, eo: eventOpenType, t: type } = deals[0]!;

  const isMarket = isMarketOrder(type!);

  if (event === TerminalDealNotificationType.Opened) {
    if (isMarket) {
      if (eventOpenType === TerminalDealNotificationOpenType.FromPending) {
        toast({
          size: "lg",
          icon: <SymbolIcon size="lg" symbol="" />,
          title: t("terminal.notifications.order-opened.market.executed.multiple", { count: deals.length })!,
          onClick: () => {
            changeTable(TerminalTableState.OPEN);
          },
        });

        return;
      }

      // could be possible if you open orders simultaneously from different devices
      deals.forEach(deal => {
        toastDeal({ toast, deal, symbolsList, accountCurrency, changeTable, t, currencyDecimalScale });
      });

      return;
    }

    // could be possible if you open orders simultaneously from different devices
    deals.forEach(deal => {
      toastDeal({ toast, deal, symbolsList, accountCurrency, changeTable, t, currencyDecimalScale });
    });

    return;
  }

  if (event === TerminalDealNotificationType.Modified) {
    // could be possible if you modify orders simultaneously from different devices
    deals.forEach(deal => {
      toastDeal({ toast, deal, symbolsList, accountCurrency, changeTable, t, currencyDecimalScale });
    });

    return;
  }

  if (event === TerminalDealNotificationType.Closed) {
    if (isMarket) {
      if (eventCloseType === TerminalDealNotificationCloseType.Partial) {
        // could be possible if you partially close orders simultaneously from different devices
        deals.forEach(deal => {
          toastDeal({ toast, deal, symbolsList, accountCurrency, changeTable, t, currencyDecimalScale });
        });

        return;
      }

      if (eventCloseType === TerminalDealNotificationCloseType.Sl) {
        const pnl = deals.reduce((acc, deal) => acc.plus(deal.r!), new Big(0)).toNumber();

        toast({
          size: "lg",
          icon: <SymbolIcon size="lg" symbol="" />,
          title: t("terminal.notifications.order-closed.market.sl-title")!,
          description: t("terminal.notifications.order-closed.market.sl-tp.description-multiple", {
            count: deals.length,
          })!,
          accentText: <Pnl pnl={pnl} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
          onClick: () => {
            changeTable(TerminalTableState.CLOSED_POSITIONS);
          },
        });
        return;
      }

      if (eventCloseType === TerminalDealNotificationCloseType.Tp) {
        const pnl = deals.reduce((acc, deal) => acc.plus(deal.r!), new Big(0)).toNumber();

        toast({
          size: "lg",
          icon: <SymbolIcon size="lg" symbol="" />,
          title: t("terminal.notifications.order-closed.market.tp-title")!,
          description: t("terminal.notifications.order-closed.market.sl-tp.description-multiple", {
            count: deals.length,
          })!,
          accentText: <Pnl pnl={pnl} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
          onClick: () => {
            changeTable(TerminalTableState.CLOSED_POSITIONS);
          },
        });
        return;
      }

      if (eventCloseType === TerminalDealNotificationCloseType.So) {
        const pnl = deals.reduce((acc, deal) => acc.plus(deal.r!), new Big(0)).toNumber();

        toast({
          size: "lg",
          icon: ToastIcons.ERROR,
          title: t("terminal.notifications.order-closed.market.so.title")!,
          description: t("terminal.notifications.order-closed.market.so.description")!,
          accentText: <Pnl pnl={pnl} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
          onClick: () => {
            changeTable(TerminalTableState.CLOSED_POSITIONS);
          },
        });
        return;
      }

      const pnl = deals.reduce((acc, deal) => acc.plus(deal.r!), new Big(0)).toNumber();

      toast({
        size: "lg",
        icon: <SymbolIcon size="lg" symbol="" />,
        title: t("terminal.notifications.order-closed.market.close-all-title", { count: deals.length })!,
        accentText: <Pnl pnl={pnl} currency={accountCurrency} decimalScale={currencyDecimalScale} />,
        onClick: () => {
          changeTable(TerminalTableState.CLOSED_POSITIONS);
        },
      });

      return;
    }

    toast({
      size: "lg",
      icon: <SymbolIcon size="lg" symbol="" />,
      title: t("terminal.notifications.order-closed.pending.cancel-all-title")!,
    });

    return;
  }
};

export {
  isBuyOrder,
  isMarketOrder,
  isPendingOrder,
  isSellOrder,
  normalizeOrdersList,
  toastDeal,
  toastMultipleDeals,
  transformTerminalOrder,
  updateOrdersList,
};
export type { LiveOrder };
