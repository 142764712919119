import { useSearchParams } from "react-router-dom";

import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { Link } from "@/components/link";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";

import { AuthBox } from "../styles";

export const ResetPasswordSuccess = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  return (
    <AuthBox css={{ mt: "30px" }}>
      <Text family="roboto" align="center" css={{ mb: "16px" }}>
        {t("auth.reset-password.success", { user: searchParams.get("email") })}
      </Text>
      <Flex css={{ justifyContent: "center" }}>
        <Link to={cabinetRoutes.dashboard}>
          <Button>{t("auth.reset-password.done")}</Button>
        </Link>
      </Flex>
    </AuthBox>
  );
};
