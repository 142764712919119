import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const dialogParamsName = "dialog";

export enum DialogName {
  VERIFICATION = "verification",
  COMPLETE_PROFILE = "complete-profile",
  EMAIL_CONFIRM = "email-confirm",
  CONGRATULATIONS = "congratulations",
  CHANGE_EMAIL = "change-email",
  RAFFLE = "raffle",
  EXIT_TERMINAL_FEATURE_TOUR = "exit-terminal-feature-tour",
  NO_ACCOUNTS = "no-accounts",
}

export const getDialogSearchParams = (dialogName: DialogName) => {
  return `${dialogParamsName}=${dialogName}`;
};

export const useDialog = (dialogName: DialogName) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onClose = useCallback(() => {
    setSearchParams(prevParams => {
      prevParams.delete(dialogParamsName);
      return prevParams;
    });
  }, [setSearchParams]);

  return { open: searchParams.get(dialogParamsName) === dialogName, onClose };
};
