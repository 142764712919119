import { useQueryClient } from "react-query";

import { makeDeposit, makeWithdraw } from "@/services/payment";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { useBaseMutation } from "@/state/server";
import { bonusesQueryKeys } from "@/state/server/bonuses";
import { paymentQueryKeys } from "@/state/server/payment/payment.keys";
import type { MutationOptionsType } from "@/state/server/types";

export const useDepositMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof makeDeposit>, ApiRequestParamsType<typeof makeDeposit>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof makeDeposit>, ApiRequestParamsType<typeof makeDeposit>>(makeDeposit, {
    onSuccess: () => {
      client.invalidateQueries(paymentQueryKeys.base);
      client.invalidateQueries(bonusesQueryKeys.base);
    },
    ...options,
  });
};

export const useWithdrawalMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof makeWithdraw>, ApiRequestParamsType<typeof makeWithdraw>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof makeWithdraw>, ApiRequestParamsType<typeof makeWithdraw>>(
    makeWithdraw,
    {
      onSuccess: () => {
        client.invalidateQueries(paymentQueryKeys.base);
        client.invalidateQueries(bonusesQueryKeys.base);
      },
      ...options,
    },
  );
};
