const terminalLocators = {
  featureTour: {
    forkDemo: "fork-demo-account",
    forkReal: "fork-real-account",
  },
  tradingTables: {
    tabs: {
      open: "Open",
      pending: "Pending",
      closed: "Closed",
    },
    open: {
      symbol: "Symbol",
      type: "Type",
      volume: "Volume",
      margin: "Margin",
      openPrice: "Open price",
      currentPrice: "Current price",
      takeProfit: "Take profit",
      stopLoss: "Stop loss",
      timeOpened: "Time opened",
      swap: "Swap",
      pnl: "Pnl",
      close: "Close",
      closeAll: "Close all",
    },
    pending: {
      symbol: "Symbol",
      type: "Type",
      volume: "Volume",
      margin: "Margin",
      openPrice: "Open price",
      currentPrice: "Current price",
      takeProfit: "Take profit",
      stopLoss: "Stop loss",
      placementTime: "Placement time",
      delete: "Delete order",
    },
  },
};

export { terminalLocators };
