import { Box } from "@/components/box";
import { styled } from "@/styles/stitches.config";

export const AuthSeparator = styled("div", {
  color: "$darkGray",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",

  "&::before": {
    content: "",
    background: "$black",
    opacity: "0.1",
    height: "1px",
    width: "100%",
    position: "absolute",
  },
});

export const AuthBox = styled(Box, {
  px: "32px",
  "@bp3": {
    px: "56px",
  },

  a: {
    color: "$primary",
  },
});

export const SocialButtonsBox = styled(AuthBox, {
  display: "flex",
  gap: "8px",
  flexDirection: "column",

  "@bp3": {
    flexDirection: "row",
    gap: "16px",
  },
});
