import { forwardRef } from "react";

import { Text } from "../text";

type TitleProps = React.ComponentProps<typeof Text> & { as?: any };
export const DialogTitleText = forwardRef<React.ElementRef<typeof Text>, TitleProps>(
  ({ children, as = "h2", size = 6, ...restProps }, ref) => {
    return (
      <Text as={as} weight="bold" size={size} align="center" lineHeight="4" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);
DialogTitleText.displayName = "DialogTitleText";

type DescriptionProps = React.ComponentProps<typeof Text> & { as?: any };
export const DialogDescriptionText = forwardRef<React.ElementRef<typeof Text>, DescriptionProps>(
  ({ children, as = "p", ...restProps }, ref) => {
    return (
      <Text as={as} family="roboto" color="dimmed" align="center" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);
DialogDescriptionText.displayName = "DialogDescriptionText";
