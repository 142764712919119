export const AndroidIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.18446 6.25729L8.20325 12.2966C8.61134 12.7066 8.61134 13.3705 8.20325 13.7796L2.17665 19.9253L2.18446 6.25729ZM4.26492 3.3011L11.0081 7.18672L13.401 8.56523L11.1546 10.8195C10.7465 11.2285 10.0846 11.2285 9.67647 10.8195L2.4422 3.56372C2.87372 3.11854 3.65279 2.9389 4.26492 3.3011ZM3.50928 22.9108C3.10412 22.9108 2.74387 22.7301 2.48515 22.4695L9.67647 15.2567C10.0846 14.8476 10.7465 14.8476 11.1546 15.2567L13.3688 17.4787L4.23466 22.7165C4.01694 22.8434 3.76604 22.9108 3.50928 22.9108ZM18.8633 14.2618L15.2432 16.4028L12.6278 13.7796C12.2197 13.3705 12.2197 12.7066 12.6278 12.2966L15.2725 9.64402L18.875 11.7655L18.8985 11.7782C19.3544 12.0262 19.6365 12.4977 19.6365 13.0103C19.6365 13.5267 19.3505 14.0002 18.8633 14.2618ZM19.9997 9.95839L5.31443 1.49595C4.76868 1.17183 4.14386 1 3.50928 1C1.87498 1 0.443743 2.11394 0.112782 3.62035C0.0766598 3.76484 -0.00730043 4.13973 0.000509846 4.49607L0.00148746 21.541C0.0132029 21.8866 0.106925 22.3543 0.12157 22.4109C0.529657 23.9359 1.92281 25 3.50928 25C4.13508 25 4.74916 24.834 5.27733 24.5255L19.9685 16.1012C21.1088 15.489 21.8175 14.3087 21.8175 13.02C21.8175 11.745 21.1215 10.5725 19.9997 9.95839Z"
        fill="currentColor"
      />
    </svg>
  );
};
