import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { getBrowserVersion, getIsMobile } from "@/utils/helpers";
import { initBloomreachSdk } from "@/utils/initBloomreachSdk";

interface sessionStartParams {
  params?: { [key: string]: unknown };
  onSuccess?: { (): void };
}

export const useBloomreach = ({ isCookieAccepted }: { isCookieAccepted: boolean }) => {
  const [isSdkInitialized, setIsSdkInitialized] = useState(false);
  const isMobile: boolean = getIsMobile();

  useEffect(() => {
    if (import.meta.env.VITE_BLOOMREACH_API_TOKEN && isCookieAccepted) {
      console.log("Initializing Bloomreach SDK...");
      initBloomreachSdk().then(() => {
        console.log("Bloomreach SDK initialized");
        setIsSdkInitialized(true);
      });
    }
  }, [isCookieAccepted]);

  const identify = ({ customer_id, email }: { customer_id: string; email: string }) => {
    if (isSdkInitialized) {
      const exponea = window?.exponea || {};
      exponea.identify({
        customer_id,
        email,
      });
    }
  };

  const sessionStart = ({ params, onSuccess }: sessionStartParams) => {
    if (isSdkInitialized) {
      const exponea = window?.exponea || {};
      const isWeekday: number = dayjs().day() !== 0 && dayjs().day() !== 6 ? 1 : 0;
      exponea.track(
        "session_start",
        {
          ...params,
          ping: {
            properties: {
              timestamp: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              is_mobile: isMobile ? 1 : 0,
              flg_weekday: isWeekday,
              path_start: document.referrer,
              app_ver: getBrowserVersion(),
            },
          },
        },
        () => {
          if (onSuccess) onSuccess();
        },
      );
    }
  };

  if (!isSdkInitialized) {
    return {
      identify: () => {},
      sessionStart: () => {},
    };
  }

  return {
    isSdkInitialized,
    identify,
    sessionStart,
  };
};
