import { type ComponentPropsWithoutRef, type FC } from "react";
import { NumericFormat } from "react-number-format";

type Props = ComponentPropsWithoutRef<typeof NumericFormat> & {
  currency?: string;
};

const NumberFormat: FC<Props> = ({
  displayType = "text",
  thousandSeparator = ",",
  currency,
  fixedDecimalScale = true,
  ...props
}) => {
  return (
    <NumericFormat
      fixedDecimalScale={fixedDecimalScale}
      thousandSeparator={thousandSeparator}
      displayType={displayType}
      suffix={currency ? ` ${currency}` : undefined}
      renderText={value => value}
      {...props}
    />
  );
};

export { NumberFormat };
