import { BannerState, type BonusUserPlatform } from "@/services/openapi";

const getShowBonusCondition = ({
  allowedTradingServerAccounts,
  serverAccountId,
}: {
  allowedTradingServerAccounts: string[];
  serverAccountId: string;
}) => allowedTradingServerAccounts.includes(serverAccountId);

const getBonusMainPercent = ({ amountSet }: BonusUserPlatform) => amountSet![0]!.amount;

const getShowBonusBanner = ({ options }: BonusUserPlatform, isDepositPage?: boolean) => {
  if (isDepositPage) {
    return options!.bannerDepositState === BannerState.Default;
  }

  return options!.bannerAccountsState === BannerState.Default;
};

export { getShowBonusCondition, getBonusMainPercent, getShowBonusBanner };
