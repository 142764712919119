import { type FC } from "react";

import { DemoToRealContainer } from "@/features/terminal/demo-to-real/demo-to-real.container";

import { Terminal } from "./terminal";
import { TerminalContainer } from "./terminal.container";

const TerminalPage: FC = () => {
  return (
    <TerminalContainer>
      <DemoToRealContainer />
      <Terminal />
    </TerminalContainer>
  );
};

TerminalPage.displayName = "TerminalPage";

export default TerminalPage;
