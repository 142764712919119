import { NumericFormat } from "react-number-format";

export const BalanceFormat = ({
  value,
  currency,
  styled = true,
  prefix,
  fixedDecimalScale,
}: {
  value: number | string;
  currency: string;
  prefix?: string;
  styled?: boolean;
  fixedDecimalScale?: boolean;
}) => {
  const renderFormat = () => (
    <NumericFormat
      decimalScale={2}
      fixedDecimalScale={fixedDecimalScale}
      value={value}
      thousandSeparator=","
      displayType="text"
      prefix={prefix}
      suffix={currency ? ` ${currency}` : undefined}
    />
  );

  if (styled) {
    return (
      <span className="whitespace-nowrap font-gilroy text-[14px] font-semibold leading-[19px] text-contrast-primary lg:text-[22px]">
        {renderFormat()}
      </span>
    );
  }

  return renderFormat();
};
