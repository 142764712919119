import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, useRef } from "react";

import { IconCross, IconMinus, IconPlus } from "@/domains/icons";

import {
  _NewButton as _Button,
  _NewDescriptorButton as _DescriptorButton,
  type _NewExternalNumericInputProps as _ExternalNumericInputProps,
  type _NewExternalWrapperProps as _ExternalWrapperProps,
  _NewInput as _Input,
  _NewNumericInput as _NumericInput,
  _NewWrapper as _Wrapper,
} from "../_base";

type Props = _ExternalNumericInputProps &
  Omit<_ExternalWrapperProps, "hasInputValue"> & {
    value: string;
    onChange: (value: string) => void;
    onIncrement?: ComponentPropsWithoutRef<typeof _Button>["onClick"];
    onDecrement?: ComponentPropsWithoutRef<typeof _Button>["onClick"];
    plusDisabled?: boolean;
    minusDisabled?: boolean;
    clearable?: boolean;
  };

const NumberInput = forwardRef<ElementRef<typeof _NumericInput>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      startSection,
      disabled,
      invalid,
      pending,
      clearable,
      onIncrement,
      onDecrement,
      plusDisabled,
      minusDisabled,
      descriptor,
      endSection,
      floatingLabel = true,
      ...props
    },
    forwardedRef,
  ) => {
    const showClearButton = clearable && value !== null && value !== "";

    const hasEndSection = !!(showClearButton || onIncrement || onDecrement || endSection);

    const ref = useRef<ElementRef<typeof _NumericInput>>(null);
    const composedRefs = useComposedRefs(ref, forwardedRef);

    return (
      <_Wrapper
        type="input"
        className={className}
        startSection={startSection}
        invalid={invalid}
        pending={pending}
        label={label}
        disabled={disabled}
        descriptor={descriptor}
        floatingLabel={floatingLabel}
        hasInputValue={value !== ""}
        endSection={
          hasEndSection ? (
            <>
              {showClearButton && (
                <_Button
                  disabled={disabled}
                  onClick={() => {
                    ref.current!.focus();
                    onChange("");
                  }}
                >
                  <IconCross />
                </_Button>
              )}
              {onDecrement && (
                <_Button disabled={minusDisabled || disabled} onClick={onDecrement}>
                  <IconMinus />
                </_Button>
              )}
              {onIncrement && (
                <_Button disabled={plusDisabled || disabled} onClick={onIncrement}>
                  <IconPlus />
                </_Button>
              )}
              {endSection}
            </>
          ) : null
        }
      >
        <_NumericInput
          value={value}
          onValueChange={({ value }) => onChange(value)}
          customInput={_Input}
          disabled={disabled}
          {...props}
          ref={composedRefs}
        />
      </_Wrapper>
    );
  },
);

const Component = Object.assign(NumberInput, {
  DescriptorButton: _DescriptorButton,
  IconButton: _Button,
});

// TODO: remove replace NewNumberInput name with NumberInput

export { Component as NewNumberInput };
