import { type FC } from "react";

import { SymbolIcon } from "../../symbols/icon";

type Props = {
  isMobile: boolean | undefined;
  symbol: string;
};

export const ModifyOrderSymbol: FC<Props> = ({ isMobile, symbol }) => {
  if (!isMobile) {
    return null;
  }
  return (
    <div className="flex items-center gap-2">
      <SymbolIcon symbol={symbol} />
      <div className="my-4 font-gilroy text-[16px] font-semibold leading-[1.2]">{symbol}</div>
    </div>
  );
};
