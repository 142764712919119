import { useStore } from "@nanostores/react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { cva } from "class-variance-authority";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { $serverConnectionStatus } from "./store";

const styles = cva(
  "fixed inset-x-0 top-0 z-40 grid place-items-center transition-colors data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
  {
    variants: {
      variant: {
        error: "bg-negative-bg",
        success: "bg-action",
      },
    },
  },
);

const ServerConnection: FC = () => {
  const { t } = useTranslation();

  const status = useStore($serverConnectionStatus);

  return (
    <Collapsible.Root open={status !== "idle"}>
      <Collapsible.Content className={styles({ variant: status === "failed" ? "error" : "success" })}>
        <div className="py-1.5 font-roboto text-[14px] font-normal leading-normal text-text-inverse">
          {status === "failed" ? t("common.connection-lost") : t("common.connection-restored")}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export { ServerConnection };
