import type { FC } from "react";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { Button, type ExternalButtonProps } from "../button";

type Props = ExternalButtonProps & {
  checkValid?: boolean;
  checkDirty?: boolean;
  isPending?: boolean;
  width?: string;
};

const _SubmitButton: FC<Props> = ({
  children,
  checkDirty = false,
  checkValid = true,
  disabled: disabledProp,
  width = "100%",
  isPending,
  wide,
  css = {},
  ...restProps
}) => {
  const {
    formState: { isValid, isSubmitting, isDirty },
  } = useFormContext();

  const disabled = (checkValid && !isValid) || (checkDirty && !isDirty) || isSubmitting || isPending || disabledProp;

  return (
    <Button
      {...restProps}
      disabled={disabled}
      type="submit"
      css={{ width, "@bp1": { width: !wide ? "fit-content" : width }, ...css }}
    >
      {children}
    </Button>
  );
};

export const SubmitButton = memo(_SubmitButton);
