import dayjs from "dayjs";
import React, { createContext, memo, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import useWebSocket from "react-use-websocket";

import { useInterval } from "@/hooks/interval.hook";
import { TerminalEventType } from "@/services/openapi";
import { terminalQueryKeys } from "@/state/server/terminal";

import { filterTerminalSocket, terminalCommands } from "../helpers/socket.commands";

type SocketContextState = {
  socketUrl: string;
};

// https://github.com/Doto-com/gv-backend/blob/develop-test/DoTo.SharedServices/ViewModels/Terminal/Events/TerminalEvent.cs

// type SocketType = TerminalModelsContainer;

//   "terminal": {
//     "command": {
//       "type": "Ping", Ping | TickSubscribe | MarketWatchSubscribe | TradingSubscribe | TradingInitData
//       "symbols": [
//         "string"
//       ]
//     },
//     "event": {
//       "t": "Heartbeat", Subscription | Heartbeat | Tick | MarketWatch | Deal | Account | Init
//       "e": 0, eventId
//       "dt": { TickEvent
//         "s": "string", symbol
//         "b": 0, bid
//         "a": 0 ask,
//         "t": number timestamp
//       },
//       "dm": [ MarketWatchEvent
//         "t": number, timestamp
//         {
//           "s": "string", symbol
//           "b": 0, bid
//           "a": 0 ask
//         }
//       ],
//       "dd": { DealEvent
//         "d": 0, id
//         "s": "string", symbol
//         "t": "Buy", Buy | Sell | BuyLimit | SellLimit | BuyStop | SellStop | BuyStopLimit | SellStopLimit
//         "e": "In", In | Out | InOut | OutBy
//         "p": 0, profit
//         "sw": 0, swap
//         "v": 0, volume
//         "pr": 0, price
//         "sl": 0, stoploss
//         "tp": 0, takeprofit
//         "ev": "Add" Add | Delete | Update
//       },
//       "dds": [ DealsEvent
//         {
//           "d": 0, id
//           "s": "string", symbol
//           "t": "Buy", Buy | Sell | BuyLimit | SellLimit | BuyStop | SellStop | BuyStopLimit | SellStopLimit
//           "e": "In", In | Out | InOut | OutBy
//           "p": 0, profit
//           "sw": 0, swap
//           "v": 0, volume
//           "pr": 0, price
//           "sl": 0, stoploss
//           "tp": 0, takeprofit
//           "ev": "Add" Add | Delete | Update
//         }
//       ],
//       "da": { AccountUpdateEvent
//         "b": 0, balance
//         "c": 0, credit
//         "l": 0 leverage
//       },
//       "ds": { SubscriptionEvent
//         "t": [
//           "None" None | Ticks | Trading | MarketWatch
//         ],
//         "c": [
//           "None" None | Public | Private
//         ],
//         "dt": [
//           "string" symbols
//         ],
//         "dm": [
//           "string" marketwatch symbols
//         ]
//       }
//     }
//   }

const SocketContext = createContext<SocketContextState>({} as SocketContextState);

const pingInterval = 1200000; //  20 minutes

export const TerminalSocketContextProvider = memo(
  ({ socketUrl, children, accountId }: { children: React.ReactNode; socketUrl: string; accountId: string }) => {
    const queryClient = useQueryClient();

    const [heartbeatDate, setHeartbeatDate] = useState(dayjs());

    const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl, {
      share: true,
      filter: e => filterTerminalSocket(e, [TerminalEventType.Heartbeat]),
      shouldReconnect: () => true,
      retryOnError: true,
      onClose: () => queryClient.invalidateQueries(terminalQueryKeys.token(accountId)),
    });

    useEffect(() => {
      if (lastJsonMessage) {
        setHeartbeatDate(dayjs());
      }
    }, [lastJsonMessage]);

    useInterval(
      () => {
        sendJsonMessage(terminalCommands.ping);
      },
      readyState === 1 ? pingInterval : null,
    );

    useInterval(() => {
      const currentDate = dayjs();

      // 2 minutes
      if (currentDate.diff(heartbeatDate) > 120000) {
        queryClient.invalidateQueries(terminalQueryKeys.token(accountId));
      }
    }, 10000);

    const value: SocketContextState = useMemo(() => ({ socketUrl }), [socketUrl]);

    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
  },
);
TerminalSocketContextProvider.displayName = "TerminalSocketContextProvider";

export const useSocketContext = () => {
  return useContext(SocketContext);
};
