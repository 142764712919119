import dayjs from "dayjs";

import { BarTimeframe } from "@/services/openapi";

const getNextBarTime = ({ lastBarTime, timeframe }: { lastBarTime: number; timeframe: BarTimeframe }): number => {
  switch (timeframe) {
    case BarTimeframe.Month1:
      return +dayjs(lastBarTime).add(1, "month");
    case BarTimeframe.Week1:
      return +dayjs(lastBarTime).add(1, "week");
    case BarTimeframe.Day1:
      return +dayjs(lastBarTime).add(1, "day");
    case BarTimeframe.Hour4:
      return +dayjs(lastBarTime).add(4, "hour");
    case BarTimeframe.Hour1:
      return +dayjs(lastBarTime).add(1, "hour");
    case BarTimeframe.Minute30:
      return +dayjs(lastBarTime).add(30, "minute");
    case BarTimeframe.Minute15:
      return +dayjs(lastBarTime).add(15, "minute");
    case BarTimeframe.Minute5:
      return +dayjs(lastBarTime).add(5, "minute");
    case BarTimeframe.Minute1:
      return +dayjs(lastBarTime).add(1, "minute");
  }
};

export { getNextBarTime };
