import type { FC } from "react";

import { PersonalEmail } from "./styles";

type Props = {
  email: string;
  isEmailConfirmed: boolean;
};

export const ProfileInCompleteContent: FC<Props> = ({ email, isEmailConfirmed }) => {
  return <PersonalEmail email={email} isEmailConfirmed={isEmailConfirmed} />;
};
