import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { Button } from "@/components/button";
import { Link } from "@/components/link";
import { NoContentMessage } from "@/components/no-content-message";
import { useToPageState } from "@/hooks/to-page-state.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";

export const NoTransactionsMessage = () => {
  const { t } = useTranslation();
  const { toPageState } = useToPageState("no transactions");

  return (
    <NoContentMessage title={t("transactions.no-items.title")} description={t("transactions.no-items.description")}>
      <Link
        to={cabinetRoutes.deposit}
        onClick={() => track(amplitudeEvents.transcationHistory.depositClickWithNoTransactions)}
        state={toPageState}
      >
        <Button>{t("button.deposit-now")}</Button>
      </Link>
    </NoContentMessage>
  );
};
