import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const _inputTextStyles = cva(
  "typography-S-Regular text-contrast-primary transition-colors placeholder:text-contrast-tertiary group-data-[disabled=true]/input-wrapper:text-contrast-tertiary",
);

const _Input = forwardRef<ElementRef<"input">, ComponentPropsWithoutRef<"input">>(({ autoFocus, ...props }, ref) => (
  <input
    className={cn(_inputTextStyles(), "w-full bg-transparent outline-none")}
    autoFocus={autoFocus} // does not exist in DOM
    data-autofocus={autoFocus}
    {...props}
    ref={ref}
  />
));

// TODO: remove replace _NewInput name with _Input
// TODO: remove replace _newiInputTextStyles name with _inputTextStyles

export { _Input as _NewInput, _inputTextStyles as _newInputTextStyles };
