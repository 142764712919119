import { type FC, type ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/shared/ui";

import { TerminalDialogContent } from "../../components/terminal-dialog-content";
import { useLayoutContext } from "../../layout/context";
import { WatchlistContainer } from "../container";
import { WatchlistHint } from "../hint";

type Props = {
  children: ReactNode;
  watchlistOpen: ReturnType<typeof useLayoutContext>["watchlistOpen"];
  watchlistOnChange: ReturnType<typeof useLayoutContext>["watchlistOnChange"];
  closeWatchlist: ReturnType<typeof useLayoutContext>["closeWatchlist"];
};

const WatchlistDialog: FC<Props> = ({ children, closeWatchlist, watchlistOnChange, watchlistOpen }) => {
  const { t } = useTranslation();

  const ref = useRef<any>(null);

  return (
    <>
      <Dialog open={watchlistOpen} onOpenChange={watchlistOnChange}>
        <Dialog.Trigger asChild ref={ref}>
          {children}
        </Dialog.Trigger>
        <TerminalDialogContent title={t("terminal.navigation.watchlist")}>
          <WatchlistContainer onClose={closeWatchlist} />
        </TerminalDialogContent>
      </Dialog>
      <WatchlistHint virtualRef={ref} />
    </>
  );
};

export { WatchlistDialog };
