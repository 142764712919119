import { type FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { NewButton } from "@/shared/ui";
import { useRestoreAccountMutation } from "@/state/server/accounts";

type Props = {
  accountId: string;
};

const RestoreButton: FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useRestoreAccountMutation({ successMessage: "account-restored" });

  return (
    <NewButton
      size="sm"
      pending={isLoading}
      variant="secondary"
      color="accent"
      fullWidth
      onClick={() => mutate({ id: accountId })}
    >
      {t("button.restore")}
    </NewButton>
  );
};

export { RestoreButton };
