import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { useTerminalAccountSummary } from "@/features/terminal/account-summary/context";
import { useLayoutContext } from "@/features/terminal/layout/context";
import { useOrdersContext } from "@/features/terminal/orders/context";
import { useExtendedOrdersContext } from "@/features/terminal/orders/extended-orders.context";

import { TradingTableEmptyState } from "../../ui/empty-state";
import { TradingCardsContainer } from "../cards-container";
import { OpenOrderCard } from "./card";
import { CloseAllOpenOrdersButton } from "./close-all-button";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  pnl: ReturnType<typeof useTerminalAccountSummary>["pnl"];
  swaps: ReturnType<typeof useTerminalAccountSummary>["swaps"];
  orders: ReturnType<typeof useExtendedOrdersContext>["extendedOpenOrders"];
  ordersIsLoading: ReturnType<typeof useOrdersContext>["ordersIsLoading"];
  openWatchlist: ReturnType<typeof useLayoutContext>["openWatchlist"];
  onClose?: () => void;
};

const OpenOrdersCardsContainer: FC<Props> = ({
  orders,
  accountId,
  currency,
  onClose,
  openWatchlist,
  ordersIsLoading,
  currencyDecimalScale,
  pnl,
  swaps,
}) => {
  const { t } = useTranslation();

  return (
    <TradingCardsContainer
      items={orders}
      emptyState={
        <TradingTableEmptyState
          title={t("terminal.empty-state.open-title")}
          description={t("terminal.empty-state.open-description")}
          action={
            <TradingTableEmptyState.Button
              onClick={() => {
                onClose && onClose();
                openWatchlist();
              }}
            >
              {t("terminal.go-to-watchlist")}
            </TradingTableEmptyState.Button>
          }
        />
      }
      isLoading={ordersIsLoading}
      action={
        orders.length > 1 ? (
          <CloseAllOpenOrdersButton
            accountId={accountId}
            pnl={pnl}
            ordersCount={orders.length}
            swaps={swaps}
            currency={currency}
            currencyDecimalScale={currencyDecimalScale}
          />
        ) : null
      }
    >
      {order => (
        <OpenOrderCard
          key={order.id}
          order={order}
          accountId={accountId}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    </TradingCardsContainer>
  );
};

export { OpenOrdersCardsContainer };
