import type { FC, FormEvent, ReactNode } from "react";
import { useEffect } from "react";
import { memo } from "react";
import type { SubmitHandler, UseFormReturn } from "react-hook-form";
import { FormProvider } from "react-hook-form";

import { styled } from "../../styles/stitches.config";
import type { CSS } from "../../styles/types";

const StyledForm = styled("form");

type CondtionalResetType =
  | {
      resetFormStateOnSuccess?: boolean;
      resetOnSuccess?: never;
    }
  | {
      resetFormStateOnSuccess?: never;
      resetOnSuccess?: boolean;
    };

type Props = {
  form: UseFormReturn<any>;
  children: ReactNode;
  onSubmit?: SubmitHandler<any>; // Should try to pass a function returning promise to handle isSubmitting logic for SubmitButton component
  className?: string;
  css?: CSS;
} & CondtionalResetType;

const _HookForm: FC<Props> = ({
  className,
  children,
  form,
  onSubmit,
  resetFormStateOnSuccess,
  resetOnSuccess,
  css,
}) => {
  const { formState, reset, watch } = form;
  const { isSubmitSuccessful } = formState;

  useEffect(() => {
    if (isSubmitSuccessful) {
      if (resetOnSuccess) {
        reset();
      }
      if (resetFormStateOnSuccess) {
        reset(watch());
      }
    }
  }, [isSubmitSuccessful]);

  return (
    <FormProvider {...form}>
      <StyledForm
        noValidate
        className={className}
        css={css}
        onSubmit={
          onSubmit
            ? form.handleSubmit(onSubmit)
            : (e: FormEvent) => {
                e.preventDefault();
              }
        }
      >
        {children}
      </StyledForm>
    </FormProvider>
  );
};

/**
 * @deprecated
 */
export const HookForm = memo(_HookForm);
