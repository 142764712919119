import { type FC } from "react";

import { PaymentMethodToggleGroup } from "@/features/payment/ui/method-toggle-group";
import type { PaymentMethod } from "@/services/openapi";

type Props = {
  setPaymentMethod: (method: PaymentMethod) => void;
  paymentMethods: PaymentMethod[];
  next: () => void;
};

const PaymentMethodStep: FC<Props> = ({ next, paymentMethods, setPaymentMethod }) => {
  const onValueChange = (value: string) => {
    const method = paymentMethods.find(({ title }) => title === value)!;
    setPaymentMethod(method);
    next();
  };

  return (
    <PaymentMethodToggleGroup onValueChange={onValueChange}>
      {paymentMethods.map(({ title, images }) => (
        <PaymentMethodToggleGroup.Item key={title} value={title!} title={title} images={images!} />
      ))}
    </PaymentMethodToggleGroup>
  );
};

export { PaymentMethodStep };
