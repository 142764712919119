import Big from "big.js";
import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { TooltipQuestionIcon } from "@/components/icons";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { SignalDisclaimerDialog } from "@/features/terminal/place-order/signal/disclaimer-dialog";
import { useSignalContext } from "@/features/terminal/signal/context";
import { getIsUpSignal } from "@/features/terminal/signal/helpers";
import { SignalTextFormat } from "@/features/terminal/signal/text-format";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { TerminalSymbolSignal } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Button, Dialog, Tooltip } from "@/shared/ui";
import { IconPriceChangeArrowDown, IconPriceChangeArrowUp, IconTrendSignals } from "@/shared/ui/icons";

import { WidgetBlock } from "../../../widgets/block";

type Props = {
  signal: TerminalSymbolSignal;
  priceDecimalScale: number;
  onClose?: () => void;
};

const SignalWidget: FC<Props> = ({ signal, priceDecimalScale, onClose }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  const { symbolInfo } = useCurrentSymbolContext();
  const bid = symbolInfo.priceBid;

  const { openSignalForm } = useSignalContext();

  const { priceTarget, type } = signal;

  const isPositiveSignal = getIsUpSignal(type!);

  const targetPriceDiff = useMemo(() => {
    if (!bid) return 0;

    return new Big(priceTarget!).minus(bid).div(bid).mul(100).toNumber();
  }, [priceTarget, bid]);

  return (
    <Dialog>
      <WidgetBlock
        title={t("terminal.insights.widgets.signal.title")}
        customDescription={
          <Tooltip
            open={opened}
            onOpenChange={onOpenChange}
            content={
              <Trans
                i18nKey="terminal.signal.disclaimer.info"
                components={{ button: <Dialog.Trigger onClick={close} className="underline" /> }}
              />
            }
          >
            <TooltipQuestionIcon />
          </Tooltip>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1 font-gilroy text-[16px] font-semibold leading-[1.2]">
                <span className="text-contrast-primary">
                  <NumberFormat value={priceTarget} decimalScale={priceDecimalScale} />
                </span>
                <div className={cn("flex items-center gap-0.5", getNumberColorClassname(targetPriceDiff))}>
                  {targetPriceDiff < 0 ? <IconPriceChangeArrowDown /> : <IconPriceChangeArrowUp />}
                  <span>
                    <NumberFormat value={Math.abs(targetPriceDiff)} suffix="%" decimalScale={2} />
                  </span>
                </div>
              </div>
              <div className="font-roboto text-[14px] leading-normal text-contrast-secondary">
                <SignalTextFormat hasAccentText type={type!} />
              </div>
            </div>
            <div
              className={cn("grid size-14 place-items-center rounded-full bg-surface-canvas", {
                "text-positive-text": isPositiveSignal,
                "text-negative-text": !isPositiveSignal,
              })}
            >
              <IconTrendSignals className="size-8" />
            </div>
          </div>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              openSignalForm();
              onClose && onClose();
            }}
          >
            {t("terminal.signal.trade-button")}
          </Button>
        </div>
      </WidgetBlock>
      <SignalDisclaimerDialog />
    </Dialog>
  );
};

export { SignalWidget };
