import { cva } from "class-variance-authority";
import dayjs from "dayjs";
import type { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import type { AddressInfo, ProfileInfo } from "@/services/openapi";
import { cn } from "@/shared/styles";

import { PersonalEmail, PersonalLabel, PersonalValue } from "./styles";

const listStyles = cva("flex flex-wrap gap-x-[30px] gap-y-2.5 lg:gap-x-[75px] lg:gap-y-6");

type Props = {
  email: string;
  isEmailConfirmed: boolean;
  profile: ProfileInfo;
  address: AddressInfo;
};

export const ProfileFilledContent: FC<Props> = ({ email, address, isEmailConfirmed, profile }) => {
  const { t } = useTranslation();
  return (
    <>
      <ul className={listStyles()}>
        <li>
          <PersonalEmail email={email} isEmailConfirmed={isEmailConfirmed} />
        </li>
        <li>
          <PersonalLabel>{t("profile-settings.personal.name")}</PersonalLabel>
          <PersonalValue>{profile.firstName}</PersonalValue>
        </li>
        <li>
          <PersonalLabel>{t("profile-settings.personal.surname")}</PersonalLabel>
          <PersonalValue>{profile.lastName}</PersonalValue>
        </li>
      </ul>
      <ul className={cn(listStyles(), "mt-6")}>
        <li>
          <PersonalLabel>{t("profile-settings.personal.date-of-birth")}</PersonalLabel>
          <PersonalValue>{dayjs(profile.dateOfBirth).format("DD.MM.YYYY")}</PersonalValue>
        </li>
        <li>
          <PersonalLabel>{t("profile-settings.personal.country")}</PersonalLabel>
          <PersonalValue>{address.country}</PersonalValue>
        </li>
      </ul>
    </>
  );
};
