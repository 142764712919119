/* eslint-disable @typescript-eslint/await-thenable */
import { profileService } from "../api";
import type {
  ProfileApiConfirmUserEmailRequest,
  ProfileApiRemoveFcmTokenRequest,
  ProfileApiSaveEventRequest,
  ProfileApiUpdateFcmTokenRequest,
  ProfileApiUpdateProfileSettingsRequest,
  ProfileApiUpdateUserEmailRequest,
} from "../openapi";

const updateActivity = async (values: ProfileApiSaveEventRequest) =>
  await (
    await profileService().saveEvent(values)
  ).data;

const getProfile = async () => await (await profileService().getProfile()).data;

const getSettings = async () => await (await profileService().getProfileSettings()).data;

const confirmUserProfile = async () => await (await profileService().confirmUserProfile()).data;

const updateUserEmail = async (values: ProfileApiUpdateUserEmailRequest) =>
  await (
    await profileService().updateUserEmail(values)
  ).data;

const confirmUserEmail = async (values: ProfileApiConfirmUserEmailRequest) =>
  await (
    await profileService().confirmUserEmail(values)
  ).data;

const changeUserSettings = async (values: ProfileApiUpdateProfileSettingsRequest) =>
  await (
    await profileService().updateProfileSettings(values)
  ).data;

const updateFcmToken = async (values: ProfileApiUpdateFcmTokenRequest) =>
  await (
    await profileService().updateFcmToken(values)
  ).data;

const removeFcmToken = async (values: ProfileApiRemoveFcmTokenRequest) =>
  await (
    await profileService().removeFcmToken(values)
  ).data;

const targetPriceEnable = async () => await (await profileService().targetPriceEnable()).data;

const targetPriceDisable = async () => await (await profileService().targetPriceDisable()).data;

const targetPriceDialogDisable = async () => await (await profileService().targetPriceDialogDisable()).data;

export {
  updateActivity,
  getProfile,
  getSettings,
  confirmUserProfile,
  updateUserEmail,
  confirmUserEmail,
  changeUserSettings,
  updateFcmToken,
  removeFcmToken,
  targetPriceEnable,
  targetPriceDisable,
  targetPriceDialogDisable,
};
