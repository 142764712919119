import type { KeyPrefix, TFunction } from "i18next";
import { useCallback } from "react";
import type { UseTranslationOptions } from "react-i18next";
import { Trans, useTranslation as useTranslationI18n } from "react-i18next";

type TranslationOptions = Record<string, any>;

type TranslationObject = {
  t: TFunction;
  Trans: typeof Trans;
};

const isDebugMode = import.meta.env.VITE_TRANSLATION_DEBUG_MODE === "true";

export const useTranslation = (options?: UseTranslationOptions<KeyPrefix<"translation">>): TranslationObject => {
  const { t: i18nT } = useTranslationI18n("translation", options);

  // TODO: поправить типы
  // @ts-ignore
  const t: TFunction = useCallback(
    (key: string, options?: TranslationOptions) => {
      const translate = i18nT(key, { ...options });
      return isDebugMode ? `${key}: (${translate})` : `${translate}`;
    },
    [i18nT],
  );

  return { t, Trans };
};
