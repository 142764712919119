import { useCallback } from "react";

import { useSumSubInfoQuery } from "@/state/server/kyc";

export const useSumSub = (levelName: string) => {
  const { data, refetch } = useSumSubInfoQuery(levelName);

  const expirationHandler = useCallback(async () => {
    const { data: updatedData } = await refetch();
    return updatedData?.accessToken!;
  }, [refetch]);

  return { data, expirationHandler };
};
