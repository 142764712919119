import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "@/shared/ui";

import { useLoadClosedPositions } from "../../closed-positions/load-positions.hook";
import { TradingTableEmptyState } from "../../ui/empty-state";
import { TradingCardsContainer } from "../cards-container";
import { ClosedPositionCard } from "./card";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
};

const _ClosedPositionsCardsContainer: FC<Props> = ({ accountId, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const { isLoading, hasNextPage, items, ref } = useLoadClosedPositions({ accountId });

  return (
    <TradingCardsContainer
      items={items}
      isLoading={isLoading}
      emptyState={
        <TradingTableEmptyState
          title={t("terminal.empty-state.closed-title")}
          description={t("terminal.empty-state.closed-description")}
        />
      }
      bottomSection={
        hasNextPage ? (
          <div ref={ref} className="my-6">
            <Loader />
          </div>
        ) : null
      }
    >
      {position => (
        <ClosedPositionCard
          key={position.position}
          position={position}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    </TradingCardsContainer>
  );
};

const Component = memo(_ClosedPositionsCardsContainer);

export { Component as ClosedPositionsCardsContainer };
