import type { FC } from "react";

import { Flex } from "@/components/flex";
import { PasswordField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Text } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { usePasswordRules } from "@/hooks/password-rules.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { confirmPasswordRules } from "@/utils/validators";

import { AuthBox } from "../styles";

enum ResetPasswordFields {
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
}

export interface IResetPasswordFormValues {
  [ResetPasswordFields.PASSWORD]: string;
  [ResetPasswordFields.CONFIRM_PASSWORD]: string;
}

interface Props {
  onSubmit: (values: IResetPasswordFormValues) => void;
}

export const ResetPasswordForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const form = useHookForm<IResetPasswordFormValues>();
  const { watch } = form;
  const password = watch(ResetPasswordFields.PASSWORD);

  const { isPasswordCorrect, border, passwordText, maxLength } = usePasswordRules(password);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <AuthBox css={{ mt: "16px", mb: "59px" }}>
        <Text family="roboto" align="center" css={{ mb: "16px" }}>
          {t("auth.reset-password.create-password")}
        </Text>
        <PasswordField
          name={ResetPasswordFields.PASSWORD}
          bottomOffset="3"
          placeholder={t("fields.password.create")!}
          registerOptions={{ deps: [ResetPasswordFields.CONFIRM_PASSWORD] }}
          border={border}
          passwordText={passwordText}
          maxLength={maxLength}
        />
        <PasswordField
          name={ResetPasswordFields.CONFIRM_PASSWORD}
          bottomOffset="6"
          placeholder={t("fields.password.confirm")!}
          registerOptions={confirmPasswordRules(t, password)}
          maxLength={maxLength}
        />
        <Flex css={{ justifyContent: "center" }}>
          <SubmitButton disabled={!isPasswordCorrect}>{t("auth.reset-password.save")}</SubmitButton>
        </Flex>
      </AuthBox>
    </HookForm>
  );
};
