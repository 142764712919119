import { type FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";

import { TradingTable } from "../table";
import { CloseAllPendingOrdersButton } from "./close-all-button";

type Props = {
  accountId: string;
  ordersCount: number;
  currency: string;
};

const PendingOrdersHeaderRow: FC<Props> = ({ accountId, ordersCount, currency }) => {
  const { t } = useTranslation();

  return (
    <TradingTable.Row showBorder>
      <TradingTable.Head>{t("terminal.symbol")}</TradingTable.Head>
      <TradingTable.Head>{t("common.type")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.volume-lot")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.margin-currency", { currency })}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.current-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.take-profit")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.stop-loss")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.time")}</TradingTable.Head>
      <TradingTable.Head align="end">
        {ordersCount > 1 && <CloseAllPendingOrdersButton accountId={accountId} ordersCount={ordersCount} />}
      </TradingTable.Head>
    </TradingTable.Row>
  );
};

export { PendingOrdersHeaderRow };
