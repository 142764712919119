import type { FC } from "react";
import { useCallback } from "react";

import { useCopy } from "@/hooks/copy.hook";
import { useTranslation } from "@/hooks/translator.hook";

import { Button } from "../button";
import { CopyIcon } from "../icons";

type Props = {
  copyValue: string;
};

export const CopyButton: FC<Props> = ({ copyValue }) => {
  const { t } = useTranslation();
  const { copy } = useCopy();
  const handleCopy = useCallback(() => copy(copyValue), [copyValue, copy]);

  return (
    <Button onClick={handleCopy} leftIcon={<CopyIcon />} size="small" variant="secondary">
      {t("button.copy")}
    </Button>
  );
};
