import { type FC } from "react";

import { AccountSelect } from "./account-select/account-select";
import { DepositButtonContainer } from "./deposit/container";
import { TerminalContainer } from "./terminal.container";
import { MobileTradingTablesContainer } from "./trading-tables/mobile/container";

const TerminalPositionsPage: FC = () => {
  return (
    <TerminalContainer simpleLoader>
      <div className="flex h-full flex-col overflow-y-auto bg-surface-canvas p-4">
        <div className="mb-2 flex items-center justify-between gap-2">
          <AccountSelect />
          <DepositButtonContainer />
        </div>
        <div className="flex h-full flex-col overflow-y-auto scrollbar-hide">
          <MobileTradingTablesContainer />
        </div>
      </div>
    </TerminalContainer>
  );
};

TerminalPositionsPage.displayName = "TerminalPositionsPage";

export default TerminalPositionsPage;
