import type { TFunction } from "i18next";
import type { FC } from "react";
import { memo } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { cn } from "@/shared/styles";

const getTypeColor = (type: TerminalDealType) => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
    case TerminalDealType.BuyStopLimit:
      return "positive";
    default:
      return "negative";
  }
};

const getOrderTypeText = (t: TFunction, type: TerminalDealType) => {
  switch (type) {
    case TerminalDealType.Buy:
      return t("terminal.types.buy");
    case TerminalDealType.BuyLimit:
      return t("terminal.types.buy-limit");
    case TerminalDealType.BuyStop:
      return t("terminal.types.buy-stop");
    case TerminalDealType.BuyStopLimit:
      return t("terminal.types.buy-stop-limit");
    case TerminalDealType.Sell:
      return t("terminal.types.sell");
    case TerminalDealType.SellLimit:
      return t("terminal.types.sell-limit");
    case TerminalDealType.SellStop:
      return t("terminal.types.sell-stop");
    case TerminalDealType.SellStopLimit:
      return t("terminal.types.sell-stop-limit");
  }
};

type Props = {
  type: TerminalDealType;
  withCircle?: boolean;
};

const _OrderType: FC<Props> = ({ type, withCircle = true }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-2">
      {withCircle && (
        <div
          className={cn(
            "size-2 rounded-full",
            getTypeColor(type) === "positive" ? "bg-positive-text" : "bg-negative-text",
          )}
        />
      )}
      {getOrderTypeText(t, type)}
    </div>
  );
};

export { getOrderTypeText, getTypeColor };

export const OrderType = memo(_OrderType);
