import type { FC } from "react";
import { useCallback, useState } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { Button } from "@/components/button";
import { ActionIcon } from "@/components/icons";
import { Link } from "@/components/link";
import { Leverage } from "@/entities/accounts/leverage";
import { TradeButtonDialogContent } from "@/features/dashboard/components/trade-button/trade-button-dialog-content";
import { useToPageState } from "@/hooks/to-page-state.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { getWithdrawalRoute } from "@/routes/cabinet.routes";
import { type TradingAccount, TradingAccountType, TradingServerPlatform } from "@/services/openapi";
import { Dialog, DropdownMenu } from "@/shared/ui";

import { SetBalanceDialogContent } from "../set-balance-button/dialog-content";
import { ArchiveAccountDialog } from "./archive-account/archive-account-dialog";
import { ChangeAccountPasswordDialog } from "./change-account-password/change-account-password.dialog";
import { LeverageDialog } from "./leverage/leverage-dialog";

enum SettingsAction {
  NONE = "none",
  ADD_FUNDS = "addFunds",
  LEVERAGE = "leverage",
  NAME = "name",
  PASSWORD = "password",
  ARCHIVE = "archive",
  LOGIN = "login",
}

type Props = {
  account: TradingAccount;
};

export const AccountSettingsButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();
  const { toPageState } = useToPageState("account");

  const { actions, type, login, leverage, platform } = account;
  const { canArchive, canChangeLeverage, canChangePwd, canMakeDemoDeposit } = actions!;

  const [dialog, setDialog] = useState<SettingsAction>(SettingsAction.NONE);

  const closeDialog = useCallback(() => setDialog(SettingsAction.NONE), []);

  return (
    <Dialog open={dialog !== SettingsAction.NONE} onOpenChange={closeDialog}>
      <DropdownMenu modal={false}>
        <DropdownMenu.Trigger asChild>
          <Button
            size="round"
            variant="secondary"
            onClick={() => track(amplitudeEvents.myAccounts.accountOptionsClick)}
            data-test="account-settings-btn"
          >
            <ActionIcon />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Label>{t("accounts.id.menu-title", { login })}</DropdownMenu.Label>
          {type === TradingAccountType.Real && (
            <DropdownMenu.Item data-test="account-settings-withdrawal" asChild>
              <Link to={getWithdrawalRoute(account.id!)}>{t("accounts.withdrawal.menu-title")}</Link>
            </DropdownMenu.Item>
          )}
          {platform === TradingServerPlatform.MetaTrader5 && (
            <DropdownMenu.Item onSelect={() => setDialog(SettingsAction.LOGIN)} data-test="account-settings-login">
              {t("accounts.mt5-login")}
            </DropdownMenu.Item>
          )}
          {canMakeDemoDeposit && (
            <DropdownMenu.Item
              data-test="account-settings-set-balance"
              onSelect={() => setDialog(SettingsAction.ADD_FUNDS)}
            >
              {t("accounts.demo.add-balance.menu-title")}
            </DropdownMenu.Item>
          )}
          {canChangeLeverage && (
            <DropdownMenu.Item
              data-test="account-settings-leverage"
              onSelect={() => setDialog(SettingsAction.LEVERAGE)}
              subText={<Leverage leverage={leverage!} />}
            >
              {t("accounts.leverage.menu-title")}
            </DropdownMenu.Item>
          )}
          {canChangePwd && (
            <DropdownMenu.Item
              data-test="account-settings-password"
              onSelect={() => setDialog(SettingsAction.PASSWORD)}
            >
              {t("accounts.change-password.menu-title")}
            </DropdownMenu.Item>
          )}
          {canArchive && (
            <DropdownMenu.Item data-test="account-settings-archive" onSelect={() => setDialog(SettingsAction.ARCHIVE)}>
              {t("accounts.archive.menu-title")}
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu>
      {dialog === SettingsAction.ADD_FUNDS && (
        <SetBalanceDialogContent accountCurrency={account.currency!} accountId={account.id!} onClose={closeDialog} />
      )}
      {dialog === SettingsAction.LEVERAGE && <LeverageDialog account={account} onClose={closeDialog} />}
      {dialog === SettingsAction.LOGIN && <TradeButtonDialogContent account={account} />}
      {dialog === SettingsAction.PASSWORD && <ChangeAccountPasswordDialog account={account} onClose={closeDialog} />}
      {dialog === SettingsAction.ARCHIVE && <ArchiveAccountDialog account={account} onClose={closeDialog} />}
    </Dialog>
  );
};
