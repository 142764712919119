export const CopyIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0H12C13.1523 0 14 0.847715 14 2V8C14 9.15228 13.1523 10 12 10H10V12C10 13.1523 9.15228 14 8 14H2C0.847715 14 0 13.1523 0 12V6C0 4.84772 0.847715 4 2 4H4V2C4 0.847715 4.84772 0 6 0ZM4 6H2V12H8V10H6C4.84772 10 4 9.15228 4 8V6ZM6 2V8H12V2H6Z"
      fill="#212121"
    />
  </svg>
);
