import type { FC } from "react";
import { useCallback } from "react";

import { Alert } from "@/components/alert";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import type { TwoFactorRecoveryCode } from "@/services/openapi";
import { useCreateNewRecoveryCodesMutation } from "@/state/server/auth";

import { TwoFASteps } from "../two-factor.container";
import { type INewRecoveryCodesFormValues, NewRecoveryCodesForm } from "./new-recovery-codes.form";

type Props = {
  setStep: (step: TwoFASteps) => void;
  setRecoveryCodes: (codes: TwoFactorRecoveryCode[]) => void;
};
export const NewRecoveryCodesContainer: FC<Props> = ({ setRecoveryCodes, setStep }) => {
  const { t } = useTranslation();

  const { mutateAsync: createRecoveryCodes } = useCreateNewRecoveryCodesMutation();

  const handleSubmit = useCallback(
    (values: INewRecoveryCodesFormValues) => {
      return createRecoveryCodes(
        { passwordRequest: values },
        {
          onSuccess: res => {
            setRecoveryCodes(res.items!);
            setStep(TwoFASteps.RECOVERY_CODES);
          },
        },
      );
    },
    [createRecoveryCodes, setRecoveryCodes, setStep],
  );

  return (
    <>
      <Stack>
        <Text weight="bold" size="3">
          {t("two-factor.settings.action-generate")}
        </Text>
        <Alert>{t("two-factor.settings.new-recovery-alert")}</Alert>
        <NewRecoveryCodesForm onSubmit={handleSubmit} />
      </Stack>
    </>
  );
};
