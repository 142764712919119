import { useSyncExternalStore } from "react";

const store = {
  getState: () => {
    return navigator.onLine;
  },
  subscribe: (callback: () => void) => {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
      window.removeEventListener("online", callback);
      window.removeEventListener("offline", callback);
    };
  },
};

export const useOnline = () => {
  return useSyncExternalStore(store.subscribe, store.getState);
};
