import { type FC, Fragment, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useInfiniteTradingCentralNewsQuery } from "@/state/server/terminal";

import { InsightsLoader } from "../ui/loader";
import { WidgetBlock } from "../widgets/block";
import { Article } from "./article";

type Props = {
  symbol: string;
};

const _NewsContainer: FC<Props> = ({ symbol }) => {
  const { t } = useTranslation();

  const { ref, inView } = useInView();

  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading } = useInfiniteTradingCentralNewsQuery({
    pageSize: 20,
    symbol,
  });

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage]);

  return (
    <WidgetBlock hasScrollButton title={t("terminal.insights.widgets.news-feed.title")}>
      {isLoading && <InsightsLoader />}
      <div className="flex flex-col gap-10">
        {data &&
          data.pages.map((page, i) => (
            <Fragment key={i}>
              {page.items!.map(article => (
                <Article key={article.id} article={article} />
              ))}
            </Fragment>
          ))}
      </div>
      {hasNextPage ? <InsightsLoader ref={ref} /> : null}
    </WidgetBlock>
  );
};

const Component = memo(_NewsContainer);

export { Component as NewsContainer };
