import { Skeleton } from "@/shared/ui";

const DesktopTradingTablesSkeleton = () => (
  <div className="grid grid-cols-[1fr_auto_1fr] items-center bg-surface-canvas px-4 py-2.5">
    <Skeleton className="h-[22px] w-[100px] rounded-[8px]" />
    <Skeleton className="h-9 w-[494px] rounded-[36px]" />
    <Skeleton className="ms-auto size-6 rounded-[8px]" />
  </div>
);

export { DesktopTradingTablesSkeleton };
