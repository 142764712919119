import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/shared/styles";
import { Tabs } from "@/shared/ui";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { InsightsContainer } from "../../insights/container";
import { useLayoutContext } from "../../layout/context";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";
import { TradingUnavailableBlock } from "../trading-unavailable/block";

const tabContentStyles = cva(
  "h-full overflow-auto pt-4 scrollbar-hide data-[state=active]:flex data-[state=active]:flex-col",
)();

enum PlaceOrderTab {
  TRADE = "trade",
  INSIGHTS = "insights",
}

const PlaceOrderTabs: FC = () => {
  const { t } = useTranslation();

  const { insightsSection } = useLayoutContext();

  const { isTradingAvailable } = useCurrentSymbolContext();

  return (
    <Tabs
      defaultValue={insightsSection === "none" ? PlaceOrderTab.TRADE : PlaceOrderTab.INSIGHTS}
      className="flex grow flex-col overflow-auto bg-surface-canvas pt-2"
    >
      <Tabs.List grow className="mx-4">
        <Tabs.Trigger value={PlaceOrderTab.TRADE}>{t("terminal.place-order.tabs.trade")}</Tabs.Trigger>
        <Tabs.Trigger value={PlaceOrderTab.INSIGHTS}>{t("terminal.place-order.tabs.insights")}</Tabs.Trigger>
      </Tabs.List>
      {isTradingAvailable ? (
        <TerminalPlaceOrderProvider>
          <Tabs.Content className={tabContentStyles} value={PlaceOrderTab.TRADE}>
            <PlaceOrderContainer />
          </Tabs.Content>
        </TerminalPlaceOrderProvider>
      ) : (
        <Tabs.Content className={tabContentStyles} value={PlaceOrderTab.TRADE}>
          <TradingUnavailableBlock />
        </Tabs.Content>
      )}
      <Tabs.Content className={cn(tabContentStyles, "px-4 pb-4")} value={PlaceOrderTab.INSIGHTS}>
        <InsightsContainer />
      </Tabs.Content>
    </Tabs>
  );
};

export { PlaceOrderTabs };
