import type { FC } from "react";
import { memo, useMemo } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { getDecimalScaleFromTickSize } from "@/features/terminal/helpers/formatting";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { useTranslation } from "@/hooks/translator.hook";
import type { TerminalPosition } from "@/services/openapi";

import { ClosedPositionVolume } from "../../closed-positions/volume";
import { OrderType } from "../../components/trading-table/order-type";
import { TradingCard } from "../card";

type Props = {
  position: TerminalPosition;
  currency: string;
  currencyDecimalScale: number;
};

const _ClosedPositionCard: FC<Props> = ({ position, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    stopLoss,
    takeProfit,
    swap,
    profit,
    dateOpen,
    volumeSell,
    volumeBuy,
    priceOpen,
    dateClose,
    priceClose,
    position: positionNumber,
    symbolDigits,
    symbolVolumeStep,
  } = position;

  const volumeDecimalScale = useMemo(() => getDecimalScaleFromTickSize(symbolVolumeStep!), [symbolVolumeStep]);

  return (
    <TradingCard
      header={
        <TradingCard.Header
          symbol={symbol!}
          type={type!}
          volume={
            <ClosedPositionVolume
              decimalScale={volumeDecimalScale}
              type={type!}
              volumeBuy={volumeBuy}
              volumeSell={volumeSell}
            />
          }
          endBottomSection={terminalFormatDate(dateClose!)}
          endTopSection={
            <TradingCard.PnlText className={getNumberColorClassname(profit!)}>
              <PnlFormat value={profit} decimalScale={currencyDecimalScale} currency={currency} />
            </TradingCard.PnlText>
          }
        />
      }
      body={
        <TradingCard.Body>
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("common.type")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <OrderType type={type!} withCircle={false} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.volume-lot")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <ClosedPositionVolume
                  decimalScale={volumeDecimalScale}
                  type={type!}
                  volumeBuy={volumeBuy}
                  volumeSell={volumeSell}
                />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.open-time")}</TradingCard.ListLabel>}
            end={<TradingCard.ListValue>{terminalFormatDate(dateOpen!)}</TradingCard.ListValue>}
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.open-price")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <NumberFormat value={priceOpen} decimalScale={symbolDigits} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.stop-loss")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                {stopLoss ? <NumberFormat value={stopLoss} decimalScale={symbolDigits} /> : "—"}
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.take-profit")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                {takeProfit ? <NumberFormat value={takeProfit} decimalScale={symbolDigits} /> : "—"}
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.close-time")}</TradingCard.ListLabel>}
            end={<TradingCard.ListValue>{terminalFormatDate(dateClose!)}</TradingCard.ListValue>}
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.close-price")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <NumberFormat value={priceClose} decimalScale={symbolDigits} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.order")}</TradingCard.ListLabel>}
            end={<TradingCard.ListValue>{positionNumber}</TradingCard.ListValue>}
          />
          <TradingCard.ListRow
            start={
              <TradingCard.ListLabel>
                {t("terminal.swap")}, {currency}
              </TradingCard.ListLabel>
            }
            end={
              <TradingCard.ListValue>
                <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.pnl-currency", { currency: currency })}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue className={getNumberColorClassname(profit!)}>
                <PnlFormat value={profit} decimalScale={currencyDecimalScale} />
              </TradingCard.ListValue>
            }
          />
        </TradingCard.Body>
      }
    />
  );
};

const Component = memo(_ClosedPositionCard);

export { Component as ClosedPositionCard };
