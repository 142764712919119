import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { Slottable } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { popoverContentStyles } from "../popper";

const Content = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 8, collisionPadding = 10, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      collisionPadding={collisionPadding}
      className={cn(popoverContentStyles(), "w-60 overflow-hidden rounded-[24px] bg-bg py-2 shadow", className)}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
Content.displayName = DropdownMenuPrimitive.Content.displayName;

const textStyles = cva("font-roboto text-[16px] leading-normal text-text", {
  variants: {
    dimmed: {
      true: "text-text-secondary",
    },
  },
});

const Item = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Item>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    subText?: ReactNode;
  }
>(({ className, children, subText, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      textStyles(),
      "flex cursor-pointer select-none items-center justify-between p-4 outline-none transition-colors data-[disabled]:pointer-events-none data-[highlighted]:bg-bg-selected data-[disabled]:opacity-50",
      className,
    )}
    {...props}
  >
    <Slottable>{children}</Slottable>
    {subText && <div className={textStyles({ dimmed: true })}>{subText}</div>}
  </DropdownMenuPrimitive.Item>
));
Item.displayName = DropdownMenuPrimitive.Item.displayName;

const Label = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Label>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn(textStyles({ dimmed: true }), "bg-bg-back px-4 py-3", className)}
    {...props}
  />
));
Label.displayName = DropdownMenuPrimitive.Label.displayName;

const Component = Object.assign(DropdownMenuPrimitive.Root, {
  Trigger: DropdownMenuPrimitive.Trigger,
  Content,
  Item,
  Label,
});

export { Component as DropdownMenu };
