import { getKycInfo, getSumSubAccessTokenToken } from "@/services/kyc";
import type { ApiResponseType } from "@/services/types";

import { useBaseQuery } from "..";
import type { QueryOptionsType } from "../types";
import { kycQueryKeys } from "./kyc.keys";

export const useKycInfoQuery = (options?: QueryOptionsType<ApiResponseType<typeof getKycInfo>>) => {
  return useBaseQuery<ApiResponseType<typeof getKycInfo>>({
    queryKey: kycQueryKeys.info(),
    queryFn: getKycInfo,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useSumSubInfoQuery = (
  levelName: string,
  options?: QueryOptionsType<ApiResponseType<typeof getSumSubAccessTokenToken>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getSumSubAccessTokenToken>>({
    queryKey: kycQueryKeys.sumsub(levelName),
    queryFn: () => getSumSubAccessTokenToken({ levelName }),
    ...options,
  });
};
