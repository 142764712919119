import { useEffect, useMemo } from "react";

import { SelectField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { Table } from "@/components/table";
import { Tab, Tabs } from "@/components/tabs";
import { useHookForm } from "@/hooks/form.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { FundsTxType } from "@/services/openapi";
import { useTransactionsQuery } from "@/state/server/funds";

import { NoTransactionsMessage } from "./no-transactions-message";
import { TransactionGrid } from "./transaction-grid";
import { TransactionHeaderRow } from "./transaction-header-row";
import { TransactionTableRow } from "./transaction-table-row";
import { useTransactionsTableFilters } from "./transactions-table.helpers";

interface TransactionType {
  value: FundsTxType;
  label: string;
}
interface TransactionSelectFormType {
  type: FundsTxType;
}

export const TransactionsTableContainer = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const form = useHookForm<TransactionSelectFormType>({
    defaultValues: {
      type: FundsTxType.All,
    },
  });

  const { changePage, pageSize, currentPage, type, updateTypeFilter } = useTransactionsTableFilters();
  const { data } = useTransactionsQuery({ pageSize, page: currentPage, type });

  const transactionTypeOptions = useMemo<TransactionType[]>(
    () => [
      {
        value: FundsTxType.All,
        label: t("transactions.filters.all"),
      },
      {
        value: FundsTxType.Withdraw,
        label: t("transactions.filters.withdrawal"),
      },
      {
        value: FundsTxType.Deposit,
        label: t("transactions.filters.deposit"),
      },
      {
        value: FundsTxType.Converting,
        label: t("transactions.filters.transfer"),
      },
    ],
    [t],
  );

  useEffect(() => {
    const subscription = form.watch(value => {
      if (value.type) {
        updateTypeFilter(value?.type);
      }
    });
    return () => subscription.unsubscribe();
  }, [form, updateTypeFilter]);

  return (
    <>
      {isMobile ? (
        <HookForm form={form} onSubmit={() => {}}>
          <SelectField name="type" defaultValue={FundsTxType.All} options={transactionTypeOptions} />
        </HookForm>
      ) : (
        <Tabs value={type} onChange={updateTypeFilter} variant="tile">
          <Tab value={FundsTxType.All} label={t("transactions.filters.all")} />
          <Tab value={FundsTxType.Withdraw} label={t("transactions.filters.withdrawal")} />
          <Tab value={FundsTxType.Deposit} label={t("transactions.filters.deposit")} />
          <Tab value={FundsTxType.Converting} label={t("transactions.filters.transfer")} />
        </Tabs>
      )}

      {isMobile && !data?.items?.length && <NoTransactionsMessage />}

      {data && (
        <>
          {isMobile ? (
            <TransactionGrid items={data.items!} />
          ) : (
            <Table
              items={data.items!}
              renderHeader={() => <TransactionHeaderRow />}
              renderBodyRow={transaction => <TransactionTableRow key={transaction.id} transaction={transaction} />}
              emptyMessage={<NoTransactionsMessage />}
              changePage={changePage}
              currentPage={currentPage}
              pageSize={pageSize}
              total={data.total!}
            />
          )}
        </>
      )}
    </>
  );
};
