import { Link as RouterLink } from "react-router-dom";

import { css, styled } from "@/styles/stitches.config";

export const linkStyles = css({
  cursor: "pointer",

  variants: {
    color: {
      noColor: {
        color: "inherit",
      },
      white: {
        color: "$white",
      },
      primary: {
        color: "$primary",
      },
    },
  },

  defaultVariants: {
    color: "noColor",
  },
});

export const Link = styled(RouterLink, linkStyles);
