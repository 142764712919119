import * as SwitchPrimitive from "@radix-ui/react-switch";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const rootStyles = cva(
  "inline-flex items-center rounded-[32px] bg-contrast-quaternary outline-none transition-colors hover:bg-contrast-quinary data-[state=checked]:bg-positive-bg data-[state=checked]:hover:bg-positive-bg-hover",
  {
    variants: {
      size: {
        sm: "h-5 w-8",
        md: "h-7 w-[44px]",
      },
    },
  },
);

const thumbStyles = cva("block rounded-full bg-accent-over transition-transform", {
  variants: {
    size: {
      sm: "size-4 translate-x-0.5 data-[state=checked]:translate-x-[14px]",
      md: "size-6 translate-x-0.5 data-[state=checked]:translate-x-[18px]",
    },
  },
});

type Props = ComponentPropsWithoutRef<typeof SwitchPrimitive.Root> & VariantProps<typeof rootStyles>;

const Switch = forwardRef<React.ElementRef<typeof SwitchPrimitive.Root>, Props>(
  ({ className, size = "md", ...props }, ref) => (
    <SwitchPrimitive.Root className={cn(rootStyles({ size }), className)} {...props} ref={ref}>
      <SwitchPrimitive.Thumb
        // FIXME:
        style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.04)" }}
        className={thumbStyles({ size })}
      />
    </SwitchPrimitive.Root>
  ),
);

export { Switch };
