import { useQueryClient } from "react-query";

import { transfer } from "@/services/funds";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { bonusesQueryKeys } from "@/state/server/bonuses";

import { useBaseMutation } from "..";
import { accountsQueryKeys } from "../accounts";
import type { MutationOptionsType } from "../types";

export const useTransferMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof transfer>, ApiRequestParamsType<typeof transfer>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof transfer>, ApiRequestParamsType<typeof transfer>>(transfer, {
    onSuccess: () => {
      client.invalidateQueries(accountsQueryKeys.base);
      client.invalidateQueries(bonusesQueryKeys.base);
    },
    ...options,
  });
};
