import { type FC, memo } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { TerminalBackDialogContent } from "@/features/terminal/components/terminal-back-dialog-content";
import type { OpenOrder } from "@/features/terminal/orders/extended-orders.context";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { useTranslation } from "@/hooks/translator.hook";

import { OrderType } from "../../components/trading-table/order-type";
import { PartialCloseContainer } from "../../modify-order/partial-close/partial-close.container";
import { ModifyOpenOrderStopLossContainer } from "../../modify-order/stop-loss/open-order-stop-loss.container";
import { ModifyOpenOrderTakeProfitContainer } from "../../modify-order/take-profit/open-order-take-profit.container";
import { TradingCard } from "../card";
import { ModifyOrderButton } from "../modify-order/button";
import { CloseOpenOrderButton } from "./close-button";

type Props = {
  order: OpenOrder;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
};

const _OpenOrderCard: FC<Props> = ({ order, accountId, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    swap,
    contractSize,
    priceDecimalScale,
    volumeStep,
    volumeMin,
    volumeDecimalScale,
    baseCurrency,
    quoteCurrency,
    ask,
    bid,
    currentPrice,
    pnl,
    margin,
    calculatedProfitRate,
  } = order;

  return (
    <TradingCard
      header={
        <TradingCard.Header
          type={type}
          volume={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}
          endBottomSection={
            <>{currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}</>
          }
          symbol={symbol}
          endTopSection={
            <TradingCard.PnlText className={getNumberColorClassname(pnl)}>
              <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
            </TradingCard.PnlText>
          }
        />
      }
      body={
        <TradingCard.Body>
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("common.type")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <OrderType type={type} withCircle={false} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.volume-lot")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue filled>
                <ModifyOrderButton value={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}>
                  {onClose => (
                    <TerminalBackDialogContent title={t("terminal.modify-order.partial-close")!}>
                      <PartialCloseContainer
                        onClose={onClose}
                        volume={volume}
                        volumeStep={volumeStep}
                        volumeMin={volumeMin}
                        volumeDecimalScale={volumeDecimalScale}
                        currentPrice={currentPrice!}
                        orderType={type}
                        orderId={id}
                        contractSize={contractSize}
                        baseCurrency={baseCurrency}
                        quoteCurrency={quoteCurrency}
                        accountId={accountId}
                        currency={currency}
                        openPrice={price}
                        symbol={symbol}
                        priceDecimalScale={priceDecimalScale}
                        swap={swap}
                        currencyDecimalScale={currencyDecimalScale}
                        profitRate={calculatedProfitRate}
                        isMobile
                      />
                    </TerminalBackDialogContent>
                  )}
                </ModifyOrderButton>
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.margin-currency", { currency })}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.open-price")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                <NumberFormat value={price} decimalScale={priceDecimalScale} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.current-price")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue>
                {currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.take-profit")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue filled>
                <ModifyOrderButton
                  value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={priceDecimalScale} /> : null}
                >
                  {onClose => (
                    <TerminalBackDialogContent title={t("terminal.take-profit")!}>
                      <ModifyOpenOrderTakeProfitContainer
                        onClose={onClose}
                        symbol={symbol}
                        volume={volume}
                        orderType={type}
                        orderId={id}
                        priceDecimalScale={priceDecimalScale}
                        stopLoss={stopLoss}
                        takeProfit={takeProfit}
                        ask={ask}
                        bid={bid}
                        accountId={accountId}
                        baseCurrency={baseCurrency}
                        quoteCurrency={quoteCurrency}
                        contractSize={contractSize}
                        currency={currency}
                        openPrice={price}
                        currentPrice={currentPrice!}
                        currencyDecimalScale={currencyDecimalScale}
                        profitRate={calculatedProfitRate}
                        isMobile
                      />
                    </TerminalBackDialogContent>
                  )}
                </ModifyOrderButton>
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.stop-loss")}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue filled>
                <ModifyOrderButton
                  value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={priceDecimalScale} /> : null}
                >
                  {onClose => (
                    <TerminalBackDialogContent title={t("terminal.stop-loss")!}>
                      <ModifyOpenOrderStopLossContainer
                        onClose={onClose}
                        symbol={symbol}
                        volume={volume}
                        orderType={type}
                        orderId={id}
                        priceDecimalScale={priceDecimalScale}
                        stopLoss={stopLoss}
                        takeProfit={takeProfit}
                        ask={ask}
                        bid={bid}
                        accountId={accountId}
                        baseCurrency={baseCurrency}
                        quoteCurrency={quoteCurrency}
                        contractSize={contractSize}
                        currency={currency}
                        openPrice={price}
                        currentPrice={currentPrice!}
                        currencyDecimalScale={currencyDecimalScale}
                        profitRate={calculatedProfitRate}
                        isMobile
                      />
                    </TerminalBackDialogContent>
                  )}
                </ModifyOrderButton>
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.position")}</TradingCard.ListLabel>}
            end={<TradingCard.ListValue>{id}</TradingCard.ListValue>}
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.open-time")}</TradingCard.ListLabel>}
            end={<TradingCard.ListValue>{terminalFormatDate(date)}</TradingCard.ListValue>}
          />
          <TradingCard.ListRow
            start={
              <TradingCard.ListLabel>
                {t("terminal.swap")}, {currency}
              </TradingCard.ListLabel>
            }
            end={
              <TradingCard.ListValue>
                <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
              </TradingCard.ListValue>
            }
          />
          <TradingCard.ListRow
            start={<TradingCard.ListLabel>{t("terminal.pnl-currency", { currency: currency })}</TradingCard.ListLabel>}
            end={
              <TradingCard.ListValue className={getNumberColorClassname(pnl)}>
                <PnlFormat value={pnl} decimalScale={currencyDecimalScale} />
              </TradingCard.ListValue>
            }
          />
        </TradingCard.Body>
      }
      footer={<CloseOpenOrderButton orderId={id} accountId={accountId} />}
    />
  );
};

const Component = memo(_OpenOrderCard);

export { Component as OpenOrderCard };
