import { type FC } from "react";

type Props = {
  leverage: number;
};

const Leverage: FC<Props> = ({ leverage }) => {
  return <>{leverage}x</>;
};

export { Leverage };
