import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { CaptchaType } from "@/services/openapi";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

const captchaId = "googleCaptcha";

export const CaptchaProvider = ({ children }: { children: React.ReactElement }) => {
  const platform = usePlatformData();

  if (!platform.captcha!.types!.includes(CaptchaType.GoogleReCaptchaV3)) {
    return children;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={platform.captcha!.googleReCaptchaV3Info!.key!}
      container={{ parameters: { badge: "bottomleft" }, element: captchaId }}
    >
      <div id={captchaId}></div>
      {children}
    </GoogleReCaptchaProvider>
  );
};
