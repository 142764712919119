import type { FC } from "react";
import { memo } from "react";
import { Link } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes, getDepositRoute } from "@/routes/cabinet.routes";
import { Button } from "@/shared/ui";

import { useLayoutContext } from "../layout/context";

type Props = {
  accountId: string;
  isMobile: ReturnType<typeof useLayoutContext>["isMobile"];
  isMobileAppMode: ReturnType<typeof useLayoutContext>["isMobileAppMode"];
};

const _DepositButton: FC<Props> = ({ accountId, isMobile, isMobileAppMode }) => {
  const { t } = useTranslation();

  return (
    <Button onClick={() => track(amplitudeEvents.deposit.fromTerminal)} variant="secondary" size="sm" asChild>
      {isMobile && isMobileAppMode ? (
        <a href={cabinetRoutes.deposit}>{t("button.deposit")}</a>
      ) : (
        <Link to={getDepositRoute(accountId)}>{t("button.deposit")}</Link>
      )}
    </Button>
  );
};

const Component = memo(_DepositButton);

export { Component as DepositButton };
