import { useCallback } from "react";

import { usePagination } from "@/hooks/pagination.hook";
import { useSearch } from "@/hooks/search.hook";

const ITEMS_COUNT = 7;

// TODO: find better way to handling this logic
export const useNotificationsTableFilters = () => {
  const { search, setSearch, mask } = useSearch();

  const pagination = usePagination({ pageSize: ITEMS_COUNT });
  const { resetPagination } = pagination;

  const updateSearch = useCallback(
    (search: string) => {
      resetPagination();
      setSearch(search);
    },
    [resetPagination],
  );

  return { search, updateSearch, mask, ...pagination };
};
