import dayjs from "dayjs";
import { useState } from "react";

import { BalanceFormat } from "@/components/balance-format";
import { DialogContent } from "@/components/dialog";
import { Flex } from "@/components/flex";
import { BankCardIcon, TransactionTransferIcon } from "@/components/icons";
import { TableCell, TableRow } from "@/components/table";
import type { FundsTx, FundsTxAmounts } from "@/services/openapi";
import { Dialog } from "@/shared/ui";

import { TransactionDialogContent } from "../transaction-dialog-content";
import { TransactionStatusBadge } from "../transaction-status-badge";

export const getTransactionTypeIcon = (type: string) => {
  switch (type) {
    case "Transfer":
      return (
        <Flex css={{ color: "$textMain" }}>
          <TransactionTransferIcon />
        </Flex>
      );
    case "BankCard":
      return <BankCardIcon />;
    default:
      return null;
  }
};

export const getTransactionAmount = ({ first, second }: FundsTxAmounts, styled?: boolean) => {
  if (!second) {
    return <BalanceFormat value={first!.amount!} currency={first!.currency!} styled={styled} />;
  }
  return (
    <Flex css={{ gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
      <BalanceFormat value={first!.amount!} currency={first!.currency!} styled={styled} />
      →
      <BalanceFormat value={second.amount!} currency={second.currency!} styled={styled} />
    </Flex>
  );
};

export const TransactionTableRow = ({ transaction }: { transaction: FundsTx }) => {
  const { date, description, amounts, type, number, status, typeTitle } = transaction;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow hoverable onClick={() => setOpen(true)}>
        <TableCell>{dayjs(date).format("DD.MM.YYYY HH:mm")}</TableCell>
        <TableCell wide>{description}</TableCell>
        <TableCell align="end">{getTransactionAmount(amounts!)}</TableCell>
        <TableCell>
          <Flex css={{ alignItems: "center", gap: "8px" }}>
            {getTransactionTypeIcon(type!)}
            {typeTitle}
          </Flex>
        </TableCell>
        <TableCell>{number}</TableCell>
        <TableCell>
          <TransactionStatusBadge status={status!} />
        </TableCell>
      </TableRow>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent size="sm">
          <TransactionDialogContent transaction={transaction} />
        </DialogContent>
      </Dialog>
    </>
  );
};
