import { atom, onSet } from "nanostores";

const $serverConnectionStatus = atom<"idle" | "failed" | "restored">("idle");

let timeoutId: ReturnType<typeof setTimeout> | null = null;

$serverConnectionStatus.listen(newValue => {
  timeoutId && clearTimeout(timeoutId);
  if (newValue === "restored") {
    timeoutId = setTimeout(() => $serverConnectionStatus.set("idle"), 900);
  }
});

onSet($serverConnectionStatus, ({ newValue, abort }) => {
  const oldValue = $serverConnectionStatus.get();
  if (newValue === "restored" && oldValue !== "failed") {
    abort();
  }
});

export { $serverConnectionStatus };
