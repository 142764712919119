import { useState } from "react";

export const useCookie = () => {
  const [cookieValue, setCookieValue] = useState(document.cookie);

  const setCookie = (name: string, value: string, options: any = {}) => {
    options = {
      path: "/",
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (const optionKey in options) {
      updatedCookie += "; " + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
    setCookieValue(updatedCookie);
  };

  const getCookie = (name: string): string | undefined => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName!.trim() === name) {
        return cookieValue;
      }
    }
    return;
  };

  return [cookieValue, setCookie, getCookie] as const;
};
