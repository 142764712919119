import { type FC } from "react";
import { ScrollRestoration, useNavigate, useSearchParams } from "react-router-dom";

import { CabinetBack } from "@/features/cabinet/components/cabinet-back";
import { CreateAccountContainer } from "@/features/dashboard/components/create-account/create-account.container";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountType } from "@/services/openapi";
import { useServersQuery } from "@/state/server/accounts";

export enum DashboardState {
  DASHBOARD = "dashboard",
  CREATE = "create",
}

export const CreateAccountPage: FC = () => {
  const { t } = useTranslation();
  const { data: servers } = useServersQuery();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const accountType = (searchParams.get("type") || TradingAccountType.Real) as TradingAccountType;

  if (!servers) return null;

  return (
    <>
      <ScrollRestoration />
      <CabinetBack text={t("cabinet.new-account")} onClick={() => navigate(cabinetRoutes.dashboard)} />
      <div className="mt-6 lg:mt-9">
        <CreateAccountContainer
          servers={servers}
          type={accountType}
          onApply={() => navigate(cabinetRoutes.dashboard)}
        />
      </div>
    </>
  );
};
