import type { CSS } from "@/styles";

type Props = Record<string, CSS>;

export const mixinsCommon: Props = {
  disabled: {
    cursor: "default",
    pointerEvents: "none",
    color: "$textPlaceholder",
    // fill: "$textPlaceholder",

    "&:active": {
      transform: "none",
    },
  },
};
