import { useMemo } from "react";

import type { SurveyField as SurveyFieldType, SurveyPollField } from "@/services/openapi";

const getDefaultValue = (pollValues: string[], pollFields: SurveyPollField[]) => {
  if (pollValues.length > 0) {
    return pollValues[0];
  }
  const defaultPollField = pollFields.find(({ isDefault }) => isDefault);

  if (defaultPollField) {
    return defaultPollField.id!;
  }

  return undefined;
};

export const usePollOptions = ({ pollFields, pollValues }: SurveyFieldType) => {
  const defaultValue = useMemo(() => getDefaultValue(pollValues!, pollFields!), [getDefaultValue]);

  const options = useMemo(
    () => pollFields!.map(({ id: pollId, title: pollTitle }) => ({ value: pollId!, label: pollTitle! })),
    [pollFields],
  );

  return { defaultValue, options };
};
