import { Command as CommandPrimitive } from "cmdk";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { useState } from "react";

import { cn } from "@/shared/styles";

import { InputText } from "../Input/InputText/InputText";
import type { InputTextProps } from "../Input/InputText/InputText.types";
import * as Styled from "./Command.styled";

const Command = forwardRef<ElementRef<typeof CommandPrimitive>, ComponentPropsWithoutRef<typeof CommandPrimitive>>(
  ({ className, ...props }, ref) => <Styled.Button as={CommandPrimitive} ref={ref} className={className} {...props} />,
);
Command.displayName = CommandPrimitive.displayName;

const CommandInput = forwardRef<ElementRef<typeof CommandPrimitive.Input>, InputTextProps>(
  ({ className, ...props }, ref) => {
    const [value, setValue] = useState("");
    return (
      <Styled.InputWrapper cmdk-input-wrapper="">
        <InputText ref={ref} value={value} onChange={setValue} className={className} {...props} />
        <CommandPrimitive.Input ref={ref} value={value} onValueChange={setValue} className="hidden" />
      </Styled.InputWrapper>
    );
  },
);

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = forwardRef<
  ElementRef<typeof CommandPrimitive.List>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <Styled.List as={CommandPrimitive.List} ref={ref} className={className} {...props} />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = forwardRef<
  ElementRef<typeof CommandPrimitive.Empty>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty ref={ref} className="typography-M-Regular p-4 pt-0 text-contrast-secondary" {...props} />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = forwardRef<
  ElementRef<typeof CommandPrimitive.Group>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      `
      [&_[cmdk-group-heading]]:text-muted-foreground
      overflow-hidden
      [&_[cmdk-group-heading]]:px-2
      [&_[cmdk-group-heading]]:py-1.5
      [&_[cmdk-group-heading]]:text-xs
      [&_[cmdk-group-heading]]:font-medium`,
      className,
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandItem = forwardRef<
  ElementRef<typeof CommandPrimitive.Item>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & { selected?: boolean }
>(({ className, ...props }, ref) => (
  <Styled.Item as={CommandPrimitive.Item} ref={ref} className={className} {...props} />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

export { Command, CommandInput, CommandEmpty, CommandList, CommandGroup, CommandItem };
