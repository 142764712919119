import { type FC } from "react";

import { Loader } from "@/shared/ui";

const ChartLoader: FC = () => {
  return (
    <div className="grid h-full place-items-center bg-surface-canvas">
      <Loader />
    </div>
  );
};

export { ChartLoader };
