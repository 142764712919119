import { styled } from "@/styles";

import { StyledTable, Tbody, Thead } from "./styles";

const Scroll = styled("div", {
  width: "100%",
  overflowX: "auto",
  overflowY: "hidden",
});

export type TableBodyExternalProps<T> = {
  items: T[];
  renderBodyRow: (items: T) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
};

export function TableBodyContainer<T>({ items, renderBodyRow, renderHeader }: TableBodyExternalProps<T>) {
  return (
    <Scroll>
      <StyledTable>
        {renderHeader && <Thead>{renderHeader()}</Thead>}
        <Tbody>{items.map(renderBodyRow)}</Tbody>
      </StyledTable>
    </Scroll>
  );
}
