import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { DialogContent } from "@/components/dialog";
import { EmailConfirmDialogContent } from "@/features/dashboard/components/dialogs/email-confirm/email-confirm-dialog-content";
import { DialogName, dialogParamsName, useDialog } from "@/hooks/dialog.hook";
import { Dialog } from "@/shared/ui";
import { profileQueryKeys } from "@/state/server/profile";
import { useConfirmUserEmailMutation } from "@/state/server/profile/profile.mutations";

export const ChangeConfirmEmailDialog = () => {
  const { onClose, open } = useDialog(DialogName.EMAIL_CONFIRM);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isSuccess, mutate, data: newEmail } = useConfirmUserEmailMutation();

  const userId = searchParams.get("userId")!;
  const code = searchParams.get("code")!;
  const dialog = searchParams.get(dialogParamsName)!;

  useEffect(() => {
    if (userId && code && dialog === DialogName.EMAIL_CONFIRM) {
      mutate({ confirmUpdateEmailRequest: { code, userId } });
    }
  }, [userId, code, dialog]);

  const onOpenChange = () => {
    onClose();
    queryClient.invalidateQueries(profileQueryKeys.header());
    navigate(location.pathname);
  };

  if (!isSuccess) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <EmailConfirmDialogContent email={newEmail} />
      </DialogContent>
    </Dialog>
  );
};
