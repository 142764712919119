import Big from "big.js";
import type { UseControllerProps } from "react-hook-form";

import { TerminalDealType } from "@/services/openapi";

import { getTickSizeFromDecimalScale } from "../../helpers/formatting";
import { isBuyOrder } from "../../helpers/orders";

const getModifyOrderRules = ({
  type,
  thresholdValue,
  orderType,
  value,
  required,
}: {
  orderType: TerminalDealType;
  type: "stopLoss" | "takeProfit" | "openingPrice";
  thresholdValue: number;
  value: number | null;
  required?: boolean;
}): UseControllerProps["rules"] => {
  if (value === 0) {
    return { validate: () => false, required };
  }

  if (type === "stopLoss") {
    if (value === null) {
      return { required, validate: () => true };
    }
    return !isBuyOrder(orderType)
      ? { min: thresholdValue, required, validate: () => true }
      : { max: thresholdValue, required, validate: () => true };
  }

  if (type === "takeProfit") {
    if (value === null) {
      return { required, validate: () => true };
    }
    return isBuyOrder(orderType)
      ? { min: thresholdValue, required, validate: () => true }
      : { max: thresholdValue, required, validate: () => true };
  }

  return orderType === TerminalDealType.BuyStop || orderType === TerminalDealType.SellLimit
    ? { min: thresholdValue, required, validate: () => true }
    : { max: thresholdValue, required, validate: () => true };
};

const getTakeProfitThresholdValue = ({
  ask,
  bid,
  orderType,
  openPrice,
  priceDecimalScale,
}: {
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  priceDecimalScale: number;
  openPrice: number | null;
}) => {
  if (orderType === TerminalDealType.Buy) {
    return new Big(ask).minus(getTickSizeFromDecimalScale(priceDecimalScale)).toNumber();
  }
  if (orderType === TerminalDealType.Sell) {
    return new Big(bid).plus(getTickSizeFromDecimalScale(priceDecimalScale)).toNumber();
  }

  const bidAskDiff = new Big(ask).minus(bid);
  if (!openPrice) {
    const bidAskDiffHalf = bidAskDiff.div(2);
    switch (orderType) {
      case TerminalDealType.BuyStop:
        return new Big(ask).plus(bidAskDiffHalf).toNumber();
      case TerminalDealType.BuyLimit:
        return new Big(ask).plus(bidAskDiffHalf).toNumber();
      case TerminalDealType.SellStop:
        return new Big(bid).minus(bidAskDiffHalf).toNumber();
      case TerminalDealType.SellLimit:
        return new Big(bid).minus(bidAskDiffHalf).toNumber();
      default:
        return 0;
    }
  }
  const diffWithTick = bidAskDiff.minus(getTickSizeFromDecimalScale(priceDecimalScale));
  switch (orderType) {
    case TerminalDealType.BuyStop:
      return new Big(openPrice).plus(diffWithTick).toNumber();
    case TerminalDealType.BuyLimit:
      return new Big(openPrice).plus(diffWithTick).toNumber();
    case TerminalDealType.SellStop:
      return new Big(openPrice).minus(diffWithTick).toNumber();
    case TerminalDealType.SellLimit:
      return new Big(openPrice).minus(diffWithTick).toNumber();
    default:
      return 0;
  }
};

const getStopLossThresholdValue = ({
  ask,
  bid,
  orderType,
  openPrice,
  priceDecimalScale,
  takeProfitThresholdValue,
}: {
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  priceDecimalScale: number;
  takeProfitThresholdValue: number;
  openPrice: number | null;
}) => {
  if (orderType === TerminalDealType.Buy) {
    return new Big(bid).mul(2).minus(takeProfitThresholdValue).toNumber();
  }
  if (orderType === TerminalDealType.Sell) {
    return new Big(ask).mul(2).minus(takeProfitThresholdValue).toNumber();
  }

  const bidAskDiff = new Big(ask).minus(bid);
  if (!openPrice) {
    const bidAskDiffHalf = bidAskDiff.div(2);
    switch (orderType) {
      case TerminalDealType.BuyStop:
        return new Big(bid).minus(bidAskDiffHalf).toNumber();
      case TerminalDealType.BuyLimit:
        return new Big(bid).minus(bidAskDiffHalf).toNumber();
      case TerminalDealType.SellStop:
        return new Big(ask).plus(bidAskDiffHalf).toNumber();
      case TerminalDealType.SellLimit:
        return new Big(ask).plus(bidAskDiffHalf).toNumber();
      default:
        return 0;
    }
  }
  const diffWithTick = bidAskDiff.minus(getTickSizeFromDecimalScale(priceDecimalScale));
  switch (orderType) {
    case TerminalDealType.BuyStop:
      return new Big(openPrice).minus(diffWithTick).toNumber();
    case TerminalDealType.BuyLimit:
      return new Big(openPrice).minus(diffWithTick).toNumber();
    case TerminalDealType.SellStop:
      return new Big(openPrice).plus(diffWithTick).toNumber();
    case TerminalDealType.SellLimit:
      return new Big(openPrice).plus(diffWithTick).toNumber();
    default:
      return 0;
  }
};

const getOpeningPriceThresholdValue = ({
  ask,
  bid,
  orderType,
}: {
  ask: number;
  bid: number;
  orderType: TerminalDealType;
}) => {
  switch (orderType) {
    case TerminalDealType.BuyStop:
      return new Big(ask).minus(bid).plus(ask).toNumber();
    case TerminalDealType.BuyLimit:
      return bid;
    case TerminalDealType.SellStop:
      return new Big(ask).minus(bid).minus(bid).abs().toNumber();
    case TerminalDealType.SellLimit:
      return ask;
    default:
      return 0;
  }
};

export { getOpeningPriceThresholdValue, getStopLossThresholdValue, getTakeProfitThresholdValue, getModifyOrderRules };
