import { type FC, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { useTranslation } from "@/hooks/translator.hook";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { Dialog, NewButton } from "@/shared/ui";

import { TradeButtonDialogContent } from "./trade-button-dialog-content";

export type TradeButtonLinkItemType = {
  serverTerminalUrl: string;
  serverTerminalIOsUrl: string;
  serverTerminalAndroidUrl: string;
  serverTerminalMacOsUrl: string;
  serverTerminalLinuxOsUrl: string;
  platformType: TradingServerPlatform;
};

type Props = {
  account: TradingAccount;
};

export const accountSearchParamsName = "account";

export const TradeButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const onOpen = useCallback(() => {
    setSearchParams(prevParams => {
      prevParams.set(accountSearchParamsName, account.id!);
      return prevParams;
    });
  }, [setSearchParams, account.id]);

  const onClose = useCallback(() => {
    setSearchParams(prevParams => {
      prevParams.delete(accountSearchParamsName);
      return prevParams;
    });
  }, [setSearchParams]);

  if (account.platform === TradingServerPlatform.MetaTrader5) {
    return (
      <NewButton color="accent" variant="primary" fullWidth size="sm" asChild data-test="account-trade-btn">
        <Link to={getTerminalRoute(account.id!)}>{t("button.trade")}</Link>
      </NewButton>
    );
  }

  return (
    <Dialog
      open={searchParams.get(accountSearchParamsName) === account.id}
      onOpenChange={flag => {
        if (flag) {
          onOpen();
        } else {
          onClose();
        }
      }}
    >
      <Dialog.Trigger asChild>
        <NewButton color="accent" variant="primary" fullWidth size="sm" data-test="account-trade-btn">
          {t("button.trade")}
        </NewButton>
      </Dialog.Trigger>

      <TradeButtonDialogContent account={account} />
    </Dialog>
  );
};
