import { type FC } from "react";

import { Popover, PopoverTrigger } from "@/components/popover";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button } from "@/shared/ui/button";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

type Props = {
  accountId: string;
  ordersCount: number;
};

const CloseAllPendingOrdersButton: FC<Props> = ({ accountId, ordersCount }) => {
  const { t } = useTranslation();

  const [open, { onOpenChange, close }] = useDisclosure();

  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const closeOrders = () => {
    mutate(
      { tradingAccountId: accountId, terminalCloseOrderRequest: { type: TerminalCloseOrderType.Limit } },
      {
        onSuccess: () => {
          close();
        },
      },
    );
  };

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="flat" size="sm">
          {t("terminal.orders.delete-all")}
        </Button>
      </PopoverTrigger>
      <TerminalPopover title={t("terminal.orders.delete-count", { value: ordersCount })}>
        <TerminalPopover.Buttons>
          <Button pending={isLoading} onClick={closeOrders}>
            {t("terminal.orders.delete-all")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalPopover>
    </Popover>
  );
};

export { CloseAllPendingOrdersButton };
