import { hideBanner } from "@/services/banners";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseMutation } from "..";
import type { MutationOptionsType } from "../types";

const useHideBannerMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof hideBanner>, ApiRequestParamsType<typeof hideBanner>>,
) => {
  return useBaseMutation<ApiResponseType<typeof hideBanner>, ApiRequestParamsType<typeof hideBanner>>(
    hideBanner,
    options,
  );
};

export { useHideBannerMutation };
