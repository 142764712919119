import React from "react";

export const GoogleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8054 8.04152H18.9999V8.00002H9.99991V12H15.6514C14.8269 14.3285 12.6114 16 9.99991 16C6.68641 16 3.99991 13.3135 3.99991 10C3.99991 6.68652 6.68641 4.00002 9.99991 4.00002C11.5294 4.00002 12.9209 4.57701 13.9804 5.51952L16.8089 2.69102C15.0229 1.02652 12.6339 1.52588e-05 9.99991 1.52588e-05C4.47741 1.52588e-05 -9.15527e-05 4.47752 -9.15527e-05 10C-9.15527e-05 15.5225 4.47741 20 9.99991 20C15.5224 20 19.9999 15.5225 19.9999 10C19.9999 9.32952 19.9309 8.67502 19.8054 8.04152Z"
      fill="#FFC107"
    />
    <path
      d="M1.15305 5.34552L4.43855 7.75502C5.32755 5.55402 7.48055 4.00002 10 4.00002C11.5295 4.00002 12.921 4.57701 13.9805 5.51952L16.809 2.69102C15.023 1.02652 12.634 1.52588e-05 10 1.52588e-05C6.15905 1.52588e-05 2.82805 2.16852 1.15305 5.34552Z"
      fill="#FF3D00"
    />
    <path
      d="M9.99997 20C12.583 20 14.93 19.0115 16.7045 17.404L13.6095 14.785C12.6055 15.5455 11.3575 16 9.99997 16C7.39897 16 5.19047 14.3415 4.35847 12.027L1.09747 14.5395C2.75247 17.778 6.11347 20 9.99997 20Z"
      fill="#4CAF50"
    />
    <path
      d="M19.8055 8.0415H19V8H10V12H15.6515C15.2555 13.1185 14.536 14.083 13.608 14.7855C13.6085 14.785 13.609 14.785 13.6095 14.7845L16.7045 17.4035C16.4855 17.6025 20 15 20 10C20 9.3295 19.931 8.675 19.8055 8.0415Z"
      fill="#1976D2"
    />
  </svg>
);
