import { type FC } from "react";

import { AccountCard } from "@/entities/accounts/card";
import { RealAccountCard } from "@/entities/accounts/real/card";
import { AvailabilityStatus, type BonusUserPlatform, TradingAccountType } from "@/services/openapi";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { CreateAccountButton } from "../../components/create-account/create-account-button";
import { AccountsCardsWrapper } from "../cards-wrapper";
import { RealAccountsEmptyState } from "./empty-state";

type Props = {
  bonus: BonusUserPlatform | null;
  onCreateAccount: (accountType: TradingAccountType) => void;
};

const RealCardsContainer: FC<Props> = ({ bonus, onCreateAccount }) => {
  const { data, isFetching } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    status: AvailabilityStatus.Active,
  });

  return (
    <AccountsCardsWrapper
      cards={data}
      skeleton={<AccountCard.Skeleton rows={5} />}
      emptyState={<RealAccountsEmptyState onCreateAccount={onCreateAccount} />}
      isFetching={isFetching}
      button={<CreateAccountButton secondary type={TradingAccountType.Real} onClick={onCreateAccount} />}
    >
      {card => <RealAccountCard key={card.id} bonus={bonus} account={card} />}
    </AccountsCardsWrapper>
  );
};

export { RealCardsContainer };
