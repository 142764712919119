import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { NewDialogContent } from "@/components/new-dialog";
import type { TradingAccount } from "@/services/openapi";

import { ArchiveAccountContainer } from "./archive-account.container";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

const ArchiveAccountDialog: FC<Props> = ({ account, onClose }) => {
  const { t } = useTranslation();

  return (
    <NewDialogContent
      title={t("accounts.archive.dialog-title")!}
      description={t("accounts.archive.dialog-description")}
    >
      <ArchiveAccountContainer account={account} onClose={onClose} />
    </NewDialogContent>
  );
};

export { ArchiveAccountDialog };
