import { type FC, type ReactNode } from "react";

import { SidebarButton } from "@/components/sidebar";
import { cn } from "@/shared/styles";

type Props = {
  isMobileSymbolPage: boolean;
  showFullscreenChart?: boolean;
  // slots
  symbolPageSlot: ReactNode;
  chartSymbolsSlot: ReactNode;
  accountSelectSlot: ReactNode;
  depositButtonSlot: ReactNode;
  chartSlot: ReactNode;
  placeOrderSlot: ReactNode;
  tablesSlot: ReactNode;
};

const TerminalMobileFrame: FC<Props> = ({
  showFullscreenChart,
  isMobileSymbolPage,
  symbolPageSlot,
  chartSymbolsSlot,
  accountSelectSlot,
  depositButtonSlot,
  chartSlot,
  placeOrderSlot,
  tablesSlot,
}) => {
  if (showFullscreenChart) {
    return chartSlot as JSX.Element;
  }

  return (
    <div className="flex h-full flex-col bg-surface-canvas">
      {isMobileSymbolPage ? (
        symbolPageSlot
      ) : (
        <>
          <div className="sticky top-0 z-10 flex items-center justify-between gap-2 px-4 pt-3 backdrop-blur">
            <div className="flex gap-2">
              <div className="mt-2">
                <SidebarButton />
              </div>
              {accountSelectSlot}
            </div>
            {depositButtonSlot}
          </div>
          <div className="bg-surface-canvas px-4 py-2">{chartSymbolsSlot}</div>
        </>
      )}
      <div className={cn("mb-4 h-80 shrink-0", isMobileSymbolPage && "mt-2")}>{chartSlot}</div>
      <div className="bg-surface-canvas">{placeOrderSlot}</div>
      {!isMobileSymbolPage && <div className="sticky bottom-0">{tablesSlot}</div>}
    </div>
  );
};

export { TerminalMobileFrame };
