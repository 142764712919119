import type { FC } from "react";

import { IconArrowRight } from "@/domains/icons";
import { TradingCentralSignalTrend } from "@/services/openapi";
import { cn } from "@/shared/styles";

type Props = {
  trend: TradingCentralSignalTrend;
};

const SentimentIcon: FC<Props> = ({ trend }) => (
  <div
    className={cn("grid size-14 shrink-0 place-items-center rounded-[32px]", {
      "bg-negative-bg/20 text-negative-text": trend === TradingCentralSignalTrend.Bearish,
      "bg-positive-bg/20 text-positive-text": trend === TradingCentralSignalTrend.Bullish,
      "bg-constant-label/20 text-constant-label": trend === TradingCentralSignalTrend.Neutral,
    })}
  >
    <IconArrowRight
      className={cn("size-8", {
        "rotate-90": trend === TradingCentralSignalTrend.Bearish,
        "-rotate-90": trend === TradingCentralSignalTrend.Bullish,
      })}
    />
  </div>
);

export { SentimentIcon };
