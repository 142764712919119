import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { SubmitButton } from "@/app/form";

import { usePlaceOrderContext } from "../context";

type Props = {
  ask: ReturnType<typeof usePlaceOrderContext>["ask"];
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  priceDecimalScale: number;
  isDemoAccount: boolean;
};

const PlaceOrderSignalSubmitButton: FC<Props> = ({
  hasNoFreeMargin,
  isBuyOrder,
  isDemoAccount,
  ask,
  bid,
  priceDecimalScale,
}) => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    if (hasNoFreeMargin) {
      if (isDemoAccount) {
        return t("terminal.place-order.submit-button.deposit-demo");
      }
      return t("terminal.place-order.submit-button.deposit-real");
    }
    if (isBuyOrder) {
      return t("terminal.place-order.submit-button.signal-buy");
    }
    return t("terminal.place-order.submit-button.signal-sell");
  }, [hasNoFreeMargin, isBuyOrder, isDemoAccount, t]);

  return (
    <SubmitButton
      variant={hasNoFreeMargin ? "secondary" : isBuyOrder ? "primary" : "danger"}
      className="w-full flex-col gap-0"
    >
      {hasNoFreeMargin ? (
        label
      ) : (
        <>
          <div className="font-gilroy text-[14px] font-bold leading-normal">{label}</div>
          <div className="font-roboto text-[14px] font-normal leading-[1.2]">
            <NumberFormat value={isBuyOrder ? ask : bid} decimalScale={priceDecimalScale} />
          </div>
        </>
      )}
    </SubmitButton>
  );
};

export { PlaceOrderSignalSubmitButton };
