import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { TerminalPopover } from "@/features/terminal/components/popover";

type Props = {
  currency: string;
  currencyDecimalScale: number;
  pnl: number;
  swaps: number;
  ordersCount: number;
};

const CloseAllOpenOrdersDataList: FC<Props> = ({ currency, currencyDecimalScale, ordersCount, pnl, swaps }) => {
  const { t } = useTranslation();

  return (
    <TerminalPopover.List>
      <TerminalPopover.ListItem value={ordersCount} label={t("terminal.total-trades")} />
      <TerminalPopover.ListItem
        value={<NumberFormat value={swaps} decimalScale={currencyDecimalScale} />}
        label={t("terminal.total-swap", { currency })}
      />
      <TerminalPopover.ListItem
        valueClassName={getNumberColorClassname(pnl)}
        value={<NumberFormat value={pnl} decimalScale={currencyDecimalScale} />}
        label={t("terminal.total-pnl-currency", { currency })}
      />
    </TerminalPopover.List>
  );
};

export { CloseAllOpenOrdersDataList };
