import { type ElementRef, type FC, type ReactNode, useRef, useState } from "react";

import { IconChevronUp } from "@/domains/icons";

type Props = {
  children: ReactNode;
};

const WidgetScrollButton: FC<Props> = ({ children }) => {
  const scrollElement = useRef<ElementRef<"div">>(null);

  const [scrollTop, setScrollTop] = useState(0);

  const showScrollButton = scrollTop > 500;

  return (
    <>
      <div className="absolute bottom-4 end-2">
        {showScrollButton && (
          <button
            onClick={() => {
              scrollElement.current!.scrollTo({ top: 0, behavior: "smooth" });
            }}
            type="button"
            className="grid size-12 place-items-center rounded-full border border-control-border bg-control-bg text-contrast-primary outline-none transition-colors animate-in fade-in-0 hover:bg-control-bg-hover"
          >
            <IconChevronUp />
          </button>
        )}
      </div>
      <div
        className="overflow-auto scrollbar-hide"
        ref={scrollElement}
        onScroll={() => setScrollTop(scrollElement.current!.scrollTop)}
      >
        {children}
      </div>
    </>
  );
};

export { WidgetScrollButton };
