import { useStore } from "@nanostores/react";
import type { FC } from "react";
import { useEffect, useState } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { $firebaseNotification } from "@/app/libs/firebase/store";
import { useScreenSize } from "@/hooks/screen-size.hook";

import { DemoToRealBottomSheet } from "./bottom-sheet/demo-to-real-bottom-sheet";
import { DemoToRealDialog } from "./dialog/demo-to-real-dialog";

export const DemoToRealContainer: FC = () => {
  const { isMobile } = useScreenSize();
  const notificationContent = useStore($firebaseNotification);
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
    $firebaseNotification.set(undefined);
  };

  const onButtonClick = () => {
    track(amplitudeEvents.fromDemoToReal.toReal);
    $firebaseNotification.set(undefined);
  };

  useEffect(() => {
    if (notificationContent) setOpen(true);
  }, [notificationContent]);

  useEffect(() => {
    if (open) {
      track(amplitudeEvents.fromDemoToReal.show);
    }
  }, [open]);

  if (!notificationContent) return null;

  return (
    <>
      {!isMobile && (
        <DemoToRealDialog content={notificationContent} open={open} onClose={onClose} onButtonClick={onButtonClick} />
      )}
      {isMobile && (
        <DemoToRealBottomSheet
          content={notificationContent}
          open={open}
          onClose={onClose}
          onButtonClick={onButtonClick}
        />
      )}
    </>
  );
};
