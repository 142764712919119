import { QRCodeSVG } from "qrcode.react";
import type { FC } from "react";
import { useCallback } from "react";

import { CopyButton } from "@/components/copy-button";
import { Flex } from "@/components/flex";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import type { TwoFactor, TwoFactorRecoveryCode } from "@/services/openapi";
import { useConfirmTwoFAMutation } from "@/state/server/auth";

import { TwoFASteps } from "../two-factor.container";
import { ConfirmTwoFaForm, type IConfirmTwoFaFormValues } from "./confirm-two-fa.form";

type Props = {
  setStep: (step: TwoFASteps) => void;
  twoFactorData: TwoFactor;
  setRecoveryCodes: (codes: TwoFactorRecoveryCode[]) => void;
};

export const ConfirmTwoFAContainer: FC<Props> = ({ setStep, twoFactorData, setRecoveryCodes }) => {
  const { t } = useTranslation();

  const { mutateAsync: confirmTwoFa } = useConfirmTwoFAMutation({ successMessage: "two-factor-enabled" });

  const handleSubmit = useCallback(
    (values: IConfirmTwoFaFormValues) => {
      return confirmTwoFa(
        { twoFactorConfirm: { ...values, sharedKey: twoFactorData.sharedKey } },
        {
          onSuccess: res => {
            setRecoveryCodes(res.codes!);
            setStep(TwoFASteps.RECOVERY_CODES);
          },
        },
      );
    },
    [twoFactorData, setStep, setRecoveryCodes, confirmTwoFa],
  );

  return (
    <>
      <Stack css={{ mb: "32px" }}>
        <Text family="roboto">{t("two-factor.settings.scan-qr")}</Text>
        <QRCodeSVG value={twoFactorData.authenticatorUri!} size={250} />
        <Flex css={{ gap: "16px", alignItems: "center", flexWrap: "wrap" }}>
          <Text weight="bold" size="4">
            {twoFactorData.sharedKey}
          </Text>
          <CopyButton copyValue={twoFactorData.sharedKey!} />
        </Flex>
      </Stack>
      <ConfirmTwoFaForm onSubmit={handleSubmit} />
    </>
  );
};
