import type { FC } from "react";

import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";

import { LanguageSwitcherContainer } from "./container";

type Props = {
  onOpen: () => void;
};

export const LanguageSwitcherButton: FC<Props> = ({ onOpen }) => {
  return (
    <LanguageSwitcherContainer>
      {({ icon, name }) => (
        <button
          type="button"
          className={cn(
            "flex shrink-0 items-center gap-2.5 whitespace-nowrap rounded-[40px] border-none bg-none px-3 py-5 text-black transition-all",
          )}
          onClick={onOpen}
          data-test="language-switcher-button"
        >
          <img className="size-6 rounded-full" src={icon!} alt={name!} />
          <Text variant="S / Regular">{name}</Text>
        </button>
      )}
    </LanguageSwitcherContainer>
  );
};
