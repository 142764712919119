import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

type IconState = {
  IconCross: () => JSX.Element | null;
  IconChevronDown: () => JSX.Element | null;
  IconChevronUp: () => JSX.Element | null;
  IconTick: () => JSX.Element | null;
};

export const IconContext = createContext<IconState>({
  IconCross: () => null,
  IconChevronDown: () => null,
  IconChevronUp: () => null,
  IconTick: () => null,
});

export const IconProvider = ({ icons, children }: PropsWithChildren<{ icons: IconState }>) => {
  return <IconContext.Provider value={icons}>{children}</IconContext.Provider>;
};

export const useIcons = () => {
  return useContext(IconContext);
};
