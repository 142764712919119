import { getRate } from "@/services/rate/rate.service";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { useBaseQuery } from "@/state/server";
import { rateQueryKeys } from "@/state/server/rate/rate.keys";
import type { QueryOptionsType } from "@/state/server/types";

export const useRateQuery = (
  filters: ApiRequestParamsType<typeof getRate>,
  options?: QueryOptionsType<ApiResponseType<typeof getRate>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getRate>>({
    queryKey: rateQueryKeys.base(filters),
    queryFn: () => getRate(filters),
    refetchOnWindowFocus: true,
    ...options,
  });
};
