import { cva } from "class-variance-authority";
import type { FC } from "react";
import { Trans } from "react-i18next";

import type { OnboardingStep } from "@/features/onboarding/helpers";
import { Text } from "@/shared/ui";

const stepStyles = cva("h-full transition-all", {
  variants: {
    state: {
      default: "bg-contrast-quaternary",
      filled: "bg-surface-elevation-3",
      transparent: "bg-transparent",
    },
  },
  defaultVariants: {
    state: "default",
  },
});

type Props = {
  steps: OnboardingStep[];
  currentStepId: string;
};

const ProgressBar: FC<Props> = ({ currentStepId, steps }) => {
  const stepsAmount = steps.length;
  const currentStepIndex = steps.findIndex(step => step.id === currentStepId);

  return (
    <div className="sticky top-[100px] z-10 bg-surface-elevation-1 py-2">
      <Text variant="S / Medium">
        <Trans
          i18nKey="onboarding.progress-bar"
          values={{
            stepCount: currentStepIndex + 1,
            stepsAmount,
          }}
        />
      </Text>

      <div className="relative mt-2 h-[4px] w-full overflow-hidden rounded-full bg-contrast-quaternary">
        {steps.map((step, index) => {
          const isCurrent = step.id === currentStepId;
          const isFilled = step.filled;
          let stepClass = "";

          if (index < currentStepIndex && step.filled) {
            stepClass = stepStyles({ state: "filled" });
          } else if (isCurrent) {
            stepClass = stepStyles({
              state: isFilled ? "filled" : "default",
            });
          } else if (index === currentStepIndex + 1) {
            stepClass = stepStyles({ state: "transparent" });
          }

          if (stepClass) {
            return (
              <div
                key={step.id}
                className={`${stepClass} absolute left-0 top-0`}
                style={{
                  width: `${((index + 1) / stepsAmount) * 100}%`,
                }}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

export { ProgressBar };
