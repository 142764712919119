import { type FC } from "react";

import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { Label } from "@/shared/ui";

import { AccountBadge } from "../badge";
import { AccountCard } from "../card";
import { RestoreButton } from "./restore-button";

type Props = {
  account: TradingAccount;
};

const ArchivedAccountCard: FC<Props> = ({ account }) => {
  const { platformTitle, type, id, actions, platform } = account;

  return (
    <AccountCard>
      <AccountCard.Header account={account} showSettingsButton={false} />
      <AccountCard.Badges>
        <AccountBadge type={type!} />
        {platform! !== TradingServerPlatform.MetaTrader5 ? (
          <Label>{platformTitle}</Label>
        ) : (
          <>
            <Label>Doto</Label>
            <Label>{platformTitle}</Label>
          </>
        )}
      </AccountCard.Badges>
      {actions!.canRestore && (
        <AccountCard.Footer>
          <RestoreButton accountId={id!} />
        </AccountCard.Footer>
      )}
    </AccountCard>
  );
};

export { ArchivedAccountCard };
